import React from 'react';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <div>
      <section className="sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-118px)] bg-zinc-50 dark:bg-gray-900 font-dmSans pt-20">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-sm mx-auto text-center">
            <h1 className="mb-4 tracking-tight text-transparent text-7xl lg:text-9xl wavy bg-clip-text">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight text-gray-900 md:text-4xl dark:text-white">
              Something's missing.
            </p>
            <p className="mb-4 text-lg text-gray-500 dark:text-gray-400">
              Sorry, we can't find that page. Try one of these links instead:{' '}
            </p>
            <div className="space-x-4">
              {' '}
              {/* Container for buttons */}
              <Link
                to="/"
                className="inline-flex text-white bg-zinc-900 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:focus:ring-indigo-900 my-4"
              >
                Back to Homepage
              </Link>
              <Link
                to="/about"
                className="inline-flex text-zinc-800 border focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded text-sm px-5 py-2.5 text-center dark:focus:ring-gray-900 my-4 border-zinc-800"
              >
                About
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Page404;
