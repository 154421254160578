import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/24/outline';

import {
  addEnterpriseEarlyAccess,
  getWhitelist,
  removeEnterpriseEarlyAccess,
} from '../../utils/userUtils';

const AddToWhitelistModal = ({ setIsOpen, isOpen }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const [showWhiteList, setShowWhiteList] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setEmail('');
      setError('');
      setLoading(false);
      setWhitelist([]);
      setShowWhiteList(false);
    }
  }, [isOpen]);

  function closeModal() {
    setIsOpen(false);
  }

  const submitEmail = async (e) => {
    e.preventDefault();
    const feature = 'enterprise_early_access';

    if (!email.match(/^[a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      setError('Please enter a valid email address or domain');
      return;
    }

    try {
      const res = await addEnterpriseEarlyAccess({
        email,
        feature,
      });
      if (res.status === 201) {
        toast.success('Email added to whitelist');
        setWhitelist((prev) => [...prev, email]);
        setEmail('');
      }
      if (res.status === 409) {
        setError('Email already added to whitelist');
        throw new Error('Email already added to whitelist');
      }
    } catch (error) {
      toast.error('Failed to add email to whitelist');
    }
  };

  const hadleTextChange = (e) => {
    setEmail(e.target.value);
    setError('');
  };

  const getList = async () => {
    setLoading(true);
    try {
      const list = await getWhitelist();

      setWhitelist(list);
    } catch (error) {
      toast.error('Failed to get whitelist');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (showWhiteList) {
      getList();
    }
  }, [showWhiteList]);

  const handleRemoveFromWhitelist = async (e) => {
    const email = e.target.parentElement.innerText.trim();
    setLoading(true);
    try {
      const res = await removeEnterpriseEarlyAccess({
        email,
        feature: 'enterprise_early_access',
      });
      if (res.status === 204) {
        toast.success('Email removed from whitelist');
        setWhitelist((prev) => prev.filter((e) => e !== email));
      }
    } catch (error) {
      toast.error('Failed to remove email from whitelist');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 font-spaceG"
                >
                  Add email or domain to whitelist
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This will allow access to the enterprise features for the
                    email or domain you add. Type a full email or a domain name
                    starting with "@" (i.e @tromero.ai)
                  </p>
                </div>
                <form className="mt-4" onSubmit={submitEmail}>
                  <div className="relative">
                    <label
                      htmlFor="emails"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email Address
                    </label>
                    <input
                      type="text"
                      name="emails"
                      id="emails"
                      className={`block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${error ? 'border-red-600' : null}`}
                      placeholder="Enter an email or domain"
                      value={email}
                      onChange={hadleTextChange}
                    />
                    {error && (
                      <div className="absolute left-0 w-full text-xs italic text-right text-red-500 -bottom-4">
                        {error}
                      </div>
                    )}
                  </div>
                  {showWhiteList && (
                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-700">
                        Whitelisted emails and domains ({whitelist.length})
                      </p>
                      <ul className="mt-2 overflow-y-scroll text-sm text-gray-500 max-h-36">
                        {loading ? (
                          <li>Loading...</li>
                        ) : whitelist.length ? (
                          whitelist.map((email, index) => (
                            <li
                              key={index}
                              className="flex items-center gap-4 my-1 group/item"
                            >
                              {email}{' '}
                              <TrashIcon
                                className="invisible w-5 h-5 cursor-pointer group-hover/item:visible active:text-zinc-800"
                                onClick={handleRemoveFromWhitelist}
                              />
                            </li>
                          ))
                        ) : (
                          <li>No emails or domains added to whitelist</li>
                        )}
                      </ul>
                    </div>
                  )}
                  <div className="flex items-center justify-end mt-6">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 "
                      onClick={() => setShowWhiteList(!showWhiteList)}
                    >
                      {showWhiteList ? 'Hide' : 'Show'} list
                    </button>
                    <div className="grow"></div>
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    >
                      Add to whitelist
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddToWhitelistModal;
