import { useEffect, useState } from 'react';
import {
  TrashIcon,
  PlusIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Modal, Label, Tooltip } from 'flowbite-react';
import toast from 'react-hot-toast';

import Spinner from '../Spinner';
import { useUser } from '../../UserContext';

const DeleteApiKeyModal = ({ show, onClose, keyToDelete }) => {
  const { customAxios } = useUser();
  const handleDeleteKey = async () => {
    try {
      const response = await customAxios.delete(
        `tailor/v1/keys/${keyToDelete.id}`,
      );
      if (response.status === 200) {
        toast.success('API key deleted successfully');
        onClose();
      } else {
        toast.error('Error deleting API key, please try again later');
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error deleting API key, please try again later');
    }
  };

  return (
    <Modal show={show} onClose={onClose} title="Delete API key" dismissible>
      <div className="p-6">
        <div className="text-lg font-semibold text-zinc-800">
          Revoke secret key
        </div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            This API key will immediately be disabled. API requests made using
            this key will be rejected, which could cause any systems still
            depending on it to break. Once revoked, you'll no longer be able to
            view or modify this API key.
          </p>
          <div className="w-full p-2 my-4 font-mono border rounded-md text-zinc-800">
            {keyToDelete?.api_key}
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400"
            onClick={handleDeleteKey}
          >
            Revoke Key
          </button>
        </div>
      </div>
    </Modal>
  );
};

const CreateApiKeyModal = ({ show, onClose }) => {
  const [apiCreated, setApiCreated] = useState(false);
  const [newKey, setNewKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState('');
  const { customAxios } = useUser();

  const handleGenerateKey = async () => {
    setLoading(true);
    try {
      const nameToUse = name || 'Secret API Key';
      const response = await customAxios.post('tailor/v1/keys', {
        api_key_name: nameToUse,
      });

      setNewKey(response.data);
      setApiCreated(true);
    } catch (error) {
      toast.error('Error generating new API key, please try again later');
    } finally {
      setLoading(false);
    }
  };

  const sanitizeInput = (input) => {
    let sanitized = input.replace(/[^a-zA-Z0-9]/g, '');
    sanitized = sanitized.replace(/^[0-9]/, '');
    return sanitized;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newKey.message);
    setCopied(true);
    toast.success('API key copied to clipboard');
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  const handleCloseModal = () => {
    setNewKey(null);
    setApiCreated(false);
    setCopied(false);
    setName('');
    onClose();
  };

  return (
    <Modal
      show={show}
      onClose={handleCloseModal}
      title={apiCreated ? 'Save your key' : 'Generate new API key'}
      dismissible
      popup
      className="relative"
    >
      <Modal.Body>
        <div className="pt-4">
          <button
            onClick={handleCloseModal}
            className="absolute top-2 right-3 md:hidden"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
          <div className="text-lg font-semibold text-zinc-800">
            {apiCreated ? 'Save your key' : 'Generate new API key'}
          </div>
          <div className="mt-4 text-sm text-zinc-700">
            <p>
              {apiCreated ? (
                <span>
                  Please save this secret key somewhere safe and accessible. For
                  security reasons, <b>you won't be able to view it again </b>
                  through your account. If you lose this secret key, you'll need
                  to generate a new one.
                </span>
              ) : (
                <span>
                  You can generate a new API key to use with the Tailor API.
                  This key will give you access to your model's API, and should
                  be kept secret.
                </span>
              )}
            </p>
          </div>
          {!newKey && (
            <>
              <Label className="block mt-4 text-sm text-zinc-800">
                Name your key <span className="text-zinc-500">(optional)</span>
              </Label>
              <input
                className="w-full p-2 mt-1 border rounded-md border-zinc-300"
                placeholder="Name your key"
                value={name}
                onChange={(e) => setName(sanitizeInput(e.target.value))}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGenerateKey();
                  }
                }}
              />
              <div className="mt-8">
                <button
                  className="flex items-center justify-around gap-1 text-sm bg-indigo-200 rounded-md shadow h-9 w-44 text-zinc-900 hover:bg-indigo-100"
                  onClick={handleGenerateKey}
                  disabled={loading}
                >
                  {loading ? <Spinner /> : 'Generate new API key'}
                </button>
              </div>
            </>
          )}
          {newKey && (
            <div className="mt-8">
              <div className="text-sm text-zinc-800">Your new API key:</div>
              <div className="flex items-center gap-1 mt-2">
                <div className="w-9/12 p-2 font-mono break-words border rounded-l-md border-zinc-30 grow text-wrap md:w-full">
                  {newKey.message}
                </div>
                <Tooltip content="Copy to clipboard" position="top">
                  <button
                    className="flex items-center justify-around w-16 h-16 gap-1 p-2 px-4 md:w-32 md:h-auto text-zinc-700 hover:text-zinc-900 rounded-r-md bg-zinc-200 hover:bg-zinc-300 active:bg-zinc-400"
                    onClick={handleCopyToClipboard}
                  >
                    {copied ? (
                      <>
                        <ClipboardDocumentCheckIcon className="w-6 h-6 md:w-5 md:h-5" />
                        <span className="hidden md:inline-block">Copied</span>
                      </>
                    ) : (
                      <>
                        <ClipboardDocumentListIcon className="w-6 h-6 md:w-5 md:h-5" />
                        <span className="hidden md:inline-block">Copy</span>
                      </>
                    )}
                  </button>
                </Tooltip>
              </div>
              <div className="mt-10">
                <button
                  className="flex items-center gap-1 p-2 px-3 text-sm bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
                  onClick={handleCloseModal}
                >
                  Done
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const TailorApiKeys = () => {
  const [apiKeys, setApiKeys] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddApiKeyModal, setShowAddApiKeyModal] = useState(false);
  const [showDeleteApiKeyModal, setShowDeleteApiKeyModal] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const { customAxios, user } = useUser();

  useEffect(() => {
    const fetchApiKeys = async () => {
      if (!user?.confirmed) {
        toast.error(
          'Please confirm your email address to access this feature.',
        );
        return;
      }
      try {
        const response = await customAxios.get('tailor/v1/keys');
        setApiKeys(response.data.message);
      } catch (error) {
        setError(error);
        if (import.meta.env.DEV) {
          console.error('Error fetching api keys:', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchApiKeys();
  }, [
    customAxios,
    showAddApiKeyModal,
    showDeleteApiKeyModal,
    showAddApiKeyModal,
    user.confirmed,
  ]);

  const handleDeleteKey = async (key) => {
    setKeyToDelete(key);
    setShowDeleteApiKeyModal(true);
  };

  const handleShowAddApiKeyModal = () => {
    if (!user?.confirmed) {
      toast.error('Please confirm your email address to access to continue');
    } else {
      setShowAddApiKeyModal(true);
    }
  };

  if (error) {
    return <div>Error fetching api keys: {error}</div>;
  }

  return (
    <div>
      <CreateApiKeyModal
        show={showAddApiKeyModal}
        onClose={() => setShowAddApiKeyModal(false)}
      />
      <DeleteApiKeyModal
        show={showDeleteApiKeyModal}
        onClose={() => setShowDeleteApiKeyModal(false)}
        keyToDelete={keyToDelete}
      />
      <div className=" bg-zinc-50 font-dmSans">
        <header>
          <div className="flex justify-between h-16 p-2 text-xl font-medium text-zinc-800">
            <div className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
              API Keys
            </div>
          </div>
          {/* <hr className="border-t border-zinc-300" /> */}
        </header>
        <div className="p-2 text-sm">
          <div className="text-zinc-700">
            <p>
              Your secret API keys are listed below. Please note that we do not
              display your secret API keys again after you generate them.
            </p>
            <p className="mt-4">
              Do not share your API key with others, or expose it in the browser
              or other client-side code. In order to protect the security of
              your account.
            </p>
            <p className="mt-4">
              If you believe your API key has been compromised, you can generate
              a new one. and delete the old one.
            </p>
          </div>
          <div className="my-8">
            <button
              onClick={handleShowAddApiKeyModal}
              className="flex items-center h-12 gap-1 px-4 text-sm bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100"
            >
              <PlusIcon className="w-4 h-4 text-zinc-900" /> Generate new API
              key
            </button>
          </div>
          <div className="">
            {apiKeys?.length > 0 ? (
              <table className="divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-800 w-56">
                      Name
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-800 w-56">
                      Key
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-800 w-56">
                      Created At
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-transparent w-20 "></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-zinc-50">
                  {apiKeys.map((key) => (
                    <tr key={key.api_key}>
                      <td className="p-4 text-sm text-gray-500 max-w-16 md:max-w-full text-wrap">
                        {key.api_key_name || 'Secret API Key'}
                      </td>
                      <td className="p-4 text-sm text-gray-700 whitespace-nowrap">
                        {key.api_key}
                      </td>
                      <td className="p-4 text-sm text-gray-700 whitespace-nowrap">
                        {new Date(key.created_at).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </td>
                      <td className="content-center text-sm text-right whitespace-nowrap">
                        <button
                          onClick={() => handleDeleteKey(key)}
                          className="p-4 text-zinc-700 hover:text-zinc-950 hover:bg-zinc-100 active:bg-zinc-200"
                        >
                          <TrashIcon className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              loading && <Spinner size={'30px'} borderTopColor={'gray'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailorApiKeys;
