import { FC } from 'react';

import { items } from './cloudproviders';

const SkewedInfiniteScroll: FC = () => {
  const renderIcon = (iconUrl: string) => {
    if (iconUrl.startsWith('<svg')) {
      return (
        <span
          className="w-6 h-6"
          dangerouslySetInnerHTML={{ __html: iconUrl }}
        />
      );
    }
    return <img src={iconUrl} alt="icon" className="flex-none w-6 h-6" />;
  };

  return (
    <div>
      <div className="flex items-center justify-center">
        <div
          className="relative w-full max-w-screen-lg overflow-hidden"
          style={{
            maskComposite: 'intersect',
            maskImage: `
              linear-gradient(to right,  transparent, black 5rem),
              linear-gradient(to left,   transparent, black 5rem),
              linear-gradient(to bottom, transparent, black 5rem),
              linear-gradient(to top,    transparent, black 5rem)
            `,
          }}
        >
          <div className="mx-auto grid h-[250px] w-[300px] animate-skew-scroll grid-cols-1 gap-5 sm:w-[600px] sm:grid-cols-2">
            {items.map((item, index) => (
              <div
                key={`item-${index}`}
                className="flex cursor-pointer items-center space-x-2 rounded-full border border-gray-100 px-5 py-4 shadow-md transition-all hover:-translate-y-1 hover:translate-x-1 hover:scale-[1.025] hover:shadow-xl dark:border-gray-800 justify-center"
              >
                {renderIcon(item.iconUrl)}
                <p className="text-gray-600">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkewedInfiniteScroll;
