// components/LogsUpload.js
import clsx from 'clsx';
import { Modal } from 'flowbite-react';
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import { fileSizeAsString } from '../../../utils/generalUtils';
import Spinner from '../../Spinner';
import useUploadLogs from '../../../Hooks/useUploadLogs';

function LogsUpload({ validationError, setValidationError }) {
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [totalLines, setTotalLines] = useState(0);
  const {
    setFileContent,
    setSelectedFile,
    cancelUpload,
    fileContent,
    inProgress,
  } = useUploadLogs();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setSelectedFile(file);
        await getNumberOfLines(file);
        setFileContent({
          name: file.name,
          size: fileSizeAsString(file.size),
          numberOfLines: totalLines,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedFile, setFileContent, totalLines],
  );

  const getNumberOfLines = async (file) => {
    const reader = new FileReader();
    let lines = 0;
    reader.onload = async function (e) {
      const content = e.target.result;
      lines = content
        .split(/\r\n|\r|\n/)
        .filter((line) => line.trim().length > 0).length;
      setFileContent({
        name: file.name,
        size: fileSizeAsString(file.size),
        numberOfLines: lines,
      });
      setTotalLines(lines);
    };
    reader.readAsText(file);
    return lines;
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'application/jsonl': ['.jsonl'],
    },
    maxFiles: 1,
    onDropRejected: (files) => {
      if (files.length === 0) {
        setValidationError('Invalid file format. Please upload a .jsonl file.');
      } else {
        setValidationError('Only one file can be used.');
      }
    },
    onDragEnter: () => {
      setValidationError('');
    },
    onDragLeave: () => {
      setValidationError('');
    },
    onDropAccepted: () => {
      setValidationError('');
    },
    onFileDialogOpen: () => {
      setValidationError('');
    },
  });

  const clearFile = () => {
    setSelectedFile(null);
    setFileContent({ name: '', size: '', numberOfLines: 0 });
  };

  return (
    <>
      <div className="relative w-full mx-auto">
        {inProgress ? (
          <div className="flex flex-col items-center justify-center w-full h-40 border rounded-md shadow border-zinc-300 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-700">
            <p className="mb-2 text-zinc-500 dark:text-zinc-400">
              Validating file...
            </p>
            <Spinner
              size={'32px'}
              borderTopColor={'rgb(156,163,175)'}
              borderColor={'#F9FAFB'}
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                cancelUpload();
              }}
              className="mt-3 text-sm underline text-zinc-600 text-end"
            >
              {' '}
              Cancel the validation and upload
            </button>
          </div>
        ) : fileContent.name && totalLines ? (
          <div className="flex flex-col items-center justify-center w-full py-2 border rounded-md shadow min-h-40 border-zinc-300 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-700">
            <p className="text-zinc-500 dark:text-zinc-400">
              Filename: {fileContent.name}
            </p>
            <p className="mb-1 text-zinc-500 dark:text-zinc-400">
              Size: {fileContent.size} - {totalLines} logs
            </p>

            <button
              className="mt-3 text-sm underline text-zinc-600 text-end"
              onClick={(e) => {
                e.preventDefault();
                clearFile();
                open();
              }}
            >
              Select a different file
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={clsx(
              'flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded border border-dashed border-zinc-500 shadow dark:hover:border-zinc-500 dark:hover:bg-zinc-600 transition',
              isDragActive
                ? 'border-blue-600 bg-blue-50'
                : 'border-zinc-300 bg-zinc-50 hover:bg-zinc-100 dark:border-zinc-600 dark:bg-zinc-700',
              validationError && '!border-red-300 !bg-red-50 !border-solid',
            )}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-zinc-500">Drop the files here ...</p>
            ) : (
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-4 size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>

                <p className="mb-2 text-sm text-zinc-500 dark:text-zinc-400">
                  <span className="font-semibold">Click to select</span> or drag
                  and drop
                </p>
                <p className="mb-2 text-sm text-zinc-500 dark:text-zinc-400">
                  Your file will be validated automatically
                </p>
                <p className="text-xs text-zinc-500 dark:text-zinc-400">
                  .jsonl files only
                </p>
              </div>
            )}
            {validationError && (
              <p className="absolute right-0 text-xs italic text-red-500 -bottom-4">
                {validationError}
              </p>
            )}
          </div>
        )}
      </div>
      <button
        className="mt-4 text-sm underline text-zinc-600 text-end"
        onClick={(e) => {
          e.preventDefault();
          setShowExplanationModal(true);
        }}
      >
        How should the file be formatted?
      </button>

      <Modal
        show={showExplanationModal}
        onClose={() => setShowExplanationModal(false)}
        className="font-dmSans"
      >
        <Modal.Header>
          <p className="capitalize ">Prepare the data correctly</p>
        </Modal.Header>
        <Modal.Body>
          <div className=" text-zinc-700">
            <p>
              The file should be a JSONL file, where each line is a JSON object
              with the following structure:
            </p>
            <pre className="p-4 mt-4 font-mono text-sm rounded-md bg-zinc-100 text-zinc-900">
              {`{"messages": [{"role": "system","content": "Welcome!"},{"role": "user","content": "Hello!"},{"role": "assistant","content": "Hi there!"}]}
{"messages": [{"role": "user","content": "How are you?"},{"role": "assistant","content": "I'm good, thanks!"}]}`}
            </pre>
            <p className="pt-4 text-zinc-700">
              Each JSON object should have a "messages" field, which is an array
              of messages. Each message should have a "role" field, which is
              either "user", "assistant" or "system", and a "content" field,
              which is the message content.
            </p>
            <p className="pt-4 text-zinc-700">
              The file should be in JSONL format, which means that the file
              should have a .jsonl extension. We will validate the file
              automatically when you select it. You can use the file format
              conversion tool to convert from JSON to JSONL.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-end justify-between w-full gap-2">
            <Link
              to="/jsonl-formatter"
              target="_blank"
              rel="noreferrer"
              className="h-10 px-3 text-sm font-medium text-center text-white rounded-md flex-center bg-zinc-800 hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-zinc-300 dark:bg-zinc-600 dark:hover:bg-zinc-700 dark:focus:ring-slate-800 whitespace-nowrap"
            >
              File Format Tool
            </Link>
            <button
              className="h-10 px-3 text-sm font-medium text-center border rounded-md text-zinc-600 w-fit flex-center border-zinc-700 hover:bg-zinc-200 focus:ring-4 focus:outline-none focus:ring-zinc-300 dark:bg-zinc-600 dark:hover:bg-zinc-700 dark:focus:ring-zinc-800"
              onClick={() => setShowExplanationModal(false)}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogsUpload;
