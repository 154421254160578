import { useCallback, useEffect } from 'react';

import { useUser } from '../UserContext';

const useCheckUserLoggedIn = () => {
  const { checkTokenExpiration, setShowTokenExpiredModal } = useUser();
  const token = localStorage.getItem('token');

  const checkLoginStatus = useCallback(() => {
    if (token) {
      const isExpired = checkTokenExpiration(token);
      if (isExpired) {
        localStorage.setItem('shouldRedirect', 'no');
        setShowTokenExpiredModal(true);
      }
    } else {
      localStorage.setItem('shouldRedirect', 'no');
      setShowTokenExpiredModal(true);
    }
  }, [token, checkTokenExpiration, setShowTokenExpiredModal]);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  return checkLoginStatus;
};

export default useCheckUserLoggedIn;
