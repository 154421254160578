import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Models = () => {
  const [models, setModels] = useState([]);
  const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

  useEffect(() => {
    const fetchModels = async () => {
      try {
        const response = await axios.get(
          `${frontendServerUrl}/tailor/v1/base_models`,
        );
        setModels(response.data.models); // Access the models property of the response data
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, [frontendServerUrl]);

  return (
    <div className="w-full min-h-screen bg-zinc-50">
      <div className="max-w-[1500px] mx-auto py-12 sm:py-24">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="sm:py-24">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
              <h2 className="text-2xl leading-8 text-center text-gray-900 sm:text-4xl">
                Improve and Switch Between State-of-the-Art Models
              </h2>
              <div className="grid items-center max-w-lg grid-cols-4 mx-auto gap-x-2 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none">
                <img
                  alt="openai"
                  src="/openailogo.png"
                  className="object-contain w-full col-span-1 max-h-48"
                />
                <img
                  alt="mistral"
                  src="/mistrallogo.png"
                  className="object-contain w-full col-span-1 max-h-48"
                />
                <img
                  alt="meta"
                  src="/metalogo.png"
                  className="object-contain w-full col-span-1 max-h-48"
                />
                <img
                  alt="anthropic"
                  src="/anthropic.png"
                  className="object-contain w-full col-span-1 p-4 max-h-48"
                />
              </div>
              <h2 className="mt-6 text-lg leading-8 text-center text-zinc-800">
                OpenAI, Mistral, Meta, Google, Microsoft, Anthropic, and many
                more.
              </h2>

              {/* Wrapping the table in a responsive div */}
              <div className="flex items-center justify-center w-full py-12">
                <div className="w-full mt-6 overflow-x-auto">
                  <table className="min-w-full border divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Model
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Supported Context Size
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Available for Fine-tuning
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Available for Inference
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm leading-7 divide-y divide-gray-200 text-zinc-600">
                      {models.map((model) => (
                        <tr key={model.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {model.model_name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {model.supported_context_len}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {model.available_for_finetuning ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-green-500"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {model.available_for_inference ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 text-green-500"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Models;
