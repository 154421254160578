import React, { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface CapsuleTabsProps {
  tabs: string[];
  onTabSelect: (index: number) => void;
  selectedTab: number;
}

const CapsuleTabs: React.FC<CapsuleTabsProps> = ({
  tabs,
  selectedTab,
  onTabSelect,
}) => {
  const [currentLink, setCurrentLink] = useState<{
    left: undefined | number;
    width: undefined | number;
  }>({
    left: undefined,
    width: undefined,
  });

  const fired = useRef(false);

  useEffect(() => {
    const selectedElement = document.getElementById(`uuu-btn-${selectedTab}`);
    if (selectedElement) {
      setCurrentLink({
        left: selectedElement.offsetLeft,
        width: selectedElement.getBoundingClientRect().width,
      });
    }
  }, [selectedTab]);

  return (
    <div
      className={
        'w-fit relative border border-neutral-300 rounded-full flex gap-5 items-center justify-center p-2 backdrop-blur-2xl mt-4'
      }
    >
      {tabs.map((link, i) => (
        <button
          key={i}
          id={`uuu-btn-${i}`}
          onClick={() => {
            onTabSelect(i); // Notify parent about the tab selection
            fired.current = true;
          }}
          className={twMerge(
            'transition-colors duration-200 flex items-center justify-center rounded-full h-fit px-4 py-2 text-nowrap',
            selectedTab === i ? 'text-zinc-50 dark:text-neutral-900' : '',
            selectedTab === i && !fired.current
              ? 'bg-neutral-950 dark:bg-white'
              : '',
          )}
        >
          {link}
        </button>
      ))}
      <div className={'absolute inset-0 h-full p-2 -z-[1] overflow-hidden'}>
        <div className={'relative h-full w-full overflow-hidden'}>
          <div
            style={{
              left: `calc(${currentLink.left || 0}px - 0.75rem + 0.25rem)`,
              width: `${currentLink.width || 0}px`,
            }}
            className={twMerge(
              `transition-[left,width] duration-300 absolute top-1/2 -translate-y-1/2 h-full rounded-full -z-[1] bg-neutral-950 dark:bg-white`,
            )}
            onTransitionEnd={() => {
              fired.current = false;
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CapsuleTabs;
