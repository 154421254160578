import React, { Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';

const MyModelTable = ({ models, baseModels }) => {
  // const getImageUrlForFamily = useCallback((family) => {
  //   switch (family) {
  //     case 'Mistral':
  //     case 'Mixtral':
  //       return '/mistral.png';
  //     case 'LLaMA':
  //       return '/meta.png';
  //     case 'Gemma':
  //       return '/googleicon.png';
  //     case 'Phi':
  //       return '/microsoft.svg';
  //     default:
  //       return '';
  //   }
  // }, []);

  // const getModelFamily = (displayName) => {
  //   if (!displayName) {
  //     return 'Others';
  //   }
  //   const lowerName = displayName.toLowerCase();
  //   if (lowerName.includes('mistral')) {
  //     return 'Mistral';
  //   }
  //   if (lowerName.includes('mixtral')) {
  //     return 'Mixtral';
  //   }
  //   if (lowerName.includes('llama')) {
  //     return 'LLaMA';
  //   }
  //   if (lowerName.includes('gemma')) {
  //     return 'Gemma';
  //   }
  //   if (lowerName.includes('phi')) {
  //     return 'Phi';
  //   }
  //   return 'Others';
  // };

  return (
    <div>
      {/* Table for Fine-Tuned Models */}
      <table className="w-full border border-gray-300 mb-8 ">
        <thead className="bg-gray-100 ">
          <tr>
            <th className="border px-4 py-2 font-medium">Name</th>
            <th className="border px-4 py-2 font-medium">Base Model</th>
            <th className="border px-4 py-2 font-medium">State</th>
            <th className="border px-4 py-2 font-medium">Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {models.map((model) => (
            <Fragment key={model.model_id}>
              <tr
                key={model.id}
                className="hover:bg-gray-50 text-sm text-gray-700"
              >
                <td className="border px-4 py-2">
                  <Link
                    className="underline"
                    to={`../my-models/${model.model_name}`}
                  >
                    {model.model_name}
                  </Link>
                </td>
                <td className="border px-4 py-2">
                  {model.model_config.base_model}
                </td>
                <td className="border px-4 py-2">{model.state}</td>
                <td className="border px-4 py-2">{model.last_used}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>

      {/* Table for Base Models */}
      <h2 className="text-xl font-medium mb-4 text-zinc-800">Base Models</h2>
      <table className="w-full border border-gray-300">
        <thead className="bg-gray-100">
          <tr>
            <th className="border px-4 py-2">Model</th>
          </tr>
        </thead>
        <tbody>
          {baseModels.map((baseModel) => (
            <Fragment key={baseModel.model_id}>
              <tr
                key={baseModel.id}
                className="hover:bg-gray-50 border-b text-sm text-gray-700"
              >
                <td className=" px-4 py-2 flex items-center underline">
                  <img
                    src={baseModel.image_url}
                    alt={baseModel.family}
                    className="w-6 h-6 mr-2"
                  />
                  {baseModel.display_name}
                </td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MyModelTable;
