import React from 'react';
import { motion } from 'framer-motion';

import BallGradient from './BallGradient';

const Planet = () => {
  return (
    <div className="relative w-full max-w-[1500px] h-[1000px] mx-auto ">
      {/* <BallGradient> */}
      <div className="flex items-center justify-center scale-65 sm:scale-75 md+:scale-100 ">
        <motion.div className="relative flex items-center justify-center w-full h-full">
          <div className="min-w-[600px] min-h-[600px] h-[600px] w-[600px] shadow rounded-full drop-shadow ">
            <div className="flex items-center justify-center w-full h-full rounded-full wavy shadow-3xinner">
              <motion.div className="relative flex items-center justify-center w-full h-full">
                <div className="w-[500px] h-[500px] shadow rounded-full">
                  <div className="flex items-center justify-center w-full h-full rounded-full wavy shadow-3xinner">
                    <motion.div className="relative flex items-center justify-center w-full h-full">
                      <div className="w-[400px] h-[400px] shadow rounded-full">
                        <div className="flex items-center justify-center w-full h-full rounded-full wavy shadow-3xinner">
                          <motion.div className="relative flex items-center justify-center w-full h-full">
                            <div className="w-[300px] h-[300px] shadow rounded-full">
                              <div className="flex items-center justify-center w-full h-full rounded-full wavy shadow-3xinner">
                                <motion.div className="relative flex items-center justify-center w-full h-full">
                                  <div className="w-[200px] h-[200px] shadow rounded-full">
                                    <div className="flex items-center justify-center w-full h-full rounded-full wavy shadow-3xinner">
                                      {/* Additional content here if needed */}
                                    </div>
                                  </div>
                                </motion.div>
                              </div>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      {/* </BallGradient> */}
    </div>
  );
};

export default Planet;
