import {
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { Modal, Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { set, del, get } from 'idb-keyval';

import { useUser } from '../../../UserContext';
import Spinner from '../../Spinner';

const DeployInstructions = ({ onClose, showModal }) => {
  const [createKey, setCreateKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customAxios } = useUser();
  const [newKey, setNewKey] = useState(null);
  const [name, setName] = useState('');
  const [copied, setCopied] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleShowApiKey = () => {
    if (!createKey) {
      setCreateKey(true);
    } else {
      setCreateKey(false);
    }
  };

  const handleGenerateKey = async () => {
    setLoading(true);
    try {
      const nameToUse = name || 'Secret API Key';
      const response = await customAxios.post('tailor/v1/keys', {
        api_key_name: nameToUse,
      });
      setNewKey(response.data);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Error generating new API key, please try again later');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      const checkDontShowAgain = async () => {
        const dontShowAgain = await get(
          'dontShowTailorDeployNextStepsModalAgain',
        );
        if (dontShowAgain) {
          setDontShowAgain(true);
          onClose();
        }
      };
      checkDontShowAgain();
    }
  }, [showModal, onClose]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newKey.message);
    setCopied(true);
    setInterval(() => {
      setCopied(false);
    }, 4000);
    toast.success('API key copied to clipboard');
  };

  const handleSetNotShowAgain = async () => {
    const newState = !dontShowAgain;
    setDontShowAgain(newState);

    if (newState) {
      await set('dontShowTailorDeployNextStepsModalAgain', true);
    } else {
      await del('dontShowTailorDeployNextStepsModalAgain');
    }
  };

  return (
    <Modal show={showModal} onClose={onClose} dismissible>
      <div className="p-6">
        <div className="text-lg font-semibold text-zinc-800">Next Steps</div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            Now that you have successfully deployed your model, you can start
            using it in your applications. You will need an API key to access
            the model. If you don't have one, you can create one below.
          </p>
          <div
            type="button"
            className="mt-4 ml-auto text-sm underline cursor-pointer underline-offset-4 text-zinc-500 w-fit"
            onClick={handleShowApiKey}
          >
            Create API Key{' '}
            {createKey ? (
              <ChevronUpIcon className="inline w-4 h-4" />
            ) : (
              <ChevronDownIcon className="inline w-4 h-4" />
            )}
          </div>
          {createKey && (
            <div className="mt-4">
              {!newKey && (
                <>
                  <label
                    className="block text-sm font-semibold text-zinc-800"
                    htmlFor="api-key-name"
                  >
                    API Key Name{' '}
                    <span className="text-xs font-normal text-zinc-500">
                      (Optional)
                    </span>
                  </label>
                  <input
                    id="api-key-name"
                    type="text"
                    className="w-full p-2 text-sm border rounded-md border-zinc-300"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                  <button
                    className="w-32 h-10 gap-1 mt-4 text-sm font-bold bg-blue-200 rounded-md flex-center text-zinc-700 hover:bg-blue-300 active:bg-blue-400"
                    onClick={handleGenerateKey}
                    disabled={loading}
                  >
                    {loading ? <Spinner /> : 'Create API Key'}
                  </button>
                </>
              )}
              {newKey && (
                <>
                  <div className="text-sm text-zinc-800">Your new API key:</div>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="w-9/12 p-2 font-mono break-words border rounded-l-md border-zinc-30 grow text-wrap md:w-full">
                      {newKey.message}
                    </div>
                    <Tooltip content="Copy to clipboard" position="top">
                      <button
                        className="flex items-center justify-around w-16 h-16 gap-1 p-2 px-4 md:w-32 md:h-auto text-zinc-700 hover:text-zinc-900 rounded-r-md bg-zinc-200 hover:bg-zinc-300 active:bg-zinc-400"
                        aria-label="Copy to clipboard"
                        onClick={handleCopyToClipboard}
                      >
                        {copied ? (
                          <>
                            <ClipboardDocumentCheckIcon className="w-6 h-6 md:w-5 md:h-5" />
                            <span className="hidden md:inline-block">
                              Copied
                            </span>
                          </>
                        ) : (
                          <>
                            <ClipboardDocumentListIcon className="w-6 h-6 md:w-5 md:h-5" />
                            <span className="hidden md:inline-block">Copy</span>
                          </>
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        {/* add a checkbox to not show this message again */}
        <div className="flex items-end justify-between gap-4 mt-8">
          <div>
            <input
              type="checkbox"
              id="dont-show-again"
              className="w-4 h-4 border border-gray-300 rounded-md text-slate-800 dark:text-gray-100 focus:ring-2 focus:ring-slate-400 focus:outline-none focus:border-transparent"
              checked={dontShowAgain}
              onChange={handleSetNotShowAgain}
            />
            <label
              htmlFor="dont-show-again"
              className="ml-2 text-sm text-slate-800 dark:text-gray-100"
            >
              Don't show this message again
            </label>
          </div>
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400 shrink-0"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeployInstructions;
