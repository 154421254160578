import toast from 'react-hot-toast';

// Fetch base models
export const fetchBaseModels = async (customAxios, setBaseModels) => {
  try {
    const response = await customAxios.get(
      'tailor/v1/base_models?finetuning=true',
    );
    const models = response?.data?.models;
    models.forEach((model) => {
      if (model.model_name.includes('mistral')) {
        model.min_logs_required = 1_000;
        model.good_number_of_logs_required = 2_000;
        model.excellent_number_of_logs_required = 5_000;
      } else if (model.model_name.includes('mixtral')) {
        model.min_logs_required = 2_000;
        model.good_number_of_logs_required = 4_000;
        model.excellent_number_of_logs_required = 8_000;
      } else if (model.model_name.includes('llama')) {
        model.min_logs_required = 8_000;
        model.good_number_of_logs_required = 12_000;
        model.excellent_number_of_logs_required = 18_000;
      }
    });
    setBaseModels(models);
  } catch (error) {
    if (import.meta.env.DEV) console.error(error);
    toast.error('An error occurred fetching base models. Please try again.');
  }
};

// Fetch deployed models
export const fetchDeployList = async (
  customAxios,
  setDeployedModels,
  setSelectedModel,
  setError,
  id,
  setLoading,
) => {
  try {
    const response = await customAxios.get('tailor/v1/models');
    let models = response?.data?.message;
    models = models.filter((model) => model.state === 'deployed');
    setDeployedModels(models);
    if (id) {
      const foundModel = models.find((model) => model.model_id == id);
      if (foundModel) {
        setSelectedModel(foundModel);
      }
    }
  } catch (error) {
    if (import.meta.env.DEV) console.error(error);
    if (error.code === 'ERR_NETWORK') {
      toast.error('Network error. Please try again later.');
    } else {
      setError(
        'There was an error retrieving the models. Please try again later.',
      );
    }
  } finally {
    setLoading(false);
  }
};

// Fetch logs
export const fetchLogs = async (customAxios, setUniqueTags, setLoading) => {
  setLoading(true);
  try {
    const response = await customAxios.get('tailor/v1/data');
    const tags =
      response?.data?.tags
        ?.map((tag) => (tag === '[]' ? null : tag.replace(/[[\]{}"]+/g, '')))
        .filter(Boolean)
        .sort() || [];
    setUniqueTags([...new Set(tags)]);
  } catch (error) {
    if (import.meta.env.DEV) console.error(error);
  } finally {
    setLoading(false);
  }
};

// Fetch datasets
export const retrieveDatasets = async (
  customAxios,
  setDatasets,
  setLoading,
) => {
  setLoading(true);
  try {
    const response = await customAxios.get('tailor/v1/datasets');
    setDatasets(response.data?.message ?? []);
  } catch (error) {
    if (import.meta.env.DEV) console.error(error);
    toast.error('Failed to retrieve datasets, please try again');
  } finally {
    setLoading(false);
  }
};
