import React, { useState } from 'react';
import moment from 'moment';

import Spinner from '../Spinner';

const InvoiceTable = ({ transactions, selectedTab }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  const formatAmount = (amount, type) => {
    const formattedAmount =
      type === 'stripe' || type === 'purchase' || type === 'stripe purchase'
        ? amount / 100
        : amount / 100000;
    return formattedAmount > 0.01
      ? formattedAmount.toFixed(2)
      : formattedAmount.toFixed(4);
  };

  const renameItem = (item, type) => {
    const gpuReservationRegex = /Pod (\d+) Reservation/;
    const inferenceModelRegex = /Lorax_model (\d+) Reservation/;
    const creditsRegex = /^Credits$/;

    if (gpuReservationRegex.test(item)) {
      return item.replace(gpuReservationRegex, 'GPU Reservation $1');
    }

    if (inferenceModelRegex.test(item)) {
      return item.replace(inferenceModelRegex, 'Inference Model $1');
    }

    return item;
  };

  const filteredTransactions = transactions.filter((transaction) => {
    if (selectedTab === 'charges') {
      return transaction.type === 'charge';
    } else {
      return transaction.type !== 'charge' && transaction.status !== 'canceled';
    }
  });

  const currentTransactions = filteredTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  if (transactions.length === 0) {
    return (
      <Spinner size="30px" borderColor="#E4E4E7" borderTopColor="#27272A" />
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Created At
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Amount
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Vat
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Item
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Type
            </th>
            <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase whitespace-nowrap">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentTransactions.map((transaction, index) => (
            <tr key={index}>
              <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                {moment(transaction.created_at * 1000).format(
                  'DD/MM/YYYY HH:mm',
                )}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                ${formatAmount(transaction.amount, transaction.type)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                {transaction.tax
                  ? `$${formatAmount(transaction.tax, transaction.type)}`
                  : '-'}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                {renameItem(transaction.item, transaction.type)}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900 capitalize whitespace-nowrap">
                {transaction.type}
              </td>
              <td className="px-6 py-4 text-sm text-gray-900 capitalize whitespace-nowrap">
                {transaction.status ? (
                  transaction.status
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 12.75 6 6 9-13.5"
                    />
                  </svg>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceTable;
