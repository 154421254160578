/**
 * MambaReadyPage.js
 * A page component to display information about the Mamba Ready OS Template.
 * This template is designed for training and inferencing LLMs built with the Mamba SSM architecture.
 */

const MambaReadyPage = () => {
  return (
    <div className="min-h-screen p-4 mx-auto mt-10 bg-white dark:bg-gray-800 max-w-7xl">
      <h1 className="text-3xl font-bold dark:text-white font-spaceG">
        Mamba Ready OS Template
      </h1>
      <p className="mt-4 dark:text-gray-200">
        Tromero's Mamba SSM ready OS template is the easiest way to get started
        with training and inferencing LLMs built with the Mamba SSM
        architecture. It comes pre-configured with all the dependencies you need
        to get started, and a number of examples to get you started.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Skip all the hassle
      </h2>
      <p className="dark:text-gray-200">
        Don't waste time, energy, and money setting up all the dependencies and
        tools to finetune or inference mamba-based LLMs, installing and
        configuring the libraries needed can take a long time, a lot of reading
        through documentation, and a lot of painful trial and error getting it
        right for your exact system. With our Mamba SSM ready OS template, you
        can get started straight away with a pre-configured environment with all
        these tools (and more) already set up and ready to go.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Mamba architecture
      </h2>
      <p className="dark:text-gray-200">
        Even the most recent advances in transformer based architectures and the
        attempts to bring the compute closer and closer to linear time
        complexity aren't quite as promising for the future as the
        <a
          href="https://arxiv.org/abs/2312.00752"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          Mamba SSM
        </a>{' '}
        architecture. Mamba is a new approach to the problem of training LLMs
        which promises to be more efficient and more scalable than the current
        state of the art, and is built on top of the latest advances in the
        field.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Get started straight away
      </h2>
      <p className="mb-8 dark:text-gray-200">
        Even with an environment setup for you, it can be difficult to get
        started with experimenting if you're not familiar with the tools and
        libraries. That's why we've included both a finetuning and inferencing
        example to get you started straight away.
      </p>
    </div>
  );
};

export default MambaReadyPage;
