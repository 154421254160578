import { useState } from 'react';
import useUploadLogs from '../../../Hooks/useUploadLogs';
import UploadLogsModal from './UploadLogsModal';

const UploadLogsButton = () => {
  const [showUploadLogsModal, setShowUploadLogsModal] = useState(false);
  const { validationInProgress, percentComplete } = useUploadLogs();

  return (
    <>
      <button
        className="h-10 px-2 mb-1 text-sm font-medium text-gray-100 bg-zinc-900 rounded-md sm:px-4 hover:bg-zinc-800 whitespace-nowrap flex-center"
        onClick={() => setShowUploadLogsModal(true)}
      >
        {validationInProgress ? (
          <div className="flex items-center capitalize w-fit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <g>
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.14"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.29"
                  transform="rotate(30 12 12)"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.43"
                  transform="rotate(60 12 12)"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.57"
                  transform="rotate(90 12 12)"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.71"
                  transform="rotate(120 12 12)"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  opacity="0.86"
                  transform="rotate(150 12 12)"
                />
                <rect
                  width="2"
                  height="5"
                  x="11"
                  y="1"
                  fill="currentColor"
                  transform="rotate(180 12 12)"
                />
                <animateTransform
                  attributeName="transform"
                  calcMode="discrete"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
                />
              </g>
            </svg>
            <span className="ml-2 text-xs md:text-sm">Validating</span>
            <span className="w-[3ch] text-right">{percentComplete}</span>%
          </div>
        ) : (
          <div className="inline-flex items-center justify-center py-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-2 size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
              />
            </svg>
            Upload Logs
          </div>
        )}
      </button>
      <UploadLogsModal
        openModal={showUploadLogsModal}
        closeModal={() => setShowUploadLogsModal(false)}
      />
    </>
  );
};

export default UploadLogsButton;
