/**
 * CustomImageSetupTromero.js
 * A page component to guide users through setting up a custom Docker image on Tromero.
 */

import React from 'react';
import { Link } from 'react-router-dom';

const CustomImageSetupTromero = () => {
  return (
    <div className="min-h-screen p-4 mx-auto mt-10 bg-white dark:bg-gray-800 max-w-7xl">
      <h1 className="text-2xl font-bold dark:text-white font-spaceG">
        How to Run Your Own Docker Image on Tromero
      </h1>
      <p className="mt-4 dark:text-gray-200 text-sm">
        Tromero provides various types of images that you can use to develop in.
        However, if these images do not fit your needs, you have the option to
        bring your own image to our platform. Follow the steps below to get
        started.
      </p>

      <h2 className="mt-8 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Initial Configuration
      </h2>
      <p className="dark:text-gray-200 text-sm">
        Navigate to the OS Template section of our page and select the{' '}
        <strong>Custom Image</strong> button.
      </p>
      <img
        src="/tutorials/customImageTemplate/custom_image_button.jpg"
        alt="Custom Image Button"
        className="mt-4"
      />

      <p className="mt-4 dark:text-gray-200 text-sm">
        By choosing the <strong>Custom Image</strong> option, you will be
        presented with various settings.
      </p>
      <img
        src="/tutorials/customImageTemplate/settings_section.jpg"
        alt="Settings Section"
        className="mt-4"
      />

      <p className="mt-4 dark:text-gray-200 text-sm">
        Here you can add the path to your Docker image and the ports (TCP or
        HTTP) that you want to open for the container.
      </p>

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Accessing an Image in a Private Repository
      </h2>
      <p className="dark:text-gray-200 text-sm">
        To access an image in your private repository, click on the private
        button to open a field for entering your Docker access token.
      </p>
      <p className="dark:text-gray-200 text-sm">
        To create a new <strong>Access Token</strong>, use{' '}
        <Link
          to="https://docs.docker.com/security/for-developers/access-tokens/"
          target="_blank"
          className="text-indigo-600"
        >
          this link
        </Link>
        .
      </p>
      <img
        src="/tutorials/customImageTemplate/private_option.jpg"
        alt="Private Option"
        className="mt-4"
      />

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Selecting Ports for Your Container
      </h2>
      <p className="dark:text-gray-200 text-sm">
        To open ports for your container, enter your desired TCP or HTTP ports
        into their respective fields. To define a range of ports, use "-"
        between the two end-ports of the series (e.g., 10520-10534).
      </p>
      <img
        src="/tutorials/customImageTemplate/ports_selection.jpg"
        alt="Ports Selection"
        className="mt-4"
      />

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Defining Your Docker Start Command
      </h2>
      <p className="dark:text-gray-200 text-sm">
        It is popular with docker containers to define a start-command that
        starts your application. If you did not define one in your container or
        you would like to overwrite it, you can define your custom start-command
        in the <strong>Start Command</strong> field. Similarly to Docker
        containers, we require you to define your Docker start-command as an
        array of string separated by commas, e.g. ["/usr/sbin/sshd", "-D"] .
        __Important__ : this start-command overwrites the one defined in your
        Docker file so make sure that is what you want to do and the command is
        correct before you use it.
      </p>
      <img
        src="/tutorials/customImageTemplate/start_command.jpg"
        alt="Start Command"
        className="mt-4"
      />

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Adding Your Post Start Command
      </h2>
      <p className="dark:text-gray-200 text-sm">
        If you would like to execute other commands or setups after your Docker
        start command, for example you are using a public image which you do not
        know the Docker start command of and want to start a server after the
        container has started, then you can use the post-start command. This
        command is executed in the command line right after your container
        started and executed its Docker start-command. It is also defined as an
        array of strings separated by commas, e.g. ["python3", "server.py"].
      </p>
      <img
        src="/tutorials/customImageTemplate/post_start_command.jpg"
        alt="Start Command"
        className="mt-4"
      />

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        SSH Access to Your Instance
      </h2>
      <p className="dark:text-gray-200 text-sm">
        To SSH into your instance, set up an SSH server in your Docker image on
        port 22 (or your specified port) with your SSH key(s) in the authorized
        keys folder.
      </p>
      <pre className="p-2 bg-gray-100 rounded dark:bg-gray-700 text-sm">
        <code>{`FROM [INSERT YOUR BASE IMAGE HERE]

# Update the package repository and install openssh-server
RUN apt-get update && apt-get install -y openssh-server

# Create the directory for the SSH daemon to run
RUN mkdir /var/run/sshd

# Copy your public key from the build context to the image
# Replace /path/to/your/public_key with the actual path in your build context
COPY /path/to/your/public_key /tmp/id_rsa.pub

# Create SSH directory for the root user
RUN mkdir -p /root/.ssh && \\
    cat /tmp/id_rsa.pub >> /root/.ssh/authorized_keys && \\
    chmod 600 /root/.ssh/authorized_keys && \\
    rm /tmp/id_rsa.pub

# Expose port 22 to the outside world
EXPOSE 22

# Start the SSH service
CMD ["/usr/sbin/sshd", "-D"]`}</code>
      </pre>
      <p className="mt-4 dark:text-gray-200 text-sm">
        Add port 22 (or your specified port) to the TCP ports if everything is
        set up correctly.
      </p>

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Adding Environment Variables
      </h2>
      <p className="dark:text-gray-200 text-sm">
        Click on the "Environment Variables" dropdown menu to define keys
        (variable names) and values as environment variables.
      </p>
      <img
        src="/tutorials/customImageTemplate/env_varibles.jpg"
        alt="Environment Variables"
        className="mt-4"
      />

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Running Your Container as a Batch Job
      </h2>
      <p className="dark:text-gray-200 text-sm">
        If your container is meant for executing a batch job rather than serving
        requests, select the "My image runs as a batch job" option. This will
        not create an associated service or port mapping for your container; it
        simply runs the container until it completes its tasks.
      </p>
      <img
        src="/tutorials/customImageTemplate/batch_job.jpg"
        alt="Batch Job"
        className="mt-4"
      />

      <p className="mt-8 dark:text-gray-200 text-sm">
        Once you are satisfied with your setup, click on "Save & Continue" to
        select one of our many GPU options for your work.
      </p>

      <h2 className="mt-12 mb-2 text-xl font-semibold dark:text-white font-spaceG">
        Happy Coding!
      </h2>
    </div>
  );
};

export default CustomImageSetupTromero;
