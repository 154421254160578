import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

const MyModelsErrorBoundary = ({ children }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ resetErrorBoundary }) => (
            <div className="flex items-center justify-center min-h-[50vh] h-screen p-4">
              <div className="text-center">
                <p className="text-red-500 mb-4">
                  Something went wrong loading the model.
                </p>
                <button
                  onClick={() => resetErrorBoundary()}
                  className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
                >
                  Try again
                </button>
              </div>
            </div>
          )}
          onReset={reset}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export default MyModelsErrorBoundary;
