import { Modal } from 'flowbite-react';
import { toast } from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import { amountToBackend, amountToFrontend } from '../../utils/CurrencyUtils';

const InsufficientCreditsModal = ({
  showModal,
  closeModal,
  tailorJobEstimate = 0,
}) => {
  const [checkoutAmount, setCheckoutAmount] = useState('');
  const [error, setError] = useState('');
  const { customAxios, fetchAndUpdateUserDetails } = useUser();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  useEffect(() => {
    setError('');
  }, [checkoutAmount]);

  const handleCheckout = async (amount = null) => {
    setError('');
    setLoading(true);
    if (!amount && !checkoutAmount) {
      setError('Please enter an amount');
      setLoading(false);
      return;
    }
    if (!amount && typeof +checkoutAmount !== 'number') {
      setError('Invalid amount');
      setLoading(false);
      return;
    }
    if (!amount && checkoutAmount < 5) {
      setError('Minimum top-up amount is $5');
      setLoading(false);
      return;
    }
    try {
      const payload = {
        amount: amountToBackend(amount ?? checkoutAmount),
      };
      const response = await customAxios.post('credits', payload);
      if (response.data?.url) {
        window.open(response.data.url, '_blank');
      } else {
        toast.success(
          'Payment successful. Your account will be updated shortly',
        );
      }
      await fetchAndUpdateUserDetails();
      handleCloseModal();
    } catch (error) {
      toast.error(error.message || 'Error processing payment');
    } finally {
      setLoading(false);
      setCheckoutAmount('');
    }
  };

  const handleCloseModal = () => {
    setError('');
    setCheckoutAmount('');
    setLoading(false);
    closeModal();
  };

  return (
    <Modal show={showModal} dismissible onClose={handleCloseModal}>
      <Modal.Header>
        <div className="flex flex-wrap flex-col items-baseline gap-x-4 gap-y-2">
          <div className="min-w-fit">Top-up Required</div>
          <div>
            <div className="text-sm">
              (Current balance:
              <span className="ml-2 text-zinc-500">
                ${amountToFrontend(user?.new_credits_available).toFixed(2)}
              </span>
              )
            </div>
            {tailorJobEstimate > 0 && (
              <div className="text-sm">
                (Job estimate:
                <span className="ml-2 text-zinc-500">
                  ${tailorJobEstimate.toFixed(2)}
                </span>
                )
              </div>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="">
        {user?.team_role === 'admin' && (
          <div className="text-gray-700">
            <p>
              We estimate that the current balance in your account will not be
              sufficient. Please top-up your account to continue using our
              services.
            </p>
            <p className="mt-4">
              We also recommend that you also enable the auto top-up feature in
              the settings menu to avoid this in the future. If your credits run
              out while using our services, your{' '}
              {tailorJobEstimate > 0 ? 'fine-tuning job' : 'rental'} will stop
              and you will lose your work.
            </p>
          </div>
        )}
        {user?.team_role === 'member' && (
          <div className="text-gray-700">
            <p>
              We estimate that the current balance in the team account will not
              be sufficient. Please contact your team admin to top-up the
              account.
            </p>
            <p className="mt-4">
              We also recommend that the team admin enable the auto top-up
              feature in the settings menu to avoid this in the future. If the
              team credits run out while using our services, your{' '}
              {tailorJobEstimate > 0 ? 'fine-tuning job' : 'rental'} will stop
              and you will lose your work.
            </p>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {user?.team_role === 'admin' && (
          <div className="w-full">
            <div className="pb-1 -mt-4">Quick top-up</div>
            <div className="flex w-full h-12 space-x-4 text-gray-800 justify-evenly">
              <button
                onClick={() => handleCheckout(10)}
                className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
              >
                10$
              </button>
              <button
                onClick={() => handleCheckout(25)}
                className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
              >
                25$
              </button>
              <button
                onClick={() => handleCheckout(100)}
                className="w-1/3 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
              >
                100$
              </button>
            </div>
            <div className="pb-1 mt-4">
              Enter your desired amount{' '}
              <span className="text-xs text-zinc-500">
                {tailorJobEstimate > 0
                  ? `Suggested $${(
                      (tailorJobEstimate -
                        amountToFrontend(user?.new_credits_available)) *
                      1.1
                    ).toFixed(0)} `
                  : '(Minimum $5)'}
              </span>
            </div>
            <div className="relative flex w-full h-12 mb-4 space-x-4 text-gray-800 justify-evenly">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCheckout();
                }}
                className="flex w-full h-12 space-x-4 text-gray-800 justify-evenly"
              >
                <input
                  type="number"
                  placeholder="Enter amount"
                  className="w-1/2 h-12 px-2 py-2 text-gray-800 bg-gray-100 border rounded shadow placeholder:text-sm"
                  onChange={(e) => setCheckoutAmount(e.target.value)}
                  value={checkoutAmount}
                  required
                  aria-required
                  disabled={loading}
                  min={5}
                />
                <button
                  type="submit"
                  className="w-1/2 h-12 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900 flex-center"
                >
                  {loading ? <Spinner size={'22px'} /> : 'Top-up'}
                </button>
              </form>
              {error && (
                <div className="absolute bottom-0 left-0 text-xs text-red-500">
                  {error}
                </div>
              )}
            </div>
          </div>
        )}
        {user?.team_role === 'member' && (
          <div className="w-full">
            <div className="flex w-full h-12 space-x-4 text-gray-800 justify-start">
              <button
                onClick={() => handleCloseModal()}
                className="w-40 px-4 py-2 text-gray-100 bg-gray-900 border rounded shadow hover:text-gray-900 hover:bg-gray-100 hover:border-gray-900"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default InsufficientCreditsModal;
