import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Suspense, useMemo, useState, useEffect } from 'react';
import { mixEvalTests } from './ModelEvaluations/MixEvalTests';
import { useGetRankingData } from '../../Hooks/react-query';
import { ModelsErrorBoundary } from '../UserDashboard/ModelList';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import TailorHeader from './TailorHeader';
import TestSelector from './Rankings/TestSelector';
import ModelTypeFilter from './Rankings/ModelTypeFilter';
import ModelRankingsSkeleton from './Rankings/ModelRankingsSkeleton';

const ModelTable = ({ columns, data }) => {
  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="overflow-x-scroll max-w-full">
      <table className="border-collapse border text-sm min-w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="border p-2 bg-gray-100 cursor-pointer select-none"
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <div className="flex items-center gap-2">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {header.column.getIsSorted() ? (
                      header.column.getIsSorted() === 'asc' ? (
                        <FaSortUp className="inline" />
                      ) : (
                        <FaSortDown className="inline" />
                      )
                    ) : (
                      <FaSort className="inline opacity-30" />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell, i) => (
                <td
                  key={cell.id}
                  className="border p-2 whitespace-nowrap w-fit"
                >
                  {typeof row?.original?.id === 'string' ? (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  ) : (
                    <Link
                      className={clsx(
                        cell.column.columnDef.header === 'Model' &&
                          'text-blue-500 hover:underline',
                      )}
                      to={`/tailor/my-models/${row.original.name}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Link>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TailorModelRankingsContent = () => {
  const { data, error } = useGetRankingData();
  const [selectedTests, setSelectedTests] = useState(mixEvalTests);
  const [showBaseModels, setShowBaseModels] = useState(true);
  const [selectedBaseModels, setSelectedBaseModels] = useState([]);
  const [selectedCustomModels, setSelectedCustomModels] = useState([]);

  if (error) {
    throw error;
  }

  // Initialize selected models when data is first loaded
  useEffect(() => {
    if (data) {
      const baseModels = data
        .filter((model) => model.id?.toString().startsWith('base_'))
        .map((model) => model.displayName);
      const customModels = data
        .filter((model) => !model.id?.toString().startsWith('base_'))
        .map((model) => model.displayName);

      if (selectedBaseModels.length === 0) {
        setSelectedBaseModels(baseModels);
      }
      if (selectedCustomModels.length === 0) {
        setSelectedCustomModels(customModels);
      }
    }
  }, [data]);

  const filteredData = useMemo(() => {
    if (!data) {
      return [];
    }

    let filtered = [];

    // Add selected custom models
    if (selectedCustomModels.length > 0) {
      filtered = filtered.concat(
        data.filter(
          (model) =>
            !model.id?.toString().startsWith('base_') &&
            selectedCustomModels.includes(model.displayName),
        ),
      );
    } else {
      // If no custom models selected, show all custom models
      filtered = filtered.concat(
        data.filter((model) => !model.id?.toString().startsWith('base_')),
      );
    }

    // Add selected base models if enabled
    if (showBaseModels) {
      if (selectedBaseModels.length > 0) {
        filtered = filtered.concat(
          data.filter(
            (model) =>
              model.id?.toString().startsWith('base_') &&
              selectedBaseModels.includes(model.displayName),
          ),
        );
      } else {
        // If no base models selected, show all base models
        filtered = filtered.concat(
          data.filter((model) => model.id?.toString().startsWith('base_')),
        );
      }
    }

    return filtered;
  }, [data, showBaseModels, selectedBaseModels, selectedCustomModels]);

  const columns = useMemo(
    () => [
      {
        header: 'Model',
        accessorFn: (row) => row.displayName,
      },
      ...selectedTests.map((test) => ({
        header: test,
        accessorFn: (row) => row.evaluations[test],
      })),
    ],
    [selectedTests],
  );

  return (
    <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
      <TailorHeader title="Model Rankings" />
      <div className="p-4 pb-16">
        <div className="flex flex-col gap-4 lg:flex-row lg:items-start">
          <TestSelector
            selectedTests={selectedTests}
            setSelectedTests={setSelectedTests}
            availableTests={mixEvalTests}
          />
          <ModelTypeFilter
            showBaseModels={showBaseModels}
            setShowBaseModels={setShowBaseModels}
            selectedBaseModels={selectedBaseModels}
            setSelectedBaseModels={setSelectedBaseModels}
            selectedCustomModels={selectedCustomModels}
            setSelectedCustomModels={setSelectedCustomModels}
            data={data}
          />
        </div>
        <ModelTable columns={columns} data={filteredData} />
      </div>
    </div>
  );
};

const TailorModelRankings = () => {
  return (
    <ModelsErrorBoundary>
      <Suspense fallback={<ModelRankingsSkeleton />}>
        <TailorModelRankingsContent />
      </Suspense>
    </ModelsErrorBoundary>
  );
};

export default TailorModelRankings;
