import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../UserContext';
import { hasUserInLocalStorage } from '../utils/userUtils';

const ProtectedRouteLoggedIn = ({ children }) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  useEffect(() => {
    const updateUserFromLocalStorage = async () => {
      const { hasUser } = await hasUserInLocalStorage(setUser);
      if (!hasUser) {
        navigate('/');
      }
    };
    updateUserFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return user ? children : null;
};

export default ProtectedRouteLoggedIn;
