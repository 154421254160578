/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { Tooltip, Button } from 'flowbite-react';

import AddTagsModal from './AddTagsModal';
import EditMessageModal from './EditMessageModal';
import SyntheticToggle from './SyntheticToggle';

const Tags = ({ tagString, isExpanded }) => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    const result = tagString
      ?.split(',')
      ?.map((tag) => tag.trim().replace(/[[\]{}"]+/g, ''))
      ?.filter(Boolean);

    setTags(result?.length > 0 ? result : []);
  }, [tagString]);

  return (
    <>
      {tags.map((cleanedTag, index) => (
        <span
          key={index}
          className={`inline-block px-2 py-1 mr-2 text-xs font-medium text-gray-800 bg-zinc-200 rounded-full w-fit whitespace-nowrap ${
            isExpanded ? '' : 'line-clamp-4 overflow-hidden'
          }`}
        >
          {cleanedTag}
        </span>
      ))}
    </>
  );
};

const LogsTable = ({
  data = [],
  currentPage,
  setPage,
  totalPages,
  fetchLogs,
}) => {
  const [isSynthetic, setIsSynthetic] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [tags, setTags] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedLogId, setSelectedLogId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState('');

  const handlePageChange = (page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  const getDisplayedPages = () => {
    let pages = [];
    pages.push(1);

    const start = Math.max(2, currentPage - 3);
    const end = Math.min(totalPages - 1, currentPage + 3);

    if (start > 2) {
      pages.push('start-ellipsis');
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (end < totalPages - 1) {
      pages.push('end-ellipsis');
    }

    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  };

  const openAddTagsModal = (index, logId) => {
    setSelectedRow(index);
    setSelectedLogId(logId);
    setIsModalOpen(true);
  };

  const openEditMessageModal = (logId, message) => {
    setSelectedLogId(logId);
    setCurrentMessage(message);
    setIsEditModalOpen(true);
  };

  const displayedPages = getDisplayedPages();

  const toggleRowExpansion = (idx) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(idx)
        ? prevExpandedRows.filter((id) => id !== idx)
        : [...prevExpandedRows, idx],
    );
  };

  const isMultithreaded = (log) => {
    const userMessages = log.messages.filter(
      (message) => message.role === 'user',
    );
    const assistantMessages = log.messages.filter(
      (message) => message.role === 'assistant',
    );

    return userMessages.length > 1 || assistantMessages.length > 1;
  };

  return (
    <div className="bg-zinc-50">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block w-full py-2 sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  System
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  User
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <div className="flex justify-between">
                    <div className="flex items-center">Assistant</div>
                    <SyntheticToggle
                      isSynthetic={isSynthetic}
                      setIsSynthetic={setIsSynthetic}
                    />
                  </div>
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Creation Date
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Model
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Kwargs
                </th>
                <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Tags
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.length > 0 ? (
                data.map((log, idx) => {
                  let systemMessage = '-';
                  let userMessage = '-';
                  let assistantMessage = '-';

                  // Get the first user and assistant messages for initial display
                  const firstUserMessage =
                    log?.messages?.find((msg) => msg.role === 'user')
                      ?.content ?? '-';
                  const firstAssistantMessage =
                    log?.messages?.find((msg) => msg.role === 'assistant')
                      ?.content ?? '-';

                  // Handle Synthetic Messages
                  // Handle Synthetic Messages
                  let syntheticAssistantMessage = '-';
                  if (isSynthetic && Array.isArray(log.synthetic_messages)) {
                    syntheticAssistantMessage =
                      log.synthetic_messages.find(
                        (msg) => msg.role === 'assistant',
                      )?.content ?? '-';
                  }

                  const isExpanded = expandedRows.includes(idx);
                  const multithreaded = isMultithreaded(log);

                  return (
                    <tr
                      key={log.id ?? idx}
                      className="divide-x divide-gray-200 group/item hover:bg-gray-100 odd:bg-white even:bg-gray-50 hover:cursor-pointer"
                      onClick={() => toggleRowExpansion(idx)}
                    >
                      {/* System Column */}
                      <td className="max-w-xs px-4 py-4 pb-6 text-sm text-gray-500 break-words align-top">
                        <div
                          className={`${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {systemMessage ?? '-'}
                        </div>
                      </td>

                      {/* User Column */}
                      <td className="relative max-w-sm px-4 py-4 pb-6 text-sm text-gray-500 break-words align-top">
                        <div
                          className={`${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {isExpanded && multithreaded
                            ? log.messages
                                .filter((msg) => msg.role === 'user')
                                .map((msg, index) => (
                                  <p key={index}>
                                    <div className="flex flex-row items-center">
                                      <span className="inline-flex items-center">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="size-4 mr-1"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M3.74 3.749a.75.75 0 0 1 .75.75V15h13.938l-2.47-2.47a.75.75 0 0 1 1.061-1.06l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06-1.06l2.47-2.47H3.738a.75.75 0 0 1-.75-.75V4.5a.75.75 0 0 1 .75-.751Z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                        <div className="text-zinc-800 font-medium ">
                                          <span className="font-light">
                                            User {index}:
                                          </span>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="ml-6">{msg.content}</div>
                                  </p>
                                ))
                            : firstUserMessage}
                        </div>
                        {/* Expand Thread Label for Multithreaded Chats */}
                        {multithreaded && !isExpanded && (
                          <div className="absolute bottom-1 right-1 text-xs text-gray-500 italic ">
                            <div className="flex flex-row items-center ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4 mr-1"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M3.74 3.749a.75.75 0 0 1 .75.75V15h13.938l-2.47-2.47a.75.75 0 0 1 1.061-1.06l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06-1.06l2.47-2.47H3.738a.75.75 0 0 1-.75-.75V4.5a.75.75 0 0 1 .75-.751Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="text-xs">Thread</span>
                            </div>
                          </div>
                        )}
                      </td>

                      {/* Assistant Column */}
                      <td className="relative max-w-sm px-4 py-4 pb-6 text-sm break-words align-top min-w-80">
                        <div
                          className={`${
                            isSynthetic
                              ? 'text-indigo-500 italic'
                              : 'text-gray-500'
                          } ${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {isSynthetic
                            ? syntheticAssistantMessage
                            : isExpanded && multithreaded
                              ? log.messages
                                  .filter((msg) => msg.role === 'assistant')
                                  .map((msg, index) => (
                                    <p key={index}>
                                      <div className="flex flex-row items-center">
                                        <span className="inline-flex items-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="size-4 mr-1"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M3.74 3.749a.75.75 0 0 1 .75.75V15h13.938l-2.47-2.47a.75.75 0 0 1 1.061-1.06l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06-1.06l2.47-2.47H3.738a.75.75 0 0 1-.75-.75V4.5a.75.75 0 0 1 .75-.751Z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                          <div className="text-zinc-800 font-medium ">
                                            <span className="font-light">
                                              Assistant {index}:
                                            </span>
                                          </div>
                                        </span>
                                      </div>
                                      <div className="ml-6">{msg.content}</div>
                                    </p>
                                  ))
                              : firstAssistantMessage}
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              openEditMessageModal(log.id, assistantMessage);
                            }}
                            className="absolute w-4 text-xs font-medium top-1 right-1 whitespace-nowrap"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="text-indigo-300 size-3"
                            >
                              <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
                            </svg>
                          </button>
                        </div>
                        {/* Expand Thread Label for Multithreaded Chats */}
                        {multithreaded && !isExpanded && (
                          <div className="absolute bottom-1 right-1 text-xs text-gray-500 italic ">
                            <div className="flex flex-row items-center ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-4 mr-1"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M3.74 3.749a.75.75 0 0 1 .75.75V15h13.938l-2.47-2.47a.75.75 0 0 1 1.061-1.06l3.75 3.75a.75.75 0 0 1 0 1.06l-3.75 3.75a.75.75 0 0 1-1.06-1.06l2.47-2.47H3.738a.75.75 0 0 1-.75-.75V4.5a.75.75 0 0 1 .75-.751Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <span className="text-xs">Thread</span>
                            </div>
                          </div>
                        )}
                      </td>

                      {/* Creation Date Column */}
                      <td className="px-4 py-4 pb-6 text-sm text-gray-500 align-top whitespace-nowrap">
                        <div
                          className={`${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {moment(log.created_at).format('DD-MM-YYYY h:mm A') ??
                            '-'}
                        </div>
                      </td>

                      {/* Model Column */}
                      <td className="px-4 py-4 pb-6 text-sm text-gray-500 align-top whitespace-nowrap">
                        <div
                          className={`${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {log.model ?? '-'}
                        </div>
                      </td>

                      {/* Kwargs Column */}
                      <td className="px-4 py-4 pb-6 text-sm text-gray-500 break-words align-top md:w-72">
                        <div
                          className={`${isExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
                        >
                          {typeof log.kwargs === 'object'
                            ? (JSON.stringify(log.kwargs) ?? '-')
                            : (log.kwargs ?? '-')}
                        </div>
                      </td>

                      {/* Tags Column */}
                      <td className="px-4 py-4 pb-6 text-sm text-gray-500 align-top">
                        <div className="flex flex-wrap gap-x-1 gap-y-2">
                          <Tags tagString={log.tags} isExpanded={isExpanded} />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              openAddTagsModal(idx, log.id);
                            }}
                            className="inline-block px-2 py-1 mr-2 text-xs font-medium text-gray-800 bg-indigo-200 rounded-full min-w-6 whitespace-nowrap"
                          >
                            +
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="p-4 text-sm text-center text-gray-500"
                  >
                    No logs found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {data.length > 0 && (
        <nav className="flex items-center justify-between px-4 pb-10 mt-10 border-t border-gray-200 sm:px-0">
          <div className="flex flex-1 w-0 -mt-px">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                currentPage === 1 ? 'hidden' : ''
              }`}
            >
              <ArrowLongLeftIcon
                className="w-5 h-5 mr-3 text-gray-400"
                aria-hidden="true"
              />
              Previous
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {displayedPages.map((page, index) =>
              page === 'start-ellipsis' || page === 'end-ellipsis' ? (
                <span
                  key={index}
                  className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500"
                >
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  onClick={() => handlePageChange(page)}
                  className={`inline-flex items-center border-t-2 ${
                    currentPage === page
                      ? 'border-indigo-800 text-indigo-800'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } px-4 pt-4 text-sm font-medium`}
                >
                  {page}
                </button>
              ),
            )}
          </div>
          <div className="flex justify-end flex-1 w-0 -mt-px">
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                currentPage === totalPages ? 'hidden' : ''
              }`}
            >
              Next
              <ArrowLongRightIcon
                className="w-5 h-5 ml-3 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </nav>
      )}
      <AddTagsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        tags={tags}
        setTags={setTags}
        rowIndex={selectedRow}
        logIds={selectedLogId}
        fetchLogs={fetchLogs}
      />
      <EditMessageModal
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        logId={selectedLogId}
        initialMessage={currentMessage}
        isSynthetic={isSynthetic}
        fetchLogs={fetchLogs}
      />
    </div>
  );
};

export default LogsTable;
