import React from 'react';

import SmallNavbar from './SmallNavbar';
import NavbarDemo from './NavbarDemo';

const ResponsiveNavbar = () => {
  return (
    <div className="w-full">
      <div className="block lg:hidden">
        <SmallNavbar />
      </div>
      <div className="hidden lg:block">
        <NavbarDemo />
      </div>
    </div>
  );
};

export default ResponsiveNavbar;
