import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';

import { useUser } from '../UserContext';
import { getDefaultLocaleForCurrency } from '../utils/localeCurrencyMapping';
import { amountToFrontend } from '../utils/CurrencyUtils';
const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

export const useCurrencyConverter = () => {
  const { displayCurrency } = useUser();
  const [exchangeRates, setExchangeRates] = useState({});

  const fetchRates = useCallback(async () => {
    try {
      const { data } = await axios.get(`${frontendServerUrl}/exchange-rates`);
      delete data.rates.USD;
      localStorage.setItem(
        'exchangeRates',
        JSON.stringify({
          expires_at: data.expires_at,
          rates: data.rates,
        }),
      );
      setExchangeRates(data.rates);
    } catch (error) {
      console.error('Error fetching exchange rates', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frontendServerUrl]);

  useEffect(() => {
    const stored = localStorage.getItem('exchangeRates');
    const nowInSeconds = Date.now() / 1000;
    if (stored) {
      const { expires_at, rates } = JSON.parse(stored);
      if (expires_at > nowInSeconds) {
        setExchangeRates(rates);
      } else {
        fetchRates();
      }
    } else {
      fetchRates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const money = useCallback(
    (amount, per = '', maxFractionDigits = 2) => {
      let rate = exchangeRates[displayCurrency];
      const currency = rate ? displayCurrency : 'USD';
      rate = rate || 1;

      const { locale } = getDefaultLocaleForCurrency(currency);

      const convertedAmount = amountToFrontend(amount * rate);
      let formattedAmount = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        maximumFractionDigits: maxFractionDigits,
      }).format(convertedAmount);

      if (['he-IL', 'es-ES'].includes(locale)) {
        // this is to invert the currency symbol. to put it before the number, right now i am doing it for Euro and Israeli Shekels. it should be done for all right to left (RTL) languages.
        const symbol = formattedAmount.slice(-1);
        formattedAmount = `${symbol}${formattedAmount.slice(0, -1)}`;
      }

      return per ? `${formattedAmount}/${per}` : formattedAmount;
    },
    [exchangeRates, displayCurrency],
  );

  return money;
};
