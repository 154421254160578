// hooks/useUploadLogs.js
import { useState } from 'react';

import { useUser } from '../UserContext';
import { useUploadService } from '../context/UploadService';

const useUploadLogs = () => {
  const { customAxios } = useUser();
  const { uploadService, state } = useUploadService();
  const [skipLogsWithErrors, setSkipLogsWithErrorsState] = useState(
    uploadService.skipLogsWithErrors || true,
  );

  const [createSyntheticLogs, setCreateSyntheticLogsState] = useState(
    uploadService.createSyntheticLogs || true,
  );

  const uploadFile = async () => {
    await uploadService.uploadFile(customAxios);
  };

  const cancelUpload = () => {
    uploadService.cancelUpload();
  };

  const setSelectedFile = (file) => {
    uploadService.selectedFile = file;
  };

  const setFileContent = (fileContent) => {
    uploadService.fileContent = fileContent;
  };

  const setSaveLogsTagList = (tags) => {
    uploadService.saveLogsTagList = tags;
  };

  const setError = (error) => {
    uploadService.error = error;
  };

  const setValidationMessages = (validationMessages) => {
    uploadService.validationMessages = validationMessages;
  };

  const setSkipLogsWithErrors = (value) => {
    setSkipLogsWithErrorsState(value);
    uploadService.skipLogsWithErrors = value;
  };

  const setCreateSyntheticLogs = (value) => {
    setCreateSyntheticLogsState(value);
    uploadService.createSyntheticLogs = value;
  };

  const clearReport = () => {
    uploadService.clearReport({});
  };

  return {
    ...state,
    uploadFile,
    cancelUpload,
    setSelectedFile,
    setFileContent,
    setSaveLogsTagList,
    setValidationMessages,
    skipLogsWithErrors,
    setSkipLogsWithErrors,
    createSyntheticLogs,
    setCreateSyntheticLogs,
    clearReport,
    setError,
  };
};

export default useUploadLogs;
