const certificateData = {
  metadata: {
    user_id: 'username',
    start_time: '04-12-2023 14:58:56.777036',
    end_time: '04-12-2023 14:59:05.714949',
    status: 'success',
  },
  hardware: {
    cpu: {
      type: '',
      platform: 'Linux-5.10.0-26-cloud-amd64-x86_64-with-glibc2.31',
      physical_cores: 4,
      total_cores: 8,
      max_frequency_mhz: 0.0,
      min_frequency_mhz: 0.0,
      current_frequency_mhz: 2299.998,
    },
    memory: {
      total: 31557165056,
      swap: 0,
    },
    disk: {
      '/dev/sda1': {
        mountpoint: '/',
        fstype: 'ext4',
        opts: 'rw,relatime,discard,errors=remount-ro',
        total_size: 105426538496,
      },
      '/dev/sda15': {
        mountpoint: '/boot/efi',
        fstype: 'vfat',
        opts: 'rw,relatime,fmask=0022,dmask=0022,codepage=437,iocharset=ascii,shortname=mixed,utf8,errors=remount-ro',
        total_size: 129718272,
      },
    },
    gpu: {
      index: '0',
      uuid: 'GPU-0be88c65-fbb4-a446-fe80-46dde922bb3b',
      'memory.total': '16384',
      driver_version: '525.105.17',
      gpu_name: 'Tesla V100-SXM2-16GB',
      gpu_bus_id: '00000000:00:04.0',
      gpu_serial: '0321418100883',
    },
  },
  environment: {
    hostname: 'instance-22',
    os: 'Linux',
    os_release: '5.10.0-26-cloud-amd64',
    os_version: '#1 SMP Debian 5.10.197-1 (2023-09-29)',
    os_architecture: 'x86_64',
  },
  software: {
    numba: '0.58.1',
    numpy: '1.25.2',
    pandas: '2.0.3',
    'scikit-learn': '1.3.2',
    scipy: '1.11.3',
    torch: '2.0.0+cu118',
    torchvision: '0.15.1+cu118',
  },
};

export default certificateData;
