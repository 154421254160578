import { useEffect, useState } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import toast from 'react-hot-toast';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Modal } from 'flowbite-react';

import LogsTable from './LogsTable';
import Spinner from '../../Spinner';
import { useUser } from '../../../UserContext';

const DatasetsComponent = () => {
  const { customAxios } = useUser();
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);
  const [filters, setFilters] = useState({
    models: [],
    tags: [],
    startDate: '',
    endDate: '',
  });
  const [retrievedLogs, setRetrievedLogs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalLogs, setTotalLogs] = useState(0);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [hasFilters, setHasFilters] = useState(false);
  const [showDeleteDatasetModal, setShowDeleteDatasetModal] = useState(false);
  const [selectedDatasetToDelete, setSelectedDatasetToDelete] = useState(null);

  const retrieveDatasets = async () => {
    setLoading(true);
    try {
      const response = await customAxios.get('tailor/v1/datasets');
      setDatasets(response.data?.message ?? []);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to retrieve datasets, please try again');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    retrieveDatasets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDataset = (dataset) => {
    if (selectedDataset === dataset.id) {
      setSelectedDataset(null);
      setFilters({
        models: [],
        tags: [],
        startDate: '',
        endDate: '',
      });
      setRetrievedLogs([]);
      setHasFilters(false);
      setTotalPages(0);
      return;
    }
    setSelectedDataset(dataset.id);
    setFilters({
      models: dataset.filters.models,
      tags: dataset.filters.tags,
      startDate: dataset.filters.start_date,
      endDate: dataset.filters.end_date,
    });
    setTotalPages(Math.ceil(dataset.total_logs / itemsPerPage));
    setHasFilters(true);
  };

  const fetchLogs = async () => {
    setLoadingLogs(true);

    const params = {
      items_per_page: itemsPerPage,
      page_number: page,
    };
    if (filters?.models?.length) {
      params.model = filters.models;
    }
    if (filters?.tags?.length) {
      params.tag = filters.tags;
    }
    if (filters?.startDate) {
      params.from_date = filters.startDate;
    }
    if (filters?.endDate) {
      params.to_date = filters.endDate;
    }
    try {
      const response = await customAxios.get('tailor/v1/data', { params });
      setRetrievedLogs(response.data?.logs ?? []);
      // setTotalPages(Math.ceil(parseInt(response.data?.total) / itemsPerPage));
      setPage(response.data?.page_number ?? 1);
      setTotalLogs(response.data?.total);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
    } finally {
      setLoadingLogs(false);
    }
  };

  useEffect(() => {
    if (hasFilters) {
      fetchLogs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, filters]);

  const handleDeleteDataset = async () => {
    setShowDeleteDatasetModal(false);
    if (!selectedDatasetToDelete) {
      return;
    }
    try {
      await customAxios.delete(
        `tailor/v1/datasets/${selectedDatasetToDelete.id}`,
      );
      toast.success('Dataset deleted successfully');
      retrieveDatasets();
      setSelectedDataset(null);
      setRetrievedLogs([]);
      setHasFilters(false);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to delete dataset, please try again');
    }
  };

  return (
    <>
      <div className="p-4 bg-zinc-50">
        <div className="overflow-x-hidden sm:-mx-6 md:0">
          <div className="inline-block max-w-full min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-y-scroll max-h-[30vh] border-b border-gray-200 rounded">
              <table className="w-full divide-y divide-gray-300 rounded-lg ">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-300">
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-xl line-clamp-2">
                      Dataset Name
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Number of Logs
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Creation Date
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Models
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Tags
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date Range
                    </th>
                    <th className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 max-h-[30vh] overflow-y-auto">
                  {datasets.length ? (
                    datasets.map((dataset) => (
                      <tr
                        key={dataset.id}
                        className={clsx(
                          'divide-x divide-gray-300 hover:bg-gray-100 cursor-pointer',
                          selectedDataset === dataset.id && '!bg-indigo-100 ',
                        )}
                        onClick={() => handleSelectDataset(dataset)}
                      >
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900 max-w-xl line-clamp-2">
                          {dataset.name}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {dataset?.description || ''}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {dataset?.total_logs || ''}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {moment(dataset.created_at).format(
                            'YYYY-MM-DD HH:mm',
                          )}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {dataset?.filters?.models ?? '-'}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {dataset.filters.tags &&
                          dataset.filters.tags.length > 0 ? (
                            <div className="flex flex-wrap gap-1">
                              {dataset.filters.tags.map((tag, index) => (
                                <span
                                  key={index}
                                  className="px-2 py-1 text-xs font-medium text-gray-800 rounded-full bg-zinc-200"
                                >
                                  {tag}
                                </span>
                              ))}
                            </div>
                          ) : (
                            <span>-</span>
                          )}
                        </td>

                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          {dataset.filters.from_date
                            ? moment(dataset.filters.from_date)?.format(
                                'YYYY-MM-DD HH:mm',
                              )
                            : ''}{' '}
                          -{' '}
                          {dataset.filters.to_date
                            ? moment(dataset.filters.to_date)?.format(
                                'YYYY-MM-DD HH:mm',
                              )
                            : ''}
                        </td>
                        <td className="px-4 py-3.5 text-left text-sm text-gray-900">
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedDatasetToDelete(dataset);
                              setShowDeleteDatasetModal(true);
                            }}
                            className="text-gray-500"
                          >
                            <TrashIcon className="w-4 h-4 text-zinc-800" />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="7"
                        className="py-4 text-sm text-center text-gray-500"
                      >
                        {loading ? (
                          <div className="flex flex-col items-center gap-2">
                            <Spinner size={'36px'} borderTopColor={'gray'} />
                          </div>
                        ) : (
                          'No datasets found'
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {hasFilters ? (
            <div className="px-4 py-10 border-t sm:px-6 lg:px-8">
              {loadingLogs ? (
                <div className="flex items-center justify-around h-full">
                  <div className="flex flex-col items-center gap-2">
                    <Spinner size={'36px'} borderTopColor={'gray'} />
                  </div>
                </div>
              ) : (
                <div className="mx-auto ">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex flex-row items-center font-medium text-gray-900">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="flex mr-2 size-5 text-zinc-800"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                        />
                      </svg>

                      <div className="mr-2 text-zinc-500">Dataset: </div>

                      {datasets.find((d) => d.id === selectedDataset)?.name}
                    </div>
                    <div className="flex items-center gap-4">
                      <div className="flex items-center gap-2">
                        <label className="text-sm text-gray-500">
                          Items per page
                        </label>
                        <select
                          value={itemsPerPage}
                          onChange={(e) => setItemsPerPage(e.target.value)}
                          className="px-2 py-1 text-sm text-gray-900 border border-gray-300 rounded-md"
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <LogsTable
                    data={retrievedLogs}
                    setPage={setPage}
                    currentPage={page}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </div>
          ) : datasets.length ? (
            <div className="px-4 py-10 border-t sm:px-6 lg:px-8">
              <div className="flex items-center justify-center h-full">
                <div className="text-gray-500">
                  Select a dataset to view logs
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Modal
        show={showDeleteDatasetModal}
        onClose={() => setShowDeleteDatasetModal(false)}
        dismissible
      >
        <div className="p-6 font-dmSans ">
          <h2 className="text-xl font-medium text-zinc-700">
            Are you sure you want to delete this dataset?
          </h2>
          <div className="flex items-center justify-end gap-4 mt-8">
            <button
              onClick={() => setShowDeleteDatasetModal(false)}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold border border-indigo-200 rounded-md shadow-sm text-zinc-800 hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteDataset}
              className="inline-flex justify-center px-3 py-2 text-sm font-semibold bg-red-200 rounded-md shadow-sm text-zinc-800 hover:bg-red-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DatasetsComponent;
