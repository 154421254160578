import { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { useUser } from '../UserContext';

const useCurrencySelector = ({ initialCurrency }) => {
  const {
    user,
    updateUserDetails,
    displayCurrency,
    setDisplayCurrency,
    customAxios,
  } = useUser();

  const handleSelectedCurrency = useCallback(
    async (currency) => {
      const prevCurrency = initialCurrency;
      localStorage.setItem('displayCurrency', currency);
      setDisplayCurrency(currency);

      try {
        if (user) {
          await updateUserDetails({ ...user, currency });
          await customAxios.post('update_profile', {
            field: 'currency',
            value: currency,
          });
        }
        toast.success('Currency updated');
      } catch (error) {
        setDisplayCurrency(prevCurrency);
        localStorage.setItem('displayCurrency', prevCurrency);
        toast.error('Error updating currency');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [displayCurrency],
  );

  return { handleSelectedCurrency };
};

export default useCurrencySelector;
