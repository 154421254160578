import React, { useState, useRef } from 'react';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { useForm, ValidationError } from '@formspree/react';
import clsx from 'clsx';

function PartnerForm({ isStudent, selectedOption }) {
  const [state, handleSubmit] = useForm('xkndjyed');
  if (state.succeeded) {
    return (
      <div className="flex items-center justify-center w-full">
        <div className="flex flex-col p-8 mb-24 border rounded-lg shadow bg-gradient-to-tr from-white to-gray-100 w-96">
          <h2 className="mb-4 text-xl font-semibold text-indigo-600">
            Thank you for applying!
          </h2>
          <p className="text-gray-700">
            We appreciate your interest. We will review your application and get
            back to you as soon as possible. In the meantime, feel free to reach
            out if you have any questions or need further information.
          </p>
        </div>
      </div>
    );
  }

  // Mapping for different label texts based on the selected option
  const interestLabelMapping = {
    Accelerators:
      'Describe your expectations from the AI Accelerators partnership and how it aligns with your goals.',
    'AI Startups':
      'Tell us more about your business. What do you hope to get out of the AI Startups program? How would we be able to help you?',
    'Student Unions':
      'How do you plan to integrate AI into your student union activities and initiatives?',
    'Academic Institutions':
      'What are your objectives for incorporating AI into your academic institution’s curriculum and research?',
  };

  // Get the appropriate label text based on the selected option
  const interestLabel = interestLabelMapping[selectedOption];

  return (
    <div className="w-full h-screen bg-zinc-50 font-dmSans">
      <form
        onSubmit={handleSubmit}
        className="p-8 mx-2 mt-10 mb-24 border rounded-md rounded-lg shadow-lg font-dmSans sm:mx-auto md:w-1/2"
      >
        <div className="space-y-12">
          <div className="pb-6 border-b border-gray-900/10">
            <h2 className="text-base font-semibold leading-7 text-indigo-600 ">
              Apply to Tromero for {selectedOption}
            </h2>
            <div className="grid grid-cols-1 mt-4 gap-x-6 gap-y-8 sm:grid-cols-6"></div>
          </div>

          {/* General Information */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="fullName">Full Name</label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="fullName"
                type="text"
                name="fullName"
                required
              />
              <ValidationError
                prefix="Full Name"
                field="fullName"
                errors={state.errors}
              />
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email">Email Address</label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                id="email"
                type="email"
                name="email"
                required
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </div>
          </div>

          {/* Conditional Rendering for Students or Professionals */}
          {isStudent ? (
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="academicInstitution">
                  Academic Institution
                </label>
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                  id="academicInstitution"
                  type="text"
                  name="academicInstitution"
                  required={isStudent}
                />
                <ValidationError
                  prefix="Academic Institution"
                  field="academicInstitution"
                  errors={state.errors}
                />
              </div>
              <div className="sm:col-span-6">
                <label htmlFor="department">
                  {selectedOption === 'Student Unions'
                    ? 'Student Union Name'
                    : 'Department'}
                </label>
                <input
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                  id="department"
                  type="text"
                  name="department"
                  required={isStudent}
                />
                <ValidationError
                  prefix={
                    selectedOption === 'Student Unions'
                      ? 'Student Union Name'
                      : 'Department'
                  }
                  field="department"
                  errors={state.errors}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    id="companyName"
                    type="text"
                    name="companyName"
                    required={!isStudent}
                  />
                  <ValidationError
                    prefix="Company Name"
                    field="companyName"
                    errors={state.errors}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="companyWebsite">Company Website</label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    id="companyWebsite"
                    type="text"
                    name="companyWebsite"
                    required={!isStudent}
                  />
                  <ValidationError
                    prefix="Company Website"
                    field="companyWebsite"
                    errors={state.errors}
                  />
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="jobTitle">Job Title</label>
                  <input
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                    id="jobTitle"
                    type="text"
                    name="jobTitle"
                    required={!isStudent}
                  />
                  <ValidationError
                    prefix="Job Title"
                    field="jobTitle"
                    errors={state.errors}
                  />
                </div>
              </div>
            </>
          )}

          {/* Location Fields */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="country">Country</label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                id="country"
                type="text"
                name="country"
                required
              />
              <ValidationError
                prefix="Country"
                field="country"
                errors={state.errors}
              />
            </div>
            <div className="sm:col-span-3">
              <label htmlFor="city">City</label>
              <input
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2"
                id="city"
                type="text"
                name="city"
                required
              />
              <ValidationError
                prefix="City"
                field="city"
                errors={state.errors}
              />
            </div>
          </div>

          {/* Additional Information */}
          <div>
            <label htmlFor="interest">{interestLabel}</label>
            <textarea
              className="block w-full h-24 mt-2 text-gray-900 border-0 rounded-md shadow-sm resize-none ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              id="interest"
              name="interest"
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={state.submitting}
            className="block w-full px-4 py-2 font-semibold text-center text-white bg-indigo-600 rounded-md sm:w-1/2 md:w-1/4 focus:outline-none focus:bg-indigo-600 hover:bg-indigo-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {state.submitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
}

const PartnerWithUs = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(
    location.state?.title || 'AI Startups',
  );
  // console.log(location.state?.title);

  const options = [
    'Accelerators',
    'AI Startups',
    'Student Unions',
    'Academic Institutions',
  ];
  const isStudent =
    selectedOption === 'Student Unions' ||
    selectedOption === 'Academic Institutions';

  const formRef = useRef(null);

  // Function to scroll to the form
  const scrollToForm = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="w-screen min-h-screen bg-zinc-50">
      <div className="max-w-[1500px] mx-auto bg-zinc-50 dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-col sm:flex sm:flex-row ">
          {/* Left Side Content */}
          <div className="flex-grow dark:bg-gray-800 md:w-1/2 dark:text-gray-100">
            <div className="px-6 py-24 mx-auto max-w-7xl sm:py-32 lg:px-8 font-dmSans">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-100">
                Join Our Ecosystem
                <br />
                Where Innovation Thrives
              </h2>
              <div className="w-full mt-10 font-sans text-base leading-relaxed text-gray-700 lg:w-2/3 dark:text-gray-100">
                Thank you for exploring Tromero —— a community committed to
                democratic access to AI. At Tromero, we understand the critical
                role of resources and expertise in driving progress. That's why
                we offer academic institutions, AI startups, accelerators, and
                student unions unparalleled benefits, including access to GPUs
                at significantly reduced prices and innovative, out-of-the-box
                techniques for AI training and inference. These advantages
                empower our partners to accelerate their projects, from
                groundbreaking research to the development of cutting-edge
                applications, without the burden of high costs.
              </div>
              <div className="flex flex-col justify-start w-full mt-10 md:items-center sm:justify-none md:gap-x-6 gap-x-2 md:flex-row">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white h-12 w-full px-4 sm:w-96 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-xl font-medium tracking-tight text-gray-900 mb-4 md:mb-0">
                      Tromero for {selectedOption}
                      <ChevronDownIcon
                        className="w-5 h-5 -mr-1 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg sm:w-96 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {options.map((option) => (
                          <Menu.Item key={option}>
                            {({ active }) => (
                              <button
                                onClick={() => setSelectedOption(option)}
                                className={clsx(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm w-full text-left',
                                )}
                              >
                                {option}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <button
                  onClick={scrollToForm}
                  className="inline-flex justify-center items-center gap-x-1.5 rounded-md bg-indigo-600 text-white h-12 w-full px-4 min-w-36 sm:w-36    shadow-sm ring-1 ring-inset ring-indigo-600 hover:bg-indigo-700 text-lg font-medium tracking-tight"
                >
                  Apply now
                </button>{' '}
              </div>
            </div>
          </div>

          {/* Right Side - Blue Square */}
          <div className="flex items-center justify-center flex-grow">
            <div className="relative w-64 h-64 md:w-96 md:h-96">
              {/* Shadowed Div */}
              <div className="absolute inset-0 flex items-center justify-center scale-75 rounded-full shadow-xl lg:scale-100">
                {/* This div is just for the shadow */}
              </div>

              {/* Content Div */}
              <div className="absolute inset-0 flex items-center justify-center scale-75 border rounded-full lg:scale-100 wavyslow shadow-3xinner">
                <img
                  className="scale-150"
                  src="/constellation.png"
                  alt="Constellation"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Content based on Dropdown Selection */}
        <div className="p-6 mt-6 dark:bg-gray-800 dark:text-gray-100">
          {selectedOption === 'Accelerators' && (
            <div>
              <h3 className="mb-10 text-2xl font-medium">
                Accelerators Partnership
              </h3>
              <div className="w-full font-sans text-base leading-relaxed text-gray-700 md:w-4/5 dark:text-gray-100">
                <p>
                  The Accelerator Partnership Program is tailored to align with
                  early stage companies needs and support for the compute needs
                  they may have. For such programs Tromero offers bespoke
                  product engagement and program support.
                  <br></br>
                  <br></br>
                </p>

                <p>
                  Benefits of our Accelerators Partnership include:<br></br>
                  <br></br>
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    <span className="font-bold">
                      Discounted Access to GPUs:
                    </span>{' '}
                    Exclusive access to GPUs at a discounted rate. This helps
                    the accelerator startups scale their AI models more
                    cost-effectively.
                  </li>
                  <li>
                    <span className="font-bold">
                      Co-Marketing Opportunities:
                    </span>{' '}
                    Joint webinars, co-authored white papers, and shared
                    exhibition spaces at industry events. This can increase
                    visibility and brand awareness for the accelerator as well
                    as the portfolio companies.
                  </li>
                  <li>
                    <span className="font-bold">
                      Technical Support and Training:
                    </span>{' '}
                    Provide dedicated technical support and training sessions
                    for the accelerator’s startups. This includes access to
                    experts for troubleshooting, advice on best practices for AI
                    training and inference, and updates on the latest AI
                    technologies.
                  </li>
                  <li>
                    <span className="font-bold">
                      Exclusive Events and Networking Opportunities:
                    </span>{' '}
                    Startups from the program are invited to exclusive events
                    such as roundtable discussions, workshops, and networking
                    events with industry leaders. This helps build valuable
                    connections and gain insights into industry trends.
                  </li>
                </ul>

                <p>
                  <br></br>
                  Please review our program qualifications thoroughly before
                  applying, to ensure your accelerator receives the precise
                  support it needs.<br></br>
                  <br></br>
                </p>

                <h4 className="text-xl font-medium">
                  Qualifying for the Program:<br></br>
                  <br></br>
                </h4>
                <p>
                  To be eligible, ensure your AI venture aligns with the
                  following criteria:<br></br>
                  <br></br>
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    The accelerator should have a focus on companies that use
                    AI, machine learning, data science or other computationally
                    expensive technologies in their products and require HPC
                    resources.
                  </li>
                </ul>

                <p>
                  <br></br>
                  <br></br>
                  Final acceptance into this program is at our discretion.
                  Startups not eligible for the AI Accelerator Program may still
                  qualify for other forms of support or credits.<br></br>
                  <br></br>
                </p>
              </div>
            </div>
          )}
          {selectedOption === 'AI Startups' && (
            <div>
              <h3 className="mb-10 text-2xl font-medium">
                AI Startups Program
              </h3>
              <div className="w-4/5 font-sans text-base leading-relaxed text-gray-700 dark:text-gray-100">
                <p>
                  Our aim is to support growth in the AI sector, particularly
                  for emerging AI startups. The AI Startup Program is designed
                  to support enterprises at crucial early stages, providing
                  tailored product engagement and comprehensive program support.
                  Our support includes essential services and access to the
                  latest hardware and software for AI training, with goal of
                  facilitating rapid development and efficient scaling.
                  <br />
                  <br />
                </p>

                {/* <p>
                This program specifically targets AI startups, helping you scale
                your operations with product credits, specialized AI technical
                training, and business support that evolves alongside your
                startup. We offer intelligent, cost-effective technology
                solutions that are key to swift and sustainable business growth.
                <br />
                <br />
              </p> */}

                <p>
                  Joining the AI Startup Program gives your startup access to a
                  suite of benefits, including:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    <span className="font-bold">Engineers Time:</span> The major
                    help we offer is our engineers time to developing solutions
                    for your specific needs, and personalising our existing
                    offerings, so that program engineers spend less time on
                    having to develop them. E.g. Want to try custom fine-tuning
                    strategies? We’ll chip in and get you started, so it’s a 2
                    day workflow for you, not a 2 week side-quest.
                  </li>
                  <br />
                  <li>
                    <span className="font-bold">Initial Credits:</span> Some
                    initial credits for GPU-time, with opportunities for
                    additional support based on the scope of your startup's
                    needs.
                  </li>
                  <br />
                  <li>
                    <span className="font-bold">Personalised Support:</span>{' '}
                    Technical personalised support from our dedicated team.
                  </li>
                </ul>

                <p>
                  <br />
                  We encourage you to review our program qualifications
                  carefully to ensure that your startup is well-aligned with the
                  support we offer.
                  <br />
                  <br />
                </p>

                <h4 className="text-xl font-medium">
                  Eligibility for the Program:
                  <br />
                  <br />
                </h4>
                <p>
                  To qualify, your AI startup should meet the following
                  criteria:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    Your startup is focused on AI, backed by incubators,
                    accelerators, or VCs, and operates within territories that
                    are free from international sanctions.
                  </li>
                  <li>
                    You are actively seeking a long-term partnership to help
                    scale and develop your AI business.
                  </li>
                  <li>
                    Your startup has a publicly accessible website and has
                    already made significant strides in establishing a presence
                    in the AI landscape.
                  </li>
                </ul>

                <p>
                  <br />
                  Acceptance into the program is at our discretion, and we
                  consider each startup's unique potential and needs. Even if
                  your startup does not meet the criteria for the AI Startups
                  Program, other forms of support or credits may still be
                  available.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          )}
          {selectedOption === 'Student Unions' && (
            <div>
              <h3 className="mb-10 text-2xl font-medium">
                Student Unions Partnership
              </h3>
              <div className="w-4/5 font-sans text-base leading-relaxed text-gray-700 dark:text-gray-100">
                <p>
                  Our Student Unions Partnership Program is tailored to empower
                  student organisations that are enthusiastic about exploring
                  and integrating AI technologies. This initiative is dedicated
                  to providing student unions with the tools, resources, and
                  knowledge needed to foster a thriving AI culture within their
                  communities.
                  <br />
                  <br />
                </p>

                <p>
                  Key benefits of our Student Unions Partnership Program
                  include:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    Access to AI tools and platforms, enabling practical
                    experience and application of AI concepts.
                  </li>
                  <li>
                    Resources and workshops tailored to enhance technical skills
                    and understanding of AI.
                  </li>
                  <li>
                    Networking opportunities with AI professionals and industry
                    leaders, providing insights into AI trends and career
                    pathways.
                  </li>
                  <li>
                    Collaborative project opportunities to apply AI solutions to
                    real-world challenges.
                  </li>
                </ul>

                <p>
                  <br />
                  We encourage student unions to review the eligibility criteria
                  and consider how this program can benefit their members and
                  wider community.
                  <br />
                  <br />
                </p>

                <h4 className="text-xl font-medium">
                  Eligibility Criteria:
                  <br />
                  <br />
                </h4>
                <p>
                  To participate in the Student Unions Program, please ensure
                  that your organization meets the following criteria:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    Official recognition as a student union or organization
                    within an academic institution.
                  </li>
                  <li>
                    A demonstrated interest in AI and technology, with
                    initiatives or plans to integrate AI into organisational
                    activities.
                  </li>
                  <li>
                    Commitment to fostering an inclusive and collaborative
                    environment for learning and experimenting with AI.
                  </li>
                </ul>

                <p>
                  <br />
                  Our selection process is based on the union's commitment to AI
                  and its potential impact on the student body.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          )}
          {selectedOption === 'Academic Institutions' && (
            <div>
              <h3 className="mb-10 text-2xl font-medium">
                Academic Institutions Partnership Program
              </h3>
              <div className="w-4/5 font-sans text-base leading-relaxed text-gray-700 dark:text-gray-100">
                <p>
                  Our Academic Institutions Partnership Program is specifically
                  designed to support universities and colleges in integrating
                  AI into their curriculum and research activities. This program
                  aims to bridge the gap between academic theory and practical
                  AI application, enhancing the educational experience for
                  students and faculty alike.
                  <br />
                  <br />
                </p>

                <p>
                  Benefits of our Academic Institutions Program include:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    <span className="font-bold">
                      Access to State-of-the-Art GPUs:
                    </span>{' '}
                    The students will have access to state-of-the-art hardware
                    configured for AI/ML workloads which is constantly updated.
                  </li>
                  <li>
                    <span className="font-bold">Discounted Prices:</span>{' '}
                    Academic Institutions benefit from a year long contract that
                    results in a much cheaper alternative to current cloud
                    providers. Contact{' '}
                    <a
                      href="mailto:sales@tromero.ai"
                      className="text-indigo-600"
                    >
                      sales@tromero.ai
                    </a>
                    {''} for more exact quotes.
                  </li>
                  <li>
                    <span className="font-bold">Interactive Sessions:</span>{' '}
                    Many academic institutions only allow batch jobs for HPC, AI
                    workflows require interactive sessions which Tromero offers.
                  </li>
                  <li>
                    <span className="font-bold">Pre-Build Containers:</span> A
                    lot of prebuilt images making it easy to develop on top of
                    the current state-of-the-art.
                  </li>
                </ul>

                <p>
                  <br />
                  We invite academic institutions to evaluate the eligibility
                  criteria and explore the potential benefits for their students
                  and faculty and apply to be a partner.
                  <br />
                  <br />
                </p>

                <h4 className="text-xl font-medium">
                  Eligibility Criteria:
                  <br />
                  <br />
                </h4>
                <p>
                  To be eligible for the Academic Institutions Program, your
                  institution should meet the following criteria:
                  <br />
                  <br />
                </p>
                <ul className="ml-6 list-disc">
                  <li>
                    Accreditation as a higher education institution offering
                    courses or degrees in AI, computer science, or related
                    fields.
                  </li>
                  <li>
                    Demonstrated commitment to incorporating AI technologies and
                    methodologies into the curriculum.
                  </li>
                  <li>
                    A readiness to engage in collaborative research and
                    educational initiatives in the field of AI.
                  </li>
                </ul>

                <p>
                  <br />
                  Applications are evaluated based on the institution's
                  dedication to AI education and research, and the potential
                  impact on students and the academic community.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          )}
        </div>
        <div ref={formRef}>
          <PartnerForm isStudent={isStudent} selectedOption={selectedOption} />
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
