import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetModels } from '../../Hooks/react-query';
import MyModelCard from '../Tailor/MyModels/MyModelCard';
import { useSetAtom } from 'jotai';
import { modelStateAtom } from '../../context/atoms';

export const ModelsList = () => {
  return (
    <ModelsErrorBoundary>
      <Suspense fallback={<ModelsLoadingFallback />}>
        <ModelsListChild />
      </Suspense>
    </ModelsErrorBoundary>
  );
};

// Main ModelsList component
const ModelsListChild = () => {
  const setModelState = useSetAtom(modelStateAtom);
  const { data: modelsData } = useGetModels();

  useEffect(() => {
    if (modelsData) {
      for (const model of modelsData) {
        setModelState((prev) => ({
          ...prev,
          [model.model_id]: model.state,
        }));
      }
    }
  }, [modelsData, setModelState]);

  const originalSort = useCallback((models) => {
    return models.sort((a, b) => {
      const stateOrder = [
        'deployed',
        'deploying',
        'dormant',
        'training',
        'failed',
        'failed_deploy',
        'failed_undeploy',
        'start_training',
        'failed_training',
      ];
      const stateIndexA = stateOrder.indexOf(a.state);
      const stateIndexB = stateOrder.indexOf(b.state);
      if (stateIndexA !== stateIndexB) {
        return stateIndexA - stateIndexB;
      }
      return (
        new Date(b.created_at_unix).getTime() -
        new Date(a.created_at_unix).getTime()
      );
    });
  }, []);

  const deployList = useMemo(() => {
    const filteredModels = modelsData.filter(
      (model) =>
        (model.state === 'dormant' ||
          model.state === 'deployed' ||
          model.state === 'failed_deploy' ||
          model.state === 'undeploying' ||
          model.state === 'deploying' ||
          model.state === 'failed_undeploy') &&
        model.base_model_data.available_for_inference,
    );
    return originalSort(filteredModels);
  }, [modelsData, originalSort]);

  return (
    <div className="grid grid-cols-1 gap-4 md+:grid-cols-2">
      {deployList.filter((model) => model.state === 'deployed').length > 0 ? (
        deployList
          .filter((model) => model.state === 'deployed')
          .map((model) => (
            <Fragment key={model.model_id}>
              <MyModelCard model={model} basePath="./my-models" />
            </Fragment>
          ))
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

// EmptyState component
const EmptyState = () => (
  <Link
    to="/tailor/deploy"
    type="button"
    className="relative block w-full p-12 text-center border border-dashed rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
  >
    <svg
      className="w-6 h-6 mx-auto text-gray-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 1v16M1 9h16"
      />
    </svg>
    <div className="block mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200">
      Deploy a model
    </div>
  </Link>
);

// Loading fallback component - skeleton
export const ModelsLoadingFallback = () => (
  <div className="w-full h-40 animate-pulse">
    <div className="grid grid-cols-1 gap-4 md+:grid-cols-2">
      {[1, 2].map((i) => (
        <div
          key={i}
          className="relative flex flex-col justify-between w-full h-40 p-4 bg-gray-100 border rounded-lg"
        >
          <div className="w-1/2 h-4 bg-gray-200 rounded"></div>
          <div className="w-3/4 h-4 bg-gray-200 rounded"></div>
          <div className="w-1/4 h-4 bg-gray-200 rounded"></div>
        </div>
      ))}
    </div>
  </div>
);

// Error boundary component
export const ModelsErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleError = (_error) => {
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <div className="text-center text-red-500 p-4 min-h-screen">
        <p>Something went wrong loading the models.</p>
        <button
          onClick={async () => {
            setHasError(false);
            await queryClient.resetQueries({ queryKey: ['models'] });
          }}
          className="mt-2 px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
        >
          Try again
        </button>
      </div>
    );
  }

  return children;
};

export default ModelsList;
