import React, { useState, useMemo } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const ModelTypeFilter = ({
  selectedBaseModels,
  setSelectedBaseModels,
  selectedCustomModels,
  setSelectedCustomModels,
  showBaseModels,
  setShowBaseModels,
  data,
}) => {
  const [showBaseModelsList, setShowBaseModelsList] = useState(true);

  const baseModels = useMemo(() => {
    return (
      data
        ?.filter((model) => model.id?.toString().startsWith('base_'))
        .map((model) => model.displayName) || []
    );
  }, [data]);

  const customModels = useMemo(() => {
    return (
      data
        ?.filter((model) => !model.id?.toString().startsWith('base_'))
        .map((model) => model.displayName) || []
    );
  }, [data]);

  return (
    <div className="mb-4 lg:w-1/2 border p-4 rounded-lg">
      <h3 className="font-semibold mb-2">Filter Models</h3>

      <div className="mb-4">
        <div className="flex gap-2 flex-wrap text-sm">
          {customModels.map((model) => (
            <label
              key={model}
              className="flex items-center gap-2 border border-gray-200 rounded p-1 px-2"
            >
              <input
                type="checkbox"
                className="relative shadow-sm border border-gray-300 rounded bg-white w-4 h-4 ring-0 appearance-none active:ring-0 checked:bg-indigo-400"
                checked={selectedCustomModels.includes(model)}
                onChange={(e) => {
                  setSelectedCustomModels((prev) =>
                    e.target.checked
                      ? [...prev, model]
                      : prev.filter((m) => m !== model),
                  );
                }}
              />
              <span className="text-sm">{model}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="gap-2 border border-gray-200 rounded p-1 px-2 w-fit">
        <div className="flex items-center gap-2">
          <input
            id="select-all-base-models"
            type="checkbox"
            className="relative shadow-sm border border-gray-300 rounded bg-white w-4 h-4 ring-0 appearance-none active:ring-0 checked:bg-indigo-400"
            checked={showBaseModels && selectedBaseModels.length > 0}
            onChange={(e) => {
              if (e.target.checked) {
                setShowBaseModels(true);
                setSelectedBaseModels(baseModels);
              } else {
                setShowBaseModels(false);
                setSelectedBaseModels([]);
              }
            }}
          />
          <button
            onClick={() => setShowBaseModelsList(!showBaseModelsList)}
            className="flex items-center gap-2 w-full text-left hover:bg-gray-50 p-1 rounded"
          >
            <span className="text-sm font-medium flex items-center gap-2">
              Base Models
              <FaChevronDown
                className={`ml-auto transition-transform duration-200 ${
                  showBaseModelsList ? 'rotate-180' : ''
                }`}
              />
            </span>
          </button>
        </div>

        {showBaseModelsList && (
          <div className="flex gap-2 flex-wrap text-sm ml-6 transition-all duration-200 mt-2">
            {baseModels.map((model) => (
              <label
                key={model}
                className="flex items-center gap-2 border border-gray-200 rounded p-1 px-2"
              >
                <input
                  type="checkbox"
                  className="relative shadow-sm border border-gray-300 rounded bg-white w-4 h-4 ring-0 appearance-none active:ring-0 checked:bg-indigo-400"
                  checked={selectedBaseModels.includes(model)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedBaseModels((prev) => [...prev, model]);
                      setShowBaseModels(true);
                    } else {
                      setSelectedBaseModels((prev) =>
                        prev.filter((m) => m !== model),
                      );
                      if (selectedBaseModels.length <= 1) {
                        setShowBaseModels(false);
                      }
                    }
                  }}
                />
                <span className="text-sm">{model}</span>
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModelTypeFilter;
