import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const CreatePromptModal = ({ onClose, addNewPrompt, prompt, deletePrompt }) => {
  let [isOpen, setIsOpen] = useState(true);
  let [promptName, setPromptName] = useState('');
  let [promptValue, setPromptValue] = useState('');

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  function handleSubmit() {
    addNewPrompt(promptName, promptValue);
    setPromptName('');
    setPromptValue('');
  }

  useEffect(() => {
    if (prompt) {
      setPromptName(prompt.name);
      setPromptValue(prompt.value);
    }
  }, [prompt]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl font-dmSans">
                <Dialog.Title
                  as="h3"
                  className="mb-6 text-lg font-medium leading-6 text-zinc-500"
                >
                  Create prompt
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="Prompt Name"
                    value={promptName}
                    onChange={(e) => setPromptName(e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-zinc-600"
                  />
                  <textarea
                    type="text"
                    placeholder="System Prompt"
                    value={promptValue}
                    onChange={(e) => setPromptValue(e.target.value)}
                    className="w-full h-48 p-2 mt-2 text-sm border border-gray-300 resize-none focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-zinc-600"
                  />
                </div>

                <div className="mt-4 flex justify-between">
                  <div className="">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={handleSubmit}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>

                  {prompt && (
                    <button
                      type="button"
                      className="gap-1 p-2 px-3 text-sm font-bold rounded-md text-zinc-700 hover:underline underline-offset-4"
                      onClick={() => {
                        deletePrompt(prompt);
                        closeModal();
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreatePromptModal;
