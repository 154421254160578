import { Modal } from 'flowbite-react';

const PortMappingModal = ({ showModal, closeModal, ports = [] }) => {
  const handleClose = () => {
    closeModal();
  };
  return (
    <Modal show={showModal} onClose={closeModal} className="font-spaceG">
      <Modal.Header>
        <p>Port Mapping</p>
      </Modal.Header>
      <Modal.Body>
        {ports.length > 0 ? (
          <div className="text-sm font-semibold leading-relaxed md:text-base ">
            Here are the ports you can use to access your service from the
            outside
            <ul className="mt-4 space-y-2 list-inside">
              {ports.map((port) => (
                <li key={port.name} className="capitalize">
                  Requested Port:{' '}
                  <span className="uppercase">
                    {port.name.split('-')[0]} - {port.name.split('-')[2]}
                  </span>
                  <div className="px-4 py-2 mt-1 text-sm font-normal text-gray-500 break-words border-2 rounded md:text-base">
                    {port.node_port}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="text-sm font-semibold leading-relaxed md:text-base ">
            No ports were requested
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-end gap-2">
          <button
            className="h-12 text-sm font-medium text-center text-white rounded-md w-28 flex-center bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
            onClick={handleClose}
          >
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PortMappingModal;
