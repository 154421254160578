import {
  PaymentPolicyListItems,
  PaymentPolicyTailorListItems,
} from '../../Components/Modals/HowPaymentWorksModal';

const PaymentPolicy = () => {
  return (
    <div className="max-w-[1500px] min-h-screen mx-auto font-sans text-base pt-12 px-4 dark:text-gray-200">
      <h1>Payment Policy</h1>
      <br />
      <p>
        <b>Last Updated:</b> 10 July 2024
      </p>
      <br />
      <p>
        This Payment Policy (“<strong>Policy</strong>”) outlines how the payment
        system for use of our Company Services works. This Policy supplements
        our Terms
      </p>
      <br />
      <p>By adding a Card to our Website, you agree to our Terms.</p>
      <br />
      <h2>For Tromero GPU Clusters:</h2>
      <ul className="px-4 space-y-1 list-disc list-outside">
        {<PaymentPolicyListItems />}
      </ul>
      <br />
      <h2>For Tromero Tailor:</h2>
      <ul className="px-4 space-y-1 list-disc list-outside">
        {<PaymentPolicyTailorListItems />}
      </ul>
    </div>
  );
};

export default PaymentPolicy;
