import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';
import Spinner from '../../Spinner';

const AddTagsModal = ({
  isOpen,
  setIsOpen,
  tags,
  rowIndex,
  logIds,
  fetchLogs,
}) => {
  const [newTag, setNewTag] = useState('');
  const [error, setError] = useState('');
  const [localTags, setLocalTags] = useState(tags[rowIndex] || []);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showUnsavedChangesMessage, setShowUnsavedChangesMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const { customAxios } = useUser();

  useEffect(() => {
    setLocalTags(tags[rowIndex] || []);
  }, [tags, rowIndex]);

  const closeModal = async () => {
    setIsOpen(false);
    setNewTag('');
    setError('');
    setHasUnsavedChanges(false);
    setShowUnsavedChangesMessage(false);
  };

  const handleAddTag = async () => {
    let finalTags = localTags;
    const trimmedTag = newTag.trim();
    if (trimmedTag) {
      if (localTags.includes(trimmedTag)) {
        setError('This tag already exists.');
      } else {
        finalTags = [...localTags, trimmedTag];
        setLocalTags(finalTags);
        setNewTag('');
        setError('');
        setHasUnsavedChanges(true);
      }
    }
    return finalTags;
  };

  const handleRemoveTag = (tagToRemove) => {
    setLocalTags((prevTags) => prevTags.filter((tag) => tag !== tagToRemove));
    setHasUnsavedChanges(true);
  };

  const handleSave = async () => {
    setLoading(true);
    const tags = await handleAddTag();
    try {
      const logIdsArray = Array.isArray(logIds) ? logIds : [logIds];
      const payload = {
        chat_log_ids: logIdsArray,
        tags: tags,
        replace: typeof rowIndex === 'number',
      };
      await customAxios.post(`tailor/v1/bulk_add_tags`, payload);
      toast.success('Tags modified successfully');
      fetchLogs();
      closeModal();
    } catch (error) {
      setError('Failed to save tags.');
      toast.error('Failed to save tags.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddTag();
    }
  };

  const attemptCloseModal = () => {
    if (hasUnsavedChanges) {
      setShowUnsavedChangesMessage(true);
    } else {
      closeModal();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 font-dmSans"
        onClose={attemptCloseModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
                <button
                  type="button"
                  className="absolute text-gray-400 top-3 right-3 hover:text-gray-500"
                  onClick={attemptCloseModal}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
                <div className="flex items-center justify-center w-12 h-12 mx-auto bg-indigo-200 rounded-full">
                  <PlusCircleIcon
                    className="w-6 h-6 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Add Tags
                  </Dialog.Title>
                  <div className="mt-4">
                    <div className="flex flex-wrap gap-2 mt-2">
                      {localTags.map((tag, index) => (
                        <div
                          key={index}
                          className="flex items-center px-2 py-1 text-xs font-medium text-gray-800 rounded-full bg-zinc-200"
                        >
                          {tag}
                          <button
                            type="button"
                            className="ml-2 text-gray-400 hover:text-gray-500"
                            onClick={() => handleRemoveTag(tag)}
                          >
                            <XMarkIcon className="w-3 h-3" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="inline-block py-1 mt-4 text-sm font-medium text-gray-700">
                  To add multiple tags: type the tag name and press Enter to add
                  a new tag to the list, then press save to add your complete
                  list of tags.
                </div>
                <div className="">
                  <input
                    type="text"
                    value={newTag}
                    onChange={(e) => {
                      setNewTag(e.target.value);
                      setError('');
                    }}
                    placeholder="Input tag"
                    className="w-full p-2 mt-2 border border-gray-300 rounded-md focus:ring-0 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50"
                    onKeyDown={handleKeyDown}
                  />
                  {error && (
                    <p className="mt-2 text-sm text-red-600">{error}</p>
                  )}
                </div>
                {!showUnsavedChangesMessage && (
                  <div className="flex justify-between mt-4">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md w-36 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={handleSave}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner size="20px" borderTopColor="black" />
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                )}
                {showUnsavedChangesMessage && (
                  <div className="mt-4">
                    <p className="my-4 text-sm font-medium text-gray-700">
                      You have unsaved changes, would you like to save first?
                    </p>
                    <div className="flex justify-between mt-2">
                      <button
                        type="button"
                        className="inline-flex justify-center px-3 py-2 text-sm font-semibold bg-gray-200 rounded-md shadow-sm text-zinc-800 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        onClick={() => closeModal()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center px-3 py-2 text-sm font-semibold bg-indigo-200 rounded-md shadow-sm text-zinc-800 hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSave}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddTagsModal;
