import { useCallback, useMemo } from 'react';

const useGetCodeSnippet = ({
  conversation,
  model,
  finalParameters,
  isSplitView,
  chainOfThought,
}) => {
  const messagesString = useCallback((conversation) => {
    // Append the new message to the conversation array
    const updatedConversation = [
      ...conversation,
      { role: 'user', content: 'YOUR TEXT HERE' },
    ];

    if (!updatedConversation.length) {
      return '[]';
    }

    const stringLines = updatedConversation.map((line, index, array) => {
      const stringLine = JSON.stringify(line);
      // Check if this is the last item
      const isLast = index === array.length - 1;
      // Conditionally add a comma
      return `\t\t${stringLine}${isLast ? '' : ','}\n`;
    });

    return `[\n${stringLines.join('')}\t]`;
  }, []);

  const parametersString = useCallback((parameters, selectedLanguage) => {
    if (!parameters) {
      return '';
    }
    const stringLines = Object.entries(parameters).map(
      ([key, value], index, array) => {
        let stringValue;
        switch (selectedLanguage) {
          case 'python':
            if (typeof value === 'boolean') {
              stringValue = value ? 'True' : 'False';
            } else if (typeof value === 'string') {
              stringValue = `"${value}"`;
            } else {
              stringValue = value;
            }
            return `\t${key}=${stringValue},\n`;
          case 'javascript':
            if (typeof value === 'string') {
              stringValue = `"${value}"`;
            } else {
              stringValue = value;
            }
            return `\t${key}: ${stringValue},\n`;
          case 'curl':
            if (typeof value === 'boolean') {
              stringValue = value ? 'true' : 'false';
            } else if (typeof value === 'string') {
              stringValue = `"${value}"`;
            } else {
              stringValue = value;
            }
            // Check if this is the last item
            const isLast = index === array.length - 1;
            return `\n\t"${key}": ${stringValue}${isLast ? '' : ','}`;
          default:
            return '';
        }
      },
    );
    return `${stringLines.join('')}`;
  }, []);

  const snippets = useMemo(() => {
    const parameters = {
      ...(finalParameters || {}),
      ...(chainOfThought !== undefined ? { cot: chainOfThought } : {}),
      ...(isSplitView ? {} : { stream: true }),
    };

    return {
      python: `
import os
from tromero import Tromero

client = Tromero(tromero_key=os.getenv('TROMERO_KEY'))

response = client.chat.completions.create(
\tmodel="${model}",
\tmessages=${messagesString(conversation)},
${parametersString(parameters, 'python')}
)`,
      javascript: `
import Tromero from 'tromero'

const client = new Tromero({ tromeroKey: YOUR_TROMERO_KEY });

const response = await client.chat.completions.create({
\tmodel: "${model}",
\tmessages: ${messagesString(conversation)},\n
${parametersString(parameters, 'javascript')}
});
`,
      curl: `
curl -X POST "https://marketplace-production-ntdofzztya-uk.a.run.app/tailor/v1/generate/stream" \\
-H "Content-Type: application/json" \\
-H "X-API-KEY: YOUR_TROMERO_KEY" \\
-d '{
\t"adapter_name": "${model}",
\t"messages": ${messagesString(conversation)},
${parametersString(parameters, 'curl')}
}'`,
    };
  }, [
    model,
    conversation,
    parametersString,
    messagesString,
    isSplitView,
    chainOfThought,
    finalParameters,
  ]);

  return snippets;
};

export default useGetCodeSnippet;
