import { useState, useEffect } from 'react';

function useIsRef2OnTop(ref1, ref2, containerRef) {
  // ref 1 = widget
  // ref 2 = refresh button
  const [isRef2OnTop, setIsRef2OnTop] = useState(false);

  const checkOverlap = () => {
    if (!ref1.current || !ref2.current) {
      return;
    }

    const rect1 = ref1.current.getBoundingClientRect();
    const rect2 = ref2.current.getBoundingClientRect();

    const isOverlapping = !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );

    if (!isOverlapping) {
      setIsRef2OnTop(false);
      return;
    } else {
      setIsRef2OnTop(true);
      return;
    }
  };

  useEffect(() => {
    if (!ref1.current || !ref2.current) {
      return;
    }

    checkOverlap();

    // Listen for window resize and scroll
    window.addEventListener('resize', checkOverlap);
    window.addEventListener('scroll', checkOverlap);
    window.addEventListener('mouseenter', checkOverlap);
    window.addEventListener('mouseleave', checkOverlap);
    window.addEventListener('click', checkOverlap);

    // Use MutationObserver to detect changes in the DOM
    const observer = new MutationObserver(() => {
      checkOverlap();
    });

    // Observe changes in ref1 (widget) and ref2 (refresh button)
    const config = { attributes: true, childList: true, subtree: true };
    observer.observe(ref1.current, config);
    observer.observe(ref2.current, config);

    return () => {
      window.removeEventListener('resize', checkOverlap);
      window.removeEventListener('scroll', checkOverlap);
      window.removeEventListener('mouseenter', checkOverlap);
      window.removeEventListener('mouseleave', checkOverlap);
      window.removeEventListener('click', checkOverlap);
      observer.disconnect(); // Cleanup MutationObserver
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref1, ref2, containerRef]);

  return isRef2OnTop;
}

export default useIsRef2OnTop;
