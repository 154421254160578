const jobPostingsData = [
  {
    id: 31,
    imageUrl:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/articles/wordpress/image-1.jpg',
    title: 'Software Engineer',
    readMoreLink: '#',
    team: 'Engineering',
    aboutTeam:
      'The Engineering team is responsible for building, polishing, and maintaining the core product. We are a team of full-stack engineers with a strong focus on best practices. We are passionate about building high-quality software and delivering value to our customers.',
    whatYoullDo:
      "In this role, you'll be entrusted with the critical task of maintaining and evolving our core platform, guiding it from its initial MVP phase towards a stage primed for growth and scalability. Your responsibilities will include developing new features, encompassing the entire lifecycle from design to testing and production. A key aspect of your role will be a commitment to continuous improvement, where you'll actively engage in constantly changing, updating, and enhancing various aspects of our systems. Additionally, you'll play a pivotal role in our innovative project, involving the construction, testing, breaking, and subsequent refinement of our suite of Machine Learning verification tools, which are integral to our proof of useful work. Your efforts will be essential in ensuring that our tools not only meet but exceed the high standards required in our dynamic, fast-paced environment.",
    qualities:
      'We are seeking a seasoned Software Developer with at least five years of industry experience and a solid educational background in a STEM field such as Computer Science, Mathematics, Physics, Engineering, or Economics. The ideal candidate will have proficiency in Python and at least one compiled language like Go, Rust, or C++, coupled with a demonstrated interest in Machine Learning, as evidenced by GitHub repositories or previous projects. Experience with large-scale or production-grade databases, along with familiarity with tools like Alembic for migrations, is essential. Fundamental strengths in software engineering are crucial, including a proactive approach to problem-solving and a willingness to embrace new methodologies. We value individuals who are committed to working in-person for at least four days a week and prefer those who are open to a fully in-person environment. Desirably, candidates will have knowledge of GPU-level programming and experience with networking and DevOps. Experience in Blockchain development and familiarity with Rust will be highly regarded. Strong communication skills are vital, along with a readiness to engage in all business aspects, from go-to-market strategies and client drives to Machine Learning and Blockchain components. An ability to nurture plants and interact humorously with the office coffee machine will be seen as a plus, reflecting a well-rounded and adaptable personality.',
    commitment: 'Full-time',
    location: 'London, UK',
  },
  {
    id: 32,
    imageUrl:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/articles/wordpress/image-2.jpg',
    title: 'Senior Software Engineer',
    readMoreLink: '#',
    team: 'Engineering',
    aboutTeam:
      'The Engineering team is responsible for building, polishing, and maintaining the core product. We are a team of full-stack engineers with a strong focus on best practices. We are passionate about building high-quality software and delivering value to our customers.',
    whatYoullDo:
      "In this dynamic role, you'll be responsible for the maintenance and development of a cutting-edge GPU marketplace. As a pivotal figure on our software development team, you will contribute significantly to all levels of the stack, focusing on the creation of data-intensive APIs. Your role involves collaborating closely with the team to design and implement innovative solutions that ingeniously blend machine learning with proof of work in the field of cryptocurrency mining. You'll be building and maintaining robust, scalable APIs capable of handling substantial loads efficiently. Your involvement in brainstorming sessions will be crucial, as you'll contribute to the system's design and architecture, demonstrating flexibility by taking on various roles and tasks as needed to meet project objectives. Your role will also involve leveraging your low-level experience, particularly in GPU and hardware, to optimize system performance. Additionally, you'll have the opportunity to mentor junior developers, guiding them through complex challenges and fostering a collaborative team environment.",
    qualities:
      'The ideal candidate possesses a wealth of valuable qualities and experiences. They have a remarkable 8-10 years of industry experience as a Python developer, spanning across both large corporations and dynamic startup environments. Their commitment to security best practices is key. Furthermore, they have excelled as a development team leader, steering projects from inception to successful production, all while demonstrating proficiency in constructing and maintaining data-intensive APIs rooted in sound software development principles and scalable architecture. Their toolkit includes expertise in Flask, GCP, and a keen understanding of Kubernetes and virtualization. This candidate is eager to work in an office setting and is highly adaptable, eagerly taking on diverse roles with a strong sense of project ownership. They are also open to in-person work for a minimum of four days a week. In addition, it would be advantageous if they have experience with machine learning pipelines and blockchain development, as well as a background in high-performance systems within an industry setting.',
    commitment: 'Full-time',
    location: 'London, UK',
  },
  {
    id: 33,
    imageUrl:
      'https://flowbite.s3.amazonaws.com/blocks/marketing-ui/articles/wordpress/image-2.jpg',
    title: 'AI/ML Research Engineer',
    readMoreLink: '#',
    team: 'AI/ML',
    aboutTeam:
      'The AI/ML team is at the forefront of research and innovation in artificial intelligence and machine learning. Our primary focus is on identifying new opportunities and enhancements that can be integrated into our core product. Comprised of experts in various fields, our team is dedicated to exploring cutting-edge technologies and methodologies. We are passionate about pushing the boundaries of AI/ML to create high-quality, innovative solutions that deliver substantial value to our customers. Our commitment to research and continuous improvement is the driving force behind our efforts to lead and transform the industry.',
    whatYoullDo:
      "In this dynamic role, your primary responsibility will be to engage in thorough research, keeping abreast of the latest developments in your field. You'll not only develop software that brings innovative ideas to life but also contribute to the creation of reports, articles, and papers that showcase our progress and breakthroughs. Collaboration forms the cornerstone of your position, where you'll work closely with your team and interact with other teams to ensure seamless implementation and integration of your work. Additionally, your role will extend beyond your direct responsibilities as you'll provide occasional technical support to other team members and departments. This support, while sometimes deviating from your primary focus, is crucial for the holistic progress and efficiency of our projects, ensuring that we collectively achieve our objectives.",
    qualities:
      'The ideal candidate stands out with a unique blend of expertise and skills. Proficient in Python, they effortlessly manage codebases, and their mastery extends to scientific computing with numpy, scipy, and PyTorch internals. Git is their trusted tool for version control, and they prioritize strong software engineering fundamentals, creating efficient and maintainable code. They are research-oriented, adept at navigating academic papers, experimenting with new ideas, and seeking guidance as needed, with a preference for demonstrable experience. Desirable attributes include C++ proficiency, specifically in CUDA for GPU monitoring, as well as networking and MPI competence for seamless remote machine communication. Additionally, they possess strong communication skills to convey complex concepts and collaborate effectively.',
    commitment: 'Full-time',
    location: 'London, UK',
  },
];

export default jobPostingsData;
