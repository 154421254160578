/**
 * @file localeCurrencyMapping.js
 * @summary This file contains the mapping of currency to locale.
 * This file contains the mapping of currency to locale.
 * This is used to get the default locale for a currency.
 * This is used to get the currency name in English.
 * This is used to get the ISO code for a currency.
 * This is used to get the currency symbol.
 */

export const getDefaultLocaleForCurrency = (currency) => {
  const currencyLocaleMap = {
    USD: {
      locale: 'en-US',
      nameInEnglish: 'United States Dollar',
      ISO_code: 'us',
    },
    EUR: { locale: 'es-ES', nameInEnglish: 'Euro', ISO_code: 'eu' },
    JPY: { locale: 'ja-JP', nameInEnglish: 'Japanese Yen', ISO_code: 'jp' },
    GBP: { locale: 'en-GB', nameInEnglish: 'British Pound', ISO_code: 'gb' },
    CNY: { locale: 'zh-CN', nameInEnglish: 'Chinese Yuan', ISO_code: 'cn' },
    AED: {
      locale: 'ar-AE',
      nameInEnglish: 'United Arab Emirates Dirham',
      ISO_code: 'ae',
    },
    AUD: {
      locale: 'en-AU',
      nameInEnglish: 'Australian Dollar',
      ISO_code: 'au',
    },
    CAD: { locale: 'en-CA', nameInEnglish: 'Canadian Dollar', ISO_code: 'ca' },
    CHF: { locale: 'de-CH', nameInEnglish: 'Swiss Franc', ISO_code: 'ch' },
    SEK: { locale: 'sv-SE', nameInEnglish: 'Swedish Krona', ISO_code: 'se' },
    NZD: {
      locale: 'en-NZ',
      nameInEnglish: 'New Zealand Dollar',
      ISO_code: 'nz',
    },
    MXN: { locale: 'es-MX', nameInEnglish: 'Mexican Peso', ISO_code: 'mx' },
    SGD: {
      locale: 'en-SG',
      nameInEnglish: 'Singapore Dollar',
      ISO_code: 'sg',
    },
    HKD: { locale: 'zh-HK', nameInEnglish: 'Hong Kong Dollar', ISO_code: 'hk' },
    NOK: { locale: 'nb-NO', nameInEnglish: 'Norwegian Krone', ISO_code: 'no' },
    KRW: { locale: 'ko-KR', nameInEnglish: 'South Korean Won', ISO_code: 'kr' },
    TRY: { locale: 'tr-TR', nameInEnglish: 'Turkish Lira', ISO_code: 'tr' },
    RUB: { locale: 'ru-RU', nameInEnglish: 'Russian Ruble', ISO_code: 'ru' },
    INR: { locale: 'hi-IN', nameInEnglish: 'Indian Rupee', ISO_code: 'in' },
    BRL: { locale: 'pt-BR', nameInEnglish: 'Brazilian Real', ISO_code: 'br' },
    ZAR: {
      locale: 'en-ZA',
      nameInEnglish: 'South African Rand',
      ISO_code: 'za',
    },
    PHP: { locale: 'en-PH', nameInEnglish: 'Philippine Piso', ISO_code: 'ph' },
    IDR: {
      locale: 'id-ID',
      nameInEnglish: 'Indonesian Rupiah',
      ISO_code: 'id',
    },
    THB: { locale: 'th-TH', nameInEnglish: 'Thai Baht', ISO_code: 'th' },
    MYR: {
      locale: 'ms-MY',
      nameInEnglish: 'Malaysian Ringgit',
      ISO_code: 'my',
    },
    PLN: { locale: 'pl-PL', nameInEnglish: 'Polish Złoty', ISO_code: 'pl' },
    BGN: { locale: 'bg-BG', nameInEnglish: 'Bulgarian Lev', ISO_code: 'bg' },
    ILS: {
      locale: 'he-IL',
      nameInEnglish: 'Israeli New Shekel',
      ISO_code: 'il',
    },
    DKK: { locale: 'da-DK', nameInEnglish: 'Danish Krone', ISO_code: 'dk' },
    COP: { locale: 'es-CO', nameInEnglish: 'Colombian Peso', ISO_code: 'co' },
    SAR: { locale: 'ar-SA', nameInEnglish: 'Saudi Riyal', ISO_code: 'sa' },
    CZK: { locale: 'cs-CZ', nameInEnglish: 'Czech Koruna', ISO_code: 'cz' },
    RON: { locale: 'ro-RO', nameInEnglish: 'Romanian Leu', ISO_code: 'ro' },
    HUF: { locale: 'hu-HU', nameInEnglish: 'Hungarian Forint', ISO_code: 'hu' },
    QAR: { locale: 'ar-QA', nameInEnglish: 'Qatari Riyal', ISO_code: 'qa' },
    KWD: { locale: 'ar-KW', nameInEnglish: 'Kuwaiti Dinar', ISO_code: 'kw' },
    HRK: { locale: 'hr-HR', nameInEnglish: 'Croatian Kuna', ISO_code: 'hr' },
    LKR: { locale: 'si-LK', nameInEnglish: 'Sri Lankan Rupee', ISO_code: 'lk' },
    PEN: { locale: 'es-PE', nameInEnglish: 'Peruvian Sol', ISO_code: 'pe' },
    UAH: {
      locale: 'uk-UA',
      nameInEnglish: 'Ukrainian Hryvnia',
      ISO_code: 'ua',
    },
    NGN: { locale: 'en-NG', nameInEnglish: 'Nigerian Naira', ISO_code: 'ng' },
    EGP: { locale: 'ar-EG', nameInEnglish: 'Egyptian Pound', ISO_code: 'eg' },
    CLP: { locale: 'es-CL', nameInEnglish: 'Chilean Peso', ISO_code: 'cl' },
    VND: { locale: 'vi-VN', nameInEnglish: 'Vietnamese đồng', ISO_code: 'vn' },
    PKR: { locale: 'ur-PK', nameInEnglish: 'Pakistani Rupee', ISO_code: 'pk' },
    BDT: { locale: 'bn-BD', nameInEnglish: 'Bangladeshi Taka', ISO_code: 'bd' },
    JOD: { locale: 'ar-JO', nameInEnglish: 'Jordanian Dinar', ISO_code: 'jo' },
    KES: { locale: 'sw-KE', nameInEnglish: 'Kenyan Shilling', ISO_code: 'ke' },
    LBP: { locale: 'ar-LB', nameInEnglish: 'Lebanese Pound', ISO_code: 'lb' },
    OMR: { locale: 'ar-OM', nameInEnglish: 'Omani Rial', ISO_code: 'om' },
    ARS: { locale: 'es-AR', nameInEnglish: 'Argentine Peso', ISO_code: 'ar' },
    MUR: { locale: 'en-MU', nameInEnglish: 'Mauritian Rupee', ISO_code: 'mu' },
    NPR: { locale: 'ne-NP', nameInEnglish: 'Nepalese Rupee', ISO_code: 'np' },
    AMD: { locale: 'hy-AM', nameInEnglish: 'Armenian Dram', ISO_code: 'am' },
    UYU: { locale: 'es-UY', nameInEnglish: 'Uruguayan Peso', ISO_code: 'uy' },
    AZN: {
      locale: 'az-AZ',
      nameInEnglish: 'Azerbaijani Manat',
      ISO_code: 'az',
    },
    FJD: { locale: 'en-FJ', nameInEnglish: 'Fijian Dollar', ISO_code: 'fj' },
    JMD: { locale: 'en-JM', nameInEnglish: 'Jamaican Dollar', ISO_code: 'jm' },
    GEL: { locale: 'ka-GE', nameInEnglish: 'Georgian Lari', ISO_code: 'ge' },
    BYN: { locale: 'be-BY', nameInEnglish: 'Belarusian Ruble', ISO_code: 'by' },
    KZT: {
      locale: 'kk-KZ',
      nameInEnglish: 'Kazakhstani Tenge',
      ISO_code: 'kz',
    },
    MAD: { locale: 'ar-MA', nameInEnglish: 'Moroccan Dirham', ISO_code: 'ma' },
    TWD: {
      locale: 'zh-TW',
      nameInEnglish: 'New Taiwan Dollar',
      ISO_code: 'tw',
    },
    MZN: {
      locale: 'pt-MZ',
      nameInEnglish: 'Mozambican Metical',
      ISO_code: 'mz',
    },
    ISK: { locale: 'is-IS', nameInEnglish: 'Icelandic Króna', ISO_code: 'is' },
    PAB: {
      locale: 'es-PA',
      nameInEnglish: 'Panamanian Balboa',
      ISO_code: 'pa',
    },
    BAM: {
      locale: 'bs-BA',
      nameInEnglish: 'Bosnian Convertible Marka',
      ISO_code: 'ba',
    },
    CRC: {
      locale: 'es-CR',
      nameInEnglish: 'Costa Rican Colón',
      ISO_code: 'cr',
    },
    GTQ: {
      locale: 'es-GT',
      nameInEnglish: 'Guatemalan Quetzal',
      ISO_code: 'gt',
    },
    UZS: { locale: 'uz-UZ', nameInEnglish: 'Uzbekistani Som', ISO_code: 'uz' },
    TND: { locale: 'ar-TN', nameInEnglish: 'Tunisian Dinar', ISO_code: 'tn' },
    BHD: { locale: 'ar-BH', nameInEnglish: 'Bahraini Dinar', ISO_code: 'bh' },
    LRD: { locale: 'en-LR', nameInEnglish: 'Liberian Dollar', ISO_code: 'lr' },
    BWP: { locale: 'en-BW', nameInEnglish: 'Botswana Pula', ISO_code: 'bw' },
    GHS: { locale: 'en-GH', nameInEnglish: 'Ghanaian Cedi', ISO_code: 'gh' },
    MKD: { locale: 'mk-MK', nameInEnglish: 'Macedonian Denar', ISO_code: 'mk' },
    SYP: { locale: 'ar-SY', nameInEnglish: 'Syrian Pound', ISO_code: 'sy' },
    DZD: { locale: 'ar-DZ', nameInEnglish: 'Algerian Dinar', ISO_code: 'dz' },
    IQD: { locale: 'ar-IQ', nameInEnglish: 'Iraqi Dinar', ISO_code: 'iq' },
    MGA: { locale: 'fr-MG', nameInEnglish: 'Malagasy Ariary', ISO_code: 'mg' },
    MNT: { locale: 'mn-MN', nameInEnglish: 'Mongolian Tugrik', ISO_code: 'mn' },
    RSD: { locale: 'sr-RS', nameInEnglish: 'Serbian Dinar', ISO_code: 'rs' },
    AFN: { locale: 'ps-AF', nameInEnglish: 'Afghan Afghani', ISO_code: 'af' },
    ALL: { locale: 'sq-AL', nameInEnglish: 'Albanian Lek', ISO_code: 'al' },
    ANG: {
      locale: 'nl-CW',
      nameInEnglish: 'Netherlands Antillean Guilder',
      ISO_code: 'cw',
    },
    AOA: { locale: 'pt-AO', nameInEnglish: 'Angolan Kwanza', ISO_code: 'ao' },
    AWG: { locale: 'nl-AW', nameInEnglish: 'Aruban Florin', ISO_code: 'aw' },
    BBD: {
      locale: 'en-BB',
      nameInEnglish: 'Barbadian Dollar',
      ISO_code: 'bb',
    },
    BIF: { locale: 'fr-BI', nameInEnglish: 'Burundian Franc', ISO_code: 'bi' },
    BMD: { locale: 'en-BM', nameInEnglish: 'Bermudian Dollar', ISO_code: 'bm' },
    BND: { locale: 'ms-BN', nameInEnglish: 'Brunei Dollar', ISO_code: 'bn' },
    BOB: {
      locale: 'es-BO',
      nameInEnglish: 'Bolivian Boliviano',
      ISO_code: 'bo',
    },
    BSD: { locale: 'en-BS', nameInEnglish: 'Bahamian Dollar', ISO_code: 'bs' },
    BTN: {
      locale: 'dz-BT',
      nameInEnglish: 'Bhutanese Ngultrum',
      ISO_code: 'bt',
    },
    BZD: { locale: 'en-BZ', nameInEnglish: 'Belize Dollar', ISO_code: 'bz' },
    CDF: { locale: 'fr-CD', nameInEnglish: 'Congolese Franc', ISO_code: 'cd' },
    CUP: { locale: 'es-CU', nameInEnglish: 'Cuban Peso', ISO_code: 'cu' },
    CVE: {
      locale: 'pt-CV',
      nameInEnglish: 'Cape Verdean Escudo',
      ISO_code: 'cv',
    },
    DJF: { locale: 'fr-DJ', nameInEnglish: 'Djiboutian Franc', ISO_code: 'dj' },
    DOP: { locale: 'es-DO', nameInEnglish: 'Dominican Peso', ISO_code: 'do' },
    ERN: { locale: 'ti-ER', nameInEnglish: 'Eritrean Nakfa', ISO_code: 'er' },
    ETB: { locale: 'am-ET', nameInEnglish: 'Ethiopian Birr', ISO_code: 'et' },
    FKP: {
      locale: 'en-FK',
      nameInEnglish: 'Falkland Islands Pound',
      ISO_code: 'fk',
    },
    GIP: { locale: 'en-GI', nameInEnglish: 'Gibraltar Pound', ISO_code: 'gi' },
    GMD: { locale: 'en-GM', nameInEnglish: 'Gambian Dalasi', ISO_code: 'gm' },
    GNF: { locale: 'fr-GN', nameInEnglish: 'Guinean Franc', ISO_code: 'gn' },
    GYD: { locale: 'en-GY', nameInEnglish: 'Guyanese Dollar', ISO_code: 'gy' },
    HTG: { locale: 'ht-HT', nameInEnglish: 'Haitian Gourde', ISO_code: 'ht' },
    IMP: { locale: 'en-IM', nameInEnglish: 'Manx Pound', ISO_code: 'im' },
    IRR: { locale: 'fa-IR', nameInEnglish: 'Iranian Rial', ISO_code: 'ir' },
    KGS: { locale: 'ky-KG', nameInEnglish: 'Kyrgyzstani Som', ISO_code: 'kg' },
    KHR: { locale: 'km-KH', nameInEnglish: 'Cambodian Riel', ISO_code: 'kh' },
    KMF: { locale: 'ar-KM', nameInEnglish: 'Comorian Franc', ISO_code: 'km' },
    LAK: { locale: 'lo-LA', nameInEnglish: 'Lao Kip', ISO_code: 'la' },
    LSL: { locale: 'en-LS', nameInEnglish: 'Lesotho Loti', ISO_code: 'ls' },
    LYD: { locale: 'ar-LY', nameInEnglish: 'Libyan Dinar', ISO_code: 'ly' },
    MDL: { locale: 'ro-MD', nameInEnglish: 'Moldovan Leu', ISO_code: 'md' },
    MMK: { locale: 'my-MM', nameInEnglish: 'Burmese Kyat', ISO_code: 'mm' },
    MOP: { locale: 'zh-MO', nameInEnglish: 'Macanese Pataca', ISO_code: 'mo' },
    MRU: {
      locale: 'ar-MR',
      nameInEnglish: 'Mauritanian Ouguiya',
      ISO_code: 'mr',
    },
    MVR: {
      locale: 'dv-MV',
      nameInEnglish: 'Maldivian Rufiyaa',
      ISO_code: 'mv',
    },
    MWK: { locale: 'ny-MW', nameInEnglish: 'Malawian Kwacha', ISO_code: 'mw' },
    NAD: { locale: 'en-NA', nameInEnglish: 'Namibian Dollar', ISO_code: 'na' },
    NIO: {
      locale: 'es-NI',
      nameInEnglish: 'Nicaraguan Córdoba',
      ISO_code: 'ni',
    },
    PGK: {
      locale: 'en-PG',
      nameInEnglish: 'Papua New Guinean Kina',
      ISO_code: 'pg',
    },
    PYG: {
      locale: 'gn-PY',
      nameInEnglish: 'Paraguayan Guarani',
      ISO_code: 'py',
    },
    RWF: { locale: 'rw-RW', nameInEnglish: 'Rwandan Franc', ISO_code: 'rw' },
    SBD: {
      locale: 'en-SB',
      nameInEnglish: 'Solomon Islands Dollar',
      ISO_code: 'sb',
    },
    SCR: {
      locale: 'en-SC',
      nameInEnglish: 'Seychellois Rupee',
      ISO_code: 'sc',
    },
    SDG: { locale: 'ar-SD', nameInEnglish: 'Sudanese Pound', ISO_code: 'sd' },
    SHP: {
      locale: 'en-SH',
      nameInEnglish: 'Saint Helena Pound',
      ISO_code: 'sh',
    },
    SLL: {
      locale: 'en-SL',
      nameInEnglish: 'Sierra Leonean Leone',
      ISO_code: 'sl',
    },
    SOS: { locale: 'so-SO', nameInEnglish: 'Somali Shilling', ISO_code: 'so' },
    SRD: {
      locale: 'nl-SR',
      nameInEnglish: 'Surinamese Dollar',
      ISO_code: 'sr',
    },
    SSP: {
      locale: 'en-SS',
      nameInEnglish: 'South Sudanese Pound',
      ISO_code: 'ss',
    },
    STN: {
      locale: 'pt-ST',
      nameInEnglish: 'São Tomé and Príncipe Dobra',
      ISO_code: 'st',
    },
    SVC: { locale: 'es-SV', nameInEnglish: 'Salvadoran Colón', ISO_code: 'sv' },
    SZL: { locale: 'ss-SZ', nameInEnglish: 'Swazi Lilangeni', ISO_code: 'sz' },
    TJS: {
      locale: 'tg-TJ',
      nameInEnglish: 'Tajikistani Somoni',
      ISO_code: 'tj',
    },
    TMT: {
      locale: 'tk-TM',
      nameInEnglish: 'Turkmenistani Manat',
      ISO_code: 'tm',
    },
    TOP: { locale: 'to-TO', nameInEnglish: 'Tongan Paʻanga', ISO_code: 'to' },
    TTD: {
      locale: 'en-TT',
      nameInEnglish: 'Trinidad and Tobago Dollar',
      ISO_code: 'tt',
    },
    TZS: {
      locale: 'sw-TZ',
      nameInEnglish: 'Tanzanian Shilling',
      ISO_code: 'tz',
    },
    UGX: { locale: 'en-UG', nameInEnglish: 'Ugandan Shilling', ISO_code: 'ug' },
    VES: {
      locale: 'es-VE',
      nameInEnglish: 'Venezuelan Bolívar',
      ISO_code: 've',
    },
    VUV: { locale: 'bi-VU', nameInEnglish: 'Vanuatu Vatu', ISO_code: 'vu' },
    WST: { locale: 'sm-WS', nameInEnglish: 'Samoan Tala', ISO_code: 'ws' },
    XAF: {
      locale: 'fr-CM',
      nameInEnglish: 'Central African CFA Franc',
      ISO_code: 'cm',
    },
    XOF: {
      locale: 'fr-BJ',
      nameInEnglish: 'West African CFA Franc',
      ISO_code: 'bj',
    },
    XPF: { locale: 'fr-PF', nameInEnglish: 'CFP Franc', ISO_code: 'pf' },
    YER: { locale: 'ar-YE', nameInEnglish: 'Yemeni Rial', ISO_code: 'ye' },
    ZMW: { locale: 'en-ZM', nameInEnglish: 'Zambian Kwacha', ISO_code: 'zm' },
    ZWL: {
      locale: 'en-ZW',
      nameInEnglish: 'Zimbabwean Dollar',
      ISO_code: 'zw',
    },
  };

  return currencyLocaleMap[currency] || currencyLocaleMap['USD'];
};

export const popularCurrencies = [
  'USD',
  'EUR',
  'GBP',
  'JPY',
  'CNY',
  'CHF',
  'ILS',
  'CAD',
  'AUD',
  'INR',
];

export const restCurrencies = [
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BTN',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'COP',
  'CRC',
  'CUP',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ERN',
  'ETB',
  'FJD',
  'FKP',
  'GEL',
  'GHS',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'IMP',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRU',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SDG',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'SSP',
  'STN',
  'SVC',
  'SYP',
  'SZL',
  'THB',
  'TJS',
  'TMT',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VES',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW',
  'ZWL',
];
