import { Outlet } from 'react-router-dom';

import Footer from '../Components/Footer';
import UnderNavbarBanner from '../Components/UnderNavbarBanner.jsx';
import ResponsiveNavbar from '../V2LandingThings/ResponsiveNavbar.jsx';

const CommonLayout = () => {
  return (
    <>
      <ResponsiveNavbar />
      <UnderNavbarBanner />
      <Outlet />
      <Footer />
    </>
  );
};

export default CommonLayout;
