import React, { useState } from 'react';

import certificateData from '../certsData';

const VerifiedJobs = () => {
  const [jobCode, setJobCode] = useState('');
  const [jobs, setJobs] = useState([]);
  const [openJobs, setOpenJobs] = useState({});
  const [error, setError] = useState('');

  const downloadJson = () => {
    const dataStr = JSON.stringify(certificateData, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const link = document.createElement('a');
    link.download = 'tromero_certificate_data.json';
    link.href = window.URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleOkClick = () => {
    if (jobCode.trim() === '') {
      setError('Please enter a job code.');
      return;
    }

    const newJob = {
      id: jobs.length + 1,
      name: `Job ${jobs.length + 1}`,
      date: 'Date of Completion',
      title: 'Job Title',
    };
    setJobs([...jobs, newJob]);
    setOpenJobs({ ...openJobs, [newJob.id]: true });
    setJobCode('');
  };

  const toggleAccordion = (jobId) => {
    setOpenJobs({ ...openJobs, [jobId]: !openJobs[jobId] });
  };

  return (
    <div className="h-screen">
      <div className="flex flex-col w-full h-full max-w-[1300px] mx-auto items-center mt-12 font-spaceG">
        <div className="w-full mb-4 ml-16 text-3xl italic text-left font-extralight text-slate-800 dark:text-gray-100">
          Verified Jobs
        </div>
        <div className="flex flex-col items-start w-full my-12 ml-16">
          <label htmlFor="job-code" className="mb-1 dark:text-gray-300">
            Please enter unique job code:
          </label>
          <div className="flex items-center">
            <input
              type="text"
              id="job-code"
              value={jobCode}
              onChange={(e) => {
                setJobCode(e.target.value);
                setError('');
              }}
              className="w-64 p-2 mr-2 border-2 border-gray-300 rounded-md sm:w-72 dark:bg-gray-700 dark:text-gray-100"
            />
            <button
              onClick={handleOkClick}
              className="px-4 py-2 font-bold text-white rounded-md bg-slate-500 hover:bg-slate-700"
            >
              OK
            </button>
          </div>
          {error && <p className="text-sm italic text-red-500">{error}</p>}
        </div>
        {jobs.map((job) => (
          <div
            key={job.id}
            className="w-full px-4 sm:px-0 sm:ml-16 dark:text-gray-200"
          >
            <div
              className="flex items-center justify-between w-full my-4 border-b-2 border-gray-300 cursor-pointer sm:w-96"
              onClick={() => toggleAccordion(job.id)}
            >
              <div>
                <svg
                  className={`transform transition-transform ${
                    openJobs[job.id] ? 'rotate-180' : ''
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  width="20"
                  height="20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div>
                {job.name} - {job.date} - {job.title}
              </div>
            </div>
            {openJobs[job.id] && (
              <div className="flex flex-col items-center justify-center p-2 -my-2 rounded-lg shadow w-96 dark:bg-gray-900/40">
                <button className="flex items-center w-48 p-2 my-2 border rounded-md shadow bg-gray-50 hover:bg-gray-200 dark:bg-gray-900 ">
                  <svg
                    className="w-5 h-5 mr-2 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4.828 10h6.239m-6.239 4h6.239M6 1v4a1 1 0 0 1-1 1H1m14-4v16a.97.97 0 0 1-.933 1H1.933A.97.97 0 0 1 1 18V5.828a2 2 0 0 1 .586-1.414l2.828-2.828A2 2 0 0 1 5.828 1h8.239A.97.97 0 0 1 15 2Z"
                    />
                  </svg>
                  View Logs
                </button>
                <button
                  onClick={downloadJson}
                  className="flex items-center w-48 p-2 my-2 border rounded-md shadow bg-gray-50 hover:bg-gray-200 dark:bg-gray-900"
                >
                  <svg
                    class="w-5 h-5 mr-2 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fill="currentColor"
                      d="m18.774 8.245-.892-.893a1.5 1.5 0 0 1-.437-1.052V5.036a2.484 2.484 0 0 0-2.48-2.48H13.7a1.5 1.5 0 0 1-1.052-.438l-.893-.892a2.484 2.484 0 0 0-3.51 0l-.893.892a1.5 1.5 0 0 1-1.052.437H5.036a2.484 2.484 0 0 0-2.48 2.481V6.3a1.5 1.5 0 0 1-.438 1.052l-.892.893a2.484 2.484 0 0 0 0 3.51l.892.893a1.5 1.5 0 0 1 .437 1.052v1.264a2.484 2.484 0 0 0 2.481 2.481H6.3a1.5 1.5 0 0 1 1.052.437l.893.892a2.484 2.484 0 0 0 3.51 0l.893-.892a1.5 1.5 0 0 1 1.052-.437h1.264a2.484 2.484 0 0 0 2.481-2.48V13.7a1.5 1.5 0 0 1 .437-1.052l.892-.893a2.484 2.484 0 0 0 0-3.51Z"
                    />
                    <path
                      fill="#1f2937"
                      d="M8 13a1 1 0 0 1-.707-.293l-2-2a1 1 0 1 1 1.414-1.414l1.42 1.42 5.318-3.545a1 1 0 0 1 1.11 1.664l-6 4A1 1 0 0 1 8 13Z"
                    />
                  </svg>
                  View Certificate{' '}
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerifiedJobs;
