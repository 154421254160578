import HeaderSkeleton from './HeaderSkeleton';

const BenchmarkEvaluationSkeleton = () => {
  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans">
      {/* Header Skeleton */}
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className="mx-auto w-full px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse" />
          </div>
        </div>
      </header>

      {/* Main Content Skeleton */}
      <div className="flex flex-col min-h-screen h-full bg-zinc-50 px-4 py-6 max-w-7xl mx-auto">
        <div className="space-y-8">
          {/* Title Section */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg min-h-[600px]">
            <div className="flex flex-col space-y-4">
              <div className="h-6 bg-gray-200 rounded w-1/4 animate-pulse" />
              <div className="h-4 bg-gray-200 rounded w-1/3 animate-pulse" />
            </div>

            {/* Model Selection Skeleton */}
            <div className="mt-8 flex flex-wrap gap-2">
              {[1, 2, 3, 4, 5].map((i) => (
                <div
                  key={i}
                  className="h-8 bg-gray-200 rounded-md w-32 animate-pulse"
                />
              ))}
            </div>

            {/* Charts Grid Skeleton */}
            <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4">
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <div
                  key={i}
                  className="border rounded-lg p-4 bg-white h-[350px]"
                >
                  <div className="h-6 bg-gray-200 rounded w-1/3 mb-4 animate-pulse" />
                  <div className="h-[250px] bg-gray-100 rounded animate-pulse" />
                </div>
              ))}
            </div>
          </div>

          {/* Test Suite Section Skeleton */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg">
            <div className="h-6 bg-gray-200 rounded w-1/4 mb-4 animate-pulse" />
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                <div
                  key={i}
                  className="p-4 bg-white border rounded-lg h-[120px] animate-pulse"
                >
                  <div className="h-4 bg-gray-200 rounded w-1/2 mb-2" />
                  <div className="h-4 bg-gray-200 rounded w-3/4" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkEvaluationSkeleton;
