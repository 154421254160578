import {
  BoltIcon,
  LockClosedIcon,
  CloudIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const features = [
  {
    name: 'Improved Model Accuracy',
    description:
      'Improve the accuracy of your models by fine-tuning them on your data. With Tromero, data can be easily collected and improved by up to 15% with our synthetic data solution.',
    icon: BoltIcon,
  },
  {
    name: 'Universal Model Support',
    description:
      'With Tromero you can change between any model, whether it is open-source, close-source or fine-tuned. We support all of the major models.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Enterprise-grade Security',
    description:
      'Tromero is ISO 27001 and GDPR compliant ensuring the highest standards of data protection and privacy.',
    icon: LockClosedIcon,
  },
  {
    name: 'Any cloud, on prem or on Tromero',
    description:
      'Tromero can be hosted anywhere. From any cloud, self-hosted on your ownservers or VPC (Virtual Private Cloud) to deploying on Tromero’s API or having a dedicated endpoint.',
    icon: CloudIcon,
  },
];

export default function FeatureSection() {
  return (
    <div className="py-6 my-6 md:my-12 lg:my-24 sm:py-16 xl:my-40">
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <motion.div
          initial={{ opacity: 0.0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.2,
            duration: 0.4,
            ease: 'easeInOut',
          }}
          viewport={{ once: true }}
          className="grid max-w-2xl grid-cols-1 mx-auto gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2"
        >
          <h2 className="text-2xl text-gray-900 sm:text-4xl">
            Specialized, flexible, and secure.
          </h2>
          <dl className="grid grid-cols-1 col-span-2 gap-x-8 gap-y-16 sm:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt className="text-base font-semibold leading-7 text-zinc-900">
                  <div className="flex items-center justify-center w-10 h-10 mb-6 rounded bg-zinc-800">
                    <feature.icon
                      aria-hidden="true"
                      className="w-6 h-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 text-base leading-7 text-zinc-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </motion.div>
      </div>
    </div>
  );
}
