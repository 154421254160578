import { Fragment, useState, useEffect, useCallback } from 'react';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useUser } from '../../../UserContext';
import toast from 'react-hot-toast';
import { Modal } from 'flowbite-react';

export default function NewCustomEvalModal({ isOpen, onClose, onEvalAdded }) {
  // Internal state
  const [customEvalName, setCustomEvalName] = useState('');
  const [customEvalText, setCustomEvalText] = useState('');
  const [dataInput, setDataInput] = useState('all');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [userHasLogs, setUserHasLogs] = useState(true);
  const [validationMessages, setValidationMessages] = useState({
    tags: '',
    file: '',
    datasets: '',
    name: '',
    prompt: '',
  });
  const [loading, setLoading] = useState(false);
  const [showExplanation, setShowExplanation] = useState(false);

  const { customAxios } = useUser();

  useEffect(() => {
    if (userHasLogs) {
      setDataInput('all');
    } else {
      setDataInput('upload');
    }
  }, [userHasLogs]);

  // Fetch tags and datasets
  const fetchTags = useCallback(async () => {
    try {
      const response = await customAxios.get('tailor/v1/tags');
      const tags =
        response?.data?.message
          ?.map((tag) => (tag === '[]' ? null : tag.replace(/[[\]{}"]+/g, '')))
          .filter(Boolean) || [];
      setTagList([...new Set(tags)]);

      const userHasLogs = !!response?.data?.total_logs;
      setUserHasLogs(userHasLogs);
      setDatasets(response?.data?.datasets || []);
    } catch (error) {
      console.error(error);
      toast.error('An error occurred fetching tags. Please try again later.');
    }
  }, [customAxios]);

  useEffect(() => {
    if (isOpen) {
      fetchTags();
    }
  }, [fetchTags, isOpen]);

  // Reset form on close
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        resetForm();
      }, 100);
    }
  }, [isOpen]);

  const resetForm = () => {
    setCustomEvalName('');
    setCustomEvalText('');
    setDataInput('all');
    setSelectedTags([]);
    setSelectedDataset(null);
    setValidationMessages({
      tags: '',
      file: '',
      datasets: '',
      name: '',
      prompt: '',
    });
    setShowExplanation(false);
  };

  const handleNameChange = (e) => {
    setCustomEvalName(e.target.value);
  };

  const handleInputChange = (e) => {
    setCustomEvalText(e.target.value);
  };

  const toggleTags = (tag) => {
    setSelectedTags((prevSelected) =>
      prevSelected.includes(tag)
        ? prevSelected.filter((t) => t !== tag)
        : [...prevSelected, tag],
    );
    setValidationMessages({
      ...validationMessages,
      tags: '',
    });
  };

  const isTagSelected = (tag) => selectedTags.includes(tag);

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {
      tags: '',
      file: '',
      datasets: '',
      name: '',
      prompt: '',
    };

    if (customEvalName.trim() === '') {
      // toast.error('Please enter an evaluation name.', {
      //   id: 'name-error',
      // });
      newValidationMessages.name = 'Please enter an evaluation name';
      isValid = false;
    }

    if (customEvalText.trim() === '') {
      // toast.error('Please enter evaluation criteria.', {
      //   id: 'prompt-error',
      // });
      newValidationMessages.prompt = 'Please enter evaluation criteria';
      isValid = false;
    }

    if (dataInput === 'tags' && selectedTags.length === 0) {
      // toast.error('Please select at least one tag', {
      //   id: 'tags-error',
      // });
      newValidationMessages.tags = 'Please select at least one tag';
      isValid = false;
    }

    if (dataInput === 'datasets' && !selectedDataset) {
      // toast.error('Please select a dataset', {
      //   id: 'datasets-error',
      // });
      newValidationMessages.datasets = 'Please select a dataset';
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    const payload = {
      name: customEvalName,
      prompt: customEvalText,
      all: dataInput === 'all',
    };

    if (dataInput === 'tags') {
      payload.tags = selectedTags;
    } else if (dataInput === 'datasets') {
      payload.dataset_id = selectedDataset.id;
    }
    try {
      setLoading(true);
      const response = await customAxios.post(
        '/tailor/v1/custom_eval/create_new_eval',
        payload,
      );

      if (response.status === 200 || response.status === 201) {
        toast.success('Evaluation saved successfully!');
        onEvalAdded(); // Notify parent component to refresh the list
        onClose();
      } else {
        toast.error('Failed to save the evaluation. Please try again.');
      }
    } catch (error) {
      console.error('Error saving evaluation:', error);
      toast.error('An error occurred while saving the evaluation.');
    } finally {
      setLoading(false);
    }
  };

  const toggleExplanation = () => {
    setShowExplanation(!showExplanation);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[49]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-lg bg-white p-6 shadow transition-all duration-300 ease-in-out">
                <div className="flex justify-between">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 min-w-fit"
                  >
                    New Custom Evaluation
                  </Dialog.Title>
                  <button
                    onClick={toggleExplanation}
                    id="how-does-this-work"
                    className="text-sm text-zinc-500 hover:text-zinc-800 underline decoration-dotted underline-offset-4 text-right"
                  >
                    How Custom Evaluations work?
                  </button>
                </div>

                <div
                  className={clsx(
                    'overflow-hidden transition-[height] duration-300 ease-in-out',
                    showExplanation ? 'lg:h-32 h-48' : 'h-0',
                  )}
                >
                  <div className="mt-4 p-4 bg-zinc-50 rounded-lg text-sm text-zinc-700">
                    Create a custom evaluation to assess your model using
                    specific criteria. Define how the evaluation should be
                    conducted (e.g., "evaluate responses for kindness and
                    politeness"), and the judge model will assess outputs from
                    your selected log's prompts.
                  </div>
                </div>

                <div
                  className={clsx(
                    'transition-[margin] duration-300 ease-in-out',
                    showExplanation ? 'mt-2' : 'mt-8',
                  )}
                >
                  <div className="mb-4 relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Evaluation Name
                    </label>
                    <input
                      value={customEvalName}
                      onChange={handleNameChange}
                      onFocus={() => {
                        setValidationMessages({
                          ...validationMessages,
                          name: '',
                        });
                      }}
                      aria-invalid={validationMessages.name ? 'true' : 'false'}
                      aria-errormessage={validationMessages.name}
                      className={clsx(
                        'w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-zinc-800 focus:border-zinc-800 text-gray-700',
                        validationMessages.name ? '!border-red-500' : '',
                      )}
                      placeholder="Enter a name for your evaluation..."
                    />
                    {validationMessages.name && (
                      <p className="text-xs italic text-red-500 text-right">
                        {validationMessages.name}
                      </p>
                    )}
                  </div>

                  <div className="mb-4 relative">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Define Your Custom Evaluation Criteria
                    </label>
                    <textarea
                      value={customEvalText}
                      onChange={handleInputChange}
                      onFocus={() => {
                        setValidationMessages({
                          ...validationMessages,
                          prompt: '',
                        });
                      }}
                      className={clsx(
                        'w-full h-32 p-3 border rounded-md shadow-sm focus:outline-none focus:ring-zinc-800 focus:border-zinc-800 resize-none text-gray-700',
                        validationMessages.prompt ? '!border-red-500' : '',
                      )}
                      placeholder="Describe how you want to evaluate your model..."
                    />
                    {validationMessages.prompt && (
                      <p className="text-xs italic text-red-500 text-right -mt-[6px]">
                        {validationMessages.prompt}
                      </p>
                    )}
                  </div>

                  <div className="mb-4 relative">
                    <div className="block text-sm font-medium text-zinc-800">
                      Select Data for Evaluation{' '}
                    </div>
                    <small className="block text-xs text-zinc-500 mb-2">
                      (The prompts from the selected logs will be used for the
                      evaluation)
                    </small>
                    <div className="items-center gap-6 mx-1 mt-1 mb-3 md:flex">
                      <div className="flex items-center mt-2 min-w-fit w-fit shrink-0 md:mt-0">
                        <input
                          type="radio"
                          id="all"
                          name="dataInput"
                          value="all"
                          disabled={!userHasLogs}
                          className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                          checked={dataInput === 'all'}
                          onChange={() => {
                            setDataInput('all');
                            setSelectedDataset(null);
                            setSelectedTags([]);
                            setValidationMessages({
                              ...validationMessages,
                              tags: '',
                              file: '',
                            });
                          }}
                        />
                        <label
                          htmlFor="all"
                          className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                        >
                          All logs
                        </label>
                      </div>

                      <div className="flex items-center mt-3 md:mt-0">
                        <input
                          type="radio"
                          id="tags"
                          name="dataInput"
                          value="tags"
                          className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                          checked={dataInput === 'tags'}
                          onChange={() => setDataInput('tags')}
                          disabled={tagList?.length === 0}
                        />
                        <label
                          htmlFor="tags"
                          className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                        >
                          By tags
                          <span className="ml-2 text-xs text-zinc-500">
                            (select multiple)
                          </span>
                        </label>
                      </div>

                      <div className="flex items-center mt-3 md:mt-0">
                        <input
                          type="radio"
                          id="datasets"
                          name="dataInput"
                          value="datasets"
                          className="w-5 h-5 bg-gray-100 border-gray-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent peer disabled:cursor-not-allowed"
                          checked={dataInput === 'datasets'}
                          onChange={() => setDataInput('datasets')}
                          disabled={datasets.length === 0}
                        />
                        <label
                          htmlFor="datasets"
                          className="ml-2 text-sm font-normal text-zinc-800 peer-disabled:opacity-50"
                        >
                          By dataset
                        </label>
                      </div>
                    </div>

                    {dataInput === 'tags' && (
                      <>
                        <div
                          className={clsx(
                            'p-3 rounded-md max-h-40 h-40 border',
                            tagList.length === 0 ? 'hidden' : 'visible',
                            validationMessages.tags
                              ? 'border-red-500'
                              : 'border-gray-200',
                          )}
                        >
                          <div className="flex flex-wrap content-start gap-x-2 gap-y-3 overflow-y-auto h-full pb-3">
                            {tagList.map((tag, index) => (
                              <div
                                key={index + tag}
                                className={clsx(
                                  'flex flex-col items-center justify-start p-1 rounded-full shadow cursor-pointer border text-sm font-normal text-zinc-800 h-fit w-fit px-4',
                                  isTagSelected(tag)
                                    ? 'bg-indigo-100 shadow-indigo-200'
                                    : 'bg-zinc-50 border-transparent',
                                )}
                                onClick={() => toggleTags(tag)}
                                role="button"
                                aria-pressed={isTagSelected(tag)}
                              >
                                {tag}
                              </div>
                            ))}
                          </div>
                        </div>
                        {validationMessages.tags && (
                          <p className="text-xs italic text-red-500 text-right">
                            {validationMessages.tags}
                          </p>
                        )}
                      </>
                    )}

                    {dataInput === 'datasets' && (
                      <div className="mt-4">
                        <Listbox
                          value={selectedDataset}
                          onChange={setSelectedDataset}
                          aria-invalid={
                            validationMessages.datasets ? 'true' : 'false'
                          }
                          aria-errormessage={validationMessages.datasets}
                        >
                          <div className="relative mt-1">
                            <Listbox.Button
                              onFocus={() => {
                                setValidationMessages({
                                  ...validationMessages,
                                  datasets: '',
                                });
                              }}
                              className={clsx(
                                'relative w-full h-10 pl-3 pr-10 text-left bg-white border rounded shadow cursor-default border-gray-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-gray-900',
                                validationMessages.datasets
                                  ? '!border-red-500'
                                  : '',
                              )}
                            >
                              <span className="block truncate">
                                {selectedDataset?.name || 'Select a dataset'}
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon
                                  className="w-5 h-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>
                            {validationMessages.datasets && (
                              <p className="text-xs italic text-right text-red-500">
                                {validationMessages.datasets}
                              </p>
                            )}
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded shadow max-h-48 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                {datasets.map((dataset) => (
                                  <Listbox.Option
                                    key={dataset.id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                        active
                                          ? 'bg-indigo-100 text-indigo-900'
                                          : 'text-gray-900'
                                      }`
                                    }
                                    value={dataset}
                                  >
                                    {({ selected }) => (
                                      <>
                                        <span
                                          className={`block truncate ${
                                            selected
                                              ? 'font-medium'
                                              : 'font-normal'
                                          }`}
                                        >
                                          {dataset.name}
                                        </span>
                                        {selected && (
                                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                            <CheckIcon
                                              className="w-5 h-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    className="inline-flex items-center justify-center h-10 px-4 py-2 ml-2 text-sm font-medium text-red-900 border border-gray-300 rounded-md hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={onClose}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className={clsx(
                      'px-4 py-2 bg-zinc-800 text-white rounded hover:bg-zinc-700 text-sm font-medium cursor-pointer',
                      loading && 'opacity-50 cursor-not-allowed',
                    )}
                    onClick={handleSave}
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save Evaluation'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
