export const faqData = [
  {
    question: 'What happens to my data when I stop my instance?',
    answer: () => (
      <p>
        Once your server rental period concludes, we will immediately terminate
        your virtual machine (VM) and delete any associated images.
        Consequently, all stored data will be permanently removed. We strongly
        advise you to externally save and back up your data prior to initiating
        a server cancellation. Tromero assumes no responsibility for data loss
        resulting from the termination of server services.
      </p>
    ),
  },
  {
    question: 'Why is Responsible AI important for Tromero?',
    answer: () => (
      <p>
        Tromero was founded by Sophia Kalanovska and Charles Higgins, who are
        both PhD Researchers at the Centre for Doctoral Training in Safe and
        Trusted AI. AI safety and responsibility is therefore firmly rooted
        within Tromero's culture and values. Their expertise and dedication
        underscore our commitment to embedding responsible AI practices at the
        core of our platform's development and operations.
      </p>
    ),
  },
  {
    question:
      'Do you process personal data and/or share it with third parties?',
    answer: () => (
      <p>
        We do not engage in the processing of your personal data for any reason.
        Furthermore, we do not disclose or sell your information to any third
        parties. If you wish to have your data deleted, you may do so at any
        time by sending a request to our support team at{' '}
        <a
          className="text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 hover:text-indigo-700"
          href="mailto:support@tromero.ai"
        >
          support@tromero.ai
        </a>
        .
      </p>
    ),
  },
];

export const faqIntro = [
  {
    question: 'Why use Tromero?',
    answer: () => (
      <p>
        Our unique software layer significantly reduces your engineering time.
        Further, Tromero’s platform is incredibly intuitive and user-friendly.
        You can start using our platform immediately without any training,
        streamlining your workflow effortlessly.
      </p>
    ),
  },
  {
    question: 'Do you offer a free trial?',
    answer: () => (
      <p>
        Yes, each new user who signs up is credited with $2 to get started. This
        allows you to test out our platform and see if it is right for you. If
        you are a student or a startup and you believe you could benefit from
        free credits, please check out the{' '}
        <a
          className="text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-500"
          href="/partner"
        >
          partner with us{' '}
        </a>
        tab.
      </p>
    ),
  },
  {
    question: 'Which payment methods do you accept?',
    answer: () => (
      <p>
        We only accept debit or credit card payments for now. Please get in
        touch with{' '}
        <a
          className="text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 hover:text-indigo-700"
          href="mailto:sales@tromero.ai"
        >
          sales@tromero.ai{' '}
        </a>
        if you’d like to explore other options.
      </p>
    ),
  },
  {
    question: 'What is your payment policy?',
    answer: () => (
      <p>
        We require users to keep a valid payment method on file and maintain a
        positive account balance. Once you begin a rental, charges will accrue
        in 15-minute increments and be deducted from your balance. Should your
        balance fall below the required amount, your instance will be
        terminated.
      </p>
    ),
  },
];

export const faqTech = [
  {
    question: 'How long does it take to get access to the machine I purchased?',
    answer: () => (
      <p>
        If you are renting an hourly machine, you will gain access to the server
        within seconds. If you’d like to talk about a dedicated bare-metal
        server for an extended period, please contact{' '}
        <a
          className="text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 hover:text-indigo-700"
          href="mailto:sales@tromero.ai"
        >
          sales@tromero.ai{' '}
        </a>{' '}
        and we hope to be able get you up and running with a dedicated server
        within 24 hours.
      </p>
    ),
  },
  {
    question:
      "Is it possible for me to test the machine's latency before committing to a rental?",
    answer: () => (
      <p>
        Absolutely, you are welcome to rent the server for a brief duration,
        even if it's just for a few minutes, at a starting cost of $0.30 per
        hour, to evaluate its latency. To achieve the lowest latency, selecting
        a server location nearest to you is crucial. Our servers are distributed
        worldwide, ensuring you can find a suitable option close to your
        location. For any additional questions about latency or assistance in
        selecting the most appropriate server, please contact our support team
        via live chat or email at{' '}
        <a
          className="text-indigo-600 dark:text-indigo-400 dark:hover:text-indigo-500 hover:text-indigo-700"
          href="mailto:support@tromero.ai"
        >
          support@tromero.ai
        </a>
        . Since latency may vary across servers, our team is ready to provide
        the necessary guidance.
      </p>
    ),
  },
  {
    question: 'Are your machines fully dedicated or are they spot instances?',
    answer: () => (
      <p>
        Machines are fully dedicated 24/7 to you. They will only stop when you
        spin down the server or finish your rental period. The price is locked
        for the duration of your rental. However, providers do regularly remove
        instances (at scheduled times) for maintenance.
      </p>
    ),
  },
];
