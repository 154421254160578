import { Modal } from 'flowbite-react';
import toast from 'react-hot-toast';
import { useState } from 'react';

import { useUser } from '../../../UserContext';
const DeleteModelWaringModal = ({ showModal, onClose, model }) => {
  const { customAxios } = useUser();
  const [loading, setLoading] = useState(false);
  const handleDeleteModel = async () => {
    setLoading(true);
    try {
      const response = await customAxios.delete(
        `tailor/v1/models/${model.model_id}`,
      );
      if (response.status === 200) {
        toast.success('Model deleted successfully');
        setLoading(false);
        onClose();
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to delete model, try again later.');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      show={showModal}
      onClose={onClose}
      title="Delete API key"
      dismissible
    >
      <div className="p-6">
        <div className="text-lg font-semibold text-zinc-800">Delete Model</div>
        <div className="mt-4 text-sm text-zinc-700">
          <p>
            This Model will immediately be deleted. This action is irreversible.
            Once deleted, you'll no longer be able to view or deploy this Model.
          </p>
          <p className="mt-2">
            If this model was previously deployed, you won't have access to the
            historical usage data other than in the billing section.
          </p>
          <div className="w-full p-2 my-4 border rounded-md text-zinc-800">
            {model?.model_name}
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-8">
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400 disabled:bg-red-100 disabled:text-zinc-400"
            onClick={() => handleDeleteModel()}
            disabled={loading}
          >
            Delete Model
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModelWaringModal;
