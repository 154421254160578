import { useLayoutEffect } from 'react';

const Discord = () => {
  useLayoutEffect(() => {
    window.location.href = 'https://discord.gg/76gpnWzNPQ';
  }, []);
  return null;
};

export default Discord;
