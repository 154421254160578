import { Modal } from 'flowbite-react';

import useUploadLogs from '../../../Hooks/useUploadLogs';

const UploadLogsReportModal = ({ showModal, closeModal }) => {
  const { report } = useUploadLogs();

  const handleCloseModal = () => {
    closeModal();
  };

  const handleDownloadCSV = () => {
    if (report && report.validationMessages?.length > 0) {
      const csvRows = [
        ['Line Number', 'Error Message'], // CSV header
        ...report.validationMessages.map((message) => {
          const match = message.match(/on line (\d+):\s*(.*)/i);
          if (match) {
            const [, lineNumber, errorMessage] = match;
            // Enclose the error message in double quotes to handle commas
            return [lineNumber, `"${errorMessage.replace(/"/g, '""')}"`];
          }
          return ['N/A', `"${message.replace(/"/g, '""')}"`];
        }),
      ];

      const csvContent = csvRows.map((row) => row.join(',')).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `validation_errors_${report.fileName.replace(/\s/g, '_')}.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Modal
      show={showModal}
      onClose={handleCloseModal}
      title="Upload Logs"
      dismissible
      className="dark:bg-zinc-800 font-dmSans"
    >
      <Modal.Header className="dark:bg-zinc-800">
        <div className="text-lg font-semibold text-zinc-700 dark:text-zinc-300">
          Validation & Upload Report - {report.error ? 'Failed' : 'Success'}
        </div>
      </Modal.Header>
      <Modal.Body className="dark:bg-zinc-800">
        {report && (
          <>
            <ul>
              {report.error && (
                <li>
                  <span className="font-semibold">Error:</span>{' '}
                  {report.errorMessage}
                </li>
              )}
              <li>
                <span className="font-semibold">File Name:</span>{' '}
                {report.fileName}
              </li>
              <li>
                <span className="font-semibold">Total Lines:</span>{' '}
                {report.totalLines}
              </li>
              <li>
                <span className="font-semibold">Valid Lines:</span>{' '}
                {report.validLinesCount}
              </li>
            </ul>
            {report?.validationMessages?.length > 0 && (
              <div>
                <span className="font-semibold">Validation Messages:</span>
                <div className="overflow-y-auto h-40 border rounded-md p-2 mt-2">
                  <ol className="list-decimal list-inside font-mono text-xs">
                    {report.validationMessages.map((message, index) => (
                      <li key={index}>{message}</li>
                    ))}
                  </ol>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={handleDownloadCSV}
                    className="mt-4 inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md min-w-36 max-w-64 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 whitespace-nowrap"
                  >
                    Download CSV
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UploadLogsReportModal;
