import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLayoutEffect, useState, useCallback } from 'react';
import toast from 'react-hot-toast';

import { useUser } from '../UserContext';
import { applyDarkModePreference } from '../utils/generalUtils';

/**
 * OauthCallback handles the callback from an OAuth login service,
 * managing the login process and subsequent navigation based on user status.
 */
const OauthCallback = () => {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const { updateUserDetails } = useUser();
  const [isProvider, setIsProvider] = useState(false);

  const server = import.meta.env.VITE_FRONTEND_SERVER_URL;
  const storedState = localStorage.getItem('state');
  const code = queryParameters.get('code');
  const state = queryParameters.get('state');
  const service = state ? state.split('-')[0] : null;
  const postLoginRedirect = localStorage.getItem('postLoginRedirect');

  const login = useCallback(async () => {
    if (!state || state !== storedState || !code || !service) {
      if (import.meta.env.DEV) {
        console.log('Invalid state, code, or service');
      }
      navigate('/');
      return;
    }
    try {
      const response = await axios.post(`${server}/login/socials`, {
        code,
        service,
      });

      if (response.status === 200 || response.status === 201) {
        const token = (
          response.headers['Authorization'] || response.headers['authorization']
        ).substring(7);
        if (!token) {
          throw new Error('Invalid token');
        }

        localStorage.setItem('token', token);
        const userDetails = await updateUserDetails(response.data.user);

        if (postLoginRedirect) {
          navigate(postLoginRedirect);
          localStorage.removeItem('postLoginRedirect');
        }
        if (userDetails.is_provider) {
          const currentTheme = localStorage.getItem('darkMode');
          if (!currentTheme) {
            localStorage.setItem('darkMode', 'true');
            document.documentElement.classList.add('dark');
          } else {
            applyDarkModePreference();
          }
          setIsProvider(true);
        } else {
          applyDarkModePreference();
        }
        redirectToPath(isProvider);
      } else if (response.status === 401) {
        toast.error('Please sign up first');
        navigate('/signup');
      } else {
        if (import.meta.env.DEV) {
          console.error('Problems with the Token from server');
        }
        throw new Error('There was an error logging in. Please try again.');
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error('Please sign up first');
        navigate('/signup');
      } else {
        if (import.meta.env.DEV) {
          console.error(error);
        }
        toast.error('There was an error logging in. Please try again.');
        navigate('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, navigate, server, service, state, storedState, updateUserDetails]);

  const redirectToPath = (isProvider) => {
    const shouldRedirect = localStorage.getItem('shouldRedirect');
    if (shouldRedirect === 'no') {
      return;
    }
    const redirectPath = isProvider ? '/providerdashboard' : '/marketplace';
    localStorage.removeItem('shouldRedirect');
    navigate(redirectPath);
  };

  useLayoutEffect(() => {
    login();
  }, [login]);

  return null;
};

export default OauthCallback;
