// context/UploadServiceContext.js
import { createContext, useContext, useEffect, useState } from 'react';

import uploadService from '../services/uploadService';

const UploadServiceContext = createContext(null);

// eslint-disable-next-line react-refresh/only-export-components
export const useUploadService = () => {
  const context = useContext(UploadServiceContext);

  if (!context) {
    throw new Error(
      'useUploadService must be used within a UploadServiceProvider',
    );
  }
  return context;
};

export const UploadServiceProvider = ({ children }) => {
  const [state, setState] = useState(uploadService.getState());

  useEffect(() => {
    const unsubscribe = uploadService.subscribe((newState) => {
      setState(newState);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UploadServiceContext.Provider value={{ state, uploadService }}>
      {children}
    </UploadServiceContext.Provider>
  );
};
