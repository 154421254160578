import { useEffect, useMemo, useState } from 'react';
import {
  AdjustmentsHorizontalIcon,
  CodeBracketSquareIcon,
  FolderIcon,
  HomeIcon,
  CloudArrowUpIcon,
  CloudIcon,
  ChartBarSquareIcon,
  // ArrowRightEndOnRectangleIcon,
  AtSymbolIcon,
  BookOpenIcon,
  CpuChipIcon,
  RectangleGroupIcon,
  Cog6ToothIcon,
  ArrowRightCircleIcon,
} from '@heroicons/react/24/outline';
import { BsGraphDown } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Crisp } from 'crisp-sdk-web';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Dropdown } from 'flowbite-react';
import { Transition } from '@headlessui/react';

import DarkModeToggle from '../DarkModeToggle';
import CustomLink from '../../utils/CustomLink';
import {
  checkTokenExpiration,
  hasUserInLocalStorage,
} from '../../utils/userUtils';
import { useUser } from '../../UserContext';

const TailorNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user,
    setUser,
    logout,
    setShowSignInModal,
    setShowTokenExpiredModal,
  } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [minimized, setMinimized] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [toggleView, setToggleView] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    const updateUserFromLocalStorage = async () => {
      await hasUserInLocalStorage(setUser);
    };
    updateUserFromLocalStorage();
  }, [setUser]);

  useEffect(() => {
    if (!minimized) {
      setTimeout(() => {
        setIsReady(true);
      }, 200);
    } else {
      setIsReady(false);
    }
  }, [minimized]);

  const handleSignOut = () => {
    logout();
    localStorage.removeItem('darkMode');
    localStorage.removeItem('logoutTime');
    localStorage.removeItem('selectedRental');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('email');
    localStorage.clear();
    setIsMenuOpen(false);
    navigate('/');
  };

  useEffect(() => {
    let pathSegment = location.pathname.replace(/^\/+/, '');
    if (pathSegment === '') {
      pathSegment = 'dashboard';
    } else if (pathSegment.includes('tailor')) {
      pathSegment = pathSegment.replace('tailor/', '');
    }
    pathSegment = pathSegment.split('/')[0];

    const matchedItem = categories
      .flatMap((category) => category.items)
      .find((item) => item.href === pathSegment);

    const matchedLink = links.find((link) => link.href === pathSegment);

    if (matchedItem) {
      setCurrentItem(matchedItem);
    } else if (matchedLink) {
      setCurrentItem(matchedLink);
    } else {
      setCurrentItem(null);
    }
  }, [location.pathname]);

  const categories = useMemo(
    () => [
      {
        category: 'Data & Datasets',
        items: [
          {
            name: 'Data',
            href: 'logs-datasets',
            icon: FolderIcon,
            count: '',
            current: true,
          },
        ],
      },
      // {
      //   category: 'Fine-tuning',
      //   items: [
      //     {
      //       name: 'Launch',
      //       href: 'finetuning',
      //       icon: AdjustmentsHorizontalIcon,
      //       count: '',
      //       current: false,
      //     },
      //     {
      //       name: 'Running',
      //       href: 'training-metrics',
      //       icon: BsGraphDown,
      //       current: false,
      //     },
      //   ],
      // },

      {
        category: 'Models',
        items: [
          {
            name: 'My Models',
            href: 'my-models',
            icon: CpuChipIcon,
            current: false,
          },
          {
            name: 'Model Aliases',
            href: 'model-aliasing',
            icon: AtSymbolIcon,
            current: false,
          },
          {
            name: 'Model Rankings',
            href: 'rankings',
            icon: ChartBarSquareIcon,
            current: false,
          },
        ],
      },
      {
        category: 'Inference',
        items: [
          // {
          //   name: 'Model Evaluations',
          //   href: 'evaluate',
          //   icon: ChartBarSquareIcon,
          //   current: false,
          // },
          {
            name: 'Playground',
            href: 'playground',
            icon: CodeBracketSquareIcon,
            current: false,
          },
        ],
      },
      // {
      //   category: 'Deployment',
      //   items: [
      //     // {
      //     //   name: 'Deploy',
      //     //   href: 'deploy',
      //     //   icon: CloudArrowUpIcon,
      //     //   current: false,
      //     // },
      //     // {
      //     //   name: 'Self Hosting',
      //     //   href: 'self-hosting',
      //     //   icon: CloudIcon,
      //     // },
      //   ],
      // },
      // // {
      // //   category: 'GPU Clusters',
      // //   items: [
      // //     {
      // //       name: 'Rent GPUs',
      // //       href: 'marketplace',
      // //       icon: CpuChipIcon,
      // //       current: false,
      // //     },
      // //   ],
      // // },
      {
        category: 'Continuous Development',
        items: [
          {
            name: 'Pipeline',
            href: 'pipeline',
            icon: ArrowRightCircleIcon,
            current: false,
          },
        ],
      },
    ],
    [],
  );

  const links = [
    {
      id: 1,
      name: 'Dashboard',
      href: '',
      icon: RectangleGroupIcon,
      current: false,
    },
    {
      id: 3,
      name: 'Settings',
      href: 'settings',
      icon: Cog6ToothIcon,
      current: false,
    },
    {
      id: 4,
      name: 'Documentation',
      href: 'https://docs.tromero.ai',
      icon: BookOpenIcon,
      current: false,
    },
    {
      id: 1,
      name: 'Return to Home',
      href: '/',
      icon: HomeIcon,
      current: false,
    },
  ];

  const handleItemClick = (item) => {
    const previousItem = currentItem;
    setCurrentItem(item);
    setIsMenuOpen(false);
    if (item.href === 'https://tromero.ai') {
      navigate('https://tromero.ai');
    } else if (item.href.startsWith('http')) {
      window.open(item.href, '_blank');
    } else if (item.href === 'action:open-crisp') {
      Crisp.chat.open();
    } else if (item.href === '/') {
      navigate('/');
    } else {
      if (checkTokenExpiration()) {
        setCurrentItem(previousItem);
        setMinimized(false);
        const redirectPath = item.href.startsWith('tailor/')
          ? item.href
          : `tailor/${item.href}`;
        localStorage.setItem('postLoginRedirect', redirectPath);
        setShowTokenExpiredModal(true);
        return;
      }
      navigate(item.href);
    }
  };

  const handleLogin = () => {
    localStorage.setItem('shouldRedirect', 'no');
    setShowSignInModal(true);
  };

  function MenuToggleButton({ isMenuOpen, setIsMenuOpen }) {
    const variants = {
      open: {
        rotate: 90,
        scale: 1.2,
      },
      closed: {
        rotate: 0,
        scale: 1,
      },
    };

    return (
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-label="Toggle menu"
        className="pr-4"
      >
        <motion.div
          animate={isMenuOpen ? 'open' : 'closed'}
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          {isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-zinc-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-zinc-700"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}
        </motion.div>
      </button>
    );
  }

  useEffect(() => {
    const handleMouseEnter = () => {
      document.body.classList.add('!overflow-hidden');
      document.documentElement.classList.add('!overflow-hidden');
    };

    const handleMouseLeave = () => {
      document.body.classList.remove('!overflow-hidden');
      document.documentElement.classList.remove('!overflow-hidden');
    };

    const navbar = document.getElementById('desktop-navbar');
    if (navbar) {
      navbar.addEventListener('mouseenter', handleMouseEnter);
      navbar.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (navbar) {
        navbar.removeEventListener('mouseenter', handleMouseEnter);
        navbar.removeEventListener('mouseleave', handleMouseLeave);
      }
      handleMouseLeave();
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('!overflow-hidden');
      document.documentElement.classList.add('!overflow-hidden');
    } else {
      document.body.classList.remove('!overflow-hidden');
      document.documentElement.classList.remove('!overflow-hidden');
    }

    return () => {
      document.body.classList.remove('!overflow-hidden');
      document.documentElement.classList.remove('!overflow-hidden');
    };
  }, [isMenuOpen]);

  return (
    <div>
      {/* Mobile navbar */}
      <div
        className={`fixed font-dmSans top-0 left-0 right-0 z-50 py-2 lg:hidden backdrop-blur ${isMenuOpen ? 'bg-zinc-50' : ''}`}
      >
        <div className="flex items-center justify-between">
          <Link to="" className="flex items-center pl-4">
            <img
              src="/Logo.png"
              alt="Tromero Logo"
              className="w-10 h-10 mr-2"
            />
          </Link>
          <MenuToggleButton
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        </div>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0.0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              delay: 0.1,
              duration: 0.3,
              ease: 'easeInOut',
            }}
            className="h-[calc(100vh-4rem)] px-4 pt-4 pb-6 mt-4 space-y-4 sm:border sm:rounded-md sm:shadow-[2px] md:pt-2 sm:m-4 sm:h-[calc(100vh-5rem)] bg-zinc-50 overflow-y-auto"
          >
            {categories.map((category) => (
              <div key={category.category}>
                <p className="font-medium text-gray-500 text-xs">
                  {category.category}
                </p>
                {category.items.map((item) => (
                  <button
                    key={item.name}
                    className="flex items-center w-full px-2 py-2 font-medium text-left text-gray-700 hover:bg-gray-50"
                    onClick={() => {
                      handleItemClick(item);
                    }}
                  >
                    <item.icon
                      className={clsx(
                        'w-5 h-5 mr-3 text-gray-400',
                        item.name === 'Model Evaluations' && 'scale-x-[-1]',
                        item.name === 'Training Metrics' && '!h-4 !w-4',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                ))}
              </div>
            ))}
            <div className="pt-6">
              <hr className="space-y-6 border-gray-200" />
            </div>
            {
              <div className="gap-y-1">
                {links.map((link) => (
                  <button
                    key={link.name}
                    className="flex items-center w-full px-2 py-1 font-medium text-left text-gray-700 hover:bg-gray-50"
                    onClick={() => handleItemClick(link)}
                  >
                    <link.icon
                      className="w-5 h-5 mr-3 text-gray-400"
                      aria-hidden="true"
                    />
                    {link.name}
                  </button>
                ))}
              </div>
            }
            <div className="flex items-center w-24 h-12 -ml-0.5">
              <Dropdown
                arrowIcon={false}
                inline
                label={
                  <div className="flex items-center justify-center w-10 h-10 text-gray-100 bg-gray-900 rounded-full shadow-lg">
                    {user.email ? (
                      user.email[0].toUpperCase()
                    ) : (
                      <svg
                        className="w-4 h-4 text-gray-200 dark:text-zinc-50"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                        />
                      </svg>
                    )}
                  </div>
                }
              >
                <Dropdown.Header>
                  <span className="block text-sm">{user.name}</span>
                  <span className="block text-sm font-medium truncate">
                    {user.email === 'bobbobingtonabc@gmail.com'
                      ? 'Bob'
                      : user.email}
                  </span>
                  {/* {user.is_provider && (
                    <div className="mt-4">
                      <DarkModeToggle setToggleView={setToggleView} />
                    </div>
                  )} */}
                </Dropdown.Header>
                <CustomLink to="/tailor/settings">
                  <Dropdown.Item>Settings</Dropdown.Item>
                </CustomLink>
                {user.is_admin && (
                  <CustomLink to="/admin">
                    <Dropdown.Item>Admin Dashboard</Dropdown.Item>
                  </CustomLink>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
              </Dropdown>
            </div>
          </motion.div>
        )}
      </div>

      {/* Navbar for larger screens (lg and above) */}
      <Transition
        show={true}
        enter="transition-all ease-in-out duration-600"
        enterFrom="w-16"
        enterTo="w-64"
        leave="transition-all ease-in-out duration-600"
        leaveFrom="w-64"
        leaveTo="w-16"
        id="desktop-navbar"
        className={clsx(
          'fixed flex-col hidden h-dvh bg-white border-r border-gray-200 lg:flex',
          'transition-all duration-300 ease-in-out z-[49]',
          'overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 overflow-x-hidden',
          minimized ? 'w-16 items-center' : 'w-64 px-6',
          'hover:w-64 hover:px-6',
        )}
        onMouseEnter={() => setMinimized(false)}
        onMouseLeave={() => setMinimized(true)}
      >
        <div className="w-full mt-4">
          <Link to="">
            <img
              src="/tromero_bright.png"
              alt="Tromero Logo"
              className={clsx(
                'mx-auto',
                minimized ? 'w-auto h-8 ' : 'w-auto h-14 pb-2',
              )}
            />
          </Link>
        </div>
        {categories.map((category) => (
          <div key={category.category} className="my-2">
            <motion.span
              initial={{ opacity: 0, x: minimized ? -50 : 0 }} // Start hidden and slightly to the left when minimized
              animate={{
                opacity: minimized ? 0 : 1,
                x: minimized ? -50 : 0,
              }} // Animate to visible or hidden and adjust position
              exit={{ opacity: 0, x: minimized ? -50 : 0 }} // Exit animation mirrors the initial state
              transition={{ duration: 0.5, ease: 'easeInOut' }} // Smooth transition
              className={clsx(
                'mb-2 font-medium text-xs',
                minimized ? 'text-transparent' : 'text-zinc-500',
              )}
            >
              {minimized ? '.' : category.category}
            </motion.span>

            <div className="flex divide-x divide-zinc-300">
              <div></div>
              <div className="flex-grow">
                <ul role="list" className="flex flex-col flex-grow space-y">
                  {category.items.map((item) => (
                    <li
                      key={item.name}
                      // className={clsx(minimized ? 'first:mt-2' : null)}
                    >
                      <button
                        onClick={() => handleItemClick(item)}
                        className={clsx(
                          currentItem?.name === item.name
                            ? 'bg-gray-100 text-zinc-900'
                            : 'text-gray-500 hover:text-zinc-600 hover:bg-gray-50',
                          'group gap-x-3 rounded-r-md p-2 text-xs leading-6 font-semibold w-full',
                          minimized ? 'flex-col items-center gap-y-3' : 'flex',
                        )}
                      >
                        <item.icon
                          className={clsx(
                            currentItem?.name === item.name
                              ? 'text-zinc-900'
                              : 'text-gray-500 group-hover:text-zinc-600',
                            'h-6 w-6 shrink-0',
                            minimized ? 'text-xs' : null,
                            item.name === 'Model Evaluations' && 'scale-x-[-1]',
                            item.name === 'Running' && '!h-5 !w-5',
                          )}
                          aria-hidden="true"
                        />
                        <motion.span
                          initial={{ opacity: 0 }}
                          animate={{
                            opacity: minimized ? 0 : 1,
                          }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5, ease: 'easeInOut' }} // Smooth transition
                          className={clsx(
                            minimized ? 'hidden' : 'opacity-100',
                            isReady ? null : 'hidden',
                          )}
                        >
                          {item.name}
                        </motion.span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
        <div className="flex-grow "></div>

        <div
          className={clsx(
            'flex-col flex -ml-6 mb-4',
            minimized ? 'w-16 items-center ml-1' : 'w-64 px-6',
            'hover:w-64 hover:mr-6',
          )}
        >
          <hr className="my-4 border-gray-200" />
          <ul className="">
            {links.map((link) => (
              <li key={link.name}>
                <button
                  onClick={() => handleItemClick(link)}
                  className={clsx(
                    currentItem?.name === link.name
                      ? 'bg-gray-100 text-zinc-900'
                      : 'text-gray-500 hover:text-zinc-600 hover:bg-gray-50 hover:pr-6',
                    'group gap-x-3 rounded-md p-2 text-xs leading-6 font-semibold w-full',
                    minimized ? 'flex-col items-center gap-y-3' : 'flex',
                  )}
                >
                  <link.icon
                    className={clsx(
                      currentItem?.name === link.name
                        ? 'text-zinc-900'
                        : 'text-gray-500 group-hover:text-zinc-600',
                      'h-6 w-6 shrink-0',
                      minimized ? 'text-xs' : null,
                    )}
                    aria-hidden="true"
                  />
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: minimized ? 0 : 1,
                    }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }} // Smooth transition
                    className={clsx(
                      minimized ? 'hidden' : 'opacity-100',
                      isReady ? null : 'hidden',
                    )}
                  >
                    {link.name}
                  </motion.span>
                </button>
              </li>
            ))}
          </ul>
          <div className={clsx('h-12 w-12 mt-2', minimized && 'flex-center')}>
            <Dropdown
              arrowIcon={false}
              inline
              className="font-dmSans"
              label={
                <div className="flex items-center justify-center w-10 h-10 text-gray-100 bg-gray-900 rounded-full shadow-lg">
                  {user.email ? (
                    user.email[0].toUpperCase()
                  ) : (
                    <svg
                      className="w-4 h-4 text-gray-200 dark:text-zinc-50"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                      />
                    </svg>
                  )}
                </div>
              }
            >
              <Dropdown.Header>
                <span className="block text-sm">{user.name}</span>
                <span className="block text-sm font-medium truncate">
                  {user.email === 'bobbobingtonabc@gmail.com'
                    ? 'Bob'
                    : user.email}
                </span>
              </Dropdown.Header>
              <button className="w-full" onClick={() => Crisp.chat.open()}>
                <Dropdown.Item>Support</Dropdown.Item>
              </button>
              {user.is_admin && (
                <>
                  <CustomLink to="/admin">
                    <Dropdown.Item>Admin Dashboard</Dropdown.Item>
                  </CustomLink>
                </>
              )}

              <Dropdown.Divider />
              <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </Transition>

      {/* Spacer div that matches navbar width */}
      <div
        className={clsx(
          'hidden lg:block shrink-0',
          'transition-all duration-300 ease-in-out',
          minimized ? 'w-16' : 'w-64',
        )}
        aria-hidden="true"
      />

      {/* Overlay when navbar is expanded */}
      {!minimized && (
        <div
          className="fixed inset-0 bg-transparent z-[48] pointer-events-none hidden lg:block"
          aria-hidden="true"
        />
      )}
    </div>
  );
};

export default TailorNavbar;
