import { useNavigate } from 'react-router-dom';

import { useUser } from '../UserContext'; // Import your useUser hook

/**
 * @module CustomLink
 * @description A component to handle navigation links, checks for token expiration, if the token is expired, it shows a modal to the user to login again without redirecting to the link
 * @param {string} to - The link to navigate to
 * @param {string} children - The component it is wrapping
 * @returns {JSX.Element}
 */
const CustomLink = ({ to, children, className = '' }) => {
  const navigate = useNavigate();
  const {
    setShowTokenExpiredModal,
    checkTokenExpiration,
    user,
    fetchAndUpdateUserDetails,
  } = useUser();

  const handleClick = async (e) => {
    e.preventDefault();
    const isExpired = checkTokenExpiration();

    if (isExpired) {
      localStorage.setItem('postLoginRedirect', to);
      setShowTokenExpiredModal(true);
    } else {
      if (user) {
        await fetchAndUpdateUserDetails();
      }
      navigate(to);
    }
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      className={`cursor-pointer ${className}`}
    >
      {children}
    </a>
  );
};

export default CustomLink;
