import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from './MyModels/Breadcrumbs';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../UserContext';
import MyModelsCompletedJob from './MyModels/MyModelsCompletedJob';
import Spinner from '../Spinner';

const TailorTrainingMetricsv2 = () => {
  const { customAxios, user } = useUser();
  const { model_name } = useParams();
  const queryClient = useQueryClient();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchNow, setFetchNow] = useState(false);

  useEffect(() => {
    const fetchModels = async () => {
      setIsLoading(true);
      const models = queryClient.getQueryData(['models']);
      const foundModel = models?.find((m) => m.model_name === model_name);
      setModel(foundModel || null);
      setIsLoading(false);
    };

    fetchModels();
  }, [model_name, queryClient]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const failedStates = useMemo(
    () => ['failed_training', 'cancelled', 'training-cancelled'],
    [],
  );

  const trainingStates = useMemo(() => ['start_training', 'training'], []);

  const getModelStatus = (model) => {
    if (model.state.toLowerCase().includes('fail')) {
      return 'failed';
    } else if (model.state.toLowerCase().includes('cancelled')) {
      return 'cancelled';
    } else if (model.state.toLowerCase().includes('train')) {
      return 'running';
    } else {
      return 'completed';
    }
  };

  const modelStatus = model ? getModelStatus(model) : null;

  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans w-full">
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className="mx-auto w-full px-4 sm:px-6 lg:px-8 overflow-x-hidden">
          <div
            className="flex items-center justify-between h-16 w-full"
            role="banner"
          >
            <Breadcrumbs model={model} />
            <div className="flex items-center gap-2 lg:gap-6" />
          </div>
        </div>
      </header>
      <div className="flex flex-col bg-zinc-50 font-dmSans p-4 max-w-7xl mx-auto">
        {/* Grid Container */}
        <div className="grid gap-8">
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg min-h-screen">
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-medium text-zinc-800 mb-8">
                Training Metrics
              </h2>
            </div>

            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <Spinner size="48px" borderTopColor="gray" />
              </div>
            ) : (
              <>
                {/* Render based on `isMobile` */}
                {isMobile ? (
                  <div className="p-4 mt-2 border rounded bg-gray-50 border-zinc-300">
                    {model && modelStatus === 'failed' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="failed"
                        setFetchNow={setFetchNow}
                      />
                    )}
                    {model && modelStatus === 'running' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="running"
                        setFetchNow={setFetchNow}
                      />
                    )}
                    {model && modelStatus === 'completed' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="completed"
                        setFetchNow={setFetchNow}
                      />
                    )}
                  </div>
                ) : (
                  <>
                    {model && modelStatus === 'failed' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="failed"
                        setFetchNow={setFetchNow}
                      />
                    )}
                    {model && modelStatus === 'running' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="running"
                        setFetchNow={setFetchNow}
                      />
                    )}
                    {model && modelStatus === 'completed' && (
                      <MyModelsCompletedJob
                        job={model}
                        status="completed"
                        setFetchNow={setFetchNow}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailorTrainingMetricsv2;
