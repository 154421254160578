import React from 'react';

const TabInfoLayout = ({ tabInfo, selectedTab }) => {
  const info = tabInfo[selectedTab];

  return (
    <div className="w-full">
      {/* <h2 className="mb-4 text-3xl text-gray-900 sm:text-4xl">{info.name}</h2> */}
      <div className="grid grid-cols-1 gap-2 text-center md:grid-cols-3">
        <div className="p-2 xl:p-4">
          <h3 className="mb-2 font-bold xl:text-xl">{info.firstTitle}</h3>
          <p className="hidden text-sm text-gray-700 xl:text-base md:block">
            {info.firstBody}
          </p>
        </div>
        <div className="p-2 xl:p-4">
          <h3 className="mb-2 font-bold xl:text-xl">{info.secondTitle}</h3>
          <p className="hidden text-sm text-gray-700 md:block xl:text-base">
            {info.secondBody}
          </p>
        </div>
        <div className="p-2 xl:p-4">
          <h3 className="mb-2 font-bold xl:text-xl">{info.thirdTitle}</h3>
          <p className="hidden text-sm text-gray-700 md:block xl:text-base">
            {info.thirdBody}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TabInfoLayout;
