/**
 * @module CurrencyUtils
 * @description Utility functions for currency to get currency symbol and name
 * @param {string} currency
 * @param {string} locale
 * @returns {string}
 */

/**
 * function to get the currency symbol.
 * @description This function returns the currency symbol for a given currency and locale.
 * @param {string} currency - The currency code.
 * @param {string} locale - The locale.
 * @returns {string} - The currency symbol.
 */
export const getCurrencySymbol = (currency, locale) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

/**
 * function to get the currency name.
 * @description This function returns the currency name for a given currency and locale.
 * @param {string} currency - The currency code.
 * @param {string} locale - The locale - default is 'en'.
 * @returns {string} - The currency name.
 */
export const getCurrencyName = (currency, locale = 'en') => {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency.toUpperCase(),
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      currencyDisplay: 'name',
    })
    .replace(/\d/g, '')
    .trim();
};

const dollarInCredits = 100000;

/**
 * function to convert amount to backend.
 * @description This function converts the amount to the backend format.
 * @param {number} amount - The amount.
 * @returns {number} - The amount in backend format.
 */
export const amountToBackend = (amount) => {
  return amount * dollarInCredits;
};

/**
 * function to convert amount to frontend.
 * @description This function converts the amount to the frontend format.
 * @param {number} amount - The amount.
 * @returns {number} - The amount in frontend format.
 */
export const amountToFrontend = (amount) => {
  return amount / dollarInCredits;
};
