import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';

const DeleteLogsModal = ({
  isOpen,
  onClose,
  filters,
  fetchLogs,
  clearFilters,
}) => {
  let [loading, setLoading] = useState(false);
  const { customAxios } = useUser();

  function closeModal() {
    onClose();
  }

  const handleCloseModal = () => {
    closeModal();
  };

  const handleSubmit = async () => {
    setLoading(true);
    let params = {};
    if (filters.models.length) {
      params.model = filters.models;
    }
    if (filters.tags.length) {
      params.tag = filters.tags;
    }
    if (filters.startDate) {
      params.from_date = filters.startDate;
    }
    if (filters.endDate) {
      params.to_date = filters.endDate;
    }
    try {
      const res = await customAxios.delete('tailor/v1/data', { params });
      if (res.status === 200) {
        toast.success(`Logs deleted successfully`);
        setLoading(false);
        clearFilters();
        fetchLogs();
        closeModal();
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to delete logs');
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md font-dmSans">
                <Dialog.Title
                  as="h3"
                  className="mb-4 text-lg font-medium leading-6 text-zinc-500 capitalize"
                >
                  Delete Logs based on filters
                </Dialog.Title>
                <div className="text-sm text-gray-500 mb-4">
                  Filters applied:
                  {Object.entries(filters).map(
                    ([key, value]) =>
                      (Array.isArray(value) ? value.length > 0 : !!value) && (
                        <div key={key}>
                          <span className="font-semibold capitalize">
                            {key === 'startDate'
                              ? 'Start Date'
                              : key === 'endDate'
                                ? 'End Date'
                                : key}
                            :{' '}
                          </span>
                          {key === 'startDate' || key === 'endDate' ? (
                            <span>
                              {new Date(value).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })}{' '}
                              {new Date(value).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </span>
                          ) : (
                            value.join(', ')
                          )}
                        </div>
                      ),
                  )}
                </div>
                <div className="my-2">
                  <p className="text-sm text-gray-500">
                    Are you sure you want to delete the logs based on the above
                    filters?
                  </p>
                  <p className="text-sm text-gray-500">
                    This action cannot be undone.
                  </p>
                </div>

                <div className="mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={handleSubmit}
                    disabled={loading}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 ml-2 text-sm font-medium text-zinc-900 border border-gray-300 hover:bg-zinc-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={handleCloseModal}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DeleteLogsModal;
