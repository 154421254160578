import React, { useState, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

const modelSizes = [
  { name: 'Mistral' },
  { name: 'Mixtral' },
  { name: 'Llama' },
];

const Pricing = () => {
  const [modelSize, setModelSize] = useState(modelSizes[0]);
  const [numTokens, setNumTokens] = useState('');
  const [calculationType, setCalculationType] = useState('tokens');
  const [estimatedCost, setEstimatedCost] = useState(null);

  const handleCalculate = () => {
    const x =
      calculationType === 'tokens'
        ? parseFloat(numTokens) / 8192
        : parseFloat(numTokens);

    let y;
    switch (modelSize.name) {
      case 'Mistral':
        y = 1.048 * x + 95.568;
        break;
      case 'Mixtral':
        y = 2.043 * x + 593.275;
        break;
      case 'Llama':
        y = 4.884 * x + 856.518;
        break;
      default:
        y = 0;
    }

    const cost = (y / 60 / 60) * (2.72 * 4);
    setEstimatedCost(cost.toFixed(2));
  };

  return (
    <div className="max-w-[1500px] mx-auto font-dmSans text-base sm:pt-12 pb-16 px-4 text-zinc-800">
      <div className="max-w-[800px] mx-auto flex flex-col justify-center items-center">
        <div className="max-w-[800px] sm:px-4">
          <h2 className="mt-12 text-2xl font-bold">Pricing overview</h2>
          <p className="mt-4">
            Tromero operates on a pay-as-you-go model for all offerings, except
            for enterprise deals.
          </p>
          <p>
            Our pay-as-you-go products include fine-tuning, and on-demand
            private GPU inference, and GPU cluster reservations.
          </p>
        </div>
        <h3 className="mt-8 text-lg font-semibold w-full sm:px-4">
          Inference models
        </h3>
        <table className="mt-4 divide-y divide-gray-200 max-w-[800px]">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-sm font-medium tracking-wider text-left text-gray-500 uppercase sm:px-4">
                Base model parameter count
              </th>
              <th className="px-4 py-2 text-sm font-medium tracking-wider text-left text-gray-500 uppercase sm:px-4">
                $/1M tokens (Applies to both input and output tokens)
              </th>
            </tr>
          </thead>
          <tbody className="text-sm bg-white divide-y divide-gray-200">
            <tr>
              <td className="px-4 py-2">0B - 16B</td>
              <td className="px-4 py-2 ">$0.24</td>
            </tr>
            <tr>
              <td className="px-4 py-2">16.1B - 80B</td>
              <td className="px-4 py-2">$1.08</td>
            </tr>
            <tr>
              <td className="px-4 py-2">MoE 0B - 56B (e.g. Mixtral 8x7B)</td>
              <td className="px-4 py-2">$0.60</td>
            </tr>
            <tr>
              <td className="px-4 py-2">
                MoE 56.1B - 176B (e.g. DBRX, Mixtral 8x22B)
              </td>
              <td className="px-4 py-2">$1.44</td>
            </tr>
          </tbody>
        </table>
        <div className="max-w-[800px] sm:px-4">
          <p className="mt-4">
            Per-token pricing is applied only for serverless inference. See
            below for on-demand deployment pricing.
          </p>
          <p className="mt-4">
            LoRA models deployed to our serverless inference service are charged
            at the same rate as the underlying base model. There is no
            additional cost for serving LoRA models.
          </p>
        </div>
        <div className="sm:px-4 flex flex-col items-center justify-center w-full mx-auto my-8">
          <h3 className="mt-8 text-lg font-semibold w-full">Fine-tuning</h3>
          <div className="p-6 mt-4 bg-white rounded border w-full max-w-[800px]">
            <div className="flex justify-end w-full">
              <div className="inline-flex rounded-md shadow-sm isolate">
                <button
                  type="button"
                  className={`relative inline-flex items-center rounded-l-md px-3 h-8 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                    calculationType === 'tokens' ? 'bg-gray-200 ' : 'bg-zinc-50'
                  }`}
                  onClick={() => setCalculationType('tokens')}
                >
                  Tokens
                </button>
                <button
                  type="button"
                  className={`relative -ml-px inline-flex items-center rounded-r-md px-3 h-8 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 ${
                    calculationType === 'datapoints'
                      ? 'bg-gray-200 '
                      : 'bg-zinc-50'
                  }`}
                  onClick={() => setCalculationType('datapoints')}
                >
                  Datapoints
                </button>
              </div>
            </div>
            <label className="block my-2 text-sm font-medium text-gray-700">
              Select Model Size:
            </label>
            <Listbox value={modelSize} onChange={setModelSize}>
              {({ open }) => (
                <>
                  <div className="relative mt-2">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                      <span className="block truncate">{modelSize.name}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white border border-gray-300 rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm">
                        {modelSizes.map((size, sizeIdx) => (
                          <Listbox.Option
                            key={sizeIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? 'bg-zinc-800 text-zinc-100'
                                  : 'text-gray-900'
                              }`
                            }
                            value={size}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                >
                                  {size.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <label className="block my-2 text-sm font-medium text-gray-700">
              Enter Number of{' '}
              {calculationType === 'tokens' ? 'Tokens' : 'Datapoints'}:
              <input
                type="number"
                value={numTokens}
                min={0}
                onChange={(e) => setNumTokens(e.target.value)}
                className="block w-full px-3 py-2 mt-1 text-base leading-6 text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-transparent focus:border-gray-300"
              />
            </label>
            <button
              onClick={handleCalculate}
              className="px-4 py-2 mt-4 text-white rounded bg-zinc-800"
            >
              Calculate
            </button>
            {estimatedCost !== null && (
              <p className="mt-4 text-lg font-semibold text-gray-700">
                Estimated Cost: ${estimatedCost}
              </p>
            )}
          </div>
        </div>
        <h3 className="mt-8 text-lg font-semibold w-full sm:px-4">
          On-demand GPU Clusters
        </h3>
        <div className="max-w-[800px] w-full sm:px-4">
          <table className="min-w-full mt-4 text-sm divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-sm font-medium tracking-wider text-left text-gray-500 uppercase">
                  GPU Type
                </th>
                <th className="px-4 py-2 text-sm font-medium tracking-wider text-left text-gray-500 uppercase">
                  $/hour
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td className="px-4 py-2">A100 80 GB GPU</td>
                <td className="px-4 py-2">$2.72</td>
              </tr>
              <tr>
                <td className="px-4 py-2">A6000 GPU</td>
                <td className="px-4 py-2">$0.78</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="mt-4 w-full max-w-[800px] px-4">
          On-demand GPU clusters are billed by GPU-second using the above rates.
          Pricing scales linearly when using multiple GPUs.
        </p>
      </div>
    </div>
  );
};

export default Pricing;
