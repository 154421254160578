import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import jobPostingsData from '../../jobPostingsData';

const getTeamColorClass = (team) => {
  const teamColors = {
    Engineering: 'bg-gray-600',
    'AI/ML': 'bg-gray-600',
    Blockchain: 'bg-gray-600',
    'Sales & Marketing': 'bg-gray-600',
  };

  return teamColors[team] || 'bg-gray-600';
};

const JobPostingCard = ({
  title,
  readMoreLink,
  index,
  team,
  id,
  commitment,
  location,
  totalCards,
}) => {
  //   // Calculate background position
  //  const cols = 3; // Number of columns
  //  const rows = Math.ceil(totalCards / cols); // Number of rows
  //  const colSize = 100 / (cols - 1); // Adjust for correct size
  //  const rowSize = 100 / (rows - 1); // Adjust for correct size
  //  const col = index % cols; // Column index
  //  const row = Math.floor(index / cols); // Row index
  //  const backgroundPosition = `${col * colSize}% ${row * rowSize}%`;

  const backgroundClass = index % 2 === 0 ? 'wavy' : 'wavyextraslow';

  return (
    <Link
      to={`/job-postings/${id}`}
      className="relative mb-5 overflow-hidden rounded-lg shadow font-spaceG hover:shadow-xl dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-900"
    >
      {/* Wavy Background */}
      <div
        className={`w-full h-48 ${backgroundClass} shadow-3xinner dark:bg-gray-800 dark:border-gray-600 dark:hover:bg-gray-900`}
        // style={{

        //   backgroundSize: `${cols * 100}% ${rows * 100}%`,
        //   backgroundPosition,
        // }}
      ></div>

      {/* Gradient Overlay */}
      <div className="absolute top-0 w-full h-56 bg-gradient-to-l from-white via-transparent to-transparent "></div>

      {/* Team Chip */}
      <div className="absolute bottom-0 right-0 z-10 mb-4 mr-4">
        <span
          className={`px-3 py-1 text-xs font-medium text-white rounded-full ${getTeamColorClass(
            team,
          )}`}
        >
          {team}
        </span>
      </div>

      {/* Content */}
      <div className="absolute top-0 p-4">
        <h2 className="px-4 py-0.5 text-xl font-medium leading-tight text-gray-900 border rounded-full border-black/10 dark:text-white bg-white dark:bg-gray-800 ">
          {title}
        </h2>
      </div>

      <div className="absolute bottom-0 w-full p-4 bg-white bg-opacity-75 rounded-b-lg ">
        <p className="text-sm font-medium text-gray-700 ">
          {commitment}
          <br></br>
          {location}
        </p>
        <div className="inline-flex items-center text-sm font-medium underline underline-offset-4 hover:no-underline">
          More info
        </div>
      </div>
    </Link>
  );
};

const JobPostings = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [filteredPostings, setFilteredPostings] = useState(jobPostingsData);

  useEffect(() => {
    const filteredData = jobPostingsData.filter((posting) => {
      return (
        (searchTerm === '' ||
          posting.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (selectedTeam === '' || posting.team === selectedTeam) &&
        (selectedLocation === '' || posting.location === selectedLocation)
      );
    });

    setFilteredPostings(filteredData);
  }, [searchTerm, selectedTeam, selectedLocation]);
  return (
    <div className="">
      <aside
        aria-label="Related articles"
        className="py-8 antialiased bg-white lg:py-16 dark:bg-gray-800 bg-zinc-50"
      >
        <div className="max-w-screen-xl px-4 mx-auto">
          <h2 className="flex justify-center my-8 text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">
            Open Roles
          </h2>
          <h2 className="flex justify-center mb-8 text-lg leading-8 text-gray-900 dark:text-white dark:text-gray-300">
            Help us power the next generation of secure, democratic and
            decentralised AI applications. Find your spot in our team.
          </h2>
          <div className="flex flex-col w-full mt-6 mb-12 border-b md:flex-row FILTERS">
            <form className="max-w-sm mx-auto mb-4 md:mb-0 md:mr-4">
              <label
                htmlFor="website-admin"
                className="mb-2 text-sm dark:text-white "
              >
                Search Open Roles
              </label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                  <svg
                    class="w-4 h-4 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeWidth="2"
                      d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  id="website-admin"
                  className="rounded-none rounded-r-lg bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="ex. Engineer..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
            <form className="w-64 mx-auto mb-4 md:mb-0 md:mr-4">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm text-gray-900 dark:text-white"
              >
                Filter By Team
              </label>
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                <option value="">All Teams</option>
                <option>Engineering</option>
                <option>AI/ML</option>
                <option>Blockchain</option>
                <option>Product, Ops, Marketing & Sales</option>
              </select>
            </form>
            <form className="w-64 mx-auto">
              <label
                htmlFor="countries"
                className="block mb-2 text-sm text-gray-900 dark:text-white"
              >
                Filter By Location
              </label>
              <select
                id="countries"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-8 sm:mb-4"
                value={selectedLocation}
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <option value="">All Locations</option>
                <option>London, UK</option>
                <option>Remote</option>
              </select>
            </form>
          </div>

          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {filteredPostings.length > 0 ? (
              filteredPostings.map((posting, index) => (
                <JobPostingCard
                  key={posting.id}
                  {...posting}
                  index={index}
                  totalCards={filteredPostings.length}
                />
              ))
            ) : (
              <div className="flex justify-center w-full col-span-full">
                <p className="text-lg text-gray-800 dark:text-white">
                  No career postings match your filters. Please adjust your
                  search criteria.
                </p>
              </div>
            )}
          </div>
        </div>
      </aside>
    </div>
  );
};

export default JobPostings;
