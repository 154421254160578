import React from 'react';

const AIPolicy = () => {
  return (
    <div className="max-w-[1500px] mx-auto font-sans text-base my-12 px-4">
      <h1>Responsible AI Policy</h1>
      <br />
      <p>Last Updated: 6 March 2024</p>
      <br />
      <p>
        This Responsible AI Policy (“<strong>Policy</strong>”) applies to your
        use of artificial intelligence and machine learning services, features,
        and functionality (including third-party models) that we provide through
        our website (collectively, “<strong>AI/ML Services</strong>”). This
        Policy supplements our Terms.
      </p>
      <br />
      <h2 className="font-bold underline">Prohibitions:</h2>
      <br />
      <p>
        You may not use, or facilitate or allow others to use, the AI/ML
        Services:
      </p>
      <br />
      <ul className="ml-12 list-disc">
        <li>for intentional disinformation or deception;</li>
        <li>
          to violate the privacy rights of others, including unlawful tracking,
          monitoring, and identification;
        </li>
        <li>
          to depict a person’s voice or likeness without their consent or other
          appropriate rights, including unauthorized impersonation and
          non-consensual sexual imagery;
        </li>
        <li>
          for harm or abuse of a minor, including grooming and child sexual
          exploitation;
        </li>
        <li>
          to harass, harm, or encourage the harm of individuals or specific
          groups;
        </li>
        <li>
          to intentionally circumvent safety filters and functionality or prompt
          models to act in a manner that violates our Terms;
        </li>
        <li>
          to perform a lethal function in a weapon without human authorization
          or control.
        </li>
      </ul>
      <br />
      <h2 className="font-bold underline">Responsible AI Requirements:</h2>
      <br />
      <p>
        If you use the AI/ML Services to make consequential decisions, you must
        evaluate the potential risks of your use case and implement appropriate
        human oversight, testing, and other use case-specific safeguards to
        mitigate such risks. Consequential decisions include those impacting a
        person’s fundamental rights, health, or safety (e.g., medical diagnosis,
        judicial proceedings, access to critical benefits like housing or
        government benefits, opportunities like education, decisions to hire or
        terminate employees, or access to lending/credit, and providing legal,
        financial, or medical advice). You agree to provide information about
        your intended uses of the AI/ML Services and compliance with this Policy
        upon request.
      </p>
      <br />
      <p>
        You and your end users are responsible for all decisions made, advice
        given, actions taken, and failures to take action based on your use of
        AI/ML Services. AI/ML Services use machine learning models that generate
        predictions based on patterns in data. Output generated by a machine
        learning model is probabilistic, and generative AI may produce
        inaccurate or inappropriate content. Outputs should be evaluated for
        accuracy and appropriateness for your use case.
      </p>
      <br />
      <p>
        We may investigate and enforce violations of this Policy. We may
        investigate any suspected violation of this Policy, and remove or
        disable access to any content or resource that violates this Policy. You
        agree to cooperate with us to remedy any violation.
      </p>
      <br />
      <p>
        When determining whether there has been a violation of this Policy, we
        may consider your ability and willingness to comply with this Policy,
        including the policies and processes you have in place to prevent or
        identify and remove any prohibited content or activity.
      </p>
    </div>
  );
};

export default AIPolicy;
