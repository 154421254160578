import React, { useEffect, useState } from 'react';
import { Modal } from 'flowbite-react';

import CopyToClipboardButton from '../CopyToClipboardButton';

const JupyterModal = ({
  jupyter_url,
  jupyter_token,
  showJupyterModal,
  onClose,
}) => {
  useEffect(() => {
    if (showJupyterModal && jupyter_token) {
      navigator.clipboard
        .writeText(jupyter_token)
        .then(() => {})
        .catch((err) => {
          console.error('Could not copy text: ', err);
        });
    }
  }, [showJupyterModal, jupyter_token]);

  const handleSetupClick = () => {
    const notebookUrl = `${jupyter_url}`;
    window.open(notebookUrl, '_blank', 'noopener,noreferrer');
    onClose();
  };

  if (!showJupyterModal) {
    return null;
  }

  return (
    <Modal show={showJupyterModal} onClose={onClose} className="font-spaceG">
      <Modal.Header>Set Up Jupyter Notebook</Modal.Header>
      <Modal.Body>
        <div className="flex justify-center space-y-6">
          <div className="text-sm font-semibold leading-relaxed md:text-base ">
            Your user token has been copied to the clipboard:{' '}
            <div className="p-4 mt-4 text-sm font-normal text-gray-500 break-words border-2 rounded w-[350px] md:text-base">
              {jupyter_token}
              <div>
                <CopyToClipboardButton textToCopy={jupyter_token} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="flex items-center justify-center w-full h-12 text-sm font-medium text-center text-white rounded-md sm:w-24 bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
          onClick={handleSetupClick}
        >
          Continue
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default JupyterModal;
