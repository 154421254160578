import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Menu, MenuItem } from '../V2LandingThings/navbar-menu';
// import { Dropdown } from 'flowbite-react';
import { cn } from '../utils/cn';
import { useUser } from '../UserContext';

const NavbarDemo = () => {
  return (
    <div className="relative flex items-center justify-center w-full ">
      <div className="fixed top-0 z-50 w-full h-16 backdrop-blur-sm">
        <Navbar className="top-2" />
      </div>
    </div>
  );
};

function Navbar({ className }: { className?: string }) {
  const [active, setActive] = useState<string | null>(null);
  // const [, setIsNavOpen] = useState<boolean>(false);
  // const [, setShowEmailModal] = useState<boolean>(false);
  // const [toggleView] = useState<boolean>(false);
  // const navigate = useNavigate();

  interface User {
    email: string;
    name: string;
    is_admin?: boolean;
    kyc_status?: string;
  }

  interface UserContextType {
    user: User | null;
    logout: () => void;
    setShowSignInModal: (value: boolean) => void;
    setUser: (user: User) => void;
    setShowGetVerifiedModal: (value: boolean) => void;
    setShowTokenExpiredModal: (value: boolean) => void;
  }

  const userContext = useUser() as UserContextType | null;

  const handleLogin = () => {
    if (userContext) {
      userContext.setShowSignInModal(true);
    }
  };

  // const handleSignOut = () => {
  //   if (userContext) {
  //     userContext.logout();
  //   }
  //   localStorage.removeItem('darkMode');
  //   localStorage.removeItem('logoutTime');
  //   localStorage.removeItem('selectedRental');
  //   localStorage.removeItem('userDetails');
  //   localStorage.removeItem('email');
  //   localStorage.clear();
  //   setIsNavOpen(false);
  //   navigate('/');
  // };

  // const handleToggleEmailModal = () => {
  //   setShowEmailModal((prevState) => !prevState);
  // };

  if (!userContext) {
    return null;
  }

  const { user } = userContext;

  return (
    <div
      className={cn(
        'fixed top-10 inset-x-0 max-w-3xl mx-auto z-50  ',
        className,
      )}
    >
      <Menu setActive={setActive}>
        <div className={`flex ${user ? 'mt-2.5' : 'mt-1.5'} space-x-4`}>
          <Link to="/models" className="hover:text-zinc-600 ">
            Models
          </Link>
          <Link to="/pricing" className="hover:text-zinc-600 ">
            Pricing
          </Link>
          <a
            href="https://docs.tromero.ai"
            className="hover:text-zinc-600 "
            target="_blank"
            rel="noopener noreferrer"
          >
            Docs
          </a>
          <MenuItem setActive={setActive} active={active} item="Company">
            <div className="flex flex-col space-y-4 text-sm ">
              <Link to="/about">About Us</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/faq">FAQs</Link>
              <Link to="/press">Press</Link>
            </div>
          </MenuItem>
          <MenuItem
            setActive={setActive}
            active={active}
            item="Articles & Learning"
          >
            <div className="flex flex-col space-y-4 text-sm">
              <Link to="/articles">Articles</Link>
              <Link to="/articles">Blogs</Link>
              <Link to="/articles">Guides</Link>
            </div>
          </MenuItem>

          <MenuItem setActive={setActive} active={active} item="Contact">
            <div className="flex flex-col space-y-4 text-sm">
              <Link to="/providersignup">Provide GPUs</Link>
              <Link to="/contact">Contact Us</Link>
              <Link to="/partner">Partner with Us</Link>
            </div>
          </MenuItem>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <div className="flex items-center justify-center ">
            {user ? (
              <Link
                to="/tailor"
                // arrowIcon={false}
                // className="bg-white  backdrop-blur-sm rounded-2xl overflow-hidden border border-black/[0.2]  shadow-xl"
                // inline
                // label={
                // <div className="flex items-center justify-center w-10 h-10 text-gray-100 bg-gray-900 rounded-full shadow-lg">
                //   {user.email ? (
                //     user.email[0].toUpperCase()
                //   ) : (
                //     <svg
                //       className="w-4 h-4 text-gray-200 dark:text-zinc-50"
                //       aria-hidden="true"
                //       xmlns="http://www.w3.org/2000/svg"
                //       fill="none"
                //       viewBox="0 0 14 18"
                //     >
                //       <path
                //         stroke="currentColor"
                //         strokeLinecap="round"
                //         strokeLinejoin="round"
                //         strokeWidth="2"
                //         d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                //       />
                //     </svg>
                //   )}
                // </div>
                // }
              >
                {' '}
                <div className="flex items-center justify-center w-10 h-10 text-gray-100 bg-gray-900 rounded-full shadow-lg">
                  {user.email ? (
                    user.email[0].toUpperCase()
                  ) : (
                    <svg
                      className="w-4 h-4 text-gray-200 dark:text-zinc-50"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
                      />
                    </svg>
                  )}
                </div>
                {/* <Dropdown.Header>
                  <span className="block text-sm">{user.name}</span>
                  <span className="block text-sm font-medium truncate">
                    {user.email === 'bobbobingtonabc@gmail.com'
                      ? 'Bob'
                      : user.email}
                  </span>
                </Dropdown.Header>
                <a onClick={handleToggleEmailModal}>
                  <Dropdown.Item className="text-indigo-500">
                    Provide Feedback
                  </Dropdown.Item>
                </a>
                {!toggleView && ( */}
                <>
                  {/* {' '}
                      {user?.kyc_status !== 'approved' && (
                        <Dropdown.Item onClick={showVerification}>
                          Get Verified
                        </Dropdown.Item>
                      )} */}
                  {/* <Link to="/tailor">
                      <Dropdown.Item>Dashboard</Dropdown.Item>
                    </Link> */}
                  {/* <CustomLink to="/instances">
                        <Dropdown.Item>My Instances</Dropdown.Item>
                      </CustomLink> */}
                </>
                {/* )} */}
                {/* {toggleView && (
                  <>
                    <Link to="/providerdashboard">
                      <Dropdown.Item>Dashboard</Dropdown.Item>
                    </Link>
                  </>
                )}
                <Link to="/tailor/settings">
                  <Dropdown.Item>Settings</Dropdown.Item>
                </Link>
                {user.is_admin && (
                  <>
                    <Link to="/admin">
                      <Dropdown.Item>Admin Dashboard</Dropdown.Item>
                    </Link>
                  </>
                )}
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item> */}
              </Link>
            ) : (
              <>
                <button
                  onClick={handleLogin}
                  aria-current="page"
                  className="px-3 py-1.5 text-sm text-gray-100 bg-gray-900  rounded-full shadow-lg zinc-50 whitespace-nowrap"
                >
                  Log in
                </button>
              </>
            )}
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default NavbarDemo;
