import {
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from '@heroicons/react/24/outline';
import { useAtomValue } from 'jotai';
import {
  costChangeAtom,
  percentageChangeAtom,
  totalTokensAtom,
} from '../../../context/atoms';
import { getPricePerToken } from '../../../utils/generalUtils';

const UsageDataCard = ({ baseModel }) => {
  const percentageChange = useAtomValue(percentageChangeAtom);
  const costChange = useAtomValue(costChangeAtom);
  const totalTokens = useAtomValue(totalTokensAtom);
  const costPerToken = getPricePerToken(baseModel);

  const getDollars = (tokens, decimalPlaces = 2) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: decimalPlaces,
    }).format(tokens);
  };
  return (
    <div className="h-full flex flex-col bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
      <div className="mb-4 text-center">
        <h2 className="text-lg text-left font-semibold text-zinc-800">
          Model Usage
        </h2>
        <div className="mt-1 text-sm text-zinc-600 text-left">
          This Month vs. Last Month
        </div>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 w-full">
        {/* Tokens Card */}
        <div className="relative p-4 bg-white border rounded-lg border-zinc-200 hover:shadow-md transition-shadow">
          <span
            className={`absolute top-2 right-2 text-xs flex items-center ${
              percentageChange > 0
                ? '!text-lime-600'
                : percentageChange < 0
                  ? 'text-rose-600'
                  : 'text-zinc-600'
            }`}
          >
            {percentageChange > 0 ? (
              <ArrowLongUpIcon className="w-4 h-4" />
            ) : percentageChange < 0 ? (
              <ArrowLongDownIcon className="w-4 h-4" />
            ) : null}
            {percentageChange === 0 ? '⎯' : Math.abs(percentageChange) + '%'}
          </span>
          <div className="mt-2 text-center">
            <span className="text-2xl font-bold">
              {totalTokens.toLocaleString()}
            </span>
            <div className="mt-2 text-sm text-zinc-700">Tokens Used</div>
            <div className="text-xs text-zinc-500">(this month)</div>
          </div>
        </div>

        {/* Cost Card */}
        <div className="relative p-4 bg-white border rounded-lg border-zinc-200 hover:shadow-md transition-shadow">
          <span
            className={`absolute top-2 right-2 text-xs flex items-center ${
              costChange > 0
                ? '!text-lime-600'
                : costChange < 0
                  ? 'text-rose-600'
                  : 'text-zinc-600'
            }`}
          >
            {costChange > 0 ? (
              <ArrowLongUpIcon className="w-4 h-4" />
            ) : costChange < 0 ? (
              <ArrowLongDownIcon className="w-4 h-4" />
            ) : null}
            {getDollars(Math.abs(costChange))}
          </span>
          <div className="mt-2 text-center">
            <span className="text-2xl font-bold">
              {getDollars(totalTokens * costPerToken)}
            </span>
            <div className="mt-2 text-sm text-zinc-700">Cost</div>
            <div className="text-xs text-zinc-500">(this month)</div>
          </div>
        </div>

        {/* Cost per Million Tokens Card */}
        <div className="relative p-4 bg-white border rounded-lg border-zinc-200 hover:shadow-md transition-shadow sm:col-span-2 lg:col-span-1">
          <div className="mt-2 text-center">
            <span className="text-2xl font-bold">
              {getDollars(costPerToken * 1_000_000)}
            </span>
            <div className="mt-2 text-sm text-zinc-700">Cost per 1M tokens</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageDataCard;
