import React from 'react';

const EnterprisePrivacyPolicy = () => {
  return (
    <div className="max-w-[1500px] mx-auto font-sans text-base my-12 px-4">
      <h1>Enterprise Data, Privacy and Security at Tromero</h1>
      <br />
      <p>Last Updated: 16 July 2024</p>
      <br />
      <p>
        This article provides details regarding how data provided by you to
        Tromero is processed, used, and stored. Tromero is committed to ensuring
        your data is secure and private.
      </p>
      <br />
      <h2 className="font-bold ">Our commitments to you</h2>
      <br />
      <h3 className="font-semibold underline">
        Ownership: You own and control your data
      </h3>
      <br />
      <p>
        Customers can upload their training data to Tromero’s platform to
        finetune a model or log the data from the usage of other models. If the
        model output is not allowed to be used for training, then a synthetic
        version of the data is created. The training data is stored in the GCP
        buckets.
      </p>
      <br />
      <p>Training data:</p>
      <br />
      <ul className="ml-12 list-disc">
        <li>Is NOT available to other customers.</li>
        <li>Is NOT used to improve other models.</li>
        <li>
          Is stored within the geographic region selected by the user when using
          our Services. This region is used for data storage, as well as for the
          location of the inference and training of the models (see ‘finetuned
          models’ below).
        </li>
        <li>
          Is double encrypted at rest (by default with GCP’s AES-256 encryption
          and optionally with a customer managed key).
        </li>
      </ul>
      <br />
      <h3 className="font-semibold">Base and finetuned models:</h3>
      <br />
      <p>
        You own your inputs and outputs (where allowed by law). Your prompts
        (inputs) and completions (outputs), your embeddings, and your training
        data:
      </p>
      <br />
      <ul className="ml-12 list-disc">
        <li>are NOT used to improve other models.</li>
        <li>are NOT used to improve any Tromero products or services.</li>
        <li>
          are NOT used for automatically improving any foundational model for
          your use in your resource.
        </li>
      </ul>
      <br />
      <p>
        Your finetuned models on Tromero are available exclusively for your use.
      </p>
      <br />
      <p>
        Data for training and models can be deleted whenever you would like,
        directly on our platform, without having to contact us.
      </p>
      <br />
      <h3 className="font-semibold underline">
        Control: You decide who has access
      </h3>
      <br />
      <ul className="ml-12 list-disc">
        <li>Fine-grained control over access and available features.</li>
        <li>
          Models (base or finetuned) deployed in your resource process your
          input prompts and generate responses with text, images or embeddings.
          Prompts and responses are processed within the customer-specified
          geography, but may be processed between regions within the geography
          for operational purposes (including performance and capacity
          management).
        </li>
        <li>
          The base models are stateless: no prompts or generations are stored in
          the model.
        </li>
      </ul>
      <br />
      <h3 className="font-semibold underline">
        Security: Comprehensive compliance
      </h3>
      <br />
      <ul className="ml-12 list-disc">
        <li>
          We are committed to the highest standards of information security
          compliance and are ISO 27001 certified.
        </li>
        <li>Data encryption at rest (AES-256) and in transit (TLS 1.2+).</li>
      </ul>
    </div>
  );
};

export default EnterprisePrivacyPolicy;
