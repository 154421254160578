const ModelRankingsSkeleton = () => {
  return (
    <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans animate-pulse">
      {/* Header Skeleton */}
      <div className="h-16 bg-white border-b" />

      <div className="p-4">
        <div className="flex flex-col gap-4 lg:flex-row lg:items-start">
          {/* Test Selector Skeleton */}
          <div className="mb-4 lg:w-1/2 border p-4 rounded-lg bg-white">
            <div className="h-5 w-24 bg-gray-200 rounded mb-4" />{' '}
            {/* "Select Tests" */}
            <div className="flex gap-2 flex-wrap">
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i} className="h-8 w-32 bg-gray-200 rounded" />
              ))}
            </div>
          </div>

          {/* Model Type Filter Skeleton */}
          <div className="mb-4 lg:w-1/2 border p-4 rounded-lg bg-white">
            <div className="h-5 w-32 bg-gray-200 rounded mb-4" />{' '}
            {/* "Filter Models" */}
            <div className="flex gap-2 flex-wrap">
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="h-8 w-36 bg-gray-200 rounded" />
              ))}
            </div>
          </div>
        </div>

        {/* Table Skeleton */}
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border">
            <thead>
              <tr>
                {[1, 2, 3, 4, 5].map((i) => (
                  <th key={i} className="border p-2 bg-gray-100">
                    <div className="h-5 w-24 bg-gray-200 rounded" />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4, 5].map((row) => (
                <tr key={row}>
                  {[1, 2, 3, 4, 5].map((col) => (
                    <td key={col} className="border p-2">
                      <div className="h-5 w-20 bg-gray-200 rounded" />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ModelRankingsSkeleton;
