import { KeyIcon } from '@heroicons/react/20/solid';
import {
  CreditCardIcon,
  MapPinIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { set } from 'idb-keyval';

import { useUser } from '../UserContext';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import ProfileContent from '../Components/SettingsPage/ProfileContent';
import BillingContent from '../Components/SettingsPage/BillingContent';
import SSHKeysContent from '../Components/SettingsPage/SshKeyContent';
import Organization from '../Components/Organization';
import Spinner from '../Components/Spinner';
import SettingsLocation from '../Components/SettingsPage/SettingsLocation';

const Settings = () => {
  const { fetchAndUpdateUserDetails, customAxios } = useUser();
  const [activeTab, setActiveTab] = useState('profile');
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [teamError, setTeamError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [teamData, setTeamData] = useState(null);

  const secondaryNavigation = [
    {
      name: 'Profile',
      href: '#profile',
      icon: UserCircleIcon,
      key: 'profile',
    },
    !teamError && {
      name: 'Billing & Top-up',
      href: '#billing',
      icon: CreditCardIcon,
      key: 'billing',
    },
    !teamError && {
      name: 'Location',
      href: '#location',
      icon: MapPinIcon,
      key: 'location',
    },
    {
      name: 'Security',
      href: '#ssh',
      icon: KeyIcon,
      key: 'ssh',
    },
    !teamError && {
      name: 'Organization',
      href: '#organization',
      icon: UserGroupIcon,
      key: 'organization',
    },
  ].filter(Boolean);

  const fetchTeamData = async () => {
    try {
      const response = await customAxios.get('team');
      setTeamData(response.data);
      return response.data;
    } catch (error) {
      setTeamError(true);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchTeamData();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserDetails = async () => {
      await fetchAndUpdateUserDetails();
    };
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageParam = searchParams.get('page');
    const hash = location.hash.slice(1);
    const isPageParamValid = secondaryNavigation.some(
      (item) => item.key === pageParam,
    );
    const isHashValid = secondaryNavigation.some((item) => item.key === hash);
    if (!isPageParamValid && !isHashValid) {
      setActiveTab('profile');
    } else if (pageParam) {
      setActiveTab(pageParam);
    } else if (hash) {
      setActiveTab(hash);
    }
  }, [searchParams, location.hash, secondaryNavigation]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Spinner borderTopColor="#27272A" />
      </div>
    );
  }

  return (
    <div className="w-full bg-zinc-50">
      <h1 className="pt-4 pl-6 mx-auto mb-4 text-xl italic text-left md:pl-8 font-semilight text-slate-800 sm:mb-6 dark:text-white font-spaceG max-w-7xl bg-zinc-50">
        Settings
      </h1>
      <div className="mx-auto lg:-mt-16 max-w-7xl lg:flex lg:gap-x-16 lg:px-8 bg-zinc-50">
        <aside className="flex py-4 overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    onClick={() => setActiveTab(item.key)}
                    className={clsx(
                      activeTab === item.key
                        ? 'bg-gray-900 text-gray-100'
                        : 'text-gray-700 hover:text-gray-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-medium',
                    )}
                  >
                    <item.icon
                      className={clsx(
                        activeTab === item.key
                          ? 'text-gray-100'
                          : 'text-gray-400 group-hover:text-gray-600',
                        'h-6 w-6 shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20 bg-zinc-50">
          {activeTab === 'profile' && <ProfileContent />}
          {activeTab === 'billing' && <BillingContent team={teamData} />}
          {activeTab === 'ssh' && <SSHKeysContent />}
          {activeTab === 'organization' && <Organization />}
          {activeTab === 'location' && <SettingsLocation />}
        </main>
      </div>
    </div>
  );
};

export default Settings;
