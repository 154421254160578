import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import TailorNavbar from '../Components/Tailor/TailorNavbar';
import UnderNavbarBanner from '../Components/UnderNavbarBanner';

//
const TailorLayout = () => {
  // useEffect(() => {
  //   document.documentElement.classList.remove('dark');
  // }, []);

  return (
    <>
      <UnderNavbarBanner />
      <div className="flex flex-col w-screen lg:flex-row pt-14 lg:pt-0 bg-zinc-50">
        <TailorNavbar />
        <div className="flex-1 overflow-x-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TailorLayout;
