import { useEffect, useState } from 'react';

import { useInstanceUtils } from '../../Hooks/useInstanceUtils';
import { useCurrencyConverter } from '../../Hooks/useCurrencyConverter';

/**
 * RunningCostForActiveInstance
 * @component
 * @description Component that displays the running cost for an active instance, by calculating the elapsed time and multiplying it by the instance's price
 * @param {Object} props
 * @param {Object} props.instance - Instance object
 * @returns {JSX.Element}
 * **/
const RunningCostForActiveInstance = ({ instance }) => {
  const { getElapsedTime } = useInstanceUtils(instance);
  const [cost, setCost] = useState(0);
  const money = useCurrencyConverter();

  useEffect(() => {
    const interval = setInterval(() => {
      const elapsedSeconds = getElapsedTime();
      const elapsedHours = (elapsedSeconds / 3600).toFixed(2);
      let cost;
      if (elapsedSeconds < 300) {
        cost = 0;
      } else {
        cost = elapsedHours * instance.node.price_per_gpu * instance.gpu_count;
      }
      setCost(cost);
    }, 1000);
    return () => clearInterval(interval);
  }, [getElapsedTime, instance.node.price_per_gpu, instance.gpu_count]);

  return money(cost);
};

export default RunningCostForActiveInstance;
