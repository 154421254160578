import { Transition } from '@headlessui/react';
import { Toaster, resolveValue } from 'react-hot-toast';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';

const Toast = () => {
  return (
    <Toaster position="top-right">
      {(t) => (
        <Transition
          appear
          show={t.visible}
          className="flex items-center p-4 transform dark:!bg-gray-900 dark:shadow-white/10 rounded-md shadow-lg w-full sm:max-w-fit !bg-gray-50"
          enter="transition-all duration-150"
          enterFrom="opacity-0 scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-75"
        >
          <div className="flex-shrink-0">
            {t.type === 'success' && (
              <CheckCircleIcon
                className="w-6 h-6 font-bold text-green-600 dark:text-green-400"
                aria-hidden="true"
              />
            )}
            {t.type === 'error' && (
              <ExclamationCircleIcon
                className="w-6 h-6 font-bold text-red-500 dark:text-[#cf4c54]"
                aria-hidden="true"
              />
            )}
          </div>
          <div
            className={`ml-3 sm:min-w-60 w-full flex-1 pt-0.5 text-sm text-gray-600 dark:text-gray-300`}
          >
            {resolveValue(t.message)}
          </div>
          <div className="flex flex-shrink-0 ml-4"></div>
        </Transition>
      )}
    </Toaster>
  );
};

export default Toast;
