import { motion } from 'framer-motion';
import { Tooltip } from 'flowbite-react';

const TOGGLE_CLASSES =
  'font-medium flex items-center gap-2 px-2.5 py-2 transition-colors relative z-10';

const SyntheticToggle = ({ isSynthetic, setIsSynthetic }) => {
  return (
    <div className="grid h-10 transition-colors place-content-center">
      <SliderToggle isSynthetic={isSynthetic} setIsSynthetic={setIsSynthetic} />
    </div>
  );
};

const SliderToggle = ({ isSynthetic, setIsSynthetic }) => {
  return (
    <div className="flex items-center text-xs border rounded-md w-fit">
      <Tooltip
        content={
          <div className="w-56 p-2 text-sm text-gray-700">
            <div className="flex items-center my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-2 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                />
              </svg>{' '}
              Original
            </div>
            These are the original logs generated by a model which doesn't
            permit training.
          </div>
        }
        style="light"
        placement="top"
      >
        <button
          className={`mr-1 ${TOGGLE_CLASSES} ${!isSynthetic ? 'text-zinc-800 border rounded-md border-zinc-600' : 'text-zinc-400'}`}
          onClick={() => setIsSynthetic(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          <span className="relative z-10">Original</span>
        </button>
      </Tooltip>
      <Tooltip
        content={
          <div className="w-56 p-2 text-sm text-gray-700">
            <div className="flex items-center my-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-2 text-indigo-600 size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                />
              </svg>{' '}
              Synthetic
            </div>
            These are synthetic logs, which are derived from a model which
            doesn't permit training.
          </div>
        }
        style="light"
        placement="top"
      >
        <button
          className={`${TOGGLE_CLASSES} ${isSynthetic ? 'text-indigo-500 border rounded-md border-indigo-600' : 'text-zinc-400'}`}
          onClick={() => setIsSynthetic(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4 text-zinc-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
            />
          </svg>
          <span className="relative z-10">Synthetic</span>
        </button>
      </Tooltip>
      {/* <div
        // className={`absolute inset-0 z-0 flex ${isSynthetic ? 'justify-end' : 'justify-start'}`}
      >
        <span
          layout
          // transition={{ type: 'spring', damping: 17, stiffness: 250 }}
          // className={`w-1/2 h-full border rounded-md ${isSynthetic ? 'border-indigo-500' : 'border-zinc-400'}`}
        />
      </div> */}
    </div>
  );
};

export default SyntheticToggle;
