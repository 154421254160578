import MyModelsCompletedJob from '../MyModels/MyModelsCompletedJob';

const TrainingMetricsCard = ({ model }) => {
  const getModelStatus = (model) => {
    if (model.state.toLowerCase().includes('fail')) {
      return 'failed';
    } else if (model.state.toLowerCase().includes('cancelled')) {
      return 'cancelled';
    } else if (model.state.toLowerCase().includes('train')) {
      return 'running';
    }
  };
  const modelStatus = model ? getModelStatus(model) : null;

  return (
    <div className="bg-white h-full flex items-center w-full">
      <MyModelsCompletedJob job={model} status={modelStatus} card />
    </div>
  );
};

export default TrainingMetricsCard;
