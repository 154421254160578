import HeaderSkeleton from './HeaderSkeleton';

const CustomEvaluationsSkeleton = () => (
  <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
    <HeaderSkeleton />
    <div className="flex flex-col  bg-zinc-50 font-dmSans p-4 max-w-7xl w-full mx-auto">
      <div className="grid  gap-8 ">
        <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg animate-pulse">
          <div className="flex flex-col space-y-2">
            {/* Title */}
            <div className="h-6 bg-zinc-200 rounded w-1/3"></div>
            {/* Subtitle */}
            <div className="h-5 bg-zinc-200 rounded w-1/2"></div>
          </div>
          <div className="sm:flex-auto mt-12 space-y-4">
            {/* Evaluation Results Title */}
            <div className="h-5 bg-zinc-200 rounded w-1/4"></div>
            {/* Description */}
            <div className="h-4 bg-zinc-200 rounded w-3/4"></div>
            <div className="h-4 bg-zinc-200 rounded w-2/3"></div>

            {/* Loading Table */}
            <div className="space-y-4 mt-4">
              {[1, 2, 3].map((_, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-4 p-4 rounded-lg bg-white border border-gray-200"
                >
                  <div className="h-4 bg-zinc-200 rounded w-1/4"></div>
                  <div className="h-4 bg-zinc-200 rounded w-1/3"></div>
                  <div className="h-4 bg-zinc-200 rounded w-1/6"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CustomEvaluationsSkeleton;
