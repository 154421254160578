import { useCallback, useEffect, useRef, useState } from 'react';

import { useUser } from '../UserContext';

/**
 * Custom hook to manage and utilize instance utilities.
 *
 * @param {object} instance The initial instance object.
 * @returns {object} The state and utility functions related to the instance.
 */
export const useInstanceUtils = (instance) => {
  const [instanceState, setInstanceState] = useState(instance);
  const [isEndButtonDisabled, setIsEndButtonDisabled] = useState(true);
  const [buttonText, setButtonText] = useState('End');
  const [instanceError, setInstanceError] = useState(null);
  const { customAxios } = useUser();

  const instanceRef = useRef(instanceState);
  const elapsedTimeRef = useRef(0);
  const intervalIdRef = useRef(null);

  // Sync the latest instance state.
  // useEffect(() => {
  //   instanceRef.current = instanceState;
  // }, [instanceState]);

  // Polling for instance status.
  useEffect(() => {
    if (
      instanceState.state === 'starting' ||
      instanceState.state === 'ending'
    ) {
      const pollInstanceStatus = async () => {
        try {
          const response = await customAxios.get(`/pods/${instance.id}`);
          const updatedInstance = response.data.pod;
          if (
            updatedInstance.state === 'ready' ||
            updatedInstance.state === 'ended'
          ) {
            setIsEndButtonDisabled(false);
            setInstanceState(updatedInstance);
            instanceRef.current = updatedInstance;
            clearInterval(intervalIdRef.current);
          }

          // if (updatedInstance.state !== instanceRef.current.state) {
          //   setInstanceState(updatedInstance);
          // }
          if (
            updatedInstance.state === 'failed' ||
            updatedInstance.state === 'failed-starting' ||
            updatedInstance.state === 'failed-ending'
          ) {
            clearInterval(intervalIdRef.current);
            setInstanceError(
              instanceState.state === 'failed-starting'
                ? 'Failed to start instance'
                : instanceState.state === 'failed-starting'
                  ? 'Failed to end instance'
                  : 'Instance failed',
            );
          }
        } catch (error) {
          console.error('Failed to poll instance status:', error);
        }
      };

      intervalIdRef.current = setInterval(pollInstanceStatus, 2000);
      return () => clearInterval(intervalIdRef.current);
    } else {
      clearInterval(intervalIdRef.current);
      if (instanceState.state === 'ready') {
        setIsEndButtonDisabled(false);
      }
    }
    return () => clearInterval(intervalIdRef.current);
  }, [instance.id, instanceState.state, customAxios]);

  // Update elapsed time.
  useEffect(() => {
    const creationTime = new Date(instance.created_at).getTime();
    const updateElapsedTime = () => {
      const now = Date.now();
      elapsedTimeRef.current = Math.floor((now - creationTime) / 1000);

      if (instanceRef.current.state === 'starting') {
        setButtonText(`initiating Instance... (${elapsedTimeRef.current}s)`);
      }
    };

    updateElapsedTime();
    const timerId = setInterval(updateElapsedTime, 1000);

    return () => clearInterval(timerId);
  }, [instance.created_at]);

  const getElapsedTime = useCallback(() => elapsedTimeRef.current, []);

  return {
    instanceState,
    isEndButtonDisabled,
    buttonText,
    getElapsedTime,
    instanceError,
  };
};

// import { useCallback, useEffect, useRef, useState } from 'react';
// import { useUser } from '../UserContext';

// /**
//  * Custom hook to manage and utilize instance utilities.
//  *
//  * @param {object} instance The initial instance object.
//  * @returns {object} The state and utility functions related to the instance.
//  */
// export const useInstanceUtils = (instance) => {
//   const [instanceState, setInstanceState] = useState(instance);
//   const [isEndButtonDisabled, setIsEndButtonDisabled] = useState(true);
//   const [buttonText, setButtonText] = useState('End');
//   const [instanceError, setInstanceError] = useState(null);
//   const { customAxios } = useUser();

//   const instanceRef = useRef(instance);
//   const elapsedTimeRef = useRef(0);
//   const intervalIdRef = useRef(null);

//   // Sync the latest instance state.
//   useEffect(() => {
//     instanceRef.current = instanceState;
//   }, [instanceState]);

//   // Polling for instance status.
//   useEffect(() => {
//     const pollInstanceStatus = async () => {
//       try {
//         console.log('Polling instance status...', new Date());
//         const response = await customAxios.get(`/pods/${instance.id}`);
//         const updatedInstance = response.data.pod;
//         if (updatedInstance.state !== instanceRef.current.state) {
//           setInstanceState(updatedInstance);
//           instanceRef.current = updatedInstance;
//         }

//         if (['ready', 'ended'].includes(updatedInstance.state)) {
//           setIsEndButtonDisabled(false);
//           clearInterval(intervalIdRef.current);
//         } else if (
//           ['failed', 'failed-starting', 'failed-ending'].includes(
//             updatedInstance.state,
//           )
//         ) {
//           clearInterval(intervalIdRef.current);
//           setInstanceError('Instance operation failed');
//         }
//       } catch (error) {
//         console.error('Failed to poll instance status:', error);
//       }
//     };

//     if (['starting', 'ending'].includes(instanceRef.current.state)) {
//       intervalIdRef.current = setInterval(pollInstanceStatus, 2000);
//       return () => clearInterval(intervalIdRef.current);
//     }
//   }, [instance.id, customAxios]);

//   // Update elapsed time.
//   useEffect(() => {
//     const creationTime = new Date(instance.created_at).getTime();
//     const updateElapsedTime = () => {
//       const now = Date.now();
//       elapsedTimeRef.current = Math.floor((now - creationTime) / 1000);

//       if (instanceRef.current.state === 'starting') {
//         setButtonText(`initiating Instance... (${elapsedTimeRef.current}s)`);
//       }
//     };

//     updateElapsedTime();
//     const timerId = setInterval(updateElapsedTime, 1000);

//     return () => clearInterval(timerId);
//   }, [instance.created_at]);

//   const getElapsedTime = useCallback(() => elapsedTimeRef.current, []);

//   return {
//     instanceState,
//     isEndButtonDisabled,
//     buttonText,
//     getElapsedTime,
//     instanceError,
//   };
// };
