import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'flowbite-react';
import { useForm, ValidationError } from '@formspree/react';

import Spinner from '../Components/Spinner';

const ProviderSignup = () => {
  const [state, handleSubmit] = useForm('mnqkwwbv');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setShowModal(true);
    }
  }, [state.succeeded]);

  return (
    <div className="min-h-screen bg-zinc-50">
      <div className="relative isolate ">
        <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
          <svg
            className="h-[40rem] w-[80rem] flex-none stroke-gray-200 dark:stroke-gray-900"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                width={200}
                height={200}
                x="50%"
                y="50%"
                patternUnits="userSpaceOnUse"
                patternTransform="translate(-100 0)"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg
              x="50%"
              y="50%"
              className="overflow-visible fill-gray-50 dark:fill-gray-900"
            >
              <path
                d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
            />
          </svg>
        </div>

        <section class="font-spaceG">
          <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
            <div class="flex flex-col justify-center">
              <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-4xl dark:text-white">
                Turn GPU resources into revenue. Explore new opportunities with
                us!
              </h1>
              <p class="mb-6 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
                Ready to join our network? Contact us to get started.
              </p>
              <Link
                to="/about"
                class="text-indigo-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center"
              >
                Read more about Tromero
                <svg
                  class="w-3.5 h-3.5 ml-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </Link>
            </div>
            <div class="w-full rounded-lg shadow-xl dark:bg-gray-800 wavyslow lg:max-w-xl">
              <div className="m-0.5 bg-white/95 rounded-md  p-6 space-y-8 sm:p-8 dark:bg-gray-800">
                <div className="">
                  <h2 className="mb-4 text-xl font-extrabold tracking-tight text-center sm:text-4xl text-slate-900 dark:text-white">
                    Contact Us
                  </h2>

                  <form className="space-y-4" onSubmit={handleSubmit}>
                    <label
                      className="block mb-2 text-sm font-medium text-slate-900 dark:text-slate-300"
                      htmlFor="email"
                    >
                      Email Address
                    </label>
                    <input
                      className="shadow-sm bg-slate-50 border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500 dark:shadow-sm-light"
                      placeholder="name@email.com"
                      id="email"
                      type="email"
                      name="email"
                      required
                    />
                    <ValidationError
                      prefix="Email"
                      field="email"
                      errors={state.errors}
                    />
                    <label
                      className="block mb-2 text-sm font-medium text-slate-900 dark:text-slate-300"
                      htmlFor="email"
                    >
                      Message
                    </label>
                    <textarea
                      className="block p-2.5 w-full text-sm text-slate-900 bg-slate-50 rounded-lg shadow-sm border border-slate-300 focus:ring-slate-500 focus:border-slate-500 dark:bg-slate-700 dark:border-slate-600 dark:placeholder-slate-400 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500"
                      placeholder="Leave a message..."
                      id="message"
                      name="message"
                      required
                    />
                    <ValidationError
                      prefix="Message"
                      field="message"
                      errors={state.errors}
                    />
                    <button
                      className="flex items-center justify-center w-full h-12 text-sm font-medium text-center text-white rounded-lg sm:w-24 bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                      type="submit"
                      disabled={state.submitting}
                    >
                      {state.submitting ? <Spinner /> : 'Submit'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Modal component */}
          {showModal && (
            <Modal
              className="font-spaceG"
              show={showModal}
              onClose={() => setShowModal(false)}
            >
              <Modal.Header>
                <img className="w-6" src="/Noname.png" />
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <h3 className="mb-5 text-xl font-normal text-gray-500 dark:text-gray-400">
                    Thank you for your message!
                  </h3>
                  <p className="mb-6 text-sm font-normal text-gray-500 dark:text-gray-400">
                    We have received your message and will get back to you
                    shortly.
                  </p>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Okay
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          )}
        </section>
      </div>
    </div>
  );
};

export default ProviderSignup;
