import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { useUser } from '../../UserContext';

export default function AdminDashConfirmationModal({ isOpen, user, onClose }) {
  const [ban, setBan] = useState(false);
  const { customAxios } = useUser();

  useEffect(() => {
    if (user) {
      setBan(user.banned);
    }
  }, [user]);

  const confirmBanUser = async () => {
    if (!user.banned) {
      try {
        const response = await customAxios.post('admin/ban_user', {
          user_email: user.email,
        });
        if (import.meta.env.DEV) {
          console.log(`Banning user with email: ${user.email}`);
          console.log('Response:', response.data);
        }
        toast.success('User has been banned');
      } catch (error) {
        console.error('Error banning user:', error);
        toast.error('Error banning user');
      }
    } else {
      try {
        const response = await customAxios.post('admin/revoke_ban', {
          user_email: user.email,
        });
        if (import.meta.env.DEV) {
          console.log(`Revoking ban for user with email: ${user.email}`);
          console.log('Response:', response.data);
        }
        toast.success('Ban has been revoked');
      } catch (error) {
        console.error('Error revoking ban:', error);
        toast.error('Error revoking ban');
      }
    }
    onClose();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment} onClose={onClose}>
        <Dialog as="div" className="relative z-10">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-gray-900 font-spaceG"
                  >
                    User Management:
                    <div className="mt-1 font-sans text-base font-bold text-gray-600">
                      {user?.email}
                    </div>
                  </Dialog.Title>

                  <div className="mt-6">
                    <div className="mr-4 text-sm text-gray-500">
                      This user is currently{' '}
                      {user?.banned ? 'banned' : 'not banned'}.
                    </div>
                  </div>

                  <div className="flex mt-12">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={confirmBanUser}
                    >
                      {ban ? 'Revoke ban' : 'Ban'}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium text-red-900 bg-red-100 border border-red-100 rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
