import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import Spinner from '../../Spinner';
import LogsTable from './LogsTable';
import CreateDatasetModal from './CreateDatasetModal';
import SynthesizeDataModal from './SynthesizeDataModal';
import DeleteLogsModal from './DeleteLogsModal';
import FiltersArea from './FiltersArea';

const LogsComponent = ({
  retrievedLogs,
  setFilters,
  filters,
  uniqueModels,
  uniqueTags,
  loading,
  page,
  setPage,
  totalPages,
  itemsPerPage,
  setItemsPerPage,
  fetchLogs,
  jProgress,
  setJProgress,
  showProgress,
  setShowProgress,
}) => {
  const [isDateRangeDropdownOpen, setIsDateRangeDropdownOpen] = useState(false);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: '',
    end: '',
  });
  const dateRangeDropdownRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [showCreateDatasetModal, setShowCreateDatasetModal] = useState(false);
  const [showSynthesizeDataModal, setShowSynthesizeDataModal] = useState(false);
  const [showDeleteLogsModal, setShowDeleteLogsModal] = useState(false);
  const [hasFiltersApplied, setHasFiltersApplied] = useState(false);
  const [filteredLogIds, setFilteredLogIds] = useState([]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeDropdownRef.current &&
        !dateRangeDropdownRef.current.contains(event.target) &&
        (!startDateRef.current ||
          !startDateRef.current.flatpickr ||
          !startDateRef.current.flatpickr.calendarContainer.contains(
            event.target,
          )) &&
        (!endDateRef.current ||
          !endDateRef.current.flatpickr ||
          !endDateRef.current.flatpickr.calendarContainer.contains(
            event.target,
          ))
      ) {
        setIsDateRangeDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      filters.models.length > 0 ||
      filters.tags.length > 0 ||
      filters.startDate !== '' ||
      filters.endDate !== ''
    ) {
      setHasFiltersApplied(true);
    } else {
      setHasFiltersApplied(false);
    }
  }, [filters]);

  useEffect(() => {
    if (retrievedLogs.length > 0) {
      const logIds = retrievedLogs.map((log) => log.id);
      setFilteredLogIds(logIds);
    }
  }, [retrievedLogs]);

  const handleDateRangeChange = useCallback(
    (key, date) => {
      const dateInUnix = date[0].getTime();
      setSelectedDateRange({
        ...selectedDateRange,
        [key]: dateInUnix,
      });
    },
    [selectedDateRange],
  );

  const handleApplyFilters = useCallback(() => {
    if (
      selectedDateRange.start !== '' &&
      selectedDateRange.end !== '' &&
      selectedDateRange.start > selectedDateRange.end
    ) {
      toast.error('End date cannot be before start date');
      return;
    }
    setFilters({
      models: selectedModels,
      tags: selectedTags,
      startDate: selectedDateRange.start,
      endDate: selectedDateRange.end,
    });
  }, [selectedDateRange, selectedModels, selectedTags, setFilters]);

  const handleClearFilters = useCallback(() => {
    setSelectedModels([]);
    setSelectedTags([]);
    setSelectedDateRange({ start: '', end: '' });
    setFilters({
      models: [],
      tags: [],
      startDate: '',
      endDate: '',
    });
  }, [setFilters]);

  const handleCreateDataset = useCallback(() => {
    if (
      filters.models.length === 0 &&
      filters.tags.length === 0 &&
      filters.startDate === '' &&
      filters.endDate === ''
    ) {
      toast.error('Please apply filters to create a dataset');
      return;
    } else {
      setShowCreateDatasetModal(true);
    }
  }, [filters]);

  const handleSynthesizeData = useCallback(() => {
    setShowSynthesizeDataModal(true);
  }, []);

  const handleDeleteLogs = useCallback(() => {
    if (
      filters.models.length === 0 &&
      filters.tags.length === 0 &&
      filters.startDate === '' &&
      filters.endDate === ''
    ) {
      toast.error('Please apply filters to delete logs');
      return;
    } else {
      setShowDeleteLogsModal(true);
    }
  }, [filters]);

  const modelFilters = useMemo(() => {
    return {
      selectedModels,
      setSelectedModels,
      uniqueModels,
    };
  }, [selectedModels, setSelectedModels, uniqueModels]);

  const tagFilters = useMemo(() => {
    return {
      selectedTags,
      setSelectedTags,
      uniqueTags,
    };
  }, [selectedTags, setSelectedTags, uniqueTags]);

  const dateRangeFilters = useMemo(() => {
    return {
      dateRangeDropdownRef,
      selectedDateRange,
      setSelectedDateRange,
      isDateRangeDropdownOpen,
      setIsDateRangeDropdownOpen,
      startDateRef,
      endDateRef,
      handleDateRangeChange,
    };
  }, [
    dateRangeDropdownRef,
    selectedDateRange,
    setSelectedDateRange,
    isDateRangeDropdownOpen,
    setIsDateRangeDropdownOpen,
    startDateRef,
    endDateRef,
    handleDateRangeChange,
  ]);

  const filterActions = useMemo(() => {
    return {
      handleDeleteLogs,
      handleApplyFilters,
      handleClearFilters,
      handleCreateDataset,
      handleSynthesizeData,
    };
  }, [
    handleDeleteLogs,
    handleApplyFilters,
    handleClearFilters,
    handleCreateDataset,
    handleSynthesizeData,
  ]);

  return (
    <>
      <div className="">
        <FiltersArea
          modelFilters={modelFilters}
          tagFilters={tagFilters}
          dateRangeFilters={dateRangeFilters}
          filterActions={filterActions}
          filteredLogIds={filteredLogIds}
        />
      </div>

      <div className="px-8 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <div className="flex items-center justify-between mb-4">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Logs Table{' '}
              <span className="text-sm text-gray-500 ">
                {hasFiltersApplied && '(Filtered)'}
              </span>
            </h1>
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <label className="text-sm text-gray-500">Items per page</label>
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(e.target.value)}
                  className="px-2 py-1 text-sm text-gray-900 border border-gray-300 rounded-md"
                >
                  {![10, 25, 50, 100].includes(itemsPerPage) && (
                    <option value={itemsPerPage}>{itemsPerPage}</option>
                  )}
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          <p className="mt-6 text-sm text-gray-700">
            List of logs generated by the models.
          </p>
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        {loading ? (
          <div className="flex items-center justify-around h-full">
            <div className="flex flex-col items-center gap-2">
              <Spinner size={'36px'} borderTopColor={'gray'} />
            </div>
          </div>
        ) : (
          <LogsTable
            data={retrievedLogs}
            setPage={setPage}
            currentPage={page}
            totalPages={totalPages}
            fetchLogs={fetchLogs}
          />
        )}
      </div>
      <CreateDatasetModal
        isOpen={showCreateDatasetModal}
        onClose={() => setShowCreateDatasetModal(false)}
        filters={filters}
      />
      <SynthesizeDataModal
        isOpen={showSynthesizeDataModal}
        onClose={() => setShowSynthesizeDataModal(false)}
        filters={filters}
        fetchLogs={fetchLogs}
        jProgress={jProgress}
        setJProgress={setJProgress}
        showProgress={showProgress}
        setShowProgress={setShowProgress}
      />
      <DeleteLogsModal
        isOpen={showDeleteLogsModal}
        onClose={() => setShowDeleteLogsModal(false)}
        filters={filters}
        fetchLogs={fetchLogs}
        clearFilters={handleClearFilters}
      />
    </>
  );
};

export default LogsComponent;
