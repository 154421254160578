const TailorHeader = ({ title }) => {
  return (
    <header className="sticky top-0 bg-zinc-50 z-50">
      <div
        className="flex items-center justify-between h-16 lg:p-4 pr-0 pl-4 text-xl font-medium text-zinc-800"
        role="banner"
      >
        {title}
      </div>
      <hr className="border-t border-zinc-300" />
    </header>
  );
};

export default TailorHeader;
