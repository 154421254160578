import { memo } from 'react';

import InstanceListing from './InstanceListing';

const FilteredListings = memo(
  ({ filteredListings, selectedRental, handleSelectRental }) => {
    // Determine the grid column layout based on the length of filteredListings wip
    const gridLayoutClass =
      filteredListings.length < 3
        ? 'grid-cols-1 gap-4  px-6 pb-10 666:grid-cols-2 980:grid-cols-3 xl:grid-cols-[360px_360px_360px]'
        : 'grid-cols-1 gap-4 px-6 pb-10 666:grid-cols-2 980:grid-cols-3 xl:grid-cols-4';

    return (
      <>
        <div className={`grid ${gridLayoutClass}`}>
          {filteredListings.map((listing, index) => (
            <div className="z-10 w-full" key={index}>
              <InstanceListing
                listing={listing}
                selectedRental={selectedRental}
                handleSelectRental={handleSelectRental}
              />
            </div>
          ))}
        </div>
      </>
    );
  },
);

export default FilteredListings;
