import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CopyButton = ({ textToCopy, buttonFixed = false, className = '' }) => {
  const [buttonText, setButtonText] = useState('Copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    setButtonText('Copied');
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setButtonText('Copy');
        setIsCopied(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <button
      className={clsx(
        'top-2 right-2 px-2 py-1 text-sm text-white bg-gray-800 hover:bg-gray-700 rounded-md active:bg-gray-600 z-[49]',
        buttonFixed ? 'fixed' : 'absolute',
        className,
      )}
      onClick={handleCopy}
    >
      {buttonText}
    </button>
  );
};

const CodeSnippet2 = ({
  code,
  language,
  overrideStyle = false,
  buttonFixed = false,
  className = '',
}) => {
  return (
    <div className="relative">
      <SyntaxHighlighter
        language={language}
        style={dracula}
        wrapLongLines
        className={clsx(
          overrideStyle ? '!bg-transparent text-xs' : 'text-sm',
          className,
        )}
      >
        {code}
      </SyntaxHighlighter>
      <CopyButton
        textToCopy={code}
        buttonFixed={buttonFixed}
        className={clsx(
          className,
          overrideStyle
            ? '!bg-gray-700 hover:!bg-gray-600 active:!bg-gray-700'
            : '',
        )}
      />
    </div>
  );
};

export default CodeSnippet2;
