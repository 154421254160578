import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import { normalAxios } from '../utils/generalUtils';
import { useUser } from '../UserContext';

const InvitationsPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { updateUserDetails, fetchAndUpdateUserDetails } = useUser();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const [passwordMeetsLength, setPasswordMeetsLength] = useState(false);
  const [passwordHasNumber, setPasswordHasNumber] = useState(false);
  const [passwordHasUppercase, setPasswordHasUppercase] = useState(false);

  useEffect(() => {
    const fetchInvitationDetails = async () => {
      try {
        await normalAxios.get(`/invitations/${token}`);
      } catch (error) {
        console.error(error);
        toast.error('Invalid or expired invitation token.');
        navigate('/');
      }
    };

    fetchInvitationDetails();
  }, [token, navigate]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    updatePasswordCriteria(newPassword);
    setError(''); // Reset error on input change
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setError(''); // Reset error on input change
  };

  const updatePasswordCriteria = (password) => {
    setPasswordMeetsLength(password.length >= 8);
    setPasswordHasNumber(/\d/.test(password));
    setPasswordHasUppercase(/[A-Z]/.test(password));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setError('Password does not meet requirements');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await normalAxios.post(`/invitations/${token}/accept`, {
        password,
        first_name: firstName,
        last_name: lastName,
        address: 'null',
        email: email,
      });

      // Extract the token from the response headers
      const authToken =
        response.headers['authorization'] || response.headers['Authorization'];
      if (authToken) {
        const token = authToken.substring(7); // Remove "Bearer " from the token string
        localStorage.setItem('token', token);

        // Update user details
        await updateUserDetails(response.data.user);

        // Navigate to the home page
        navigate('/');
      } else {
        throw new Error('No token found in response');
      }
    } catch (error) {
      // Handle error
      toast.error('Failed to accept invitation. Please try again.');
    }
  };

  return (
    <div className="min-h-screen pt-12 bg-zinc-50 font-dmSans">
      <div className="p-8 pt-24 mx-auto border rounded-md max-w-[750px]">
        <h2 className="text-2xl font-bold text-zinc-600">
          You've been invited to join a team on Tromero.
        </h2>
        <h2 className="mb-6 text-lg font-bold">
          Please complete the form below to finish creating your account.
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="relative mb-4">
            <label
              htmlFor="first-name"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 "
              required
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="first-name"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              First Name
            </label>
            <input
              type="text"
              id="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 "
              required
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="last-name"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 "
              required
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={handlePasswordChange}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 "
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-3 text-sm leading-5"
              onClick={togglePasswordVisibility}
            >
              {!showPassword ? (
                <svg
                  className="w-4 h-4 text-gray-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-4 h-4 text-gray-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 14"
                >
                  <g
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  >
                    <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                  </g>
                </svg>
              )}
            </button>
            <ul className="flex mt-2 text-xs">
              <li
                className={
                  passwordMeetsLength
                    ? 'text-green-700 bg-green-100 dark:bg-green-300 dark:text-black mx-1 rounded p-1'
                    : password
                      ? 'text-red-700 bg-red-100 dark:bg-red-300 dark:text-black mx-1 rounded p-1'
                      : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                }
              >
                Min. 8 Characters
              </li>
              <li
                className={
                  passwordHasNumber
                    ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                    : password
                      ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                      : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                }
              >
                1 Number
              </li>
              <li
                className={
                  passwordHasUppercase
                    ? 'text-green-700 bg-green-100 mx-1 rounded p-1 dark:bg-green-300 dark:text-black'
                    : password
                      ? 'text-red-700 bg-red-100 mx-1 rounded p-1 dark:bg-red-300 dark:text-black'
                      : 'text-gray-700 bg-gray-100 mx-1 rounded p-1'
                }
              >
                1 Uppercase Letter
              </li>
            </ul>
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="confirm-password"
              className="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
            >
              Confirm Password
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id="confirm-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className="flex-1 block w-full border-gray-300 rounded-md shadow-sm form-input focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-800 dark:text-gray-100 dark:placeholder-gray-300 "
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center px-3 text-sm leading-5"
              onClick={togglePasswordVisibility}
            >
              {!showPassword ? (
                <svg
                  className="w-4 h-4 mt-6 text-gray-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1.933 10.909A4.357 4.357 0 0 1 1 9c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 19 9c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M2 17 18 1m-5 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              ) : (
                <svg
                  className="w-4 h-4 mt-6 text-gray-400 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 14"
                >
                  <g
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  >
                    <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z" />
                  </g>
                </svg>
              )}
            </button>
          </div>
          {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
          <button
            type="submit"
            className="px-4 py-2 mt-4 text-sm font-medium text-white rounded bg-zinc-800 hover:bg-zinc-600"
          >
            Accept Invitation
          </button>
        </form>
      </div>
    </div>
  );
};

export default InvitationsPage;
