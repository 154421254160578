import React, { useLayoutEffect, useState } from 'react';
import axios from 'axios';

import Spinner from '../Components/Spinner';

const GeneratePayment = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const urlSegments = window.location.pathname.split('/');
  const token = urlSegments[urlSegments.length - 1];
  const frontendServerUrl = import.meta.env.VITE_FRONTEND_SERVER_URL;

  useLayoutEffect(() => {
    setLoading(true);
    const fetchPaymentLink = async () => {
      try {
        const response = await axios.get(
          `${frontendServerUrl}/generate_payment_link/${token}`,
          { headers: { 'Content-Type': 'application/json' } },
        );

        // Redirect user to the payment link
        window.location.href = response.data.url;
      } catch (error) {
        setError('Error fetching payment link');
        console.error('Error fetching payment link:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentLink();
  }, [frontendServerUrl, token]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center w-full min-h-screen gap-10">
      <div>
        <img src="/Noname.png" className="w-auto h-16" alt="Logo" />
      </div>
      <div className="text-md font-spaceG bold">Redirecting to payment...</div>
    </div>
  );
};

export default GeneratePayment;
