import { v4 as uuidv4 } from 'uuid';

class UploadService {
  constructor() {
    this._abortController = null;
    this._validationInProgress = false;
    this._uploadInProgress = false;
    this._validationMessages = [];
    this._fileContent = { name: '', size: null, numberOfLines: 0 };
    this._selectedFile = null;
    this._saveLogsTagList = [];
    this._report = {};
    this._error = null;
    this._totalLines = 0;
    this._uploadSuccessful = null;
    this._status = ''; // 'validating' | 'uploading' | 'complete' | 'error'
    this._percentComplete = 0;
    this._inProgress = this.validationInProgress || this.uploadInProgress;
    this.listeners = [];
    this.worker = null;
    this._skipLogsWithErrors = true;
    this._createSyntheticLogs = true;
  }

  initWorker() {
    this.worker = new Worker(
      new URL('../workers/validationWorker.js', import.meta.url),
      { type: 'module' },
    );
    this.worker.onmessage = this.handleWorkerMessage.bind(this);
    this.worker.onerror = this.handleWorkerError.bind(this);
  }

  get createSyntheticLogs() {
    return this._createSyntheticLogs;
  }

  set createSyntheticLogs(value) {
    this._createSyntheticLogs = value;
  }

  get skipLogsWithErrors() {
    return this._skipLogsWithErrors;
  }

  set skipLogsWithErrors(value) {
    this._skipLogsWithErrors = value;
  }

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = value;
    this.notify();
  }

  set clearReport(value) {
    this._report = value;
    this.notify();
  }

  get inProgress() {
    return this._validationInProgress || this._uploadInProgress;
  }

  get percentComplete() {
    return this._percentComplete;
  }

  set percentComplete(value) {
    this._percentComplete = value;
    this.notify();
  }

  get abortController() {
    return this._abortController;
  }

  set abortController(value) {
    this._abortController = value;
  }

  set uploadSuccessful(value) {
    this._uploadSuccessful = value;
    this.notify();
  }

  get uploadSuccessful() {
    return this._uploadSuccessful;
  }

  get validationInProgress() {
    return this._validationInProgress;
  }

  set validationInProgress(value) {
    this._validationInProgress = value;
    this.notify();
  }

  get uploadInProgress() {
    return this._uploadInProgress;
  }

  set uploadInProgress(value) {
    this._uploadInProgress = value;
    this.notify();
  }

  get validationMessages() {
    return this._validationMessages;
  }

  set validationMessages(value) {
    this._validationMessages = value;
    this.notify();
  }

  get fileContent() {
    return this._fileContent;
  }

  set fileContent(value) {
    this._fileContent = value;
    this.notify();
  }

  get selectedFile() {
    return this._selectedFile;
  }

  set selectedFile(value) {
    this._selectedFile = value;
    this.notify();
  }

  get saveLogsTagList() {
    return this._saveLogsTagList;
  }

  set saveLogsTagList(value) {
    this._saveLogsTagList = value;
    this.notify();
  }

  get report() {
    return this._report;
  }

  set report(value) {
    this._report = value;
    this.notify();
  }

  get totalLines() {
    return this._totalLines;
  }

  set totalLines(value) {
    this._totalLines = value;
    this.notify();
  }

  get error() {
    return this._error;
  }

  set error(value) {
    this._error = value;
    this.notify();
  }

  handleWorkerError(e) {
    console.error('Worker error:', e);
  }

  handleWorkerMessage(e) {
    const { type, validationMessages, message, validLines } = e.data;
    if (type === 'lineCount') {
      this.totalLines = message;
    } else if (type === 'progress') {
      this.percentComplete = message;
    } else if (type === 'validationError') {
      this.status = 'validationError';
      this.validationMessages = validationMessages;
      this.report = {
        validLinesCount: 0,
        validLines: [],
        validationMessages,
        totalLines: this.totalLines,
      };
      this.validationInProgress = false;
    } else {
      this.report = {
        validLinesCount: validLines.length,
        validLines,
        validationMessages,
        totalLines: this.totalLines,
      };

      const newJsonlContent = validLines.join('\n');
      const newJsonlFile = new Blob([newJsonlContent], {
        type: 'application/jsonl',
      });
      const newFileName = 'validated_logs.jsonl';

      this.selectedFile = new File([newJsonlFile], newFileName, {
        type: 'application/jsonl',
      });

      this.validationMessages = validationMessages;
      this.validationInProgress = false;
    }
    this.notify();
  }

  terminateWorker() {
    if (this.worker) {
      this.worker.terminate();
      this.worker = null;
      this.validationInProgress = false;
      this.validationMessages = [];
      this.notify();
    }
  }

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  notify() {
    this.listeners.forEach((listener) => listener(this.getState()));
  }

  getState() {
    return {
      validationInProgress: this.validationInProgress,
      uploadInProgress: this.uploadInProgress,
      validationMessages: this.validationMessages,
      fileContent: this.fileContent,
      selectedFile: this.selectedFile,
      saveLogsTagList: this.saveLogsTagList,
      report: this.report,
      totalLines: this.totalLines,
      error: this.error,
      uploadSuccessful: this.uploadSuccessful,
      percentComplete: this.percentComplete,
      inProgress: this.inProgress,
      status: this.status,
      skipLogsWithErrors: this.skipLogsWithErrors,
    };
  }

  async validateFile(content) {
    this.validationInProgress = true;
    this.notify();

    return new Promise((resolve) => {
      this.worker.onmessage = (e) => {
        const { type, validationMessages, message, validLines } = e.data;
        if (type === 'lineCount') {
          this.totalLines = message;
          this.notify();
        } else if (type === 'progress') {
          this.percentComplete = message;
          this.notify();
        } else if (type === 'validationError') {
          this.status = 'validationError';
          this.validationMessages = validationMessages;
          this.report = {
            id: uuidv4(),
            createdAt: Date.now(),
            fileName: this.selectedFile.name,
            error: true,
            status: 'failed',
            validLinesCount: 0,
            validLines: [],
            validationMessages,
            totalLines: this.totalLines,
            errorMessage: 'Validation failed',
            fileToUpload: null,
            generateSyntheticLogs: this.createSyntheticLogs,
          };
          this.validationInProgress = false;
          this.notify();
          resolve(false);
        } else {
          this.status = 'validationComplete';
          this.validationMessages = validationMessages;
          this.report = {
            id: uuidv4(),
            createdAt: Date.now(),
            fileName: this.selectedFile.name,
            error: false,
            status: 'validationComplete',
            validLinesCount: validLines.length,
            validLines,
            validationMessages,
            totalLines: this.totalLines,
            errorMessage: '',
            generateSyntheticLogs: this.createSyntheticLogs,
          };

          const newJsonlContent = validLines.join('\n');
          const newJsonlFile = new Blob([newJsonlContent], {
            type: 'application/jsonl',
          });
          const newFileName = `${this.selectedFile.name.replace(
            '.jsonl',
            '',
          )}_validated.jsonl`;

          this.selectedFile = new File([newJsonlFile], newFileName, {
            type: 'application/jsonl',
          });

          this.report = {
            ...this.report,
            fileToUpload: this.selectedFile,
          };

          this.validationMessages = validationMessages;
          this.validationInProgress = false;
          this.notify();
          resolve(true);
        }
      };

      this.worker.postMessage({
        content,
        skipLogsWithErrors: this.skipLogsWithErrors,
      });
    });
  }

  async uploadFile(customAxios) {
    this.uploadSuccessful = null;
    this.initWorker();
    this.status = 'validating';
    this.uploadInProgress = true;
    this.notify();
    let filename = '';

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          const content = event.target.result;
          await this.validateFile(content);

          if (this.status === 'validationError') {
            this.uploadInProgress = false;
            this.notify();
            return;
          }
          this.status = 'uploading';
          this.notify();

          let response;
          try {
            response = await customAxios.get('tailor/v1/generate_signed_url');
          } catch (error) {
            if (import.meta.env.DEV) {
              console.error('error getting signed url: ', error);
            }
            this.report = {
              ...this.report,
              error: true,
              status: 'failed',
              errorMessage: 'Failed to get signed url.',
            };
            this.uploadInProgress = false;
            this.notify();
            return;
          }
          const url = response?.data?.signedUrl;
          filename = response?.data?.filename;

          try {
            const formData = new FormData();
            formData.append('file', this.selectedFile);
            await fetch(url, {
              method: 'PUT',
              body: formData,
              headers: {
                'Content-Type': 'application/octet-stream',
              },
              signal: this.abortController?.signal,
            });
          } catch (error) {
            if (import.meta.env.DEV) {
              console.error('error uploading to bucket: ', error);
            }
            this.status = 'error';
            this.report = {
              ...this.report,
              error: true,
              status: 'failed',
              errorMessage: 'Upload to bucket failed.',
            };
            this.uploadInProgress = false;
            this.notify();
            return;
          }

          const payload = {
            custom_logs_filename: filename,
            save_logs_with_tags: this.saveLogsTagList,
            make_synthetic_version: this.createSyntheticLogs,
          };

          try {
            await customAxios.post('tailor/v1/custom_log_upload', payload);
            this.fileContent = { name: '', size: null, numberOfLines: 0 };
            this.uploadSuccessful = true;
            this.uploadInProgress = false;
            this.report = {
              ...this.report,
              error: false,
              status: 'success',
              errorMessage: '',
            };
            this.notify();
          } catch (error) {
            if (import.meta.env.DEV) {
              console.error('error associating logs with account: ', error);
            }
            this.report = {
              ...this.report,
              error: true,
              status: 'failed',
              errorMessage: 'Associating logs with account failed.',
            };
            this.uploadInProgress = false;
            this.uploadSuccessful = false;
            this.notify();
          }
        } catch (error) {
          if (import.meta.env.DEV) {
            console.error('General error: ', error);
          }
          throw error;
        }
      };
      reader.readAsText(this.selectedFile);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      // if (error.code === 'FailedUpload') {
      //   this.error = 'Upload failed';
      // } else {
      //   this.error = 'An error occurred. Please try again.';
      // }
      this.uploadInProgress = false;
    }
  }

  cancelUpload() {
    if (this.abortController) {
      this.abortController.abort();
    }

    this.terminateWorker();
    this.uploadInProgress = false;
  }
}

const uploadService = new UploadService();
export default uploadService;
