import { useState, useEffect, useCallback } from 'react';
import ApexCharts from 'react-apexcharts';
import { XMarkIcon } from '@heroicons/react/24/solid';
import toast from 'react-hot-toast';
import { useUser } from '../../../UserContext';
import {
  getInitialDisplayDate,
  getPricePerToken,
} from '../../../utils/generalUtils';
import { prepareChartData } from '../Deploy/prepareChartData';
import Spinner from '../../Spinner';
import MonthPicker from '../Deploy/MonthPicker';
import { useSetAtom } from 'jotai';
import {
  costChangeAtom,
  percentageChangeAtom,
  totalTokensAtom,
  totalTokensLastMonthAtom,
} from '../../../context/atoms';

const TokenUsageChart = ({ model }) => {
  const { customAxios } = useUser();
  const costPerToken = getPricePerToken(model?.model_config?.base_model);
  const [loading, setLoading] = useState(true);
  const [errorNoModel, setErrorNoModel] = useState(false);
  const setTotalTokens = useSetAtom(totalTokensAtom);
  const setTotalTokensLastMonth = useSetAtom(totalTokensLastMonthAtom);
  const [chartData, setChartData] = useState([]);
  const [chartSeries, setChartSeries] = useState([]);
  const setPercentageChange = useSetAtom(percentageChangeAtom);
  const setCostChange = useSetAtom(costChangeAtom);

  const [displayDate, setDisplayDateState] = useState(() => {
    const currentMonth = new Date().getUTCMonth();
    const currentYear = new Date().getUTCFullYear();
    const previousMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
    const previousYear = currentMonth - 1 < 0 ? currentYear - 1 : currentYear;

    return {
      thisMonth: { month: currentMonth, year: currentYear },
      previousMonth: { month: previousMonth, year: previousYear },
    };
  });

  useEffect(() => {
    setDisplayDateState(getInitialDisplayDate());
  }, [model]);

  const setDisplayDate = useCallback((newDisplayDate) => {
    setDisplayDateState((prevDisplayDate) => {
      if (
        prevDisplayDate.thisMonth.month !== newDisplayDate.thisMonth.month ||
        prevDisplayDate.thisMonth.year !== newDisplayDate.thisMonth.year ||
        prevDisplayDate.previousMonth.month !==
          newDisplayDate.previousMonth.month ||
        prevDisplayDate.previousMonth.year !== newDisplayDate.previousMonth.year
      ) {
        return newDisplayDate;
      }
      return prevDisplayDate;
    });
  }, []);

  useEffect(() => {
    const getCurrentMonthData = async () => {
      setLoading(true);
      setChartData([]);
      setChartSeries([]);
      setErrorNoModel(false);

      try {
        if (!model) {
          return;
        }

        const endpoint =
          model.type === 'base_model'
            ? `tailor/v1/base_model/${model.model_id}`
            : `tailor/v1/models/${model.model_id}`;

        const response = await customAxios.get(endpoint, {
          params: {
            month: displayDate.thisMonth.month + 1,
            year: displayDate.thisMonth.year,
          },
        });

        if (response.status === 404) {
          setErrorNoModel(true);
          return;
        }

        const { usage_data, deployment_records } = response.data.message;

        const preparedData = prepareChartData(
          usage_data,
          deployment_records,
          displayDate.thisMonth,
          model.created_at_unix * 1000,
        );

        setChartData(preparedData);
        setChartSeries([{ name: 'Tokens', data: preparedData }]);

        const totalTokensThisMonth = preparedData.reduce(
          (acc, cur) => acc + (cur.y || 0),
          0,
        );
        setTotalTokens(totalTokensThisMonth);

        return totalTokensThisMonth;
      } catch (error) {
        console.error('Error fetching current month data:', error);
        toast.error('Failed to fetch current month data, try again later.', {
          id: 'current-month-fetch-error',
        });
      } finally {
        setLoading(false);
      }
    };

    getCurrentMonthData();
  }, [model?.model_id, customAxios, displayDate.thisMonth]);

  useEffect(() => {
    const getPreviousMonthData = async () => {
      if (!model || !chartData.length) {
        return;
      }

      try {
        const endpoint =
          model.type === 'base_model'
            ? `tailor/v1/base_model/${model.model_id}`
            : `tailor/v1/models/${model.model_id}`;

        const response = await customAxios.get(endpoint, {
          params: {
            month: displayDate.previousMonth.month + 1,
            year: displayDate.previousMonth.year,
          },
        });

        const {
          usage_data: usageDataLastMonth,
          deployment_records: deploymentRecordsLastMonth,
        } = response.data.message;

        const preparedDataLastMonth = prepareChartData(
          usageDataLastMonth,
          deploymentRecordsLastMonth,
          displayDate.previousMonth,
          model.created_at_unix * 1000,
        );

        const totalTokensLastMonth = preparedDataLastMonth.reduce(
          (acc, cur) => acc + (cur.y || 0),
          0,
        );
        setTotalTokensLastMonth(totalTokensLastMonth);

        const totalTokensThisMonth = chartData.reduce(
          (acc, cur) => acc + (cur.y || 0),
          0,
        );

        const percentageChange = totalTokensLastMonth
          ? ((totalTokensThisMonth - totalTokensLastMonth) /
              totalTokensLastMonth) *
            100
          : 0;
        setPercentageChange(Number(percentageChange.toFixed(2)));

        const costChange =
          (totalTokensThisMonth - totalTokensLastMonth) * costPerToken;
        setCostChange(costChange);
      } catch (error) {
        console.error('Error fetching previous month data:', error);
        toast.error('Failed to fetch previous month data, try again later.', {
          id: 'previous-month-fetch-error',
        });
      }
    };

    getPreviousMonthData();
  }, [
    model?.model_id,
    customAxios,
    displayDate.previousMonth,
    chartData,
    costPerToken,
  ]);

  const options = {
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        className: 'z-1',
        show: true,
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          download: false,
          selection: true,
          pan: true,
          reset: true,
        },
      },
    },
    xaxis: {
      type: 'datetime', // Set the x-axis to use datetime values
      labels: {
        datetimeUTC: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 2,
      strokeColors: '#818CF8',
      colors: '#818CF8',
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          const valueString = (value / 1000).toLocaleString();
          return valueString + ' k';
        },
      },
    },
    colors: ['#A5B4FC'],
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];
        const date = new Date(data.x);
        const formattedDate = date.toLocaleString('en-US', {
          // timeZone: 'UTC',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
        if (data.y === null) {
          return `<div class="bg-white border border-zinc-300 rounded-md shadow-md">
                    <div class="text-xs text-zinc-600 px-2 pt-2 pb-1 border-b border-zinc-300">
                      ${formattedDate}
                    </div>
                    <div class="text-sm text-zinc-600 bg-zinc-100 w-full px-2 pt-1">
                      Model Not Deployed
                    </div>
                  </div>`;
        } else {
          return `<div class="bg-white border border-zinc-300 rounded-md shadow-md">
                    <div class="text-xs text-zinc-600 px-2 pt-2 pb-1 border-b border-zinc-300">
                      ${formattedDate}

                    </div>
                    <div class="text-sm text-zinc-600 bg-zinc-100 w-full px-2 pt-1">
                      Tokens: ${data.y}
                    </div>
                    <div class="text-sm px-2 pt-1 pb-2 text-zinc-600 bg-zinc-100">
                      Cost: $${(data.y * costPerToken).toFixed(2)}
                    </div>
                  </div>`;
        }
      },
    },
    stroke: {
      width: 4,
      curve: 'smooth',
    },
    title: {
      text: 'Token Usage Per Hour',
      align: 'left',
    },
  };

  if (loading) {
    return (
      <div className="min-h-80 flex flex-col">
        <h2 className="text-lg font-semibold text-zinc-800 mb-4">
          Token Usage Over Time
        </h2>
        <div className="flex items-center justify-around relative flex-grow">
          <Spinner size={'36px'} borderTopColor={'gray'} />
        </div>
      </div>
    );
  }

  if (errorNoModel) {
    return (
      <div className="min-h-80 ">
        <h2 className="text-lg font-semibold text-zinc-800 mb-4">
          Token Usage Over Time
        </h2>
        <div className="min-h-80 flex flex-col items-center justify-center text-sm md:text-base text-zinc-800 text-center relative">
          <p>Model not found. Please select a different model.</p>
          <p>If you believe this is an error, please contact support.</p>
        </div>
      </div>
    );
  }

  if (chartData.length === 0) {
    return (
      <div className="min-h-96 flex flex-col items-center justify-center text-zinc-800 text-center">
        <p>No usage data for the selected month.</p>
      </div>
    );
  }

  return (
    <div className=" h-full relative">
      <h2 className="text-lg font-semibold text-zinc-800 mb-4">
        Token Usage Over Time
      </h2>
      <div className="flex flex-col flex-wrap items-start mb-4 gap-x-20 gap-y-2 lg:absolute top-0 right-0">
        <MonthPicker setDate={setDisplayDate} currentDate={displayDate} />
      </div>
      <div className="px-2 relative h-fit mt-10">
        <ApexCharts
          options={options}
          series={chartSeries}
          height={250}
          type={
            chartData?.filter((entry) => entry.tokens !== null).length > 1
              ? 'area'
              : 'scatter'
          }
        />
      </div>
    </div>
  );
};

export default TokenUsageChart;
