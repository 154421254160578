import React, { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { set } from 'idb-keyval';

import Spinner from './Spinner';
import { useUser } from '../UserContext';

const Organization = () => {
  const { user, setUser, customAxios, fetchAndUpdateUserDetails } = useUser();
  const [organizationName, setOrganizationName] = useState(
    'Your Organization Name',
  );

  const [currentTab, setCurrentTab] = useState('Members');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [teamData, setTeamData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [inviteEmails, setInviteEmails] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const [teamInvitations, setTeamInvitations] = useState([]);
  const [enabled, setEnabled] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedRole, setSelectedRole] = useState('member');
  const [membershipId, setMembershipId] = useState(null);

  const roles = [
    {
      id: 'member',
      name: 'Member',
      description: 'A team user with access to team resources and data.',
    },
    {
      id: 'admin',
      name: 'Admin',
      description:
        'Full permissions to modify team settings including membership and billing.',
    },
  ];

  const handleNameChange = (e) => {
    setOrganizationName(e.target.value);
    setIsEdited(true);
  };

  const handleEmailsChange = (e) => {
    setInviteEmails(e.target.value);
  };

  const saveOrganizationName = async () => {
    try {
      setIsSaving(true);
      await customAxios.put('/team', { name: organizationName });

      const updatedData = await fetchTeamData();
      setTeamData(updatedData);
      setIsEdited(false);
      toast.success('Organization name updated successfully');
    } catch (error) {
      toast.error('Error updating organization name');
    } finally {
      setIsSaving(false);
    }
  };

  const sendInvitations = async () => {
    setIsInviting(true);
    const emails = inviteEmails.split(',').map((email) => email.trim());
    try {
      await Promise.all(
        emails.map((email) => customAxios.post('invitations', { email })),
      );
      setInviteEmails('');
      setIsModalOpen(false);
      toast.success('Invitations sent successfully');
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error sending invitations:', error);
      }
      toast.error('Error sending invitations');
    } finally {
      setIsInviting(false);
    }
  };

  const extendInvitation = async (token) => {
    try {
      const newExpiryDate = moment().add(7, 'days').unix(); // Extend by 7 days
      await customAxios.put(`/invitations/${token}`, {
        expires_at: newExpiryDate,
      });
      toast.success('Invitation extended successfully');
      // Update the invitations list
      const invitations = await fetchInvitations();
      setTeamInvitations(invitations.invitations || []);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error('Error extending invitation:', error);
      }
      toast.error('Error extending invitation');
    }
  };

  const updateMembershipRole = async (membershipId, newRole) => {
    try {
      await customAxios.put('/team/memberships', {
        membership_id: membershipId,
        role: newRole,
      });
      toast.success('Membership role updated successfully');
      const updatedData = await fetchTeamData();
      setTeamData(updatedData);
      setIsRoleModalOpen(false);
    } catch (error) {
      toast.error('Error updating membership role');
    }
  };

  const tabs = [
    { name: 'Members', current: currentTab === 'Members' },
    { name: 'Pending requests', current: currentTab === 'Pending requests' },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const fetchTeamData = async () => {
    try {
      const response = await customAxios.get('team');
      return response.data;
    } catch (error) {
      toast.error('Error fetching team data');
      throw error;
    }
  };

  const fetchInvitations = async () => {
    try {
      const response = await customAxios.get('invitations');
      return response.data;
    } catch (error) {
      toast.error('Error fetching invitation data');
      throw error;
    }
  };

  useEffect(() => {
    const getTeamData = async () => {
      try {
        const data = await fetchTeamData();
        setTeamData(data);
        setOrganizationName(data.name);
        const invitations = await fetchInvitations();
        setTeamInvitations(invitations.invitations || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getTeamData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    if (currentTab === 'Members') {
      return (
        <div className="w-full h-auto">
          <table className="min-w-full mt-12 divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Email
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Role
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {teamData?.members
                ?.sort((a, b) => {
                  if (a.role === 'admin' && b.role !== 'admin') {
                    return -1;
                  }
                  if (a.role !== 'admin' && b.role === 'admin') {
                    return 1;
                  }
                  return 0;
                })
                .map((person) => (
                  <tr key={person.email}>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {person.email}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 capitalize whitespace-nowrap">
                      {person.role}
                    </td>
                    <button
                      onClick={() => {
                        setSelectedPerson(person);
                        setSelectedRole(person.role);
                        setMembershipId(person.id);
                        setIsRoleModalOpen(true);
                      }}
                      className="px-3 py-4 text-sm text-indigo-500 whitespace-nowrap"
                    >
                      Modify Role
                    </button>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    } else if (currentTab === 'Pending requests') {
      return (
        <div className="w-full h-auto">
          <table className="min-w-full mt-12 divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Expires
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {teamInvitations
                ?.filter((invitation) => invitation.invited_user_id === null)
                .map((person) => (
                  <tr key={person.email}>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {person.email}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {moment(person.expires_at).format(
                        'MMMM Do YYYY, h:mm:ss a',
                      )}
                    </td>
                    <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                      <button
                        onClick={() => extendInvitation(person.token)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Resend Invitation
                        <span className="sr-only">, {person.email}</span>
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeRoleModal = () => {
    setIsRoleModalOpen(false);
  };

  return (
    <div className="w-full min-h-screen p-5 mx-auto border rounded-lg rightside font-dmSans">
      {loading ? (
        <div className="flex items-center justify-center w-full p-8 text-center ">
          <Spinner borderTopColor="#27272A" />
        </div>
      ) : error ? (
        <div className="p-8 text-center">
          This section is accessible to admin users only.
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <h2 className="font-sans text-xl font-medium leading-tight tracking-tight text-slate-900 dark:text-white">
              Organization:
            </h2>
            <div className="relative flex-grow mt-1 ml-2 max-w-56 font-dmSans">
              <input
                type="text"
                value={organizationName}
                onChange={handleNameChange}
                className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:leading-6"
              />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-zinc-600"
              />
            </div>
            {isEdited ? (
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={saveOrganizationName}
                  className="px-2 py-1 ml-2 text-sm font-medium text-white rounded bg-zinc-800 hover:bg-zinc-600"
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save'}
                </button>
                <button
                  type="button"
                  onClick={() => setIsEdited(false)}
                  className="px-2 py-1 ml-2 text-sm font-medium text-zinc-900 hover:text-zinc-500"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 fill-zinc-600"
              >
                <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
              </svg>
            )}
          </div>
          <div className="mb-12 font-medium">
            Org ID:
            <span className="font-bold text-indigo-600"> {teamData?.id}</span>
          </div>
          <div>
            <div className="sm:hidden">
              <select
                id="tabs"
                name="tabs"
                value={currentTab}
                onChange={(e) => setCurrentTab(e.target.value)}
                className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-zinc-500 focus:outline-none focus:ring-zinc-500 sm:text-sm"
              >
                {tabs.map((tab) => (
                  <option key={tab.name} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="items-center justify-between hidden border-b border-gray-200 sm:flex">
              <nav aria-label="Tabs" className="flex -mb-px space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={() => setCurrentTab(tab.name)}
                    aria-current={tab.current ? 'page' : undefined}
                    className={classNames(
                      tab.current
                        ? 'border-zinc-500 text-zinc-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                    )}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
              <button
                type="button"
                onClick={openModal}
                className="px-4 py-2 ml-4 text-sm font-medium rounded-md shadow text-zinc-100 bg-zinc-800 hover:bg-zinc-700"
              >
                Invite users to join the organization
              </button>
            </div>
          </div>
          <div className="mt-4">{renderContent()}</div>

          <Transition appear show={isModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10 font-dmSans"
              onClose={closeModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black/25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Invite Users
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Enter the email addresses of the users you want to
                          invite to join your organization.
                        </p>
                        <input
                          type="text"
                          value={inviteEmails}
                          onChange={handleEmailsChange}
                          placeholder="Email addresses"
                          className="w-full px-3 py-2 mt-2 border rounded-md focus:outline-none focus:ring-0 focus:border-zinc-800"
                        />
                      </div>

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-zinc-100 bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus-visible:ring-0"
                          onClick={sendInvitations}
                          disabled={isInviting}
                        >
                          {isInviting ? 'Sending...' : 'Send Invitations'}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          <Transition appear show={isRoleModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeRoleModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Modify Role
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          The user{' '}
                          <span className="underline">
                            {selectedPerson?.email}
                          </span>{' '}
                          currently has the role{' '}
                          <span className="text-indigo-600 capitalize">
                            '{selectedPerson?.role}'
                          </span>
                          . Would you like to modify this role?
                        </p>
                      </div>

                      <fieldset aria-label="Role" className="mt-6">
                        <div className="space-y-5">
                          {roles.map((role) => (
                            <div
                              key={role.id}
                              className="relative flex items-start"
                            >
                              <div className="flex items-center h-6">
                                <input
                                  checked={role.id === selectedRole}
                                  id={role.id}
                                  name="role"
                                  type="radio"
                                  aria-describedby={`${role.id}-description`}
                                  className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-600"
                                  onChange={() => setSelectedRole(role.id)}
                                />
                              </div>
                              <div className="ml-3 text-sm leading-6">
                                <label
                                  htmlFor={role.id}
                                  className="font-medium text-gray-900"
                                >
                                  {role.name}
                                </label>
                                <p
                                  id={`${role.id}-description`}
                                  className="text-gray-500"
                                >
                                  {role.description}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>

                      <div className="mt-12">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-1.5 text-sm font-medium text-white border border-transparent rounded bg-zinc-800 hover:bg-zinc-700 focus:outline-none "
                          onClick={() => {
                            updateMembershipRole(
                              selectedPerson.id,
                              selectedRole,
                              membershipId,
                            );
                          }}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-1.5 ml-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                          onClick={closeRoleModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )}
    </div>
  );
};

export default Organization;
