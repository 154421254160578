import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Listbox } from '@headlessui/react';
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
} from '@react-pdf/renderer';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';

import { useUser } from '../../UserContext';
import InvoiceTable from './InvoiceTable';
import Spinner from '../Spinner';
import InvoiceModal from './InvoiceModal';

const period = [
  { id: 'month', title: 'One month' },
  { id: 'range', title: 'Date range' },
];

const tabs = [
  { id: 'creditPurchases', name: 'Credit Purchases' },
  { id: 'charges', name: 'Charges' },
];

const InvoiceHistory = () => {
  const { user, customAxios } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(period[0]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState({
    id: 1,
    name: 'January',
  });
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState({ id: 1, name: 'January' });
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [endMonth, setEndMonth] = useState({ id: 1, name: 'January' });
  const [dateError, setDateError] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [combinedTransactions, setCombinedTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const itemsPerPage = 50;

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const years = Array.from(
    { length: new Date().getFullYear() - 2022 },
    (_, i) => 2023 + i,
  );
  const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setDateError('');
  };

  const handleStartYearChange = (year) => {
    if (year > endYear || (year === endYear && startMonth.id > endMonth.id)) {
      setDateError('Start date must be before end date');
      return;
    }
    setDateError('');
    setStartYear(year);
  };

  const handleStartMonthChange = (month) => {
    if (startYear === endYear && month.id > endMonth.id) {
      setDateError('Start date must be before end date');
      return;
    }
    setDateError('');
    setStartMonth(month);
  };

  const handleEndYearChange = (year) => {
    if (
      year < startYear ||
      (year === startYear && endMonth.id < startMonth.id)
    ) {
      setDateError('End date must be after start date');
      return;
    }
    setDateError('');
    setEndYear(year);
  };

  const handleEndMonthChange = (month) => {
    if (endYear === startYear && month.id < startMonth.id) {
      setDateError('End date must be after start date');
      return;
    }
    setDateError('');
    setEndMonth(month);
  };

  const fetchTransactions = async (payload) => {
    try {
      const response = await customAxios.get('transaction_history', {
        params: payload,
      });
      if (
        response.data &&
        Array.isArray(response.data.transactions) &&
        Array.isArray(response.data.payment_methods)
      ) {
        return response.data;
      } else {
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      throw error;
    }
  };

  const combineTransactions = (transactions) => {
    const combined = [];
    const stripeTransactions = transactions.filter((t) => t.type === 'stripe');
    const purchaseTransactions = transactions.filter(
      (t) => t.type === 'purchase',
    );

    purchaseTransactions.forEach((purchase) => {
      const matchingStripe = stripeTransactions.find(
        (stripe) =>
          stripe.created_at < purchase.created_at && // The stripe transaction must come before the purchase
          Math.abs(purchase.created_at - stripe.created_at) <= 3 * 60 * 60 && // within 3 hours
          purchase.amount + (purchase.tax || 0) === stripe.amount, // amount match
      );

      if (matchingStripe) {
        combined.push({
          created_at: matchingStripe.created_at,
          item: 'Credits',
          payment_method: matchingStripe.payment_method,
          status: matchingStripe.status,
          type: 'stripe purchase',
          amount: purchase.amount,
          tax: purchase.tax || 0,
        });

        stripeTransactions.splice(
          stripeTransactions.indexOf(matchingStripe),
          1,
        );
      } else {
        combined.push(purchase);
      }
    });

    combined.push(...stripeTransactions);

    const otherTransactions = transactions.filter(
      (t) => t.type !== 'stripe' && t.type !== 'purchase',
    );

    combined.push(...otherTransactions);

    return combined;
  };

  const getFilteredTransactions = (transactionsToFilter) => {
    return transactionsToFilter.filter((transaction) => {
      if (selectedTab === 'charges') {
        return transaction.type === 'charge';
      } else if (selectedTab === 'creditPurchases') {
        return transaction.type !== 'charge';
      }
      return true;
    });
  };

  useEffect(() => {
    const payload = { userId: user.id };
    fetchTransactions(payload)
      .then((data) => {
        const sortedTransactions = data.transactions.sort(
          (a, b) => b.created_at - a.created_at,
        );
        setTransactions(sortedTransactions);
        const combined = combineTransactions(sortedTransactions);
        setCombinedTransactions(combined);
        setPaymentMethods(data.payment_methods);
        setLoading(false);

        // Calculate total pages based on the selected tab
        const filteredTransactions = getFilteredTransactions(combined);
        const total = Math.ceil(filteredTransactions.length / itemsPerPage);
        setTotalPages(total);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id, selectedTab]);

  if (loading) {
    return (
      <Spinner size="30px" borderColor="#E4E4E7" borderTopColor="#27272A" />
    );
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatAmount = (amount, type) => {
    const formattedAmount =
      type === 'stripe' || type === 'stripe purchase'
        ? amount / 100
        : amount / 100000;
    return formattedAmount > 0.01
      ? formattedAmount.toFixed(2)
      : formattedAmount.toFixed(4);
  };

  const filterTransactionsByDateRange = (combinedTransactions) => {
    if (selectedPeriod.id === 'month') {
      const startDate = moment(`${selectedYear}-${selectedMonth.id}-01`);
      const endDate = moment(startDate).endOf('month');
      return combinedTransactions.filter(
        (transaction) =>
          (transaction.type === 'stripe' ||
            transaction.type === 'stripe purchase') &&
          moment(transaction.created_at * 1000).isBetween(
            startDate,
            endDate,
            null,
            '[]',
          ),
      );
    } else {
      const startDate = moment(`${startYear}-${startMonth.id}-01`);
      const endDate = moment(`${endYear}-${endMonth.id}-01`).endOf('month');
      return combinedTransactions.filter(
        (transaction) =>
          (transaction.type === 'stripe' ||
            transaction.type === 'stripe purchase') &&
          moment(transaction.created_at * 1000).isBetween(
            startDate,
            endDate,
            null,
            '[]',
          ),
      );
    }
  };

  const getFileName = () => {
    if (selectedPeriod.id === 'month') {
      return `Tromero-Receipt-${selectedMonth.name}-${selectedYear}.pdf`;
    } else {
      return `Tromero-Receipt-${startMonth.name}-${startYear}-to-${endMonth.name}-${endYear}.pdf`;
    }
  };

  const getPaymentMethodDetails = (paymentMethodId) => {
    if (!paymentMethods) {
      return 'N/A';
    }
    const paymentMethod = paymentMethods.find(
      (method) => method.id === paymentMethodId,
    );
    return paymentMethod
      ? `${paymentMethod.brand} **** ${paymentMethod.last4}`
      : 'N/A';
  };

  const handleGeneratePDF = () => {
    const filteredTransactions = filterTransactionsByDateRange(
      combinedTransactions,
    ).filter(
      (transaction) =>
        transaction.status !== 'canceled' && transaction.type !== 'charge',
    );

    const receiptTimestamp = moment(Date.now()).format('MMMM Do, YYYY h:mm A');

    // Calculate the sum total of all succeeded transactions, including the tax
    const totalAmount = filteredTransactions.reduce((sum, transaction) => {
      if (transaction.status === 'succeeded') {
        return sum + transaction.amount + (transaction.tax || 0); // Adding tax if available
      }
      return sum;
    }, 0);

    return (
      <Document>
        <Page style={{ padding: 30, position: 'relative' }}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 100,
              backgroundColor: '#F4F4F5',
              zIndex: -1,
            }}
          >
            <svg height="100%" width="100%">
              <defs>
                <clipPath id="clip" clipPathUnits="objectBoundingBox">
                  <path d="M0,1 L1,0 L1,1 Z" />
                </clipPath>
              </defs>
              <rect width="100%" height="100%" clipPath="url(#clip)" />
            </svg>
          </View>

          <Image
            src="/circle-logo.png"
            style={{
              position: 'absolute',
              left: 10,
              width: 100,
              height: 100,
            }}
          />
          <Text
            style={{
              fontSize: 15,
              marginBottom: 10,
              marginTop: 80,
              textAlign: 'center',
            }}
          >
            Receipt from Tromero ltd.
          </Text>
          <Text
            style={{
              color: '#808080',
              fontSize: 8,
              textAlign: 'center',
              marginBottom: 20,
            }}
          >
            Receipt Generated {receiptTimestamp}
          </Text>
          <View style={{ display: 'table', width: 'auto', marginTop: 20 }}>
            <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                Amount
              </Text>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                Status
              </Text>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                Date Paid
              </Text>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                VAT
              </Text>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                Payment Method
              </Text>
            </View>
            {filteredTransactions.map((transaction, index) => (
              <View
                style={{ flexDirection: 'row', paddingBottom: 5 }}
                key={index}
              >
                <Text
                  style={{
                    width: '20%',
                    margin: 'auto',
                    marginTop: 5,
                    fontSize: 10,
                    color: '#3F3F46',
                  }}
                >
                  ${formatAmount(transaction.amount, transaction.type)}
                </Text>
                <Text
                  style={{
                    width: '20%',
                    margin: 'auto',
                    marginTop: 5,
                    fontSize: 10,
                    color: '#3F3F46',
                    textTransform: 'capitalize',
                  }}
                >
                  {transaction.status}
                </Text>
                <Text
                  style={{
                    width: '20%',
                    margin: 'auto',
                    marginTop: 5,
                    fontSize: 10,
                    color: '#3F3F46',
                  }}
                >
                  {moment(transaction.created_at * 1000).format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </Text>
                <Text
                  style={{
                    width: '20%',
                    margin: 'auto',
                    marginTop: 5,
                    fontSize: 10,
                    color: '#3F3F46',
                  }}
                >
                  {transaction.tax !== null
                    ? `$${(transaction.tax / 100).toFixed(2)}`
                    : '-'}
                </Text>
                <Text
                  style={{
                    width: '20%',
                    margin: 'auto',
                    marginTop: 5,
                    fontSize: 10,
                    color: '#3F3F46',
                    textTransform: 'capitalize',
                  }}
                >
                  {getPaymentMethodDetails(transaction.payment_method)}
                </Text>
              </View>
            ))}
          </View>
          <Text style={{ fontSize: 14, marginBottom: 20, marginTop: 40 }}>
            Summary
          </Text>
          <View style={{ display: 'table', width: 'auto', marginTop: 20 }}>
            <View
              style={{
                flexDirection: 'row',
                paddingBottom: 5,
                borderBottom: '1px solid #E4E4E7',
              }}
            >
              <Text
                style={{
                  width: '80%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                }}
              >
                Tromero
              </Text>
              <Text
                style={{
                  width: '20%',
                  margin: 'auto',
                  marginTop: 5,
                  fontSize: 10,
                  color: '#3F3F46',
                }}
              >
                ${formatAmount(totalAmount.toFixed(2), 'stripe')}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontSize: 12,
              marginTop: 40,
              paddingVertical: 20,
              borderTop: '1px solid #E4E4E7',
              borderBottom: '1px solid #E4E4E7',
            }}
          >
            If you have any questions, contact us at{' '}
            <Text style={{ color: '#4F46E5', paddingVertical: 10 }}>
              info@tromero.org.
            </Text>
          </Text>
          <View
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 50,
              backgroundColor: '#F4F4F5',
              zIndex: -1,
            }}
          >
            <Text style={{ fontSize: 12, padding: 30 }}>Tromero.ai</Text>
            <svg height="100%" width="100%">
              <defs>
                <clipPath id="clip" clipPathUnits="objectBoundingBox">
                  <path d="M0,1 L1,0 L1,1 Z" />
                </clipPath>
              </defs>
              <rect width="100%" height="100%" clipPath="url(#clip)" />
            </svg>
          </View>
        </Page>
      </Document>
    );
  };

  const calculatePageNumbers = (totalPages, currentPage) => {
    const windowSize = 5; // Determines the number of pages around the current page
    let pages = [];

    if (totalPages <= windowSize + 4) {
      // No need for ellipsis
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Need to consider ellipsis
      const startPages = [1, 2];
      const endPages = [totalPages - 1, totalPages];
      const middlePagesStart = Math.max(
        Math.min(currentPage - 1, totalPages - windowSize),
        3,
      );
      const middlePagesEnd = Math.min(currentPage + 1, totalPages - 2);

      pages = [
        ...startPages,
        '...',
        ...Array.from(
          { length: middlePagesEnd - middlePagesStart + 1 },
          (_, i) => middlePagesStart + i,
        ),
        '...',
        ...endPages,
      ];
      if (currentPage <= 3) {
        pages = Array.from({ length: windowSize + 2 }, (_, i) => i + 1).concat([
          '...',
          ...endPages,
        ]);
      } else if (currentPage >= totalPages - 2) {
        pages = [
          ...startPages,
          '...',
          ...Array.from(
            { length: windowSize + 2 },
            (_, i) => totalPages - windowSize - 1 + i,
          ),
        ];
      }
    }

    return pages;
  };

  const filteredTransactions = getFilteredTransactions(combinedTransactions);
  const pageNumbers = calculatePageNumbers(totalPages, currentPage);

  const currentTransactions = filteredTransactions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div className="flex-col justify-center flex-1 w-full p-6 mx-auto mb-16 text-xs border rounded-lg rightside">
      <div className="break-all">
        <div className="w-full mb-10 text-sm text-left text-zinc-500 dark:text-gray-300">
          <h2 className="mb-4 text-xl font-medium leading-tight tracking-tight text-slate-900 sm:mb-6 dark:text-white">
            Transaction History
          </h2>
        </div>
        <div>
          <div className="mb-8">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    onClick={() => {
                      setSelectedTab(tab.id);
                      setCurrentPage(1); // Reset to the first page when changing tabs
                    }}
                    className={classNames(
                      tab.id === selectedTab
                        ? 'border-zinc-500 text-zinc-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                    )}
                    aria-current={tab.id === selectedTab ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
          {selectedTab === 'creditPurchases' && (
            <button
              className="w-1/4 h-12 rounded-md min-w-[200px] items-center flex justify-center group shadow bg-gray-900 mb-6 hover:bg-gray-800"
              onClick={openModal}
            >
              <div className="flex items-center justify-center my-auto">
                <div className="flex text-sm font-medium text-gray-100">
                  Generate Invoice
                </div>
              </div>
            </button>
          )}
          <div>
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="flow-root mt-8">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <InvoiceTable
                      transactions={currentTransactions}
                      selectedTab={selectedTab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="flex items-center justify-between px-4 mt-4 border-t border-gray-200 sm:px-0">
          <div className="flex flex-1 w-0 -mt-px">
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <ArrowLongLeftIcon
                className="w-5 h-5 mr-3 text-gray-400"
                aria-hidden="true"
              />
              Previous
            </button>
          </div>
          <div className="hidden md:-mt-px md:flex">
            {pageNumbers.map((number, index) =>
              number === '...' ? (
                <span key={index} className="px-4 pt-4 text-sm font-medium">
                  ...
                </span>
              ) : (
                <button
                  key={index}
                  onClick={() => setCurrentPage(number)}
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${
                    currentPage === number
                      ? 'border-zinc-800 text-zinc-800'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  }`}
                >
                  {number}
                </button>
              ),
            )}
          </div>
          <div className="flex justify-end flex-1 w-0 -mt-px">
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              className={`inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              Next
              <ArrowLongRightIcon
                className="w-5 h-5 ml-3 text-gray-400"
                aria-hidden="true"
              />
            </button>
          </div>
        </nav>
        <InvoiceModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          startYear={startYear}
          setStartYear={setStartYear}
          startMonth={startMonth}
          setStartMonth={setStartMonth}
          endYear={endYear}
          setEndYear={setEndYear}
          endMonth={endMonth}
          setEndMonth={setEndMonth}
          dateError={dateError}
          handleStartYearChange={handleStartYearChange}
          handleStartMonthChange={handleStartMonthChange}
          handleEndYearChange={handleEndYearChange}
          handleEndMonthChange={handleEndMonthChange}
          handleGeneratePDF={handleGeneratePDF}
          getFileName={getFileName}
          period={period}
          years={years}
          months={months}
        />
      </div>
    </div>
  );
};

export default InvoiceHistory;
