import clsx from 'clsx';

const ValidationError = ({ error }) => {
  return (
    <p
      className={clsx(
        'text-sm after:content-["."] after:text-transparent text-right pt-1',
      )}
    >
      {error && (
        <span
          aria-live="assertive"
          aria-atomic="true"
          role="alert"
          className="text-red-500"
        >
          {error}
        </span>
      )}
    </p>
  );
};

export default ValidationError;
