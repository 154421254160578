import { Link } from 'react-router-dom';

const MyBaseModelCard = ({ model }) => {
  return (
    <Link
      to={`../my-models/base/${model.model_name}`}
      className="relative p-4 border border-gray-300 shadow hover:shadow-md transition-shadow bg-white h-24 overflow-hidden rounded-lg hover:border-zinc-800"
    >
      <div className="absolute p-20 text-xs font-semibold bg-gray-50  rounded-full  -top-4 -right-24 shadow-inner z-1"></div>
      <div className="text-lg font-semibold text-zinc-800 gap-2 flex flex-row items-center z-10 relative">
        <img
          src={model.image_url}
          alt={model.family}
          className="w-auto h-5 mr-2"
        />
        {model.display_name}
      </div>
      <div className="text-sm text-gray-700 mt-2 z-10 relative">
        Click to display your base model usage.
      </div>
    </Link>
  );
};

export default MyBaseModelCard;
