/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
// import { toast } from 'react-hot-toast';

import EmailModal from '../Components/Modals/EmailModal';
import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import { useUser } from '../UserContext';
// import { calculateDuration } from '../utils/InstanceUtils';
import LiveInstancesList from '../Components/UserDashboard/LiveInstancesList';
import TopupModal from '../Components/Modals/TopupModal';
import { amountToFrontend } from '../utils/CurrencyUtils';
// import EmergencyBanner from '../Components/UserDashboard/EmergencyBanner';
// import { useAtomValue, useSetAtom } from 'jotai';
// import { modelsListAtom } from '../context/atoms';
import ModelsList from '../Components/UserDashboard/ModelList';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import ActivePipelines from '../Components/UserDashboard/ActivePipelinesProgress';
import QuickActionsSection from '../Components/UserDashboard/QuickActionsSection';

const UserDashboard = () => {
  const { user, customAxios, refresh, setRefresh } = useUser();
  const money = useCurrencyConverter();
  const [updatedData, setUpdatedData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    credits: '',
  });
  const [currentTime, setCurrentTime] = useState(moment().format('lll'));
  const [, setChartData] = useState({ series: [], categories: [] });
  const [, setAverageCostPerHour] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [open, setOpen] = useState(false);
  const userEmail = localStorage.getItem('email') || '';
  const [emailModalMessage, setEmailModalMessage] = useState(
    "If you're running into technical difficulties while trying to rent an instance - reach out and we'll find a solution.",
  );
  const [liveInstances, setLiveInstances] = useState([]);
  const [historicalInstances, setHistoricalInstances] = useState([]);
  const [instances, setInstances] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(true);

  const queryClient = useQueryClient();

  useEffect(() => {
    // Force refetch both queries when dashboard mounts
    queryClient.invalidateQueries({ queryKey: ['models'] });
    queryClient.invalidateQueries({ queryKey: ['pipelines'] });
  }, [queryClient]);

  const handleToggleEmailModal = (message = '') => {
    setEmailModalMessage(message);
    setShowEmailModal((prevState) => !prevState);
  };

  // Animation variants for rest of the content
  const headerVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 3, delay: 0 } },
  };

  const combinedVariants = {
    initial: {
      // Properties from containerVariants' initial state
      backgroundColor: 'rgba(255, 255, 255, 0)',
      boxShadow: 'none',
      // Properties from dashboardVariants' hidden state
      y: 20,
      opacity: 0,
    },
    animate: {
      // Properties from containerVariants' animate state
      backgroundColor: 'rgba(250, 250, 250, 1)',
      // boxShadow:
      //   '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)',
      // Properties from dashboardVariants' visible state
      y: 0,
      opacity: 1,
      transition: {
        // Merge the transition properties. Adjust as needed.
        duration: 1,
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(moment().format('lll'));
    }, 30000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (user) {
      setUpdatedData({
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        email: user.email || '',
        credits: user.new_credits_available || '',
      });
    }
  }, [user]);

  useEffect(() => {
    const fetchInstances = async () => {
      try {
        const response = await customAxios.get('pods');

        const fetchedInstances = response.data.pods;
        setInstances(fetchedInstances);

        const { active, historical } = fetchedInstances.reduce(
          (result, instance) => {
            if (
              instance.state !== 'ended' &&
              instance.state !== 'ending' &&
              instance.state !== 'failed' &&
              instance.state !== 'failed-starting' &&
              instance.state !== 'failed-ending'
            ) {
              result.active.push(instance);
            } else {
              result.historical.push(instance);
            }
            return result;
          },
          { active: [], historical: [] },
        );

        historical.sort((a, b) => {
          const endA = a.updated_at;
          const endB = b.updated_at;
          return new Date(endB) - new Date(endA);
        });

        setLiveInstances(active);
        setHistoricalInstances(historical);
      } catch (error) {
        console.error('Could not fetch instance:', error);
        // toast.error('Error fetching instances. Please try again', {
        //   id: 'fetch-instances',
        // });
      }
    };

    if (shouldFetch) {
      fetchInstances();
      setShouldFetch(false);
    }
  }, [shouldFetch, customAxios]);

  useEffect(() => {
    if (refresh) {
      setShouldFetch(true);
      setRefresh(false);
    }
  }, [refresh]);

  const processDataForChart = (data) => {
    const costByDate = data.reduce((acc, instance) => {
      const dateKey = moment(instance.created_at).format('YYYY-MM-DD');
      acc[dateKey] = (acc[dateKey] || 0) + instance.current_cost;
      return acc;
    }, {});

    // Ensure today's date is included in the dataset
    const today = moment().format('YYYY-MM-DD');
    costByDate[today] = costByDate[today] || 0;

    // Sort dates and prepare series data with toFixed(2)
    const sortedDates = Object.keys(costByDate).sort(
      (a, b) => new Date(a) - new Date(b),
    );
    const seriesData = sortedDates.map((date) => costByDate[date].toFixed(2));

    return {
      series: [{ name: 'Cost', data: seriesData, color: 'gray' }],
      categories: sortedDates,
    };
  };

  useEffect(() => {
    const processedData = processDataForChart(instances);
    setChartData(processedData);
  }, [instances]);

  const calculateAverageCostPerHour = () => {
    let totalCost = 0;
    let totalDurationInHours = 0;

    historicalInstances.forEach((instance) => {
      // Parsing the dates
      const createdAt = new Date(instance.created_at);
      const endedAt = new Date(instance.ended_at);

      // Calculate the duration in hours
      const durationInMilliseconds = endedAt - createdAt;
      const durationInHours = durationInMilliseconds / 1000 / 3600; // Convert from milliseconds to hours

      totalCost += instance.total_cost / 100;
      totalDurationInHours += durationInHours;
    });

    const averageCost =
      totalDurationInHours > 0 ? totalCost / totalDurationInHours : 0;

    return averageCost;
  };

  useEffect(() => {
    const averageCost = calculateAverageCostPerHour();
    setAverageCostPerHour(averageCost);
  }, [historicalInstances]);

  return (
    <div className="sm:min-h-[calc(100vh-88px-118px)] min-h-[calc(100vh-88px-130px)] lg:min-h-[calc(100vh-96px-118px)] bg-zinc-50 pb-20">
      {/* <EmergencyBanner /> */}
      {/* top banner */}
      <motion.div
        variants={headerVariants}
        initial="initial"
        animate="animate"
        className="w-full h-full wavyextraslow shadow-3xinner dark:bg-gray-800 TOPBANNER absolute inset-0"
      >
        {/* <div className="absolute top-0 bottom-0 left-0 right-0 w-full overlay dark:bg-gray-800 "></div> */}
        {/* <div className="absolute top-0 bottom-0 left-0 right-0  overlay bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-transparent via-zinc-50/50 to-zinc-50 "></div> */}
        {/* <div className="absolute top-0 bottom-0 left-0 right-0 overlay md:bg-gradient-to-t from-zinc-50 via-transparent to-zinc-50 min-h-screen "></div> */}
      </motion.div>

      {/* whole dashboard  */}
      <motion.div
        variants={combinedVariants}
        initial="initial"
        animate="animate"
        className="container relative z-0 mx-auto rounded-lg lg:bg-zinc-50/50 font-dmSans backdrop-blur-xl pb-12 pt-8 px-2"
      >
        <div className="grid grid-cols-4 gap-4 ">
          {/* Window 1 */}
          <div className="container relative col-span-4 bg-transparent min-h-40 rounded-xl">
            <div className="grid grid-cols-1 gap-y-4 xl:gap-4 xl:grid-cols-3 md:grid-rows-2">
              {/* Welcome Box */}
              <div className="col-span-3 row-span-2 xl:col-span-1 welcomebox rounded-xl ">
                <div className="h-full bg-white border rounded-lg dark:bg-gray-900 p-2 px-4 pb-4 xl:p-6 dark:text-gray-200 relative">
                  {/* Left side content */}
                  <div className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Welcome, {updatedData.firstName}
                  </div>
                  {/* <div className="text-2xl font-bold tracking-wide text-gray-700 sm:text-3xl dark:text-gray-200">
                        {updatedData.firstName}
                      </div> */}
                  <div className="text-gray-500 dark:text-gray-200 whitespace-nowrap">
                    {currentTime}
                  </div>
                  <div className="mt-10 xl:mt-16">
                    <div className="text-gray-500 dark:text-gray-200">
                      Balance
                    </div>
                    <div className="flex items-center justify-between h-12 mt-2 text-2xl font-bold tracking-wide text-gray-700 sm:text-2xl dark:text-gray-200">
                      <div>{money(updatedData.credits)}</div>

                      <div>
                        {user.team_role === 'admin' && (
                          <button
                            onClick={() => setOpen(true)}
                            className="h-10 px-2 mb-1 text-sm font-medium text-gray-100 bg-zinc-900 rounded-md sm:px-4 hover:bg-zinc-800 whitespace-nowrap"
                          >
                            + Manage balance
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="items-center w-full mt-4 text-sm text-gray-500 rounded-md xl:mt-8">
                      {user.new_credits_top_up_threshold > 0 &&
                      user.new_credits_top_up_amount > 0 &&
                      user.payment_methods.length !== 0 ? (
                        <>
                          <div>
                            Your balance will Auto-top-up with $
                            {amountToFrontend(
                              user.new_credits_top_up_amount,
                            ).toFixed(2)}
                          </div>
                          <div>
                            when it falls below $
                            {amountToFrontend(
                              user.new_credits_top_up_threshold,
                            ).toFixed(2)}
                            . VAT will also be added to this Auto-top-up.
                          </div>
                        </>
                      ) : (
                        <div className="inline-flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="w-4 h-4 mr-2 text-yellow-300"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.701 2.25c.577-1 2.02-1 2.598 0l5.196 9a1.5 1.5 0 0 1-1.299 2.25H2.804a1.5 1.5 0 0 1-1.3-2.25l5.197-9ZM8 4a.75.75 0 0 1 .75.75v3a.75.75 0 1 1-1.5 0v-3A.75.75 0 0 1 8 4Zm0 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>
                            Auto-top-up is disabled.
                            {user.payment_methods.length === 0 &&
                              ' No payment method found'}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Deployed Models Box */}
              <div className="col-span-1 lg:col-span-2 lg:row-span-2 min-h-96 max-h-72 BIG">
                <div className="h-full px-2 overflow-y-auto bg-white border rounded-lg xl:px-6 dark:bg-gray-900">
                  <div className="sticky top-0 z-10 bg-white">
                    <div className="pt-6 mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200">
                      <Link to="/tailor/my-models">Deployed Models</Link>
                    </div>
                  </div>
                  <div className="w-full h-auto px-2 py-4">
                    <ModelsList />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container relative col-span-4 bg-transparent rounded-xl ">
            {/* Div with Continuous Training Pipeline Progress*/}
            <div className="grid grid-cols-1 xl:gap-4 gap-y-4 xl:grid-cols-3">
              <div className="p-2 pb-4 bg-white border rounded-lg xl:p-6 dark:bg-gray-900 min-h-fit">
                <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200 w-full">
                  Quick Actions
                </div>
                <QuickActionsSection />
              </div>
              <div className="col-span-2 p-2 bg-white border rounded-lg xl:p-6 dark:bg-gray-900">
                <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200 ">
                  Continuous Training
                </div>
                <ActivePipelines />
              </div>
            </div>
          </div>
          {/*Active Instances section*/}
          <div className="container relative col-span-4 bg-transparent rounded-xl ">
            <div className="bg-transparent md:col-span-2 md:row-span-2 min-h-56 BIG">
              <div className="p-2 bg-white border rounded-lg xl:p-6 dark:bg-gray-900">
                <div className="mt-0 mb-2 ml-1 text-xl font-semibold text-gray-800 dark:text-gray-200 ">
                  Active Instances
                </div>{' '}
                {liveInstances && liveInstances?.length > 0 ? (
                  <LiveInstancesList liveInstances={liveInstances} />
                ) : (
                  <div
                    // to="/tailor/marketplace"
                    // type="button"
                    className="relative block w-full p-12 text-center border border-dashed rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 cursor-not-allowed"
                  >
                    <svg
                      className="w-6 h-6 mx-auto text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                    <div className="block mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200 ">
                      Rent an instance
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/*Tables*/}
        </div>
      </motion.div>
      <EmailModal
        userEmail={userEmail}
        showModal={showEmailModal}
        onClose={handleToggleEmailModal}
        alternativeMessage={emailModalMessage}
      />
      <TopupModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default UserDashboard;
