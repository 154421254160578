import React from 'react';

import FAQ from '../Components/LandingPage/Faq2';

const FAQPage = () => {
  return (
    <di className="max-w-[1500px] mx-auto">
      <FAQ />
    </di>
  );
};

export default FAQPage;
