export const tabInfo = {
  0: {
    name: 'Data',
    firstTitle: 'Importing data',
    firstBody:
      'Seamlessly import or collect data using Python and Typescript wrappers for any AI model.',
    secondTitle: 'Improved Synthetic Data',
    secondBody:
      'Boost model performance by 5-15% with techniques like Chain-of-Thought Prompting and Mixture of Agents.',
    thirdTitle: 'Data Labeling & Dataset Creation',
    thirdBody:
      'Automatically label logs and create high-quality datasets for efficient machine learning development.',
  },
  1: {
    name: 'Fine-tuning',
    firstTitle: '100+ Pre-Trained Models',
    firstBody:
      'Choose from Tromero’s vast repository of pre-trained models to accelerate your training job.',
    secondTitle: 'Orchestrated Training',
    secondBody:
      'Tromero handles GPU resources and data transformation, simplifying the model training process for you.',
    thirdTitle: 'Real-Time Training Metrics',
    thirdBody: 'Monitor your model’s training and validation loss.',
  },
  2: {
    name: 'Evaluation',
    firstTitle: 'Popular Benchmarks',
    firstBody:
      "Use Tromero's extensive library to evaluate your model on established tasks and datasets.",
    secondTitle: 'Qualatative and Quantitative Evaluation ',
    secondBody:
      'Define metrics tailored to your specific needs for precise model performance measurement or test in the Playground.',
    thirdTitle: 'Detailed Performance Reports',
    thirdBody:
      "Generate comprehensive reports to analyze and visualize your model's results for better insights and decision-making.",
  },
  3: {
    name: 'Deployment',
    firstTitle: 'Serverless Deployments',
    firstBody:
      'Deploy models with one click, paying only per token, and scale effortlessly.',
    secondTitle: 'On-Prem Deployment Options',
    secondBody:
      "Deploy on your own cloud or VPC for full control, or use Tromero's managed infrastructure.",
    thirdTitle: 'Cost-Effective Dedicated GPU Clusters',
    thirdBody:
      'Save 50% on hosting costs with pay-per-minute dedicated clusters for reliable, scalable solutions.',
  },
  4: {
    name: 'GPU Clusters',
    firstTitle: 'Pay-Per-Second, Pay-As-You-Go',
    firstBody:
      'Pay only for the resources you use with our flexible, per-second billing.',
    secondTitle: 'Top-Tier GPU Performance with A100s',
    secondBody:
      'Take advantage of NVIDIA A100 GPUs to handle the most demanding AI training tasks with efficiency.',
    thirdTitle: 'Customizable Resource Allocation',
    thirdBody:
      'Allocate GPUs based on your project’s specific needs, whether it’s for production-grade deployments or prototyping.',
  },
};
