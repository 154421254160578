import { Modal } from 'flowbite-react';

const NoDeployedModelModal = ({ showModal, closeModal }) => {
  return (
    <>
      <Modal show={showModal} onClose={closeModal} dismissible>
        <Modal.Header className="!pb-3">
          <div className="text-lg font-semibold text-zinc-800">
            No Models Deployed
          </div>
        </Modal.Header>
        <div className="px-5 py-6">
          <p className="text-zinc-800 text-base">
            You have not deployed any models yet. Please deploy a model to use
            the playground.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default NoDeployedModelModal;
