import { atom } from 'jotai';

export const preferredLanguageAtom = atom(['python', 'Python']);

export const showButtonsAtom = atom([true, true]);

export const modelsListAtom = atom([]);

export const percentageChangeAtom = atom(0);
export const costChangeAtom = atom(0);
export const totalTokensAtom = atom(0);
export const totalTokensLastMonthAtom = atom(0);

export const modelStateAtom = atom({});
export const sortCriteriaAtom = atom('state');
