import React from 'react';
import { Disclosure } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import { faqData, faqIntro, faqTech } from './FaqData2';
import { FaqSection } from './FaqComponents2';

const Faq = () => {
  return (
    <div className="bg-zinc-50 dark:bg-gray-800">
      <div className="px-6 py-24 mx-auto max-w-[1500px] sm:py-32 lg:px-8 lg:py-40">
        <h2 className="text-3xl font-bold leading-10 tracking-tight text-gray-900 sm:text-4xl font-dmSans dark:text-gray-200">
          Frequently Asked Questions
        </h2>

        {/* Introduction & Trial Section */}
        <FaqSection title="Introduction & Trial" faqs={faqIntro} />
        {/* Technicals Section */}
        <FaqSection title="Technicals" faqs={faqTech} />
        {/* Data & Security Section */}
        <FaqSection title="Data & Security" faqs={faqData} />
      </div>
    </div>
  );
};

export default Faq;
