import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

import supabase from '../../SupabaseClient';
/**
 * @module userUtils
 * @description Utility functions for user authentication and authorization
 * @exports hasUserInLocalStorage
 * @exports checkTokenExpiration
 * @exports areAllCreditCardsExpired
 * @exports isCardAboutToExpire
 * @requires jwt-decode
 **/
/**
 * hasUserInLocalStorage
 * @description Check if the user is logged in and set the user in the context if they are logged in and return the user's status (logged in or not) and if they are an admin
 * @param {function} setUser - function to set the user in the context
 * @returns {object} {hasUser: boolean, isAdmin: boolean}
 */
export const hasUserInLocalStorage = async (setUser) => {
  const userFromLocalStorage = await JSON.parse(
    localStorage.getItem('userDetails'),
  );
  if (userFromLocalStorage) {
    const isExpired = checkTokenExpiration();
    if (isExpired) {
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('userDetails');
      localStorage.removeItem('user');
      localStorage.removeItem('isAdmin');
      setUser(null);
      return {
        hasUser: false,
        isAdmin: false,
      };
    } else {
      setUser(userFromLocalStorage);
      return {
        hasUser: true,
        isAdmin: userFromLocalStorage.is_admin,
      };
    }
  } else {
    setUser(null);
    return {
      hasUser: false,
      isAdmin: false,
    };
  }
};
/**
 * checkTokenExpiration
 * @description Check if the token has expired and return true if it has
 * @param {string} token - user's token
 * @returns {boolean} - true if the token has expired
 */
export const checkTokenExpiration = (token = localStorage.getItem('token')) => {
  try {
    if (!token) {
      return true;
    }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Token is expired');
    return true; // Assume the token is invalid/expired if an error occurs
  }
};
/**
 * areAllCreditCardsExpired
 * @description Check if all the user's credit cards are expired. returns true if all cards are expired
 * @param {Array} paymentMethods - user's payment methods
 * @returns {boolean} - true if all cards are expired
 */
export const areAllCreditCardsExpired = (paymentMethods) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  if (!paymentMethods || paymentMethods.length === 0) {
    return false;
  }
  return paymentMethods.every((paymentMethod) => {
    const { exp_year, exp_month } = paymentMethod;
    return (
      exp_year < currentYear ||
      (exp_year === currentYear && exp_month < currentMonth)
    );
  });
};
/**
 * isCardAboutToExpire
 * @description Check if any of the user's cards are about to expire within the next 2 months. returns true if any card is about to expire
 * @param {Array} paymentMethods - user's payment methods
 * @returns {boolean} - true if any card is about to expire
 */
export const isCardAboutToExpire = (paymentMethods) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  if (!paymentMethods || paymentMethods.length === 0) {
    return false;
  }
  return paymentMethods.some(({ exp_year, exp_month }) => {
    if (exp_year === currentYear + 1 && currentMonth >= 11) {
      return exp_month <= (currentMonth + 2) % 12;
    }
    return (
      exp_year === currentYear &&
      exp_month <= currentMonth + 2 &&
      exp_month >= currentMonth
    );
  });
};
/**
 * sanitizeEmail
 * @description Check if the email is a gmail account and remove periods and anything after the +sign if it is
 * @param {string} email - user's email
 * @returns {string} - email with periods and + signs removed if it is a gmail account otherwise the original email
 */
export const sanitizeEmail = (() => {
  let isWhitelistFetched = false;
  let whitelistEmails = [];
  const getWhitelist = async () => {
    if (!isWhitelistFetched) {
      const { data, error } = await supabase.from('allowed').select('*');
      if (error) {
        console.error('Error getting whitelist: ', error);
      } else {
        whitelistEmails = data.map((item) => item.email);
        isWhitelistFetched = true;
      }
    }
  };
  const checkForPeriods = (email) => {
    if (email.toLowerCase().includes('@gmail.com')) {
      const [localPart, domainPart] = email.split('@');
      const sanitizedLocalPart = localPart.replace(/\./g, '');
      return `${sanitizedLocalPart}@${domainPart}`;
    }
    return email;
  };
  const checkForPlusSign = (email) => {
    const domains = [
      'gmail.com',
      'outlook.com',
      'hotmail.com',
      'yahoo.com',
      'fastmail.com',
      'protonmail.com',
      'zohomail.com',
      'icloud.com',
      'runbox.com',
      'posteo.de',
      'tutanota.com',
      'mailbox.org',
    ];
    const domainRegex = new RegExp(`@(${domains.join('|')})$`, 'i');
    if (email.includes('+') && domainRegex.test(email)) {
      const [localPart, domainPart] = email.split('@');
      const sanitizedLocalPart = localPart.split('+')[0];
      return `${sanitizedLocalPart}@${domainPart}`;
    }
    return email;
  };
  const checkForHyphen = (email) => {
    if (email.toLowerCase().includes('@yahoo.com') && email.includes('-')) {
      const [localPart, domainPart] = email.split('@');
      const sanitizedLocalPart = localPart.split('-')[0];
      return `${sanitizedLocalPart}@${domainPart}`;
    }
    return email;
  };
  return async (email) => {
    await getWhitelist();
    if (typeof email !== 'string') {
      return email;
    }
    let cleanEmail = email;
    cleanEmail = checkForPeriods(cleanEmail);
    cleanEmail = checkForPlusSign(cleanEmail);
    cleanEmail = checkForHyphen(cleanEmail);
    return whitelistEmails.includes(email) ? email : cleanEmail;
  };
})();

export async function checkIfUsingBannedEmailsNoApi(email) {
  // try {
  //   const res = await axios.get(
  //     `https://disposable.debounce.io/?email=${email}`,
  //   );
  //   if (res.data.disposable === 'true') {
  //     console.log('Email is disposable');
  //     return Promise.resolve(true);
  //   }
  // } catch (error) {
  //   console.error('Error checking if email is disposable: ', error);
  // }
  const bannedDomains = [
    'yogirt.com',
    'bizatop.com',
    'hidelux.com',
    'gufum.com',
    'sfpixel.com',
    'affecting.org',
    'alilot.com',
    'arxxwalls.com',
    'awgarstone.com',
    'baybabes.com',
    'biojuris.com',
    'bugfoo.com',
    'candassociates.com',
    'canfga.org',
    'carpin.org',
    'cutradition.com',
    'desertsundesigns.com',
    'diginey.com',
    'effobe.com',
    'emergentvillage.org',
    'eurokool.com',
    'exelica.com',
    'frederictonlawyer.com',
    'hldrive.com',
    'inilogic.com',
    'in.mail.tm',
    'internetkeno.com',
    'jcnorris.com',
    'karenkey.com',
    'knowledgemd.com',
    'leadwizzer.com',
    'logicstreak.com',
    'maxresistance.com',
    'metalunits.com',
    'midiharmonica.com',
    'nightorb.com',
    'northsixty.com',
    'nucleant.org',
    'pretreer.com',
    'puabook.com',
    'pussport.com',
    'scpulse.com',
    'sinaite.net',
    'solarunited.net',
    'solarunited.org',
    'spacehotline.com',
    'supersave.net',
    'triots.com',
    'trythe.net',
    'uniromax.com',
    'valanides.com',
    'wireconnected.com',
    'workingtall.com',
    'mailinator.com',
    '10minutemail.com',
    'guerrillamail.com',
    'throwawaymail.com',
    'yopmail.com',
    'temp-mail.org',
    'fakemailgenerator.com',
    'trash-mail.com',
    'mintemail.com',
    'spamgourmet.com',
    'maildrop.cc',
    'burnermail.io',
    'pirolsnet.com',
    'waterisgone.com',
    'rfcdrive.com',
    'tidissajiiu.com',
    'rentforsale7.com',
    'zlorkun.com',
    'tippabble.com',
    'pirolsnet.com',
    'dygovil.com',
  ];
  const domain = email.split('@')[1];
  return new Promise((resolve) => {
    resolve(bannedDomains.includes(domain));
  });
}

/**
 * checkForEnterpriseEarlyAccess
 * @description Check if the email is in the enterprise early access list
 * @param {string} email - user's email. can also be a domain
 * @returns {boolean} - true if the email is in the enterprise early access list
 */
export const hasEnterpriseEarlyAccess = (() => {
  let isEnterpriseListFetched = false;
  let enterpriseList = [];
  const getEnterpriseList = async () => {
    if (!isEnterpriseListFetched) {
      const { data, error } = await supabase
        .from('enterprise_early_access')
        .select('*');
      if (error) {
        console.error('Error getting enterprise list: ', error);
      } else {
        enterpriseList = data.map((item) => item.email);
        isEnterpriseListFetched = true;
      }
    }
  };
  return async (email) => {
    // the enterprise list contains full email addresses and also domains. if there is an entry that just says : "@gmail.com" it means that any email from this domain is automatically in the list. otherwise, the full email address is checked.
    await getEnterpriseList();
    if (typeof email !== 'string') {
      return email;
    }
    return (
      enterpriseList.includes(email) ||
      enterpriseList.includes(`@${email.split('@')[1]}`)
    );
  };
})();
/**
 * addEnterpriseEarlyAccess
 * @description Add the email to the enterprise early access list
 * @param {string} email - user's email
 * @param {string} feature - name of the feature to add the email to
 * @returns {Promise} - promise that resolves if the email was added successfully
 */
export const addEnterpriseEarlyAccess = ({
  email,
  feature = 'enterprise_early_access',
}) => {
  return new Promise((resolve, reject) => {
    supabase
      .from(feature)
      .insert([{ email }])
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error('Error adding to enterprise early access list: ', error);
        reject(error);
      });
  });
};
/**
 * getWhitelist
 * @description Get the whitelist from the database
 * @returns {Promise} - promise that resolves with the whitelist
 */
export const getWhitelist = (feature = 'enterprise_early_access') => {
  return new Promise((resolve, reject) => {
    supabase
      .from(feature)
      .select('*')
      .then((res) => {
        resolve(res.data.map((item) => item.email));
      })
      .catch((error) => {
        console.error('Error getting whitelist: ', error);
        reject(error);
      });
  });
};
/**
 * removeEnterpriseEarlyAccess
 * @description Remove the email from the enterprise early access list
 * @param {string} email - user's email
 * @param {string} feature - name of the feature to remove the email from
 * @returns {Promise} - promise that resolves if the email was removed successfully
 */
export const removeEnterpriseEarlyAccess = ({
  email,
  feature = 'enterprise_early_access',
}) => {
  return new Promise((resolve, reject) => {
    supabase
      .from(feature)
      .delete()
      .eq('email', email)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error(
          'Error removing from enterprise early access list: ',
          error,
        );
        reject(error);
      });
  });
};

// function to sve rejected users to the database. takes a user object with first_name, last_name, email
/**
 * saveRejectedUser - save the rejected user to the database
 * @param {object} user - user object with first_name, last_name, email
 * @returns {Promise} - promise that resolves if the user was saved successfully
 */
export const saveRejectedUser = (user) => {
  return new Promise((resolve, reject) => {
    supabase
      .from('failed_signups')
      .insert([user])
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        console.error('Error saving rejected user: ', error);
        reject(error);
      });
  });
};

/**
 * getNewToken
 * @description Get a new token using the refresh token
 * @param {string} refreshToken - user's refresh token
 * @returns {string} - new token
 */
export const getNewToken = async (refreshToken) => {
  try {
    const response = await axios.post(
      `${import.meta.env.VITE_FRONTEND_SERVER_URL}/refresh-token`,
      {
        token: refreshToken,
      },
    );
    return response.data.newToken;
  } catch (error) {
    throw new Error('Unable to refresh token');
  }
};
