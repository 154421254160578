const Pagination = ({ pageCount, currentPage, onPageChange }) => {
  // Function to generate page range
  const getPageRange = () => {
    if (pageCount <= 5) {
      return [...Array(pageCount).keys()].map((x) => x + 1);
    } else {
      // Dynamic range calculation
      const startPages = [1, 2];
      const endPages = [pageCount - 1, pageCount];
      const offsets = [currentPage - 1, currentPage, currentPage + 1].filter(
        (page) => page > 2 && page < pageCount - 1,
      );
      let pages = [...new Set([...startPages, ...offsets, ...endPages])].sort(
        (a, b) => a - b,
      );

      // Insert ellipses where there are gaps
      let lastPage = null;
      const withEllipses = [];
      pages.forEach((page) => {
        if (lastPage && page - lastPage > 1) {
          withEllipses.push('...');
        }
        withEllipses.push(page);
        lastPage = page;
      });

      return withEllipses;
    }
  };

  // Render pagination items
  return (
    <ul className="inline-flex items-center">
      <li>
        <button
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
          className="px-3 py-2 mx-1 text-sm leading-tight text-gray-700 bg-white rounded-md dark:bg-gray-700 dark:text-white"
        >
          {'<'}
        </button>
      </li>
      {getPageRange().map((pageNum, idx) => (
        <li key={idx}>
          {pageNum === '...' ? (
            <span className="px-3 py-2 mx-1">...</span>
          ) : (
            <button
              onClick={() => onPageChange(pageNum)}
              className={`px-3 py-2 mx-1 text-sm leading-tight ${
                currentPage === pageNum
                  ? 'bg-indigo-700 text-white'
                  : 'bg-white text-gray-700 dark:bg-gray-700 dark:text-white'
              } rounded-md`}
            >
              {pageNum}
            </button>
          )}
        </li>
      ))}
      <li>
        <button
          onClick={() => onPageChange(Math.min(pageCount, currentPage + 1))}
          disabled={currentPage === pageCount}
          className="px-3 py-2 mx-1 text-sm leading-tight text-gray-700 bg-white rounded-md dark:bg-gray-700 dark:text-white"
        >
          {'>'}
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
