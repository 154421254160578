import React from 'react';

const TandC = () => {
  return (
    <div className="my-12 font-sans max-w-[1500px] mx-auto px-4">
      <strong id="terms-of-service">Terms of Service</strong>
      <br />
      <br />
      Last updated: 6 March 2024
      <br />
      <br />
      <p>
        This Terms of Service Agreement (“<strong>Agreement</strong>”)
        constitutes a legally binding agreement made between you, whether
        personally or on behalf of an entity (“<strong>user</strong>” or “
        <strong>you</strong>”) and Tromero Ltd. (collectively, “
        <strong>Company</strong>” or “<strong>we</strong>” or “
        <strong>us</strong>” or “<strong>our</strong>”), concerning your access
        to and use of the Tromero service as well as any other media form, media
        channel, mobile website or mobile application related or connected
        thereto (collectively, the “<strong>Website</strong>”). The Website
        provides the following service: rental by users of hosting services (“
        <strong>User</strong>”) from independent providers (“
        <strong>Provider</strong>”), and software services allowing a user to
        act as an independent provider and get paid for doing so (“
        <strong>Company Services</strong>”). Supplemental terms and conditions
        or documents that may be posted on the Website from time to time, are
        hereby expressly incorporated into this Agreement by reference.
      </p>
      <br />
      <p>
        All engagement with the Company Services is through the Website.
        Generally, the Company does not permit direct engagement between Users
        and Providers. The Company may offer communication via Discord on or
        through the Website, but any such use of Discord is subject to its own
        terms and conditions. We do not monitor these communications and are not
        responsible for the content or tone of those communications.
      </p>
      <br />
      <p>
        <strong>
          NOTICE: THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION AGREEMENT. YOU
          AGREE THAT ANY CLAIMS YOU MAY HAVE AGAINST US RELATING TO THE WEBSITE
          OR THE COMPANY SERVICES, THIS AGREEMENT OR ANY TERMS AND CONDITIONS
          CONTAINED HEREIN MUST BE ARBITRATED AS PROVIDED IN CLAUSE 15.2 BELOW,
          AND YOU EXPRESSLY WAIVE THE RIGHT TO (1) ASSERT CLAIMS AGAINST US IN
          COURT; (2) PARTICIPATE IN A REPRESENTATIVE OR CLASS ACTION; AND (3)
          HAVE A JURY HEAR YOUR CASE. YOU EXPRESSLY CONSENT TO HAVE ALL OF YOUR
          CLAIMS ARBITRATED ON AN INDIVIDUAL BASIS ONLY. IF YOU DO NOT AGREE TO
          BE SO BOUND, YOU MAY NOT ACCESS OR USE THE WEBSITE OR ANY COMPANY
          SERVICES.
        </strong>
      </p>
      <br />
      <p>
        Company reserves the right, at its own discretion, to make changes or
        modifications by updating this Agreement from time to time. We will
        alert you regarding any changes by updating the “Last updated” date of
        this Agreement, and you waive any right to receive specific notice of
        each such change. You will be subject to, and will be deemed to have
        been made aware of, and to have accepted the changes to the Agreement by
        your continued use of the Website after the date such revised Agreement
        has been posted.
      </p>
      <br />
      <p>
        Company makes no representation that the Website is appropriate or
        available in other locations other than where it is operated by Company.
        The information provided on the Website is not intended for distribution
        to or use by any person or entity in any jurisdiction or country where
        such distribution or use would be contrary to law or regulation or which
        would subject Company to any registration requirement within such
        jurisdiction or country. Accordingly, those persons who choose to access
        the Website from other locations do so on their own initiative and are
        solely responsible for compliance with local laws, if and to the extent
        local laws are applicable.
      </p>
      <br />
      <p>
        All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Website. If
        you are a minor, you must have your parent or guardian read and agree to
        this Agreement prior to you using the Website. Persons under the age of
        13 are not permitted to register for the Website or use the Company
        Services.
      </p>
      <br />
      <p>
        YOU ACCEPT AND AGREE TO BE BOUND BY THIS AGREEMENT BY ACKNOWLEDGING SUCH
        ACCEPTANCE DURING THE REGISTRATION PROCESS (IF APPLICABLE) AND ALSO BY
        CONTINUING TO USE THE WEBSITE. IF YOU DO NOT AGREE TO ABIDE BY THIS
        AGREEMENT, OR TO MODIFICATIONS THAT COMPANY MAY MAKE TO THIS AGREEMENT
        IN THE FUTURE, DO NOT USE OR ACCESS OR CONTINUE TO USE OR ACCESS THE
        COMPANY SERVICES OR THE WEBSITE.
      </p>
      <br />
      <strong id="account-purchases-payment">
        1. ACCOUNT; PURCHASES; PAYMENT
      </strong>
      <p>
        1.1 Account Registration. If you create an account, you must provide us
        with complete and accurate information. You must promptly update such
        information to keep it complete and accurate. You are entirely
        responsible for maintaining the confidentiality of your password and
        account. You are entirely responsible for any and all activities that
        occur under your account. You may not use anyone else’s account at any
        time. We may remove or reclaim your username if we believe it is
        appropriate (such as in response to a trademark claim).
      </p>
      <p>
        1.2 Security of Your Account. You agree to notify Company immediately of
        any unauthorised use of your account or any other breach of security. We
        will not be liable for any loss, damages, liability, expenses or
        attorneys’ fees that you may incur as a result of someone else using
        your password or account, either with or without your knowledge, to the
        fullest extent permitted by applicable law. You will be liable for
        losses, damages, liability, expenses and attorneys’ fees incurred by
        Company or a third party due to someone else using your account.
      </p>
      <p>
        1.3 No Obligation to Retain a Record of Your Account. Company has no
        obligation to retain a record of your account or any data or information
        that you may have stored for your convenience by means of your account
        or the Company Services.
      </p>
      <p>
        1.4 Payment. Company bills you through an online billing account for
        purchases of products and/or services. The currency used for payments is
        the US dollar, all other currencies displayed in Website are for display
        purposes only. You agree to pay Company all charges at the prices then
        in effect for the products you or other persons using your billing
        account may purchase, and you authorise Company to charge your chosen
        payment provider for any such purchases. You agree to make payment using
        that selected payment method. If you have ordered a product or service
        that is subject to recurring charges then you consent to our charging
        your payment method on a recurring basis, without requiring your prior
        approval from you for each recurring charge until such time as you
        cancel the applicable product or service. Company reserves the right to
        correct any errors or mistakes in pricing that it makes even if it has
        already requested or received payment. Sales tax will be added to the
        sales price of purchases as deemed required by Company. Company may
        change prices at any time.{' '}
      </p>
      <p>
        1.5 Purchases. The Website sets forth other terms and conditions
        regarding the purchase of credits from the Company for the Company
        Services via the Website, as well as during the sign-up and account
        registration process. Those terms and conditions are incorporated into
        this Agreement by reference. We reserve the right to change credit
        pricing, run promotions and cause credits to expire. We will post any
        such changes to our Website. Company only offers credits not pricing
        offered by Providers. If you have any specific questions or issues
        regarding credits you have purchased, please email us at{' '}
        <a href="mailto:info@tromero.ai"></a>
        info@tromero.ai
      </p>
      <br />
      <strong id="refund-policy">2. REFUND POLICY</strong>
      <p>
        We will only offer refunds under exceptional circumstances pending
        review and agreement post contact with us.
      </p>
      <br />
      <strong id="user-representations">3. USER REPRESENTATIONS</strong>
      <p>
        3.1 By registering and using the Company Services, you represent and
        warrant that:
      </p>
      <ol>
        <li>
          i. All registration information you submit is current, truthful,
          accurate, and complete;
        </li>
        <li>ii. You will maintain the accuracy of such information;</li>
        <li>
          iii. You will keep your password confidential and will be responsible
          for all use of your password and account;
        </li>
        <li>
          iv. You are not a minor in the jurisdiction in which you reside, or if
          a minor, you have received parental permission to use this Website;
          and
        </li>
        <li>
          v. Your use of the Company Services does not violate any applicable
          law or regulation.
        </li>
      </ol>
      <p>
        3.2 You also agree to: (a) provide true, accurate, current and complete
        information about yourself as prompted by the Website’s registration
        form and (b) maintain and promptly update registration data to keep it
        true, accurate, current and complete. If you provide any information
        that is untrue, inaccurate, not current or incomplete, or Company has
        reasonable grounds to suspect that such information is untrue,
        inaccurate, not current or incomplete, Company has the right to suspend
        or terminate your account and refuse any and all current or future use
        of the Website (or any portion thereof).
      </p>
      <p>
        3.3 We reserve the right to remove or reclaim or change a user name you
        select if we determine appropriate in our discretion, such as when the
        user name is obscene or otherwise objectionable or when a trademark
        owner complains about a username that does not closely relate to a
        user&#39;s actual name.
      </p>
      <br />
      <strong id="guidelines-for-reviews">4. GUIDELINES FOR REVIEWS</strong>
      <p>
        Company may accept, reject or remove reviews in its sole discretion.
        Company has absolutely no obligation to screen reviews or to delete
        reviews, even if anyone considers reviews objectionable or inaccurate.
        Those persons posting reviews should comply with the following criteria:
        (1) reviewers should have firsthand experience with the person/entity
        being reviewed; (2) reviews should not contain: offensive language,
        profanity, or abusive, racist, or hate language; discriminatory
        references based on religion, race, gender, national origin, age,
        marital status, sexual orientation or disability; or references to
        illegal activity; (3) reviewers should not be affiliated with
        competitors if posting negative reviews; (4) reviewers should not make
        any conclusions as to the legality of conduct; and (5) reviewers may not
        post any false statements or organize a campaign encouraging others to
        post reviews, whether positive or negative. Reviews are not endorsed by
        Company, and do not represent the views of Company or of any affiliate
        or partner of Company. Company does not assume liability for any review
        or for any claims, liabilities or losses resulting from any review. By
        posting a review, the reviewer hereby grants to Company a perpetual,
        non-exclusive, worldwide, royalty-free, fully-paid, assignable and
        sublicensable license to Company to reproduce, modify, translate,
        transmit by any means, display, perform and/or distribute all content
        relating to reviews.
      </p>
      <br />
      <strong id="social-media">5. SOCIAL MEDIA</strong>
      <p>
        As part of the functionality of the Website, you may link your account
        with online accounts you may have with third party service providers
        (each such account, a “Third Party Account”) by either: (i) providing
        your Third Party Account login information through the Website; or (ii)
        allowing Company to access your Third Party Account, as is permitted
        under the applicable terms and conditions that govern your use of each
        Third Party Account. You represent that you are entitled to disclose
        your Third Party Account login information to Company and/or grant
        Company access to your Third Party Account (including, but not limited
        to, for use for the purposes described herein), without breach by you of
        any of the terms and conditions that govern your use of the applicable
        Third Party Account and without obligating Company to pay any fees or
        making Company subject to any usage limitations imposed by such third
        party service providers. By granting Company access to any Third Party
        Accounts, you understand that (i) Company may access, make available and
        store (if applicable) any content that you have provided to and stored
        in your Third Party Account (the “Social Network Content”) so that it is
        available on and through the Website via your account, including without
        limitation any friend lists, and (ii) Company may submit and receive
        additional information to your Third Party Account to the extent you are
        notified when you link your account with the Third Party Account.
        Depending on the Third Party Accounts you choose and subject to the
        privacy settings that you have set in such Third Party Accounts,
        personally identifiable information that you post to your Third Party
        Accounts may be available on and through your account on the Website.
        Please note that if a Third Party Account or associated service becomes
        unavailable or Company’s access to such Third Party Account is
        terminated by the third party service provider, then Social Network
        Content may no longer be available on and through the Website. You will
        have the ability to disable the connection between your account on the
        Website and your Third Party Accounts at any time. PLEASE NOTE THAT YOUR
        RELATIONSHIP WITH THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
        THIRD PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
        THIRD PARTY SERVICE PROVIDERS. Company makes no effort to review any
        Social Network Content for any purpose, including but not limited to,
        for accuracy, legality or non-infringement, and Company is not
        responsible for any Social Network Content. You acknowledge and agree
        that Company may access your e-mail address book associated with a Third
        Party Account and your contacts list stored on your mobile device or
        tablet computer solely for the purposes of identifying and informing you
        of those contacts who have also registered to use the Website. At your
        request made via email to our email address listed below, or through
        your account settings (if applicable), Company will deactivate the
        connection between the Website and your Third Party Account and delete
        any information stored on Company’s servers that was obtained through
        such Third Party Account, except the username and profile picture that
        become associated with your account.
      </p>
      <ol>
        <br />
        <li>
          <strong>6. SUBMISSIONS</strong>
        </li>
      </ol>
      <p>
        6.1 By sharing, submitting or uploading any questions, comments,
        reviews, templates, suggestions, ideas, feedback or other information
        about the Website, Providers or the Company Services (“Submissions”),
        you grant Company a worldwide, perpetual, irrevocable, non-exclusive,
        royalty-free, sublicensable and transferable license to use, copy,
        distribute, display, publish, perform, sell, transmit, adapt, translate,
        modify, reverse-engineer, disassemble and create derivative works from
        your Submissions in any manner existing now or created in the future.
        Subject to the license above, you retain ownership of Submissions.
      </p>
      <p>
        6.2 You irrevocably waive and agree not to assert any rights, including
        any “moral rights,” that you have to prevent us from exploiting the
        rights granted in this Section.
      </p>
      <p>
        6.3 You also grant us the right to use and display the name, photograph
        and any other biographical information that you submit with any
        Submission to provide the Company Services.
      </p>
      <p>
        6.4 You acknowledge and agree that you are solely responsible for all
        the Submissions that you make available through the Website.
        Accordingly, you represent and warrant that: (1) you have all rights,
        licenses, consents, and releases necessary to grant Company the required
        rights to disseminate any Submissions and (2) neither your Submissions
        nor your posting, uploading, publication, submission or transmittal of
        these Submissions or Company’s use of your uploaded Submissions (or any
        portion thereof) on, through or by the means of the Website, will
        infringe, misappropriate or violate a third party’s patent, copyright,
        trademark, trade secret, moral rights or other intellectual property
        rights or rights of publicity or privacy or result in the violation of
        any applicable law or regulation. You assume all risks and liability
        associated with your Submissions, including any third party or User’s
        reliance on its quality, accuracy, or reliability.
      </p>
      <p>6.5 More specifically, and without limitation, you shall not:</p>
      <p>
        i. Upload, or otherwise make available, files that contain images,
        photographs, software or other material protected by intellectual
        property laws, including, by way of example, and not as limitation,
        copyright or trademark laws (or by rights of privacy or publicity)
        unless you own or control the rights thereto or have received all
        necessary consents to do the same.
      </p>
      <p>
        ii. Defame, abuse, harass, stalk, threaten or otherwise violate any
        legal rights (including the rights of privacy and publicity) of others.
      </p>
      <p>
        iii. Upload, post, email, transmit, or otherwise make available any
        unlawful, inappropriate, defamatory, obscene, pornographic, vulgar,
        offensive, fraudulent, false, misleading or deceptive content or
        message; or
      </p>
      <p>
        iv. Promote or engage in discrimination, bigotry, racism, hated or
        harassment against any individual or group.
      </p>
      <p>
        We will not have any obligation to hold any Submissions in confidence or
        compensate you or anyone else for their use. We have the right to remove
        or edit any Submission for any reason or no reason at all. We do not
        endorse any Submission submitted to the Website by any user or other
        licensor, or any opinion, recommendation, or advice expressed therein.
      </p>
      <br />
      <strong id="prohibited-activities">7. PROHIBITED ACTIVITIES</strong>
      <p>
        You may not access or use the Website for any other purpose other than
        that for which Company makes it available. Prohibited activity includes,
        but is not limited to:
      </p>
      <ol>
        <li>
          i. Attempting to bypass any measures of the Website designed to
          prevent or restrict access to the Website, or any portion of the
          Website;
        </li>
        <li>
          ii. Attempting to impersonate another user or person or using the
          username of another user;
        </li>
        <li>iii. Criminal or tortious activity;</li>
        <li>
          iv. Deciphering, decompiling, disassembling or reverse engineering any
          of the software comprising or in any way making up a part of the
          Website;
        </li>
        <li>
          v. Interfering with, disrupting, or creating an undue burden on the
          Website or the networks or services connected to the Website;
        </li>
        <li>
          vi. Making any unauthorised use of the Company Services, including
          collecting usernames and/or email addresses of users by electronic or
          other means for the purpose of sending unsolicited email, or creating
          user accounts by automated means or under false pretenses;
        </li>
        <li> vii. Selling or otherwise transferring your profile;</li>
        <li>
          viii. Systematic retrieval of data or other content from the Website
          to create or compile, directly or indirectly, a collection,
          compilation, database or directory without written permission from
          Company;
        </li>
        <li>
          ix. Tricking, defrauding or misleading Company and other users,
          especially in any attempt to learn sensitive account information such
          as passwords;
        </li>
        <li>
          x. Using any information obtained from the Website in order to harass,
          abuse, or harm another person;
        </li>
        <li>
          xi. Using the Company Services as part of any effort to compete with
          Company or to provide services as a service bureau;
        </li>
        <li>
          xii. Circumventing, disabling, or otherwise interfering with
          security-related features of the Website, including features that
          prevent or restrict the use or copying of any content or enforce
          limitations on the use of the Website and/or the content contained
          therein;
        </li>
        <li>
          xiii. Uploading or transmitting (or attempting to upload or to
          transmit) viruses, Trojan horses, or other material, including
          excessive use of capital letters and spamming (continuous posting of
          repetitive text), that interferes with any party’s uninterrupted use
          and enjoyment of the Site or modifies, impairs, disrupts, alters, or
          interferes with the use, features, functions, operation, or
          maintenance of the Website;
        </li>
        <li>xiv. Violate any law, rule, or regulation.</li>
        <li>
          xv. Using the Website in a manner inconsistent with any and all
          applicable laws and regulations;
        </li>
        <li>
          xvi. Violate, misappropriate or infringe a third party’s intellectual
          property or other right, or any social media platform terms or;
        </li>
        <li>
          xvii. Interfere with any third party’s ability to use or enjoy, or our
          ability to provide, the Website or any services.
        </li>
      </ol>
      <br />
      <strong id="intellectual-property-rights">
        8. INTELLECTUAL PROPERTY RIGHTS
      </strong>
      <p>
        8.1 The content on the Website (“<strong>Company Content</strong>”) and
        the trademarks, service marks and logos contained therein (“
        <strong>Marks</strong>”) are owned by or licensed to Company, and are
        subject to copyright and other intellectual property rights under United
        Kingdom and foreign laws and international conventions. Company Content,
        includes, without limitation, all source code, databases, functionality,
        software, website designs, audio, video, text, photographs and graphics.
        All Company graphics, logos, designs, page headers, button icons,
        scripts and service names are registered trademarks, common law
        trademarks or trade dress of Company in the United States and/or other
        countries. Company&#39;s trademarks and trade dress may not be used,
        including as part of trademarks and/or as part of domain names, in
        connection with any product or service in any manner that is likely to
        cause confusion and may not be copied, imitated, or used, in whole or in
        part, without the prior written permission of the Company.
      </p>
      <p>
        8.2 Company Content on the Website is provided to you “AS IS” for your
        information and personal use only and may not be used, copied,
        reproduced, aggregated, distributed, transmitted, broadcast, displayed,
        sold, licensed, or otherwise exploited for any other purposes whatsoever
        without the prior written consent of the respective owners. Provided
        that you are eligible to use the Website, you are granted a limited
        license to access and use the Website and the Company Content and to
        download or print a copy of any portion of the Company Content to which
        you have properly gained access solely for your personal, non-
        commercial use. Company reserves all rights not expressly granted to you
        in and to the Website and Company Content and Marks.
      </p>
      <br></br>
      <strong id="third-party-websites-and-content">
        9. THIRD PARTY WEBSITES AND CONTENT
      </strong>
      <p>
        The Website contains (or you may be sent through the Website or the
        Company Services) links to other websites (&quot;
        <strong>Third Party Websites</strong>&quot;) as well as articles,
        photographs, text, graphics, pictures, designs, music, sound, video,
        information, applications, software and other content or items belonging
        to or originating from third parties (the &quot;
        <strong>Third Party Content</strong>&quot;). Such Third Party Websites
        and Third Party Content are not investigated, monitored or checked for
        accuracy, appropriateness, or completeness by us, and we are not
        responsible for any Third Party Websites accessed through the Website or
        any Third Party Content posted on, available through or installed from
        the Website, including the content, accuracy, offensiveness, opinions,
        reliability, privacy practices or other policies of or contained in the
        Third Party Websites or the Third Party Content. Inclusion of, linking
        to or permitting the use or installation of any Third Party Website or
        any Third Party Content does not imply approval or endorsement thereof
        by us. If you decide to leave the Website and access the Third Party
        Websites or to use or install any Third Party Content, you do so at your
        own risk and you should be aware that our terms and policies no longer
        govern. You should review the applicable terms and policies, including
        privacy and data gathering practices, of any website to which you
        navigate from the Website or relating to any applications you use or
        install from the Website. Any purchases you make through Third Party
        Websites will be through other websites and from other companies, and
        Company takes no responsibility whatsoever in relation to such purchases
        which are exclusively between you and the applicable third party.
      </p>
      <br />
      <strong id="site-management">10. SITE MANAGEMENT</strong>
      <p>Company reserves the right but does not have the obligation to:</p>
      <ol>
        <li>i. Monitor the Website for violations of this Agreement;</li>
        <li>
          ii. Take appropriate legal action against anyone who, in Company’s
          sole discretion, violates this Agreement, including without
          limitation, reporting such user to law enforcement authorities;
        </li>
        <li>
          iii. In Company’s sole discretion and without limitation, refuse,
          restrict access to or availability of, or disable (to the extent
          technologically feasible) any user’s contribution or any portion
          thereof that may violate this Agreement or any Company policy;
        </li>
        <li>
          iv. In Company’s sole discretion and without limitation, notice or
          liability to remove from the Website or otherwise disable all files
          and content that are excessive in size or are in any way burdensome to
          Company’s systems; and
        </li>
        <li>
          v. Otherwise manage the Website in a manner designed to protect the
          rights and property of Company and others and to facilitate the proper
          functioning of the Website.
        </li>
      </ol>
      <br />
      <strong id="privacy-policy">11. PRIVACY POLICY</strong>
      <p>
        We care about the privacy of our users. Please review the{' '}
        <strong>
          <a href="https://tromero.org/policy">Company Privacy Policy</a>
        </strong>
        . By using the Website or Company Services, you are consenting to the
        terms of our Privacy Policy.
      </p>
      <br />
      <strong id="term-and-termination">12. TERM AND TERMINATION</strong>
      <p>
        12.1 This Agreement shall remain in full force and effect while you use
        the Website or are otherwise a user or member of the Website, as
        applicable. You may terminate your use or participation at any time, for
        any reason, by following the instructions for terminating user accounts
        in your account settings, if available, or by contacting us using the
        contact information below.
      </p>
      <p>
        12.2 WITHOUT LIMITING ANY OTHER PROVISION OF THIS AGREEMENT, COMPANY
        RESERVES THE RIGHT TO, IN COMPANY’S SOLE DISCRETION AND WITHOUT NOTICE
        OR LIABILITY, DENY ACCESS TO AND USE OF THE WEBSITE AND THE COMPANY
        SERVICES, TO ANY PERSON FOR ANY REASON OR FOR NO REASON AT ALL,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY
        OR COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE LAW OR
        REGULATION, AND COMPANY MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
        WEBSITE AND THE COMPANY SERVICES, DELETE YOUR PROFILE AND ANY CONTENT OR
        INFORMATION THAT YOU HAVE POSTED AT ANY TIME, WITHOUT WARNING, IN
        COMPANY’S SOLE DISCRETION.
      </p>
      <p>
        12.3 In order to protect the integrity of the Website and Company
        Services, Company reserves the right at any time in its sole discretion
        to block certain IP addresses from accessing the Website and Company
        Services.
      </p>
      <p>
        12.4 Any provisions of this Agreement that, in order to fulfill the
        purposes of such provisions, need to survive the termination or
        expiration of this Agreement, shall be deemed to survive for as long as
        necessary to fulfill such purposes.
      </p>
      <p>
        12.5 If Company terminates or suspends your account for any reason, you
        are prohibited from registering and creating a new account under your
        name, a fake or borrowed name, or the name of any third party, even if
        you may be acting on behalf of the third party. In addition to
        terminating or suspending your account, Company reserves the right to
        take appropriate legal action, including without limitation pursuing
        civil, criminal, and injunctive redress.
      </p>
      <br />
      <strong id="modifications">13. MODIFICATIONS</strong>
      <ol>
        <li>
          13.1 To Agreement. Company may modify this Agreement from time to
          time. Any and all changes to this Agreement will be posted on the
          Website and revisions will be indicated by date. You agree to be bound
          to any changes to this Agreement when you use the Company Services
          after any such modification becomes effective. Company may also, in
          its discretion, choose to alert all users with whom it maintains email
          information of such modifications by means of an email to their most
          recently provided email address. It is therefore important that you
          regularly review this Agreement and keep your contact information
          current in your account settings to ensure you are informed of
          changes. You agree that you will periodically check the Website for
          updates to this Agreement and you will read the messages we send you
          to inform you of any changes. Modifications to this Agreement shall be
          effective after posting.
        </li>
        <li>
          13.2 To Services. Company cannot guarantee the Website and that
          Company Services will be always available. Company may experience
          hardware, software, or other problems or need to perform maintenance
          related to the Website, resulting in interruptions, delays, or errors.
          Company reserves the right at any time to change, revise, update,
          suspend, discontinue, or otherwise modify the Website or the offerings
          at any time or for any reason without notice to you. You agree that
          Company shall have no liability whatsoever for any loss, damage, or
          inconvenience caused by your inability to access or use the Website or
          the Company Services during any downtime or discontinuance of the
          Website or the Company Services.
        </li>
        <li>
          <br />
          <strong>14. OPERATIONS AND SERVICE</strong>
        </li>
        <li>
          14.1 Provider and User shall use the hardware and software in a
          careful and proper manner, in accordance with all applicable
          manufacturer, developers and/or vendor specifications, solely in the
          conduct of its business, and will comply with and conform to all
          federal, state or local laws, ordinances and regulations relating to
          the possession, use, and maintenance of the Hardware.
        </li>
        <li>
          14.2 Provider shall provide preventative maintenance for the hardware
          during regular business hours, as applicable, and shall furnish any
          and all parts or updates required to keep the hardware in good
          mechanical, operational and/or working order for Company Services.
        </li>
        <li>
          14.3 Access. Provider agrees to give Company reasonable access to the
          Hardware during regular business hours to provide all Company Services
          provided hereunder. Provider further agrees to provide Company access
          to additional information, and resources as may be necessary for
          Company to provide the Company Services contemplated.
        </li>
        <li>
          14.4 Logs. Providers must delete any error logs or troubleshooting
          data within 24 hours.
        </li>
        <li>
          14.5 Downloading, Storing, or Printing. DOWNLOADING, STORING,
          REVIEWING, PRINTING, OR SAVING OF THE USER’S DATA IS STRICTLY
          PROHIBITED. IN THE EVENT THAT THE AUTHORIZED USER’S DATA MUST BE
          STORED TEMPORARILY, IT SHALL NOT BE STORED FOR A LONGER PERIOD THAN
          NECESSARY TO CARRY OUT THE SERVICES.
        </li>
        <li>
          14.6 Data Breach Liability. If Provider is subject to any claims
          relating to any data breaches or security incidents, Provider shall
          indemnify and hold harmless Company and defend against any such
          claims, including reimbursement of any costs incurred by Company
          relating to those claims. This obligation is in addition to any of
          Provider’s other indemnification obligations in this Agreement.
        </li>
      </ol>
      <br />
      <strong id="disputes">15. GOVERNING LAW AND DISPUTES</strong>
      <ol>
        <li>15.1 Between Users</li>
      </ol>
      <p>
        If there is a dispute between users of the Website, or between users and
        any third party, you understand and agree that Company is under no
        obligation to become involved. In the event that you have a dispute with
        one or more other users, you hereby release Company, its officers,
        employees, agents and successors in rights from claims, demands and
        damages (actual and consequential) of every kind or nature, known or
        unknown, suspected and unsuspected, disclosed and undisclosed, arising
        out of or in any way related to such disputes and/or the Company
        Services.
      </p>
      <ol>
        <li>15.2 With Company</li>
      </ol>
      <p>
        This Agreement shall shall be governed by and construed in accordance
        with the laws of England and Wales and any dispute or claim relating to
        the Website and this Agreement and any dispute or claim arising
        therefrom or related thereto shall be referred to and finally settled by
        arbitration under the London Court of International Arbitration (LCIA)
        Rules which are deemed to be incorporated by reference to this clause.
        The numbers of arbitrators shall be three. The seat of the arbitration
        shall be London, England and the language of the arbitration shall be
        English.
      </p>
      <br />
      <p>
        <strong>
          16. WAIVER
          <br /> BY AGREEING TO THIS AGREEMENT AND SUBJECT THERETO, YOU HEREBY
          IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO A COURT TRIAL (OTHER THAN
          SMALL CLAIMS COURT AS PROVIDED ABOVE) OR TO SERVE AS A REPRESENTATIVE,
          AS A PRIVATE ATTORNEY GENERAL, OR IN ANY OTHER REPRESENTATIVE
          CAPACITY, OR TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS, IN
          ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING FILED AGAINST US AND/OR
          RELATED THIRD PARTIES.
        </strong>
      </p>
      <p>
        STATUTE OF LIMITATIONS. REGARDLESS OF ANY STATUTE OR LAW TO THE
        CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO USE
        OF THE WEBSITE, COMPANY SERVICES, OR THE AGREEMENT MUST BE FILED WITHIN
        ONE (1) YEAR AFTER SUCH CLAIM OR CAUSE OF ACTION ARISES OR IT WILL BE
        FOREVER BARRED.
      </p>
      <br />
      <strong id="corrections">17. CORRECTIONS</strong>
      <p>
        Occasionally there may be information on the Website that contains
        typographical errors, inaccuracies or omissions that may relate to
        service descriptions, pricing, availability, and various other
        information. Company reserves the right to correct any errors,
        inaccuracies or omissions and to change or update the information at any
        time, without prior notice.
      </p>
      <br />
      <strong id="representations-and-warranties">
        18. REPRESENTATIONS AND WARRANTIES
      </strong>
      <ol>
        <li>
          18.1 Company represents and warrants that (i) it has the right and
          authority to enter into and perform this Agreement, including, without
          limitation to grant the rights and licenses provided for in this
          Agreement; (ii) it shall comply in all material respects with all
          applicable federal, state, and local statutes, regulations and
          ordinances in the performance of its obligations hereunder; and (iii)
          that it owns all right, title and interest in and to or has all
          necessary license rights to all hosting software provided to Provider.
        </li>
        <li>
          18.2 Provider represents and warrants that: (i) it is the sole owner
          of the hardware and Provider has the unrestricted right to lease the
          hardware to Provider as provided herein; (ii) as of the Effective
          Date, all licenses, permits, approvals and consents, if any, required
          from any governmental authority or third party for Provider to perform
          the Company Services to Company for the purposes contemplated by this
          Agreement; (iii) it shall comply in all material respects with all
          applicable federal, state, and local statutes, regulations and
          ordinances in the performance of its obligations hereunder; and (iv)
          the Company Services under this Agreement will be performed by the
          equipment and specifications disclosed on the Website.
        </li>
        <li>
          18.3 User represents and warrants that (i) it has the right and
          authority to enter into and perform this Agreement; (ii) it shall
          comply in all material respects with all applicable international,
          federal, state, and local statutes, regulations and ordinances in
          connection with its use of the Website or the Company Services; and
          (iii) it owns all right, title and interest in and to or has all
          necessary license rights to all software, materials and content to run
          on Provider’s hardware and none of the foregoing shall infringe upon,
          misappropriate or violate the rights of any third party.
        </li>
      </ol>
      <br />
      <strong id="disclaimer-of-website">19. DISCLAIMER OF WEBSITE</strong>
      <p>
        19.1 Company cannot control the nature of all of the content available
        on the Website. By operating the Website, Company does not represent or
        imply that Company endorses any blogs, contributions or other content
        available on or linked to by the Website, including without limitation
        content hosted on third party websites or provided by third party
        applications, or that Company believes contributions, blogs or other
        content to be accurate, useful or non-harmful. We do not control and are
        not responsible for unlawful or otherwise objectionable content you may
        encounter on the Website or in connection with any contributions. The
        Company is not responsible for the conduct, whether online or offline,
        of any user of the Website or Company Services.
      </p>
      <p>
        19.2 YOU AGREE THAT YOUR USE OF THE WEBSITE AND COMPANY SERVICES WILL BE
        AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, COMPANY, ITS
        OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, IN CONNECTION WITH THE WEBSITE AND THE COMPANY
        SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
        AND NON-INFRINGEMENT. COMPANY MAKES NO WARRANTIES OR REPRESENTATIONS
        ABOUT THE ACCURACY OR COMPLETENESS OF THE WEBSITE’S CONTENT OR THE
        CONTENT OF ANY WEBSITES LINKED TO THIS WEBSITE AND ASSUMES NO LIABILITY
        OR RESPONSIBILITY FOR ANY (A) ERRORS, MISTAKES, OR INACCURACIES OF
        CONTENT AND MATERIALS, (B) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
        NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE,
        (C) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
        AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
        THEREIN, (D) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
        THE WEBSITE OR COMPANY SERVICES, (E) ANY BUGS, VIRUSES, TROJAN HORSES,
        OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY
        THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
        MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
        THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
        VIA THE WEBSITE. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
        RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
        THIRD PARTY THROUGH THE WEBSITE OR ANY HYPERLINKED WEBSITE OR FEATURED
        IN ANY BANNER OR OTHER ADVERTISING, AND COMPANY WILL NOT BE A PARTY TO
        OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
        AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE
        OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU
        SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
      </p>
      <br />
      <strong id="disclaimer-of-services">20. DISCLAIMER OF SERVICES</strong>
      <p>
        EXCEPT AS OTHERWISE SPECIFICALLY SET FORTH IN SECTION 18(A) OF THIS
        AGREEMENT, COMPANY MAKES NO WARRANTY OR REPRESENTATION, EXPRESS OR
        IMPLIED, OF MERCHANTABILITY OR OTHERWISE, REGARDING HARDWARE, THE
        SOFTWARE, SERVICES, OR ANY OTHER PRODUCTS OR SERVICES PROVIDED BY
        COMPANY OR PROVIDER HEREUNDER, AND SPECIFICALLY DISCLAIMS ANY AND ALL
        OTHER WARRANTIES OR REPRESENTATIONS, WHETHER EXPRESSED OR IMPLIED, ORAL
        OR WRITTEN, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
        FITNESS FOR A PARTICULAR PURPOSE, AND WARRANTIES WITH RESPECT TO TITLE
        OR NON-INFRINGEMENT WITH RESPECT TO THE SERVICES AND SOFTWARE. WITHOUT
        LIMITING THE GENERALITY OF THE FOREGOING, COMPANY DOES NOT WARRANT:
      </p>
      <ol>
        <li>THE FITNESS, DESIGN OR CONDITION OF THE HARDWARE; OR</li>
        <li>THE QUALITY OR CAPACITY OF THE HARDWARE; OR</li>
        <li>THE WORKMANSHIP OF THE HARDWARE; OR</li>
        <li>
          THAT THE HARDWARE WILL SATISFY THE REQUIREMENTS OF ANY LAW, RULE,
          SPECIFICATION OR CONTRACT PERTAINING THEREOF; OR
        </li>
        <li>
          ANY GUARANTEE OR WARRANTY AGAINST PATENT INFRINGEMENT OR LATENT
          DEFECTS; OR
        </li>
        <li>THAT USER’S USE OF THE SOFTWARE WILL BE UNINTERRUPTED; OR</li>
        <li>THAT OPERATION OF THE SOFTWARE WILL ERROR-FREE.</li>
      </ol>
      <br />
      <strong id="limitations-of-liability">
        21. LIMITATIONS OF LIABILITY
      </strong>
      <p>
        IN NO EVENT SHALL COMPANY OR ITS DIRECTORS, EMPLOYEES, OR AGENTS BE
        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
        INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA OR OTHER DAMAGES
        ARISING FROM YOUR USE OF THE WEBSITE OR COMPANY SERVICES, EVEN IF
        COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, COMPANY’S
        LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
        THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY
        YOU TO COMPANY FOR THE COMPANY SERVICES DURING THE PERIOD OF THREE (3)
        MONTHS PRIOR TO ANY CAUSE OF ACTION ARISING.
      </p>
      <br />
      <strong id="indemnity">22. INDEMNITY</strong>
      <ol>
        <li>
          22.1 You agree to defend, indemnify and hold Company, its
          subsidiaries, and affiliates, and their respective officers, agents,
          partners and employees, harmless from and against, any loss, damage,
          liability, claim, or demand, including reasonable attorneys’ fees and
          expenses, made by any third party due to or arising out of or in
          connection with (i) your contributed content or Submissions, (ii) use
          of the Company Services, (iii) a breach of this Agreement and/or any
          breach of your representations and warranties set forth above, or (iv)
          your intentional or negligent acts or omissions. Notwithstanding the
          foregoing, Company reserves the right, at your expense, to assume the
          exclusive defense and control of any matter for which you are required
          to indemnify Company, and you agree to cooperate, at your expense,
          with Company’s defense of such claims. Company will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </li>
        <li>
          22.2 Users also agree to defend, indemnify and hold Providers, their
          subsidiaries, and affiliates, and their respective officers, agents,
          partners and employees, harmless from and against, any loss, damage,
          liability, claim, or demand, including reasonable attorneys’ fees and
          expenses, made by any third party due to or arising out of or in
          connection with (i) User contributed content or Submissions, (ii) a
          breach of this Agreement and/or any breach of a User’s representations
          and warranties set forth above, or (iii) a User’s intentional or
          negligent acts or omissions.
        </li>
      </ol>
      <br />
      <strong id="notices">23. NOTICES</strong>
      <p>
        Except as explicitly stated otherwise, any notices given to Company
        shall be given by email to the address listed in the contact information
        below. Any notices given to you shall be given to the email address you
        provided during the registration process, or such other address as each
        party may specify. Notice shall be deemed to be given twenty-four (24)
        hours after the email is sent, unless the sending party is notified that
        the email address is invalid. We may also choose to send notices by
        regular mail.
      </p>
      <br />
      <strong id="user-data">24. USER DATA</strong>
      <p>
        Our Website will maintain certain data that you transfer to the Website
        for the purpose of the performance of the Company Services, as well as
        data relating to your use of the Company Services. Although we perform
        regular routine backups of data, you are primarily responsible for all
        data that you have transferred or that relates to any activity you have
        undertaken using the Company Services. You agree that Company shall have
        no liability to you for any loss or corruption of any such data, and you
        hereby waive any right of action against Company arising from any such
        loss or corruption of such data.
      </p>
      <br />
      <br />
      <p>Address: 9th Floor 107 Cheapside</p>
      <p>London, UK EC2V 6DN</p>
      <p>
        Email: <a href="info@tromero.ai"></a>info@tromero.ai
      </p>
    </div>
  );
};

export default TandC;
