import { Dropdown } from 'flowbite-react';

import useCurrencySelector from '../Hooks/useCurrencySelector'; // Adjust the import path as needed
import CurrencyDropDownItem from './SettingsPage/CurrencyDropDownItem';
import {
  getDefaultLocaleForCurrency,
  popularCurrencies,
} from '../utils/localeCurrencyMapping';
import { getCurrencyName } from '../utils/CurrencyUtils';
import { useUser } from '../UserContext';

const CurrencySelector = ({ grow = false, tight = false }) => {
  const { displayCurrency } = useUser();
  const { handleSelectedCurrency } = useCurrencySelector({
    initialCurrency: displayCurrency,
  });

  return (
    <Dropdown
      className="h-40 overflow-y-auto capitalize"
      label=""
      dismissOnClick
      arrowIcon
      placement={`${tight ? 'top-start' : 'bottom-start'}`}
      renderTrigger={() => (
        <span
          className={` ${grow ? 'flex-1' : 'w-fit'}  flex items-baseline shadow-sm border border-gray-300  rounded-md focus:ring-indigo-200 focus:border-indigo-300 ${tight ? 'px-2 py-1' : 'pr-6 pl-2.5 py-2'} dark:bg-gray-800 dark:text-gray-100 capitalize`}
        >
          <span
            className={`mr-2 fi fi-${getDefaultLocaleForCurrency(displayCurrency).ISO_code}`}
          ></span>
          <span className="flex-grow">
            {tight
              ? displayCurrency.toUpperCase()
              : getCurrencyName(displayCurrency)}
          </span>
          {!tight && (
            <span>
              <svg
                className="w-2.5 h-2.5 ms-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </span>
          )}
        </span>
      )}
    >
      {popularCurrencies.map((currency) => (
        <CurrencyDropDownItem
          key={currency}
          currency={currency}
          handleSelectedCurrency={handleSelectedCurrency}
          showName={!tight}
          grow={grow}
        />
      ))}
      <Dropdown.Divider />
      {/* {restCurrencies.map((currency) => (
                <CurrencyDropDownItem
                  key={currency}
                  currency={currency}
                  handleSelectedCurrency={handleSelectedCurrency}
                />
              ))} */}
    </Dropdown>
  );
};

export default CurrencySelector;
