import { Component } from 'react';
import { useQueryClient } from '@tanstack/react-query';

// Create a wrapper for the reset button to use hooks
const ResetButton = ({ resetError }) => {
  const queryClient = useQueryClient();

  const handleReset = async () => {
    await queryClient.resetQueries({ queryKey: ['models'] });
    resetError();
  };

  return (
    <button
      onClick={handleReset}
      className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
    >
      Try again
    </button>
  );
};

class ModelsErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Models Error:', error, errorInfo);
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex items-center justify-center min-h-[50vh] h-screen p-4">
          <div className="text-center">
            <p className="text-red-500 mb-4">
              Something went wrong loading the models.
            </p>
            <ResetButton resetError={this.resetError} />
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ModelsErrorBoundary;
