import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
// import CreatePromptModal from './CreatePromptModal';
import axios from 'axios';
import { motion } from 'framer-motion';
import {
  ChatBubbleLeftEllipsisIcon,
  Cog6ToothIcon,
  CogIcon,
} from '@heroicons/react/24/outline';

import { useUser } from '../UserContext';

const models = [
  { name: 'Mistral (7B) Instruct' },
  { name: 'Mixtral-8x7B Instruct v0.1' },
];

const ScamDetector = () => {
  // const [selected, setSelected] = useState(models[0]);
  // const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLeftSideVisible, setIsLeftSideVisible] = useState(true);
  const user = useUser();

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  //   const [prompts, setPrompts] = useState([
  //     { name: 'Prompt 1', value: 'prompt1' },
  //     { name: 'Prompt 2', value: 'prompt2' },
  //     { name: 'Prompt 3', value: 'prompt3' },
  //   ]);

  // const [parameters, setParameters] = useState({
  //   outputLength: 0,
  //   temperature: 0.1,
  //   topP: 0,
  //   topK: 0,
  //   repPenalty: 0,
  // });

  //   const handleTemperatureChange = (event) => {
  //     setParameters({
  //       ...parameters,
  //       temperature: parseFloat(event.target.value),
  //     });
  //   };

  //   const addPromptToTheList = (name, value) => {
  //     const newPrompt = { name, value };
  //     setSelectedPrompt(newPrompt); // Optional: Select the new prompt
  //     setPrompts((oldPrompts) => [...oldPrompts, newPrompt]);
  //   };

  const openModal = () => {
    setIsModalOpen(true);
  };

  // const sendMessage = () => {
  //   if (newMessage.trim() === '') return;

  //   const newMessageObject = { text: newMessage, isUser: true };
  //   const response = { text: 'Response to: ' + newMessage, isUser: false };

  //   // Add user message and response
  //   setMessages([...messages, newMessageObject, response]);
  //   setNewMessage('');
  // };

  const sendMessage = async () => {
    if (newMessage.trim() === '') {
      return;
    }

    const newMessageObject = { text: newMessage, isUser: true };
    setMessages((messages) => [...messages, newMessageObject]);
    setNewMessage('');

    const responseLoadingMessage = { loading: true, isUser: false };
    setMessages((messages) => [...messages, responseLoadingMessage]);

    const payload = {
      messages: [
        {
          role: 'user',
          content: newMessage,
        },
      ],
    };

    try {
      const response = await axios.post(
        'http://34.89.195.180:80/generate',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
        },
      );

      // const details = response.data.details;

      // Calculate total tokens
      // const totalTokens = details.prompt_tokens + details.generated_tokens;

      const responseText = response.data.generated_text;

      const responseMessage = {
        text: responseText,
        isUser: false,
      };

      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.filter(
          (message) => !message.loading,
        ); // Remove any loading messages
        return [...updatedMessages, responseMessage];
      });
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) =>
        prevMessages.filter((message) => !message.loading),
      ); // Remove loading message on error
    }
  };

  // const toggleLeftSide = () => {
  //   setIsLeftSideVisible(!isLeftSideVisible);
  // };

  useEffect(() => {
    // Set initial messages on component mount
    const initialMessages = [
      {
        text: 'Hello, welcome to the Tromero Scam Detector!',
        isUser: false,
        showTokens: false,
      },
      {
        text: "If you've received a suspicious message or email, paste it here and we'll analyze it for you! 🕵️",
        isUser: false,
        showTokens: false,
      },
    ];

    setMessages(initialMessages);

    // Ensure the chat scrolls to the latest message
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);

  return (
    <div className="w-full h-screen pt-8 font-dmSans text-zinc-500 bg-zinc-50">
      <div className="max-w-[1500px]  mx-auto">
        <div className="flex items-center w-full h-12 my-4 -mt-8 md:mt-10 md:mx-10 lg:mt-0">
          <div className="flex items-center p-2 sm:ml-4 bg-white border rounded-lg shadow-inner w-[400px]">
            <div className="flex items-center w-48 h-8 ml-4 bg-white border border-gray-300 rounded-lg ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mx-2 text-indigo-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
              <span className="flex my-auto mr-2 font-light tracking-wide text-zinc-500">
                Scam Detector
              </span>
            </div>
            <span className="ml-4 font-normal tracking-wide text-indigo-600 whitespace-nowrap">
              <span className="text-xs font-light text-zinc-500 sm:text-base ">
                Built with
              </span>{' '}
              Tailor AI
            </span>
          </div>

          {/* <span className="items-center justify-center hidden w-auto px-2 py-1 my-auto border-b md:flex shadow-zinc-200 text-zinc-500">
          {selected.name}
        </span> */}
          {/* <span className="flex items-center justify-center w-auto px-2 py-1 mx-2 my-auto border-b md:hidden shadow-zinc-200 text-zinc-500">
          {selected.name.length > 30
            ? `${selected.name.slice(0, 30)}...`
            : selected.name}
        </span> */}
          {/* <button
            className="flex items-center justify-center w-16 h-8 ml-4 bg-white border border-gray-300 rounded-lg md:hidden EXPANDSIDEBAR"
            onClick={toggleLeftSide}
          >
            {isLeftSideVisible ? (
              <Cog6ToothIcon className="w-6 h-6 text-indigo-400 " />
            ) : (
              <ChatBubbleLeftEllipsisIcon className="w-6 h-6 text-indigo-400" />
            )}
          </button> */}
        </div>
        <div
          className={`grid h-full  -mt-6 md:p-10 min-h-[90vh] grid-cols-5 md:-mt-12  ${isLeftSideVisible ? 'md:grid-cols-5' : 'md:col-span-5'}`}
        >
          <div
            className={`col-span-6  ${isLeftSideVisible ? 'md:col-span-5' : 'hidden'} LEFTSIDE`}
          >
            {/* Left Column Content (75%) */}
            <div className="relative p-4 h-4/6">
              <div
                ref={chatContainerRef}
                className="w-full h-full p-6 pr-12 md:pr-20 overflow-y-auto border border-gray-300 max-h-[68vh] rounded-t-xl bg-white"
                style={{ scrollBehavior: 'smooth' }}
              >
                {messages.map((message, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.5 }}
                    className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}
                  >
                    {message.loading ? (
                      <div className="animate-blink">
                        <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full">
                          {/* Loading indicator */}
                        </div>
                      </div>
                    ) : (
                      <>
                        {!message.isUser && (
                          <div className="flex items-center justify-center mt-1 mr-1 ICON">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="min-w-5 h-5 p-0.5 text-indigo-600 bg-white rounded-lg  "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                              />
                            </svg>
                          </div>
                        )}

                        <div
                          className={`m-2 p-2 text-zinc-600 ${message.isUser ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50' : ''}`}
                        >
                          {message.text}
                          {/* {!message.isUser && message.showTokens && (
                            <div className="mt-1 text-xs text-zinc-400">
                              {message.totalTokens} Total Tokens
                            </div>
                          )} */}
                        </div>
                        {message.isUser && (
                          <div className="flex items-center justify-center mt-5 mr-1 ICON">
                            <div className="flex items-center justify-center w-5 h-5 text-xs text-gray-900 rounded-full bg-indigo-50 min-w-5 font-spaceG">
                              {user.user.email
                                ? user.user.email[0].toUpperCase()
                                : 'hi'}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </motion.div>
                ))}
              </div>
              <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 group">
                <button
                  className="flex items-center justify-center w-full h-full "
                  onClick={setMessages.bind(null, [])}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="relative p-4 pt-0 md:h-1/4 h-1/6">
              <textarea
                className="w-full h-full pt-4 pl-8 bg-white border border-gray-300 shadow resize-none md:pr-36 rounded-b-xl shadow-zinc-200 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200"
                placeholder="Enter text here..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
              ></textarea>

              <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 hover:border-indigo-200 hover:rounded-t-lg hover:rounded-l-lg group hover:bg-indigo-50">
                <button
                  className="flex items-center justify-center w-full h-full "
                  onClick={sendMessage}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg>
                </div>
              </div>

              {/* <button className="absolute flex items-center justify-center w-16 h-16 m-2 border hover:shadow hover:shadow-zinc-200 right-7 top-3 hover:bg-zinc-50 group">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:h-7 group-hover:w-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                />
              </svg>
            </button> */}
            </div>
          </div>
          {/* <motion.div
            initial={{ opacity: 0.0, x: 40 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              ease: 'easeInOut',
            }}
            className={`py-4  md:block ${isLeftSideVisible ? 'hidden' : 'col-span-6 pr-6'} min-w-64 CONTROLPANEL`}
          > */}
          {/* Control Panel Content */}
          {/* <div className="w-full px-8 py-4 bg-white border border-gray-300 shadow-lg h-[512.86px] shadow-zinc-200 rounded-r-xl">
              <span className="text-lg ">Model</span>
              <div className="w-full pb-8 my-4 border-b">
                <Listbox value={selected} onChange={setSelected}>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                      <span className="block truncate">{selected.name}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <ChevronUpDownIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {models.map((model, modelIdx) => (
                          <Listbox.Option
                            key={modelIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active
                                  ? 'bg-indigo-100 text-indigo-900'
                                  : 'text-gray-900'
                              }`
                            }
                            value={model}
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? '' : 'font-normal'
                                  }`}
                                >
                                  {model.name}
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox> */}
        </div>
        {/* <div className="flex justify-between w-full ">
              <span className="text-lg ">System Prompt</span>
              <button
                onClick={openModal}
                className="flex items-center justify-center w-6 h-6 border hover:bg-indigo-50 hover:shadow hover:shadow-zinc-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5 text-zinc-500"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
              {isModalOpen && (
                <CreatePromptModal
                  onClose={() => setIsModalOpen(false)}
                  addNewPrompt={addPromptToTheList}
                />
              )}
            </div>
            <div className="pb-8 my-4 border-b">
              <Listbox value={selectedPrompt} onChange={setSelectedPrompt}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
                    <span className="block truncate">
                      {selectedPrompt ? selectedPrompt.name : 'Select a prompt'}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronUpDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {prompts.map((prompt, promptIdx) => (
                        <Listbox.Option
                          key={promptIdx}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-3 pr-4 ${
                              active
                                ? 'bg-indigo-100 text-indigo-900'
                                : 'text-gray-900'
                            }`
                          }
                          value={prompt}
                        >
                          {({ selectedPrompt }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selectedPrompt ? '' : 'font-normal'
                                }`}
                              >
                                {prompt.name}
                              </span>
                              {selectedPrompt ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                  <CheckIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div> */}
        {/* <span className="text-lg ">Parameters</span>
            <div className="py-8 border-b">
              <div className="flex items-center justify-between mb-1 ">
                <label
                  for="medium-range"
                  class="block  text-sm text-zinc-600 dark:text-white"
                >
                  Output Length
                </label>
                <div className="flex justify-center w-16 h-6 border">
                  {parameters.outputLength}
                </div>
              </div>
              <input
                id="steps-range"
                type="range"
                min="0"
                max="1000"
                // value={}
                step="10"
                // style={thumbStyle}
                // onChange={(e) => setSliderValue(Number(e.target.value))}
                className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
              />
              <div className="flex items-center justify-between mb-1 ">
                <label
                  for="medium-range"
                  class="block  text-sm text-zinc-600 dark:text-white"
                >
                  Temperature
                </label>
                <div className="flex justify-center w-16 h-6 border">
                  {parameters.temperature}
                </div>
              </div>
              <input
                id="temperature-range"
                type="range"
                min="0.1"
                max="1"
                value={parameters.temperature}
                step="0.1"
                onChange={handleTemperatureChange}
                className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
              />
              <div className="flex items-center justify-between mb-1 ">
                <label
                  for="medium-range"
                  class="block  text-sm text-zinc-600 dark:text-white"
                >
                  Top-P
                </label>
                <div className="flex justify-center w-16 h-6 border">
                  {parameters.topP}
                </div>
              </div>
              <input
                id="steps-range"
                type="range"
                min="0"
                max="1000"
                // value={}
                step="10"
                // style={thumbStyle}
                // onChange={(e) => setSliderValue(Number(e.target.value))}
                className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
              />
              <div className="flex items-center justify-between mb-1 ">
                <label
                  for="medium-range"
                  class="block  text-sm text-zinc-600 dark:text-white"
                >
                  Top-K
                </label>
                <div className="flex justify-center w-16 h-6 border">
                  {parameters.topK}
                </div>
              </div>
              <input
                id="steps-range"
                type="range"
                min="0"
                max="1000"
                // value={}
                step="10"
                // style={thumbStyle}
                // onChange={(e) => setSliderValue(Number(e.target.value))}
                className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
              />
              <div className="flex items-center justify-between mb-1 ">
                <label
                  for="medium-range"
                  class="block  text-sm text-zinc-600 dark:text-white"
                >
                  Repetition Penalty
                </label>
                <div className="flex justify-center w-16 h-6 border">
                  {parameters.repPenalty}
                </div>
              </div>
              <input
                id="steps-range"
                type="range"
                min="0"
                max="1000"
                // value={}
                step="10"
                // style={thumbStyle}
                // onChange={(e) => setSliderValue(Number(e.target.value))}
                className="w-full h-0.5 mb-6 rounded-lg appearance-none cursor-pointer bg-gray-200"
              />
            </div> */}
        {/* </div>
          </motion.div> */}

        <div className="w-full col-span-4 -mt-24 sm:text-xs sm:px-16 text-[7px] px-4">
          This chatbot is provided for informational purposes only. While we
          strive to provide accurate and up-to-date information, we cannot
          guarantee the accuracy, completeness, or reliability of any
          information provided by the chatbot. It is important to exercise
          caution and use your discretion while interpreting and acting upon the
          chatbot's responses. We cannot be held liable for any actions, losses,
          or damages resulting from the use of the chatbot. By interacting with
          the chatbot, you acknowledge and agree to the terms outlined in this
          disclaimer and the associated Privacy Policy.
        </div>
      </div>
    </div>
  );
};

export default ScamDetector;
