import React, { useState, useEffect } from 'react';
import { Tooltip } from 'flowbite-react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

import { useUser } from '../UserContext';
import { useCurrencyConverter } from '../Hooks/useCurrencyConverter';
import { cleanGpuName, getImageSrcForRegion } from '../utils/InstanceUtils';
import NoCryptoMiningTooltip from './NoCryptoMiningTooltip';
// import { Disclosure } from '@headlessui/react';

function InstanceListing({ selectedRental, listing, handleSelectRental }) {
  // const [isOpen, setIsOpen] = useState(false);
  const isSelected = selectedRental && selectedRental === listing.id;
  const { user } = useUser();
  const money = useCurrencyConverter();

  // const [isSelected, setIsSelected] = useState(
  //   selectedRental && selectedRental == listing.id,
  // );
  // useEffect(() => {
  //   setIsSelected(selectedRental == listing.id);
  // }, [selectedRental, listing.id]);

  const isUserLoggedIn = !!user;
  const userKYCStatus = isUserLoggedIn ? user.kyc_status : null;
  const isKYCRequired = listing.cluster.kyc_required;

  const data = [
    // { title: 'Host', value: listing.host },
    // { title: 'OS', value: listing.os_name },
    {
      title: 'CPU',
      value: `${listing.cpu_name.split('_').join(' ')} (${listing.number_of_physical_cores} physical cores, ${listing.total_number_of_cores} total cores)`,
    },
    {
      title: 'GPU Memory',
      value: `${listing.gpu_memory} GB`,
    },

    {
      title: 'GPU Driver version',
      value: listing.driver_version,
    },
    {
      title: 'Host <-> GPU Bandwidth',
      value: `${listing.pcie_bandwidth} GB/s (Gen ${listing.pcie_generation} x${listing.pcie_lanes_per_gpu})`,
    },
    {
      title: 'RAM',
      value: `${listing.host_ram} GB (${listing.host_swap} GB Swap)`,
    },
    { title: 'Disk size', value: `${listing.disk_size} GB` },
    // { title: 'Ports', value: listing.ports },
    {
      title: 'Network Speed',
      value: `↓ ${
        listing.network_download_speed === 'unknown'
          ? '-'
          : Number(listing.network_download_speed).toFixed(1 ?? '-')
      } Mb/s, ↑ ${
        listing.network_upload_speed === 'unknown'
          ? '-'
          : Number(listing.network_upload_speed).toFixed(1)
      } Mb/s`,
    },
  ];

  const modifiedData = data.map((item) => {
    const value =
      typeof item.value === 'string' ? item.value : String(item.value);
    return {
      ...item,
      value: value.startsWith('-') ? '-' : value,
    };
  });

  // Default to 'NVIDIA' if gpu_platform is not set
  if (listing.gpu_platform === null) {
    listing.gpu_platform = 'NVIDIA';
  }

  // Override with listing.id if there is a user and they are an admin
  if (user && user.is_admin) {
    listing.gpu_platform = listing.id;
  }

  // function getLocationLabel(location) {
  //   return locationLabels[location] || '';
  // }

  // function getLabelByValue(optionsArray, value) {
  //   const option = optionsArray.find((option) => option.value === value);
  //   return option ? option.label : '';
  // }

  // function DynamicTextSize({ text }) {
  //   let textStyle = 'md:text-base ';

  //   if (text.length < 10) {
  //     textStyle = 'md:text-2xl ';
  //   } else if (text.length < 20) {
  //     textStyle = 'md:text-xl ';
  //   } else if (text.length < 30) {
  //     textStyle = 'md:text-lg ';
  //   }

  //   return (
  //     <span
  //       className={`${textStyle}  text-slate-900 uppercase text-base dark:text-gray-100`}
  //     >
  //       {text}
  //     </span>
  //   );
  // }

  // function MobileDynamicTextSize({ text }) {
  //   let textStyle = 'text-base ';

  //   if (text.length < 10) {
  //     textStyle = 'text-2xl ';
  //   } else if (text.length < 20) {
  //     textStyle = 'text-xl ';
  //   } else if (text.length < 30) {
  //     textStyle = 'text-lg';
  //   }

  //   return (
  //     <span
  //       className={`${textStyle}  text-slate-900 font-extralight uppercase  dark:text-gray-100`}
  //     >
  //       {text}
  //     </span>
  //   );
  // }

  // const handleRentClick = (e) => {
  //   e.stopPropagation();

  //   onRentToggle(listing.id);
  //   if (!isSelected) {
  //     setTimeout(() => {
  //       window.scrollTo({
  //         top: document.body.scrollHeight,
  //         behavior: 'smooth',
  //       });
  //     }, 200);
  //   }
  // };

  function MobileView({ listing, isSelected, handleSelectRental }) {
    // Mobile-specific JSX
    return (
      <div className="block md:hidden">{/* Mobile specific components */}</div>
    );
  }

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  }

  const TruncatedText = ({ text, className = null }) => {
    const truncated = truncateString(text, 70);

    return <span className={`font-normal ${className}`}>{truncated}</span>;
  };

  function DesktopView({ listing, isSelected, handleSelectRental }) {
    // Desktop-specific JSX
    return (
      <div className="w-full font-sans ">
        <div
          onClick={() => handleSelectRental(listing)}
          className={`min-h-96 p-4 bg-white dark:bg-gray-900 rounded-lg min-w-[300px]  cursor-pointer hover:shadow-md   ${
            isSelected
              ? 'outline outline-2 outline-gray-900 dark:outline-gray-300'
              : ' shadow-xl'
          }`}
        >
          <div className="relative">
            <div className="flex flex-col h-full">
              {/* Top Row: Number of GPUs and GPU Name */}
              <div className="flex items-baseline grow">
                <span className="inline-flex items-center justify-center w-8 h-8 mr-2 text-base font-semibold rounded-full text-slate-800 bg-slate-200 dark:bg-gray-600 dark:text-gray-300">
                  {listing.gpus}x
                </span>
                <span className="flex items-center text-base text-slate-900 dark:text-gray-100 ">
                  {/* <DynamicTextSize text={cleanGpuName(listing.normalized_gpu_type)} /> */}
                  {cleanGpuName(listing.normalized_gpu_type)}{' '}
                  <span className="ml-1.5">
                    {' '}
                    <NoCryptoMiningTooltip
                      providerId={listing.cluster.provider_id}
                    />
                  </span>
                </span>
              </div>

              {/* Bottom Row: Region */}
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-center w-8 h-8 mr-2 min-w-8">
                  <img
                    className="w-5 h-5 rounded-full shadow-lg"
                    src={`/${getImageSrcForRegion(listing.region.split('_').join(' '))}`}
                    alt={`${listing.region.split('_').join(' ')} flag`}
                  />
                </div>
                <span className="text-base capitalize text-slate-900 dark:text-gray-100">
                  {listing.region.split('_').join(' ')}
                </span>
              </div>
              <div className="flex items-center w-full dark:text-gray-100">
                <span className="flex-grow text-right">
                  {money(listing.total_price, 'hr')}
                </span>
                {isUserLoggedIn && isKYCRequired && (
                  <div className="flex">
                    <Tooltip
                      className="z-50 block"
                      content={
                        <div className="w-64">
                          <p>
                            <strong className="text-indigo-600 ">
                              High-Security Listing
                            </strong>{' '}
                            <br />
                            <span className="text-sm font-normal text-gray-700">
                              This listing is located in a Tier 3 data center,
                              ensuring top-level security measures.
                            </span>
                          </p>
                          <br />
                          <p>
                            <strong>Your Benefit</strong> <br />
                            <span className="text-sm font-normal text-gray-700">
                              Exceptional security guarantees for data
                              protection and integrity.
                            </span>
                          </p>
                          {userKYCStatus !== 'approved' && (
                            <>
                              <br />
                              <p>
                                <strong>ID Verification Required</strong>
                                <br />{' '}
                                <span className="text-sm font-normal text-gray-700">
                                  Access to this listing requires a one-time ID
                                  verification, which only takes a minute to
                                  complete.
                                </span>
                              </p>
                            </>
                          )}
                        </div>
                      }
                      placement="left"
                      style="light"
                      arrow={false}
                    >
                      <div className="ml-1 ">
                        <LockClosedIcon className="w-4 h-4 text-gray-500 dark:text-indigo-300" />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 pt-2 mt-2 font-sans text-xs border-t dark:text-gray-200">
            {modifiedData.map(({ title, value }, idx) => (
              <div key={idx} className="py-1.5 text-center">
                <span className="font-medium tracking-wide text-gray-900 uppercase dark:text-gray-400">
                  {title}
                </span>
                <br />
                {title === 'CPU' ? (
                  <div className="h-12">
                    <span className="text-gray-500">
                      {' '}
                      <TruncatedText
                        className={'uppercase'}
                        text={value
                          .replace(
                            /\(\d+ physical cores, \d+ total cores\)/,
                            '',
                          )
                          .trim()}
                      ></TruncatedText>
                    </span>
                    <br />
                    <span className="text-gray-500">
                      {' '}
                      <TruncatedText
                        text={value.match(
                          /\(\d+ physical cores, \d+ total cores\)/,
                        )}
                      ></TruncatedText>
                    </span>
                  </div>
                ) : (
                  <span className="text-gray-500">
                    <TruncatedText text={value} maxLength={70} />
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
    //   className={` ${isSelected ? 'border-2 border-slate-600' : 'border-2 border-black/10'} rounded-lg shadow-lg`}
    >
      <MobileView
        listing={listing}
        isSelected={isSelected}
        handleSelectRental={handleSelectRental}
      />
      <DesktopView
        listing={listing}
        isSelected={isSelected}
        handleSelectRental={handleSelectRental}
      />
    </div>
  );
}

export default InstanceListing;
