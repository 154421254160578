import { useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';

const SuccessModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        onClose();
      }, 5000);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <div
        id="popup-modal"
        tabIndex="-1"
        className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50"
      >
        <div className="relative w-full max-w-lg max-h-full font-dmSans">
          <div className="relative bg-white rounded-lg shadow dark:bg-zinc-700">
            <div className="p-6 text-center">
              <div>
                <div className="flex items-center justify-center w-12 h-12 mx-auto rounded-full bg-zinc-800">
                  <CheckIcon
                    className="w-6 h-6 text-zinc-100"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <div
                    as="h3"
                    className="text-lg font-semibold leading-6 text-zinc-900"
                  >
                    A verification link has been sent.
                  </div>
                  <div className="mt-2">
                    <p className="text-base text-zinc-700">
                      We've received your request for access
                      <p>and we'll get back to you soon.</p>
                    </p>
                    <p className="text-base text-zinc-700">
                      You will redirect shortly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
