import { useCallback, useMemo } from 'react';

/**
 * @param {string | number} size - The size of the spinner (default is 24px) .
 * @param {string} borderColor - The color of the border (default is rgba(255, 255, 255, 0.3)).
 * @param {string} borderTopColor - The color of the border top (default is white).
 * @param {string} borderWidth - The width of the border (default is 4px).
 * @returns {JSX.Element} - A spinner element.
 */
const Spinner = ({ size, borderColor, borderTopColor, borderWidth }) => {
  const getSpinnerData = useCallback((size, defaultSize) => {
    return typeof size === 'string' && /^\d+$/.test(size)
      ? `${size}px`
      : typeof size === 'string' && /^\d+px$/.test(size)
        ? size
        : defaultSize;
  }, []);

  const spinnerSize = useMemo(
    () => getSpinnerData(size, '24px'),
    [size, getSpinnerData],
  );
  const spinnerBorderWidth = useMemo(
    () => getSpinnerData(borderWidth, '4px'),
    [borderWidth, getSpinnerData],
  );
  const spinnerBorderColor = useMemo(
    () => borderColor || 'rgba(255, 255, 255, 0.3)',
    [borderColor],
  );
  const spinnerBorderTopColor = useMemo(
    () => borderTopColor || 'white',
    [borderTopColor],
  );

  return (
    <div
      className="spinner"
      style={{
        border: `${spinnerBorderWidth} solid ${spinnerBorderColor}`,
        borderTop: `${spinnerBorderWidth} solid ${spinnerBorderTopColor}`,
        borderRadius: '50%',
        width: spinnerSize,
        height: spinnerSize,
        animation: 'spin 1s linear infinite',
      }}
    ></div>
  );
};

export default Spinner;
