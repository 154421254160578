import { Modal } from 'flowbite-react';

import Veriffication from '../Veriff';
import { useUser } from '../../UserContext';

const GetVerifiedModal = ({ status }) => {
  const { showGetVerifiedModal, setShowGetVerifiedModal } = useUser();

  const text = {
    pending: {
      title: 'Verification in Progress',
      bodyTop:
        'Thank you for submitting your verification details. Your information is currently being reviewed. This process may take a short while.',
      li1: {
        title: 'Awaiting Results:',
        body: "We are waiting for the verification results from Veriff. This usually doesn't take long, but we appreciate your patience.",
      },
      li2: {
        title: 'Notification:',
        body: 'You will be notified via email once the verification process is complete. Alternatively, you can refresh this page to check.',
      },
      li3: {
        title: 'No Further Action Needed:',
        body: "There's nothing more you need to do right now. Once the verification is complete, you will be able to rent this or any instance that requires additional verification. If the verification fails, you will see that the instances will require additional verification and you can resubmit your details",
      },
      bodyBottom:
        "Should you need any assistance or have any questions, please don't hesitate to contact our support team.",
    },
    unverified: {
      title: 'Some Rentals Require Additional Verification',
      bodyTop:
        'Some of the listings on our platform require additional verification before you can rent them. This is a one-time process and will enable you to rent any other instance that requires similar verification in the future.',
      li2: {
        title: 'Secure Verification:',
        body: 'Veriff will handle all your personal data with utmost confidentiality and security.',
      },
      li3: {
        title: 'Hassle-Free Future Rentals:',
        body: 'Completing this one-time verification successfully grants you access to rent any listing without further verification, streamlining your rental experience.',
      },

      li1: {
        title: 'Your Benefit:',
        body: 'Verification is required only on listings which offer exceptional security guarantees for data protection and integrity.',
      },
      bodyBottom:
        'We appreciate your cooperation in ensuring the security and trustworthiness of our rental services.',
    },
  };

  return (
    <>
      {text && (
        <Modal
          show={showGetVerifiedModal}
          onClose={() => setShowGetVerifiedModal(false)}
          size="xl"
          className="font-sans "
          dismissible
        >
          <Modal.Header className="relative flex items-start justify-between p-5 bg-white border-b rounded-t dark:border-gray-600 dark:bg-gray-400 font-spaceG">
            <div className="text-xl font-medium text-gray-900 dark:text-white">
              <div className="text-[#212121] darks:text-white">
                {text[status].title}
              </div>
            </div>
            <div className="absolute hidden dark:block top-5 right-5">
              <button
                aria-label="Close"
                className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-[#212121] hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-[#212121]"
                type="button"
                onClick={() => setShowGetVerifiedModal(false)}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="w-5 h-5"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body className="bg-white rounded-b dark:bg-gray-400">
            <div className="flex flex-col items-center justify-center p-4 px-8">
              <div className="text-[#212121] darks:text-white">
                <p>{text[status].bodyTop}</p>
                <ul className="pl-5 mt-4 space-y-3 list-disc">
                  <li>
                    <strong>{text[status].li1.title}</strong>{' '}
                    {text[status].li1.body}
                  </li>
                  {text[status].li2 && (
                    <li>
                      <strong>{text[status].li2.title}</strong>{' '}
                      {text[status].li2.body}
                    </li>
                  )}
                  {text[status].li3 && (
                    <li>
                      <strong>{text[status].li3.title}</strong>{' '}
                      {text[status].li3.body}
                    </li>
                  )}
                </ul>
                <p className="mt-4 mb-8">{text[status].bodyBottom}</p>
              </div>
              {(status === 'unverified' || status === 'new') && (
                <Veriffication onClose={() => setShowGetVerifiedModal(false)} />
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default GetVerifiedModal;
