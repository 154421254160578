const ModelDetailSkeleton = () => {
  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans animate-pulse">
      {/* Header Skeleton */}
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className="mx-auto w-full">
          <div className="flex items-center h-16 px-4 sm:px-6 lg:px-8">
            <div className="h-4 w-48 bg-zinc-200 rounded" />
          </div>
        </div>
      </header>

      {/* Main Content Skeleton */}
      <main className="w-full max-w-7xl mx-auto px-4 py-6 space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:auto-rows-min grid-flow-row">
          {/* Model Details Card Skeleton */}
          <div className="row-span-1 bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
            <div className="h-6 w-48 bg-zinc-200 rounded mb-4" />
            <div className="space-y-4">
              <div className="h-4 w-32 bg-zinc-200 rounded" />
              <div className="h-4 w-40 bg-zinc-200 rounded" />
              <div className="flex justify-between mt-8">
                <div>
                  <div className="h-3 w-24 bg-zinc-200 rounded mb-2" />
                  <div className="h-3 w-32 bg-zinc-200 rounded" />
                </div>
                <div>
                  <div className="h-3 w-24 bg-zinc-200 rounded mb-2" />
                  <div className="h-3 w-32 bg-zinc-200 rounded" />
                </div>
              </div>
            </div>
          </div>

          {/* Usage Data Card Skeleton */}
          <div className="lg:col-span-2 bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
            <div className="h-6 w-48 bg-zinc-200 rounded mb-4" />
            <div className="grid grid-cols-3 gap-4">
              {[...Array(3)].map((_, i) => (
                <div key={i} className="h-20 bg-zinc-200 rounded" />
              ))}
            </div>
          </div>

          {/* Deploy Card Skeleton */}
          <div className="bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
            <div className="h-6 w-32 bg-zinc-200 rounded mb-4" />
            <div className="h-24 bg-zinc-200 rounded" />
          </div>

          {/* Evaluations Card Skeleton */}
          <div className="bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
            <div className="h-6 w-40 bg-zinc-200 rounded mb-4" />
            <div className="h-24 bg-zinc-200 rounded" />
          </div>

          {/* Token Usage Chart Skeleton */}
          {/* <div className="lg:col-span-2 bg-white rounded-lg shadow-sm border border-zinc-200 p-4">
            <div className="h-6 w-48 bg-zinc-200 rounded mb-4" />
            <div className="h-80 bg-zinc-200 rounded" />
          </div> */}
        </div>
      </main>
    </div>
  );
};

export default ModelDetailSkeleton;
