// InvoiceModal.js
import React from 'react';
import { Transition, Dialog, Listbox } from '@headlessui/react';
import { PDFDownloadLink, Page } from '@react-pdf/renderer';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import moment from 'moment';

const InvoiceModal = ({
  isModalOpen,
  closeModal,
  selectedPeriod,
  setSelectedPeriod,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
  startYear,
  setStartYear,
  startMonth,
  setStartMonth,
  endYear,
  setEndYear,
  endMonth,
  setEndMonth,
  dateError,
  handleStartYearChange,
  handleStartMonthChange,
  handleEndYearChange,
  handleEndMonthChange,
  handleGeneratePDF,
  getFileName,
  period,
  years,
  months,
}) => (
  <Transition appear show={isModalOpen} as={React.Fragment}>
    <Dialog as="div" className="relative z-50" onClose={closeModal}>
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform rounded-lg shadow-xl bg-zinc-50">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Generate Invoice
              </Dialog.Title>
              <div className="my-6 text-sm font-medium leading-6 text-gray-700">
                Select a date range to generate a list of your payments to the
                platform through Stripe.
              </div>
              <div className="mt-12">
                <fieldset>
                  <legend className="text-sm font-medium leading-6 text-gray-700">
                    Billing Period
                  </legend>
                  <div className="mt-2 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                    {period.map((notificationMethod) => (
                      <div
                        key={notificationMethod.id}
                        className="flex items-center"
                      >
                        <input
                          id={notificationMethod.id}
                          name="notification-method"
                          type="radio"
                          className="w-4 h-4 border-gray-300 text-zinc-800 focus:ring-zinc-800"
                          onChange={() => setSelectedPeriod(notificationMethod)}
                          checked={selectedPeriod.id === notificationMethod.id}
                        />
                        <label
                          htmlFor={notificationMethod.id}
                          className="block ml-3 text-sm font-medium leading-6 text-gray-900"
                        >
                          {notificationMethod.title}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>

              <div className="flex mt-8 space-x-4">
                {selectedPeriod.id === 'month' ? (
                  <div className="flex w-full space-x-4">
                    <div className="w-1/2">
                      <Listbox value={selectedYear} onChange={setSelectedYear}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              Year
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {selectedYear}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {years.map((year) => (
                                    <Listbox.Option
                                      key={year}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={year}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {year}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="z-10 w-1/2">
                      <Listbox
                        value={selectedMonth}
                        onChange={setSelectedMonth}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              Month
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {selectedMonth.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {months.map((month) => (
                                    <Listbox.Option
                                      key={month.id}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={month}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {month.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                ) : (
                  <div className="grid w-full grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Listbox
                        value={startYear}
                        onChange={handleStartYearChange}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              Start Year
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {startYear}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {years.map((year) => (
                                    <Listbox.Option
                                      key={year}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={year}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {year}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                      <Listbox
                        value={startMonth}
                        onChange={handleStartMonthChange}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              Start Month
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {startMonth.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {months.map((month) => (
                                    <Listbox.Option
                                      key={month.id}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={month}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {month.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <div className="space-y-2">
                      <Listbox value={endYear} onChange={handleEndYearChange}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              End Year
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {endYear}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {years.map((year) => (
                                    <Listbox.Option
                                      key={year}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={year}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {year}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                      <Listbox value={endMonth} onChange={handleEndMonthChange}>
                        {({ open }) => (
                          <>
                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                              End Month
                            </Listbox.Label>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm">
                                <span className="block truncate">
                                  {endMonth.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <ChevronUpDownIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                show={open}
                                as={React.Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-36 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {months.map((month) => (
                                    <Listbox.Option
                                      key={month.id}
                                      className={({ active }) =>
                                        `${active ? 'text-white bg-zinc-800' : 'text-gray-900'} cursor-default select-none relative py-2 pl-10 pr-4`
                                      }
                                      value={month}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}
                                          >
                                            {month.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`${active ? 'text-white' : 'text-zinc-800'} absolute inset-y-0 left-0 flex items-center pl-3`}
                                            >
                                              <CheckIcon
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                            <div className="text-sm text-red-500">
                              {dateError ? dateError : ''}
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                  </div>
                )}
              </div>
              <div className="mt-40">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-zinc-800 hover:bg-zinc-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={closeModal}
                >
                  <PDFDownloadLink
                    document={handleGeneratePDF()}
                    fileName={getFileName()}
                  >
                    {({ loading }) =>
                      loading ? 'Loading document...' : 'Download Invoice'
                    }
                  </PDFDownloadLink>
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default InvoiceModal;
