import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import toast from 'react-hot-toast';
import clsx from 'clsx';

import { useUser } from '../../../UserContext';

const CreateDatasetModal = ({ isOpen, onClose, filters }) => {
  let [datasetName, setDatasetName] = useState('');
  let [datasetDescription, setDatasetDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { customAxios } = useUser();

  function closeModal() {
    onClose();
  }

  const validateForm = () => {
    if (!datasetName) {
      setError('Dataset name is required');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    setError(null);
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    const payload = {
      name: datasetName,
      description: datasetDescription,
    };
    if (filters.models.length) {
      payload.models = filters.models;
    }
    if (filters.tags.length) {
      payload.tags = filters.tags;
    }
    if (filters.startDate) {
      payload.from_date = filters.startDate;
    }
    if (filters.endDate) {
      payload.to_date = filters.endDate;
    }
    try {
      const res = await customAxios.post('tailor/v1/datasets', payload);
      if (res.status === 201) {
        toast.success('Dataset created successfully');

        closeModal();
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      if (error.code === 'duplicate_dataset_name') {
        setError(error.message);
      }
      toast.error('Failed to create dataset');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setDatasetName('');
    setDatasetDescription('');
    setError(null);
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-md shadow-xl font-dmSans">
                <Dialog.Title
                  as="h3"
                  className="mb-4 text-lg font-medium leading-6 capitalize text-zinc-500"
                >
                  Create a dataset of the filtered logs
                </Dialog.Title>
                <div className="mb-4 text-sm text-gray-500">
                  Filters applied:
                  {Object.entries(filters).map(
                    ([key, value]) =>
                      (Array.isArray(value) ? value.length > 0 : !!value) && (
                        <div key={key}>
                          <span className="font-semibold capitalize">
                            {key === 'startDate'
                              ? 'Start Date'
                              : key === 'endDate'
                                ? 'End Date'
                                : key}
                            :{' '}
                          </span>
                          {key === 'startDate' || key === 'endDate' ? (
                            <span>
                              {new Date(value).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'short',
                                day: 'numeric',
                              })}{' '}
                              {new Date(value).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                              })}
                            </span>
                          ) : (
                            value.join(', ')
                          )}
                        </div>
                      ),
                  )}
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="datasetName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Dataset Name{' '}
                    <span className="text-xs text-zinc-400">(Required)</span>
                  </label>
                  <input
                    id="datasetName"
                    type="text"
                    value={datasetName}
                    onChange={(e) => setDatasetName(e.target.value)}
                    onFocus={() => setError(null)}
                    className={clsx(
                      'w-full p-2 text-sm border border-gray-300 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-zinc-600',
                      error && '!border-red-500',
                    )}
                  />
                </div>
                <div className="mt-2">
                  <label
                    htmlFor="datasetDescription"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Dataset Description
                  </label>

                  <textarea
                    type="text"
                    value={datasetDescription}
                    onChange={(e) => setDatasetDescription(e.target.value)}
                    className="w-full p-2 text-sm border border-gray-300 resize-none h-36 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200 text-zinc-600"
                  />
                </div>
                <div className="mt-2 text-sm text-gray-500">
                  Note: A dataset is just a collection of filters. If, in the
                  future, you add more logs that fit the filter criteria, they
                  will be included in the dataset. If you want to avoid this,
                  you can add an end date to the filter.
                </div>
                {error && (
                  <div className="mt-2 text-sm text-red-500">{error}</div>
                )}

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center h-10 px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md w-36 hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center h-10 px-4 py-2 ml-2 text-sm font-medium text-red-900 border border-gray-300 rounded-md hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CreateDatasetModal;
