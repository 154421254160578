import { Tooltip } from 'flowbite-react';

import { convertSecondsToDaysHoursAndMinutes } from '../../../utils/generalUtils';
import { useMemo } from 'react';

const EstimateTimeAndCost = ({
  estimatedCost,
  estimatedTime,
  logsUsed,
  logsRequired,
}) => {
  const logsUsedInt = parseInt(logsUsed);
  const { width, message } = useMemo(() => {
    if (logsUsed === 0) {
      return { width: 0, message: '' };
    }
    if (logsUsed < logsRequired.min) {
      return {
        width: 0.02,
        message:
          'Likely insufficient logs selected to train the specified model.',
      };
    }
    if (logsUsed < logsRequired.good) {
      return {
        width: 0.33,
        message:
          'Likely adequate, but less than optimal number of logs are selected for  training.',
      };
    }
    if (logsUsed < logsRequired.excellent) {
      return {
        width: 0.66,
        message:
          'Likely sufficient logs selected for a successful fine-tuning job.',
      };
    }
    return {
      width: 1,
      message: 'Sufficient logs selected for a successful finetuning job.',
    };
  }, [logsUsed, logsRequired]);

  return (
    <div>
      <div className="border rounded-md w-full text-sm text-zinc-900 bg-white -my-2">
        <div className="grid grid-cols-8">
          <div className="border-r p-3 col-span-5">
            <div className="font-medium capitalize">
              Estimated<sup>*</sup> Time to train:{' '}
              <span className="text-sm font-normal text-zinc-800">
                {logsUsed > 0
                  ? convertSecondsToDaysHoursAndMinutes(estimatedTime)
                  : '...'}
              </span>
            </div>
          </div>
          <div className="p-3 col-span-3">
            <div className="font-medium capitalize">
              Estimated<sup>*</sup> Cost:{' '}
              <span className="text-sm font-normal text-zinc-800">
                {logsUsed > 0 ? `$${estimatedCost.toFixed(2)}` : '...'}
              </span>
            </div>
          </div>
        </div>
        <div className="border-t p-3">
          <div>
            <div className="font-medium capitalize">
              Number of logs selected:{' '}
              <span className="text-sm font-normal text-zinc-800">
                {logsUsed > 0 ? logsUsed : '...'}
              </span>
            </div>
            <div>
              <div className="relative w-full h-4 mt-3">
                <div
                  className="bg-gradient-to-r from-indigo-400 to-indigo-100 h-4 rounded-md absolute top-0 left-0 z-10 text-xs md:text-sm text-zinc-900 items-center flex "
                  style={{ width: `${width * 100}%` }}
                ></div>
                <div
                  className="border bg-zinc-50 shadow-inner h-4 rounded-md absolute top-0 left-0 z-0 "
                  style={{ width: '100%' }}
                ></div>
                <div className="grid grid-cols-4 pt-4 h-9 ml-1 items-end ">
                  <div className="text-xs text-zinc-900">not enough</div>
                  <div className="text-xs text-zinc-900">enough</div>
                  <div className="text-xs text-zinc-900">good</div>
                  <div className="text-xs text-zinc-900">excellent</div>
                </div>
              </div>
            </div>
            <div className="mt-8 flex gap-2 items-center text-gray-500">
              {message}
              {logsUsedInt > 0 && (
                <Tooltip
                  content={
                    'The assessment of the number of logs required to fine-tune a model is based on our experience and may vary depending on the quality of the logs and the complexity of the task you want to fine-tune for.'
                  }
                  arrow={false}
                  style="light"
                  className="w-64 shadow"
                >
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateTimeAndCost;
