const HeaderSkeleton = () => {
  return (
    <header className="sticky top-0 bg-zinc-50 z-50">
      <div className="flex items-center justify-between h-16 p-4">
        <div className="h-8 bg-zinc-200 rounded w-24"></div>
        <div className="flex gap-2">
          <div className="h-8 w-8 bg-zinc-200 rounded"></div>
          <div className="h-8 w-8 bg-zinc-200 rounded"></div>
        </div>
      </div>
      <hr className="border-t border-zinc-300" />
    </header>
  );
};

export default HeaderSkeleton;
