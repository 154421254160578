import { Tooltip } from 'flowbite-react';

const NoCryptoMiningTooltip = ({ providerId }) => {
  // list of provider id's that don't have a restriction on crypto mining
  const listToExclude = [4];

  if (listToExclude.includes(providerId)) {
    return null;
  }

  return (
    <Tooltip
      content="Crypto mining is not allowed on this listing."
      placement="top"
      style="light"
      arrow={false}
    >
      <span className="flex items-center font-medium text-indigo-800 dark:text-gray-200 dark:bg-gray-800 ">
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 3.5L14.5 14.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.427 4.21269C11.5096 4.21126 11.5895 4.24279 11.649 4.30026L13.2188 5.81767C13.3375 5.93246 13.3455 6.11872 13.2404 6.24301C14.1716 7.57893 14.553 9.11346 14.2849 10.4369C14.2565 10.5777 14.1356 10.6807 13.9921 10.6866C13.8487 10.6926 13.7197 10.5998 13.6797 10.4619C13.3883 9.45722 12.8485 8.41711 12.0786 7.44598L12.034 7.49209C11.9765 7.55156 11.8978 7.58576 11.815 7.58716C11.7324 7.58858 11.6525 7.55705 11.593 7.49958L11.3687 7.28279L9.74296 8.96472C9.86684 9.08446 9.87018 9.28186 9.75045 9.40574L5.63179 13.6666C5.57433 13.7261 5.49557 13.7602 5.41285 13.7616C5.33012 13.763 5.25025 13.7316 5.19076 13.674L4.06951 12.5902C3.94565 12.4705 3.94232 12.273 4.06203 12.1492L8.18068 7.88838C8.23814 7.82886 8.31693 7.79464 8.39965 7.79324C8.48238 7.79185 8.56222 7.82337 8.6217 7.88088L10.2475 6.19896L10.0232 5.98217C9.89938 5.86246 9.896 5.665 10.0157 5.54114L10.0603 5.49506C9.06359 4.7585 8.00576 4.2543 6.99178 3.99717C6.85261 3.96187 6.75552 3.83614 6.75656 3.69257C6.7576 3.549 6.85652 3.4247 6.99619 3.39143C8.30981 3.07857 9.85642 3.40778 11.2231 4.29307C11.2789 4.24267 11.3513 4.21396 11.427 4.21269Z"
            fill="currentColor"
          />
        </svg>
      </span>
    </Tooltip>
  );
};

export default NoCryptoMiningTooltip;
