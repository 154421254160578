import {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { EmojiConvertor } from 'emoji-js';

import { useUser } from '../UserContext';
import GenerateApiKeyModal from '../Components/Modals/GenerateApiKeyModal';

const PlaygroundGame = () => {
  const emoji = new EmojiConvertor();
  emoji.avoid_ms_emoji = false;
  const [messages, setMessages] = useState([]);
  const [secondMessages, setSecondMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [adapter, setAdapter] = useState('');
  const [secondAdapter, setSecondAdapter] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [secondConversation, setSecondConversation] = useState([]);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const [correctGuesses, setCorrectGuesses] = useState(0);
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({
    firstResponse: '',
    secondResponse: '',
  });
  const [feedback, setFeedback] = useState({ first: '', second: '' });
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameOverMessage, setGameOverMessage] = useState('');
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [apiCreated, setApiCreated] = useState(false);

  const chatContainerRef = useRef(null);
  const secondChatContainerRef = useRef(null);
  const {
    customAxios,
    checkTokenExpiration,
    setShowTokenExpiredModal,
    setShowSignInModal,
  } = useUser();

  const baseModels = useMemo(
    () => [
      { model_name: 'mistral-7b' },
      { model_name: 'mixtral-8x7b' },
      { model_name: 'llama-3-70b' },
    ],
    [],
  );

  useEffect(() => {
    const fetchApiKeys = async () => {
      try {
        const response = await customAxios.get('tailor/v1/keys');
        setApiKeys(response.data.message);
      } catch (error) {
        setError(error);
        console.error('Error fetching api keys:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchApiKeys();
  }, [customAxios, apiCreated]);

  const handleGenerateKey = async () => {
    setLoading(true);
    try {
      const nameToUse = 'playground';
      const response = await customAxios.post('tailor/v1/keys', {
        api_key_name: nameToUse,
      });

      setApiKeys((prevKeys) => [...prevKeys, response.data]);
      setIsApiKeyModalOpen(false);
      setIsGameStarted(true);
    } catch (error) {
      console.error('Error generating new API key, please try again later');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (secondChatContainerRef.current) {
      secondChatContainerRef.current.scrollTop =
        secondChatContainerRef.current.scrollHeight;
    }
  }, [secondMessages]);

  useEffect(() => {
    document.documentElement.classList.remove('dark');
  }, []);

  const fetchModels = useCallback(async () => {
    try {
      const deployedModels = baseModels;
      setAdapter(deployedModels[0]);

      // Ensure second model is different
      let secondModel;
      do {
        secondModel =
          deployedModels[Math.floor(Math.random() * deployedModels.length)];
      } while (secondModel.model_name === deployedModels[0].model_name);
      setSecondAdapter(secondModel);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      // toast.error('Failed to fetch models');
    }
  }, [baseModels]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const clearChat = () => {
    setMessages([]);
    setSecondMessages([]);
    setConversation([]);
    setSecondConversation([]);
    setNewMessage('');
  };

  const handleOptionSelect = (responseType, model) => {
    setSelectedOptions((prev) => {
      const newOptions = { ...prev, [responseType]: model };
      if (newOptions.firstResponse && newOptions.secondResponse) {
        submitGuess(newOptions);
      }
      return newOptions;
    });
  };

  const submitGuess = (newOptions) => {
    const newFeedback = { first: '', second: '' };
    if (newOptions.firstResponse === adapter.model_name) {
      newFeedback.first = 'correct';
    } else {
      newFeedback.first = 'incorrect';
    }
    if (newOptions.secondResponse === secondAdapter.model_name) {
      newFeedback.second = 'correct';
    } else {
      newFeedback.second = 'incorrect';
    }

    // Clear messages immediately
    setMessages([]);
    setSecondMessages([]);

    setFeedback(newFeedback);

    if (newFeedback.first === 'correct') {
      setCorrectGuesses((prev) => prev + 1);
    } else {
      setIncorrectGuesses((prev) => prev + 1);
    }

    if (newFeedback.second === 'correct') {
      setCorrectGuesses((prev) => prev + 1);
    } else {
      setIncorrectGuesses((prev) => prev + 1);
    }

    if (correctGuesses + 1 === 5 || incorrectGuesses + 1 === 5) {
      setIsGameOver(true);
      setGameOverMessage(
        correctGuesses + 1 === 5
          ? "Nice job, Legend. You've won! 🏆"
          : 'Good try, maybe next time. 😎',
      );
    } else {
      setTimeout(() => {
        clearChat();
        setFeedback({ first: '', second: '' });
        setSelectedOptions({ firstResponse: '', secondResponse: '' });
        fetchModels();
      }, 3000);
    }
  };

  useEffect(() => {
    if (isGameOver) {
      setTimeout(() => {
        setIsGameStarted(false);
        setIsGameOver(false);
        setCorrectGuesses(0);
        setIncorrectGuesses(0);
        setFeedback({ first: '', second: '' });
        setSelectedOptions({ firstResponse: '', secondResponse: '' });
        fetchModels();
      }, 3000);
    }
  }, [isGameOver, fetchModels]);

  const checkForTokenExpiration = () => {
    const isTokenExpired = checkTokenExpiration();
    const token = localStorage.getItem('token');
    if (token && isTokenExpired) {
      localStorage.setItem('shouldRedirect', 'no');
      setShowTokenExpiredModal(true);
      return true;
    }
    if (isTokenExpired && !token) {
      localStorage.setItem('shouldRedirect', 'no');
      setShowSignInModal(true);
      return true;
    }
    return false;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') {
      return;
    }
    if (checkForTokenExpiration()) {
      return;
    }

    const userMessageWithEmojis = {
      text: emoji.replace_colons(newMessage),
      isUser: true,
    };

    const loadingMessage = { loading: true, isUser: false };

    setMessages((messages) => [
      ...messages,
      userMessageWithEmojis,
      loadingMessage,
    ]);

    setSecondMessages((messages) => [
      ...messages,
      userMessageWithEmojis,
      loadingMessage,
    ]);

    setNewMessage('');

    const updatedConversation = [
      ...conversation,
      { role: 'user', content: newMessage },
    ];
    setConversation(updatedConversation);

    const updatedSecondConversation = [
      ...secondConversation,
      { role: 'user', content: newMessage },
    ];
    setSecondConversation(updatedSecondConversation);

    const payloads = [
      {
        adapter_name: adapter.model_name,
        messages: updatedConversation,
      },
      {
        adapter_name: secondAdapter.model_name,
        messages: updatedSecondConversation,
      },
    ];

    try {
      const responses = await Promise.all(
        payloads.map((payload) =>
          customAxios.post('tailor/v1/generate', payload),
        ),
      );

      const newMessages = responses.map((response) => {
        const responseText = response.data.generated_text;
        const details = response.data.details;
        const totalTokens = details.prompt_tokens + details.generated_tokens;

        const assistantMessageSegments = responseText
          .split('\n')
          .map((segment, index) => (
            <Fragment key={index}>
              {emoji.replace_unified(segment)}
              {index < responseText.split('\n').length - 1 && <br />}
            </Fragment>
          ));

        return {
          text: assistantMessageSegments,
          isUser: false,
          tokens: details.tokens.length,
          totalTokens: totalTokens,
          originalText: responseText,
        };
      });

      setMessages((messages) => {
        const updatedMessages = messages.slice(0, -1); // Remove the loading message
        return [
          ...updatedMessages,
          {
            text: newMessages[0].text,
            isUser: false,
            totalTokens: newMessages[0].totalTokens,
          },
        ];
      });

      setConversation((conversation) => [
        ...conversation,
        { role: 'assistant', content: newMessages[0].originalText },
      ]);

      setSecondMessages((messages) => {
        const updatedMessages = messages.slice(0, -1); // Remove the loading message
        return [
          ...updatedMessages,
          {
            text: newMessages[1].text,
            isUser: false,
            totalTokens: newMessages[1].totalTokens,
          },
        ];
      });

      // Ensure the second adapter's conversation is updated correctly
      setSecondConversation((secondConversation) => [
        ...secondConversation,
        { role: 'assistant', content: newMessages[1].originalText },
      ]);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      setMessages((messages) => messages.slice(0, -1)); // Removes the loading message
      setSecondMessages((messages) => messages.slice(0, -1)); // Removes the loading message
    }
  };

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const startGame = () => {
    if (apiKeys.length === 0) {
      setIsApiKeyModalOpen(true);
    } else {
      setIsGameStarted(true);
    }
  };

  return (
    <>
      <GenerateApiKeyModal
        isOpen={isApiKeyModalOpen}
        onClose={() => setIsApiKeyModalOpen(false)}
        onGenerateKey={handleGenerateKey}
      />
      <div className="w-full h-[calc(90vh)] sm:pt-14 md:pt-4 font-dmSans text-zinc-500 bg-zinc-50 overflow-hidden">
        <div className="flex items-center w-full h-12 mt-4 md:mt-10 md:mx-10 lg:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="flex w-5 h-5 my-auto ml-4 text-zinc-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
            />
          </svg>
          <span className="hidden mx-2 my-auto text-transparent sm:text-lg sm:flex wavy bg-clip-text">
            {!isGameStarted
              ? 'Which model are you speaking with?'
              : 'Which Model?'}
          </span>
          <span className="ml-4 ">
            {!isGameStarted ? (
              ''
            ) : (
              <div className="flex flex-col ml-4 sm:flex-row ">
                <span className="flex my-auto text-transparent sm:hidden sm:text-lg wavy bg-clip-text">
                  {!isGameStarted
                    ? 'Which model are you speaking with?'
                    : 'Which Model?'}
                </span>
                <div className="flex items-center space-x-2">
                  <span>Correct:</span>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      key={index}
                      className={`w-5 h-5 rounded-full border-2 ${
                        index < correctGuesses
                          ? 'bg-zinc-600 border-zinc-600'
                          : 'border-zinc-600'
                      }`}
                    ></div>
                  ))}
                </div>
                <div className="flex items-center mb-4 space-x-2 sm:mb-0 sm:ml-4">
                  <span>Incorrect:</span>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      key={index}
                      className={`w-5 h-5 rounded-full border-2 ${
                        index < incorrectGuesses
                          ? 'bg-zinc-600 border-zinc-600'
                          : 'border-zinc-600'
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
            )}
          </span>
        </div>
        <div className="grid h-full grid-cols-1 md:p-10 md:-mt-12">
          <div className="flex flex-col col-span-1">
            {!isGameStarted ? (
              <div className="relative z-0 w-full h-full mx-auto mt-10 wavyslow shadow-3xinner rounded-xl">
                <div className="absolute inset-0 z-10 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-transparent via-zinc-50 to-zinc-50 rounded-xl"></div>{' '}
                {/* Overlay */}
                <div className="relative z-20 mx-auto sm:mt-24 text-center shadow rounded-[10px] sm:w-[750px] h-96 p-[2px] wavy ">
                  <div className="flex flex-col items-center justify-center w-full h-full bg-gradient-to-t from-zinc-800 via-zinc-800 to-zinc-900 rounded-[8px]">
                    <h1 className="mb-4 text-2xl font-semibold text-zinc-300">
                      Let's play{' '}
                      <span className="text-transparent wavy bg-clip-text">
                        "Which Model?"
                      </span>
                    </h1>
                    <h1 className="mb-4 text-lg text-zinc-300 max-w-[500px]">
                      In this game, you will write a prompt and receive
                      responses from two different models. Your task is to guess
                      which model generated each response.
                    </h1>
                    <div className="animate-tada2">
                      <button
                        className="p-[2px] font-semibold text-white rounded-[4px] wavyslow hover:opacity-50 mt-10"
                        onClick={startGame} // Call the startGame function
                      >
                        <div className="w-full h-full px-12 py-2 rounded-[2px] bg-zinc-900">
                          <div className="text-transparent wavy bg-clip-text">
                            Start
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : isGameOver ? (
              <div className="flex items-center justify-center h-full text-xl text-center text-zinc-800">
                {gameOverMessage}
              </div>
            ) : (
              <>
                <div className="relative flex p-2 lg:p-4 h-[73%] md:h-3/4">
                  <div
                    ref={chatContainerRef}
                    className="flex-1 h-full p-6 pr-12 md:pr-20 overflow-y-auto border border-gray-300 max-h-[68vh] rounded-tl-xl relative bg-white"
                    style={{ scrollBehavior: 'smooth' }}
                  >
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`flex mt-4 ${
                          message.isUser ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        {message.loading ? (
                          <div className="flex">
                            <div className="animate-blink">
                              <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full"></div>
                            </div>
                            <div className="text-transparent wavy bg-clip-text">
                              Loading response...
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`m-2 p-2 text-zinc-600 ${
                              message.isUser
                                ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50'
                                : ''
                            }`}
                          >
                            {message.text}
                            {!message.isUser && (
                              <div className="mt-1 text-xs text-zinc-400">
                                {message.totalTokens} Total Tokens
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                    {messages.length > 0 &&
                      !messages[messages.length - 1].loading && (
                        <Transition
                          show={true}
                          enter="transition-opacity duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-500"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="flex flex-col items-center mt-4">
                            <span className="mb-4 text-transparent sm:text-lg wavyslow bg-clip-text">
                              Who generated this response?
                            </span>
                            <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row">
                              {baseModels.map((model) => (
                                <button
                                  key={model.model_name}
                                  className={`px-2 py-1 border rounded ${
                                    selectedOptions.firstResponse ===
                                    model.model_name
                                      ? 'bg-zinc-900 text-zinc-50'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    handleOptionSelect(
                                      'firstResponse',
                                      model.model_name,
                                    )
                                  }
                                >
                                  {model.model_name}
                                </button>
                              ))}
                            </div>
                          </div>
                        </Transition>
                      )}
                    {feedback.first && (
                      <div className="absolute inset-0 flex items-center justify-center text-xl text-center bg-white text-zinc-500">
                        {feedback.first === 'correct'
                          ? 'Correct! 🎉'
                          : `Incorrect, this model is ${adapter.model_name}`}
                      </div>
                    )}
                  </div>

                  <div
                    ref={secondChatContainerRef}
                    className="relative flex-1 h-full p-6 pr-12 md:pr-20 overflow-y-auto border border-gray-300 max-h-[68vh] bg-white rounded-tr-xl"
                    style={{ scrollBehavior: 'smooth' }}
                  >
                    {secondMessages.map((message, index) => (
                      <div
                        key={index}
                        className={`flex mt-4 ${
                          message.isUser ? 'justify-end' : 'justify-start'
                        }`}
                      >
                        {message.loading ? (
                          <div className="flex">
                            <div className="animate-blink">
                              <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full"></div>
                            </div>
                            <div className="text-transparent wavy bg-clip-text">
                              Loading response...
                            </div>
                          </div>
                        ) : (
                          <div
                            className={`m-2 p-2 text-zinc-600 ${
                              message.isUser
                                ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50'
                                : ''
                            }`}
                          >
                            {message.text}
                            {!message.isUser && (
                              <div className="mt-1 text-xs text-zinc-400">
                                {message.totalTokens} Total Tokens
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                    {secondMessages.length > 0 &&
                      !secondMessages[secondMessages.length - 1].loading && (
                        <Transition
                          show={true}
                          enter="transition-opacity duration-500"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-500"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="flex flex-col items-center mt-4">
                            <span className="mb-4 text-transparent sm:text-lg wavyslow bg-clip-text">
                              Who generated this response?
                            </span>
                            <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row">
                              {baseModels.map((model) => (
                                <button
                                  key={model.model_name}
                                  className={`px-2 py-1 border rounded ${
                                    selectedOptions.secondResponse ===
                                    model.model_name
                                      ? 'bg-zinc-900 text-zinc-50'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    handleOptionSelect(
                                      'secondResponse',
                                      model.model_name,
                                    )
                                  }
                                >
                                  {model.model_name}
                                </button>
                              ))}
                            </div>
                          </div>
                        </Transition>
                      )}
                    {feedback.second && (
                      <div className="absolute inset-0 flex items-center justify-center text-xl text-center bg-white text-zinc-500">
                        {feedback.second === 'correct'
                          ? 'Correct! 🎉'
                          : `Incorrect, this model is ${secondAdapter?.model_name}`}
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative p-2 pt-0 md:p-4 md:h-1/4 h-1/6">
                  <textarea
                    className="w-full h-full pt-4 pl-8 bg-white border border-gray-300 shadow resize-none md:pr-36 rounded-bl-xl shadow-zinc-200 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200"
                    placeholder="Enter text here"
                    value={newMessage}
                    onChange={handleNewMessageChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        sendMessage(e);
                      }
                    }}
                  ></textarea>
                  <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 hover:border-indigo-200 hover:rounded-t-lg hover:rounded-l-lg group hover:bg-indigo-50">
                    <button
                      className="flex items-center justify-center w-full h-full"
                      onClick={(e) => {
                        sendMessage(e);
                      }}
                    ></button>
                    <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="absolute items-center justify-center hidden h-10 m-2 border rounded-full bg-indigo-50 w-96 md:h-16 md:w-96 md:left-7 left-5 bottom-7 sm:flex">
                    <div className="absolute inset-0 flex items-center justify-center w-full text-transparent pointer-events-none wavy bg-clip-text">
                      Write your prompt here to get started
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PlaygroundGame;
