import { FaDiceD6, FaDotCircle, FaLayerGroup, FaUbuntu } from 'react-icons/fa';
import { SiPytorch, SiTensorflow, SiPaddlepaddle } from 'react-icons/si';
import { GiSnakeTongue, GiFrog } from 'react-icons/gi';
import { TbBrandDebian } from 'react-icons/tb';
import { BsSoundwave } from 'react-icons/bs';

import JaxIcon from './Icons/JaxIcon';
import HPCIcon from '../../assets/OIT-Icon-HPC 1.svg';
import LightningAi from '../../assets/lightning-ai-icon.svg';
import GiFrogIcon from './Icons/GiFrogIcon';

export const osTypeNames = {
  pytorch: 'PyTorch',
  tensorflow: 'TensorFlow',
  jax: 'JAX',
  ubuntu: 'Ubuntu + CUDA',
  ubuntu_base: 'Ubuntu',
  fsdp: 'Multi-GPU Ready',
  text_to_speech: 'Text to Speech',
  paddlepaddle: 'PaddlePaddle',
  hpc_scicomp: 'HPC/Sci-Comp',
  'ml-in-a-box': 'ML-in-a-Box',
  custom_image: 'Custom Image',
  text_generation: 'No Code LLM',
  mamba: 'Mamba',
  lightning: 'Lightning AI',
  debian_12: 'Debian 12',
  ubuntu_lts: 'Ubuntu 20.04 LTS',
};

export const categories = {
  framework: 'Frameworks',
  os: 'Operating Systems',
  'ml-in-a-box': 'ML-Ready',
  'no-code-llm': 'No-Code',
  'custom-image': 'Custom Image',
};

export const getOsTypeCategory = (category) => {
  return categories[category] || '';
};

export const getOsTypeDisplayName = (osType) => {
  return osTypeNames[osType] || osType;
};

const osTypes = [
  {
    name: 'pytorch',
    displayName: getOsTypeDisplayName('pytorch'),
    category: ['framework'],
    icon: <SiPytorch size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 including Python 3.10',
      'NVIDIA CUDA® 12.2.2',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/pytorch',
    isActive: true,
    canShow: true,
  },
  {
    name: 'tensorflow',
    displayName: getOsTypeDisplayName('tensorflow'),
    category: ['framework'],
    icon: <SiTensorflow size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 including Python 3.10',
      'NVIDIA CUDA® 12.3.0',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/tensorflow',
    isActive: true,
    canShow: true,
  },
  {
    name: 'jax',
    displayName: getOsTypeDisplayName('jax'),
    category: ['framework'],
    icon: <JaxIcon />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 Including Python 3.10',
      'NVIDIA CUDA® 12.2.0',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/jax',
    isActive: true,
    canShow: true,
  },
  {
    name: 'paddlepaddle',
    displayName: getOsTypeDisplayName('paddlepaddle'),
    category: ['framework'],
    icon: <SiPaddlepaddle size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 Including Python 3.10',
      'NVIDIA CUDA® 12.2.3',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/paddlepaddle',
    isActive: true,
    canShow: true,
  },
  {
    name: 'ubuntu_base',
    displayName: getOsTypeDisplayName('ubuntu_base'),
    category: ['os'],
    icon: <FaUbuntu size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04',
      'Jupyter Notebook 6.4.12',
      'NOTE: Base ubuntu image, no python, CUDA, or any other ML/GPU software pre-installed',
    ],
    moreDetailsLink:
      'https://hub.docker.com/layers/library/ubuntu/jammy/images/sha256-bcc511d82482900604524a8e8d64bf4c53b2461868dac55f4d04d660e61983cb?context=explore',
    isActive: true,
    canShow: true,
  },
  {
    name: 'ubuntu',
    displayName: getOsTypeDisplayName('ubuntu'),
    category: ['os'],
    icon: <FaUbuntu size={22} />,
    text: 'Terminal/SSH Access ',
    info: ['Ubuntu 22.04 including Python 3.8', 'NVIDIA CUDA® 12.3.0'],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/cuda',
    isActive: true,
    canShow: true,
  },
  {
    name: 'fsdp',
    displayName: getOsTypeDisplayName('fsdp'),
    category: ['ml-in-a-box'],
    icon: <FaLayerGroup size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Easily train on multiple GPUs in parallel',
      <span>
        Designed for{' '}
        <a
          href="https://pytorch.org/blog/introducing-pytorch-fully-sharded-data-parallel-api/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600"
        >
          FSDP
        </a>{' '}
        and{' '}
        <a
          href="https://pytorch.org/tutorials/beginner/ddp_series_theory.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600"
        >
          DDP
        </a>{' '}
        with{' '}
        <a
          className="text-indigo-600"
          href="https://huggingface.co/docs/accelerate/index"
          target="_blank"
          rel="noopener noreferrer"
        >
          Accelerate
        </a>{' '}
        and{' '}
        <a
          href="https://pytorch.org/tutorials/beginner/dist_overview.html"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 lowercase"
        >
          torch.distributed
        </a>
      </span>,
      'Get started quick with code examples',
    ],
    moreDetailsLink: '/templates/multi-gpu-os-template',
    isActive: true,
    canShow: true,
  },
  {
    name: 'custom_image',
    displayName: getOsTypeDisplayName('custom_image'),
    category: ['custom-image'],
    icon: <FaDotCircle size={22} />,
    text: 'Upload a custom container',
    isActive: true,
    canShow: true,
    info: [
      'Upload your custom container',
      'Customize your environment',
      'Run your own software',
      'click more details for instructions',
    ],
    moreDetailsLink: '/templates/custom-image-guide',
  },
  {
    name: 'text_to_speech',
    displayName: getOsTypeDisplayName('text_to_speech'),
    category: ['ml-in-a-box'],
    icon: <BsSoundwave size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      <span>
        Includes the{' '}
        <a
          href="https://github.com/coqui-ai/TTS/tree/dev"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600"
        >
          coqui-tts
        </a>{' '}
        library setup and ready to go
      </span>,
      <span>
        Built on top of the{' '}
        <a
          href="https://github.com/coqui-ai/TTS/tree/dev"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600"
        >
          NGC Pytorch
        </a>{' '}
        container
      </span>,
      'Get started quick with code examples',
    ],
    moreDetailsLink: 'https://docs.coqui.ai/en/dev/index.html',
    isActive: true,
    canShow: true,
  },

  {
    name: 'hpc_scicomp',
    displayName: getOsTypeDisplayName('hpc_scicomp'),
    category: ['ml-in-a-box', 'framework'],
    icon: <img src={HPCIcon} alt="HPC Computing" width={34} height={34} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 Including C++17 and Fortran 2003',
      'NVIDIA CUDA® 12.3 with cuBLAS, cuSOLVER, cuSPARSE and more, designed for HPC/Scientific-computation workloads',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/nvhpc',
    isActive: true,
    canShow: true,
  },
  {
    name: 'lightning',
    displayName: getOsTypeDisplayName('lightning'),
    category: ['framework'],
    icon: <img src={LightningAi} alt="Lightning AI" width={24} height={24} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 22.04 Including Python 3.10',
      'NVIDIA CUDA® 12.2.2',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/partners/teams/gridai/containers/pytorch-lightning',
    isActive: true,
    canShow: true,
  },
  {
    name: 'mamba',
    displayName: getOsTypeDisplayName('mamba'),
    category: ['ml-in-a-box'],
    icon: <GiSnakeTongue size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      <span>
        Train and Inference models built with the{' '}
        <a
          href="https://github.com/state-spaces/mamba/tree/main"
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600"
        >
          Mamba SSM
        </a>{' '}
        architecture
      </span>,
      'Ubuntu 22.04 Including Python 3.10, with Pytorch and the Mamba SSM library pre-installed',
      'Get started quick with code examples',
    ],
    moreDetailsLink: '/templates/mamba-ready-os-template',
    canShow: true,
    isActive: true,
  },
  {
    name: 'debian_12',
    displayName: getOsTypeDisplayName('debian_12'),
    category: ['os'],
    icon: <TbBrandDebian size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Debian 12 including Python 3.11',
      'NVIDIA CUDA® 12.4.0',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/containers/cuda',
    canShow: true,
    isActive: true,
  },
  {
    name: 'ubuntu_lts',
    displayName: 'Ubuntu 20.04 LTS',
    category: ['os'],
    icon: <FaUbuntu size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'Ubuntu 20.04 including Python 3.10',
      'NVIDIA CUDA® 12.4.0',
      'Jupyter Notebook 6.4.12',
    ],
    moreDetailsLink:
      'https://catalog.ngc.nvidia.com/orgs/nvidia/teams/base/containers/ubuntu/tags',
    isActive: true,
    canShow: true,
  },
  {
    name: 'text_generation',
    displayName: getOsTypeDisplayName('text_generation'),
    category: ['no-code-llm'],
    icon: <GiFrogIcon size={22} />,
    text: 'Terminal/SSH Access',
    info: [
      'No-code LLM',
      'Train and Inference models with no code',
      'Get started quick with code examples',
    ],
    moreDetailsLink: null,
    // moreDetailsLink: '/templates/no-code-llm-os-template',
    canShow: true,
    isActive: false,
  },
  {
    name: 'ml-in-a-box',
    displayName: getOsTypeDisplayName('ml-in-a-box'),
    category: [],
    icon: <FaDiceD6 size={22} />,
    text: 'Machine Learning Tool-Set',
    isActive: false,
    canShow: true,
  },
];

export default osTypes;
