import { useState, useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  AcademicCapIcon,
  CheckCircleIcon,
  HandRaisedIcon,
  MegaphoneIcon,
  RocketLaunchIcon,
  SparklesIcon,
  SunIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';
import {
  BoltIcon,
  ChatBubbleLeftRightIcon,
  CodeBracketIcon,
  EyeIcon,
  HandThumbUpIcon,
  LifebuoyIcon,
  PuzzlePieceIcon,
} from '@heroicons/react/24/solid';

import JobPostings from './JobPostings';
import Footer from '../Components/Footer';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
];
const stats = [
  { label: 'Business was founded', value: '2022' },
  { label: 'Nationalities across our team', value: '30+' },
  { label: '2023 Team growth rate', value: '200%' },
  { label: 'User sign-ups', value: '250+' },
];
const values = [
  {
    name: 'Speak up and ask questions.',
    description:
      'Have the courage to speak up or ask questions. No-one knows everything, and everyone has blind spots. Asking questions isn’t a sign of weakness, it’s the best way to learn.',
    icon: MegaphoneIcon,
  },
  {
    name: 'Seek truth.',
    description:
      'Seek the truth in every interaction, avoid biases and ego when debating the best solutions to problems.',
    icon: EyeIcon,
  },
  {
    name: 'We win as a team, we lose as a team.',
    description:
      'Every success is the success of the entire company. Every failing falls on the shoulders of everyone here. We don’t point fingers in the good or the bad times, and we support each other at all points.',
    icon: UserGroupIcon,
  },
  {
    name: 'Support and collaboration are crucial in all things.',
    description:
      'People here are very friendly and supportive of one another, also not afraid to tell you if you made a mistake and provide constructive ways for improving from these mistakes. Open communication and taking responsibility are crucial for our success.',
    icon: HandThumbUpIcon,
  },
  {
    name: 'Differences matter.',
    description:
      'We recognise people think differently. Where one might prefer writing things down and communicating concisely, others think through voicing their thoughts and coming up with a lot of ideas. There is a time and place for both and we believe in the power of diverse perspectives and collaboration. By bringing together talented individuals from various backgrounds, we create innovative solutions. Our culture is one of inclusivity, respect, and empowerment, enabling everyone to contribute their best.',
    icon: PuzzlePieceIcon,
  },
  {
    name: "We're innovators.",
    description:
      'We keep up to date with new developments and technology and enjoy finding ways to incorporate them in our products. We are capable of reacting quickly to implement the theory and we build on each other by sharing our domain expertise through open weekly talks.',
    icon: SparklesIcon,
  },
  {
    name: 'Talk is cheap. Show me the code.',
    description:
      'We prioritise action over words, focusing on delivering working software and real-world solutions that make a difference.',
    icon: ChatBubbleLeftRightIcon,
  },
  {
    name: 'Agility is our friend.',
    description: 'Move quick, fail fast, learn faster.',
    icon: BoltIcon,
  },
];

const benefits = [
  'Competitive salaries',
  'Flexible work hours',
  '30 days of paid vacation',
  'Generous options package',
  'A great work environment',
  'Office jungle in the making 🌳',
];

export default function Careers() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const jobPostingsRef = useRef(null);

  // Function to scroll to the JobPostings component
  const scrollToJobPostings = () => {
    jobPostingsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const plantsRef = useRef(null);

  return (
    <>
      <div className="bg-zinc-50 font-dmSans">
        <main className="relative isolate">
          {/* Background */}
          <div
            className="absolute inset-x-0 flex justify-center overflow-hidden top-4 -z-10 transform-gpu blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-b from-green-300 via-blue-500 to-purple-600 opacity-25"
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
            />
          </div>
          {/* Header section */}
          <div className="px-6 pt-14 lg:px-8">
            <div className="max-w-2xl pt-24 mx-auto text-center sm:pt-40">
              <h2 className="text-4xl font-bold tracking-tight dark:text-white sm:text-6xl font-spaceG">
                Working at Tromero
              </h2>
              <p className="mt-6 text-lg leading-8 dark:text-gray-300">
                Joining Tromero means becoming part of a team that values
                innovation, creativity, and ownership. Here, we're not just
                colleagues; we're passionate professionals who thrive on pushing
                boundaries and challenging the status quo. Our dedicated team
                combines a strong work ethic with a down-to-earth attitude and a
                good sense of humour. Our environment fosters continuous
                learning and growth, where your ideas are not only welcomed but
                essential in driving our mission forward.
              </p>
            </div>
          </div>
          {/* Content section */}
          <div className="px-6 mx-auto mt-20 max-w-7xl lg:px-8">
            <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
              <div className="grid max-w-xl grid-cols-1 gap-8 text-base leading-7 dark:text-gray-300 lg:max-w-none lg:grid-cols-2">
                <div>
                  <p className="">
                    At Tromero, we strongly believe in two core principles:
                    <br /> <br />
                    <span className="font-bold">1.</span> Democratic access to
                    AI and its importance in today's society;
                    <br />
                    <br />
                    <span className="font-bold">2.</span> Being committed to
                    breaking down the barriers that prevent small businesses,
                    researchers, and individuals from harnessing the power of
                    AI.
                    <br />
                    <br />
                  </p>
                </div>
                <div>
                  <p>
                    By providing affordable and reliable infrastructure for
                    accelerated compute, we empower individuals and
                    organisations of all sizes to explore, experiment, and
                    leverage AI for their specific needs. This democratisation
                    of AI enables a level playing field, where innovative ideas
                    and breakthrough solutions can emerge from diverse
                    perspectives and backgrounds. From the student, to the
                    startup we encourage active dialogue in providing feedback,
                    so we can make sure that whatever you’re working on, we can
                    help.
                  </p>
                </div>
              </div>
              <dl className="grid grid-cols-1 mt-16 gap-x-8 gap-y-12 sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mt-28 lg:grid-cols-4">
                {stats.map((stat, statIdx) => (
                  <div
                    key={statIdx}
                    className="flex flex-col-reverse pl-6 border-l gap-y-3 dark:border-white/20"
                  >
                    <dt className="text-base leading-7 dark:text-gray-300">
                      {stat.label}
                    </dt>
                    <dd className="text-3xl font-semibold tracking-tight dark:text-white">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>

          {/* Values section */}
          <div className="px-6 mx-auto mt-32 max-w-7xl sm:mt-40 lg:px-8">
            <div className="max-w-2xl mx-auto lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">
                Our values
              </h2>
              <p className="mt-6 text-lg leading-8 dark:text-gray-300">
                We adhere to a few fundamental principles that enable our team
                to achieve its maximum potential.
              </p>
            </div>
            <dl className="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 text-base leading-7 dark:text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
              {values.map((value) => (
                <div key={value.name} className="relative pl-9">
                  <dt className="inline font-semibold text-black dark:text-white">
                    <value.icon
                      className="absolute w-5 h-5 text-indigo-500 left-1 top-1"
                      aria-hidden="true"
                    />
                    {value.name}
                  </dt>{' '}
                  <dd className="inline">{value.description}</dd>
                </div>
              ))}
            </dl>
          </div>
          {/* CTA section */}
          <div className="relative mt-32 isolate -z-10 sm:mt-40">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex flex-col items-center max-w-2xl gap-16 px-6 py-16 mx-auto bg-white/5 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
                <div className="relative flex items-center justify-center flex-none object-cover scale-75 md:scale-100 w-96 h-96 rounded-2xl lg:aspect-square lg:h-auto lg:max-w-sm">
                  {/* <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={1.5}
                    className='absolute w-12 h-12 top-4 right-4 stroke-indigo-600'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M12 4.5v15m7.5-7.5h-15'
                    />
                  </svg> */}
                  <div className="flex items-center justify-center w-full h-full ">
                    <img
                      className="h-80 w-80 rounded-2xl lg:aspect-square lg:max-w-sm drop-shadow-2xl"
                      src="/Noname.png"
                      alt=""
                    />
                  </div>
                </div>

                <div className="flex-auto w-full">
                  <h2 className="text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">
                    Join our team
                  </h2>
                  <p className="mt-6 text-lg leading-8 dark:text-gray-300">
                    Driven by innovation and united by a shared vision, we
                    strive to create solutions that make a difference. Here,
                    every voice is heard, every idea valued, and every member
                    empowered to reach their full potential. Join us in our
                    journey to democratising AI.
                  </p>
                  <ul
                    role="list"
                    className="grid grid-cols-1 mt-10 text-base leading-7 dark:text-white gap-x-8 gap-y-3 sm:grid-cols-2"
                  >
                    {benefits.map((benefit) => (
                      <li key={benefit} className="flex gap-x-3">
                        <CheckCircleIcon
                          className="flex-none w-5 h-7"
                          aria-hidden="true"
                        />
                        {benefit}
                      </li>
                    ))}
                  </ul>
                  <div className="flex mt-10">
                    <button
                      onClick={scrollToJobPostings}
                      className="text-sm font-semibold leading-6 text-indigo-600"
                    >
                      See our job postings{' '}
                      <span aria-hidden="true">&rarr;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 flex justify-center overflow-hidden -top-16 -z-10 transform-gpu blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1318/752] w-[82.375rem] flex-none bg-gradient-to-t from-green-300 via-blue-500 to-purple-600 opacity-25"
                style={{
                  clipPath:
                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
                }}
              />
            </div>
          </div>

          {/* Reference section (plantsRef) */}
          {/* <div

            className='mt-32 sm:mt-40 xl:mx-auto xl:max-w-5xl xl:px-8'
          >
            <img
              src='/teamworking.jpg'
              alt=''
              className='aspect-[16/9] w-full object-cover xl:rounded-3xl drop-shadow-2xl'
            />
          </div>
          <div ref={plantsRef} className='w-full h-1 bg-green-500'></div>
          <motion.div
            className='flex items-center justify-center bg-red-500 h-36 w-36'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{amount: 0.99, once: true }}
          />
          */}

          {/* <div ref={plantsRef} className='w-full h-1 bg-green-500'></div> */}

          <div className="relative mt-36 xl:mx-auto xl:max-w-5xl xl:px-8">
            {/* Main Image */}
            <img
              src="/teamworking.jpg"
              alt=""
              className="aspect-[16/9] w-full object-cover xl:rounded-3xl drop-shadow-2xl  z-0"
            />

            {/* Hidden Images */}
            <div>
              <motion.img
                src="/plants.png"
                alt="Hidden Image 1"
                className="absolute top-0 left-0 w-full h-48 mt-12 md:h-96 drop-shadow-xl -z-10 md:mt-0 "
                initial={{ scale: 0.5, y: 10 }}
                whileInView={{ scale: 1, y: -100 }}
                transition={{ duration: 2 }}
                viewport={{ once: true }}
              />
            </div>
            <div className="relative mt-56 rotate-180 -z-10 ">
              {' '}
              {/* Hidden Images */}
              <motion.img
                src="/plants.png"
                alt="Hidden Image 1"
                className="absolute inset-0 w-full h-48 mt-48 md:h-96 drop-shadow-xl md:mt-36 "
                initial={{ scale: 0.5, y: 0 }}
                whileInView={{ scale: 1.05, y: -10 }}
                transition={{ duration: 2 }}
                viewport={{ once: true }}
              />
            </div>
          </div>

          {/* <div className='relative mt-32 sm:mt-40 xl:mx-auto xl:max-w-5xl xl:px-8'>
            <img
              src='/teamworking.jpg'
              alt='Description of Image 1'
              className='absolute object-cover w-1/3 transform rounded-3xl drop-shadow-2xl -rotate-6 -left-10 top-20'
            />
            <img
              src='/team3.jpg'
              alt='Description of Image 2'
              className='absolute object-cover w-1/3 transform rounded-3xl drop-shadow-2xl rotate-6 -right-10 bottom-10'
            />
            <img
              src='/teamworking.jpg'
              alt='Description of Main Image'
              className='relative aspect-[16/9] w-full object-cover rounded-3xl drop-shadow-2xl -z-10'
            />
          </div> */}

          {/* <div className='flex justify-center mx-4 md:mx-auto -mt-10 md:mt-6 md:text-lg leading-8  dark:text-gray-300 md:w-[600px] p-4 rounded-lg shadow-xl text-center bg-gray-50 border-t-2 border-t-[#3a6c37] italic text-sm dark:text-white dark:bg-gray-700'>
            Plants, symbolize not just aesthetic beauty but also embody the
            essence of growth—an essence we hold dear as a high-growth company
            that values progress and expansion.
          </div> */}
        </main>
        <div ref={jobPostingsRef} className="md:pt-10 md:pb-24">
          <JobPostings />
        </div>
      </div>
    </>
  );
}
