/**
 * Function to get the type of logs used to fine-tune a model based on the config file
 * @param {Object} config - the model configuration
 * @returns {string} - the log message
 */
export function getLogMessage(config) {
  const modelConfig = config.model_config;

  if (
    modelConfig.custom_logs_filename &&
    Array.isArray(modelConfig.save_logs_with_tags) &&
    modelConfig.save_logs_with_tags.length > 0
  ) {
    return `Custom logs uploaded, tagged with: ${modelConfig.save_logs_with_tags.join(', ')}`;
  }

  if (modelConfig.custom_logs_filename && !modelConfig.save_logs_with_tags) {
    return `Custom logs uploaded, not tagged`;
  }

  if (
    modelConfig.tags &&
    Array.isArray(modelConfig.tags) &&
    modelConfig.tags.length > 0
  ) {
    return `Logs with tags: ${modelConfig.tags.join(', ')}`;
  }

  if (modelConfig.custom_dataset) {
    return `Dataset: ${modelConfig.custom_dataset}`;
  }

  if (
    !modelConfig.tags &&
    !modelConfig.save_logs_with_tags &&
    !modelConfig.custom_logs_filename &&
    !modelConfig.custom_dataset
  ) {
    return 'All logs';
  }

  return '-';
}

/**
 * Function to return the approximate number of seconds required to train a model based on the number of logs
 * @param {string} model - the model name
 * @param {number} logs - the number of logs
 * @param {number} epochs - the number of epochs
 * @returns {number} - the number of seconds required to train the model
 */
export function requiredSecondsToTrain(model, logs, epochs = 1) {
  const modelLowerCase = model.name.toLowerCase();

  if (/mistral/.test(modelLowerCase)) {
    return 1.048 * logs * epochs + 95.568;
  } else if (/mixtral/.test(modelLowerCase)) {
    return 2.043 * logs * epochs + 593.275;
  } else if (/llama/.test(modelLowerCase)) {
    return 4.884 * logs * epochs + 856.518;
  } else {
    return 0;
  }
}
