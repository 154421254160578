import React, { useState, Fragment, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import articles from '../articlesData';

const Articles = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('All');
  const options = ['All', 'Article', 'Blog', 'Guide'];

  useEffect(() => {
    if (location.state?.title) {
      setSelectedOption(location.state.title);
    }
  }, [location.state]);

  const filteredArticles = articles.filter((article) => {
    if (selectedOption === 'All') {
      return true;
    }
    return article.type === selectedOption;
  });

  const sortedArticles = [...filteredArticles].sort((a, b) => b.id - a.id);

  return (
    <div>
      <section className="min-h-screen bg-zinc-50 dark:bg-gray-800 font-dmSans">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-sm mb-8 lg:mb-16">
            <h2 className="mt-2 mb-4 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-white">
              Articles & Learning
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              Journey through our growth story and see how we tackle industry
              challenges.
            </p>
          </div>

          <div className="w-full ">
            {/* Dropdown for article types */}
            <Menu as="div" className="relative inline-block mb-16 text-left">
              <div>
                <Menu.Button className="inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white h-12 w-full px-4 sm:w-72 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 text-xl font-medium tracking-tight text-gray-900 mb-4 md:mb-0">
                  {selectedOption}
                  {selectedOption !== 'All' && 's'}
                  <ChevronDownIcon
                    className="w-5 h-5 -mr-1 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg sm:w-72 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {options.map((option) => (
                      <Menu.Item key={option}>
                        {({ active }) => (
                          <button
                            onClick={() => setSelectedOption(option)}
                            className={`${
                              active
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-700'
                            } block px-4 py-2 text-sm w-full text-left`}
                          >
                            {option}
                            {option !== 'All' && 's'}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {sortedArticles.map((article) => (
              <Link to={`/articles/${article.blob}`} key={article.id}>
                <article className="px-4 pt-4 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 h-[600px] flex flex-col">
                  <img
                    className="object-cover w-full h-48 mb-5 rounded-lg"
                    src={article.imageUrl}
                    alt={article.title}
                  />
                  <span
                    className={`text-xs font-semibold mr-2 px-2.5 py-0.5 rounded w-fit ${
                      article.type === 'Guide'
                        ? 'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900'
                        : article.type === 'Blog'
                          ? 'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-900'
                          : 'bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900'
                    }`}
                  >
                    {article.type}
                  </span>
                  <h2 className="my-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {article.title}
                  </h2>
                  <div
                    className="mb-4 font-light text-gray-500 dark:text-gray-400 grow"
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  />
                  <div className="flex items-center space-x-4">
                    <img
                      className="w-10 h-10 pb-1 rounded-full"
                      src="/Noname.png"
                      alt="Author avatar"
                    />
                    <div className="font-medium dark:text-white">
                      <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        {article.date} · {article.readTime}
                      </div>
                    </div>
                  </div>
                </article>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Articles;
