import { useCallback } from 'react';
import { useGetBaseModels } from './react-query';

const useBaseModels = () => {
  const {
    data: baseModels = [],
    isLoading: loading,
    error,
  } = useGetBaseModels();

  const getFilteredModels = useCallback(
    (filter) => {
      return baseModels.filter(filter);
    },
    [baseModels],
  );

  return {
    loading,
    error,
    getInferenceBaseModels: useCallback(
      () => getFilteredModels((model) => model.available_for_inference),
      [getFilteredModels],
    ),
    getFineTuningBaseModels: useCallback(
      () => getFilteredModels((model) => model.available_for_finetuning),
      [getFilteredModels],
    ),
    getPlaygroundBaseModels: useCallback(
      () =>
        getFilteredModels(
          (model) =>
            model.available_for_inference &&
            model.model_type === 'language_model',
        ),
      [getFilteredModels],
    ),
    getAllBaseModels: useCallback(() => baseModels, [baseModels]),
  };
};

export default useBaseModels;
