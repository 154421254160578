import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const AdminUserRequest = ({
  filteredRequests,
  filteredDeniedRequests,
  convertAccounts,
  denyAccounts,
}) => {
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [deniedRequests, setDeniedRequests] = useState([]);
  const [activeTab, setActiveTab] = useState('pending');

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedPeople.length > 0 &&
      selectedPeople.length < filteredRequests.length;
    setChecked(selectedPeople.length === filteredRequests.length);
    setIndeterminate(isIndeterminate);
    checkbox.current.indeterminate = isIndeterminate;
  }, [selectedPeople, filteredRequests.length]);

  const handleConvertAccounts = () => {
    const accountRequestIds = selectedPeople.map((person) => person.id);
    const emails = selectedPeople.map((person) => person.email);
    convertAccounts(accountRequestIds, emails);
  };

  const handleDenyAccounts = () => {
    const accountRequestIds = selectedPeople.map((person) => person.id);
    const emails = selectedPeople.map((person) => person.email);
    denyAccounts(accountRequestIds, emails);
  };

  const toggleAll = () => {
    setSelectedPeople(checked || indeterminate ? [] : filteredRequests);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const renderTable = (requests) => (
    <table className="min-w-full divide-y divide-gray-300 table-fixed">
      <thead>
        <tr>
          <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
            <input
              type="checkbox"
              className="absolute w-4 h-4 -mt-2 text-indigo-600 border-gray-300 rounded left-4 top-1/2 focus:ring-indigo-600"
              ref={checkbox}
              checked={checked}
              onChange={toggleAll}
            />
          </th>
          <th
            scope="col"
            className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 uppercase"
          >
            First Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
          >
            Last Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
          >
            Email
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
          >
            Company
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
          >
            Date Requested
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
            <span className="sr-only">Approve</span>
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {requests.map((person) => (
          <tr
            key={person.email}
            className={
              selectedPeople.includes(person) ? 'bg-gray-50' : undefined
            }
          >
            <td className="relative px-7 sm:w-12 sm:px-6">
              {selectedPeople.includes(person) && (
                <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
              )}
              <input
                type="checkbox"
                className="absolute w-4 h-4 -mt-2 text-indigo-600 border-gray-300 rounded left-4 top-1/2 focus:ring-indigo-600"
                value={person.email}
                checked={selectedPeople.includes(person)}
                onChange={(e) =>
                  setSelectedPeople(
                    e.target.checked
                      ? [...selectedPeople, person]
                      : selectedPeople.filter((p) => p !== person),
                  )
                }
              />
            </td>
            <td
              className={classNames(
                'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                selectedPeople.includes(person)
                  ? 'text-indigo-600'
                  : 'text-gray-900',
              )}
            >
              {person.first_name}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
              {person.last_name}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
              {person.email}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
              {person.details.business}
            </td>
            <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
              {person.created_at}
            </td>
            <td className="py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-3">
              <button
                onClick={() => convertAccounts([person.id], [person.email])}
                className="text-indigo-600 hover:text-indigo-900"
              >
                Approve
                <span className="sr-only">, {person.name}</span>
              </button>
            </td>
            <td className="py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-3">
              {activeTab === 'pending' && (
                <button
                  onClick={() => denyAccounts([person.id], [person.email])}
                  className="text-red-600 hover:text-indigo-900"
                >
                  Deny<span className="sr-only">, {person.name}</span>
                </button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <div className="p-8 font-dmSans max-h-[600px] overflow-y-auto">
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="font-sans text-xl dark:text-gray-200">
              User Requests
            </h1>
          </div>
        </div>
        <div className="flex mt-4">
          <button
            onClick={() => setActiveTab('pending')}
            className={classNames(
              'px-4 h-8 rounded-md',
              activeTab === 'pending'
                ? 'bg-indigo-700 text-white'
                : 'bg-white text-indigo-700',
            )}
          >
            Pending
          </button>
          <button
            onClick={() => setActiveTab('denied')}
            className={classNames(
              'px-4 h-8 ml-2 rounded-md',
              activeTab === 'denied'
                ? 'bg-indigo-700 text-white'
                : 'bg-white text-indigo-700',
            )}
          >
            Denied
          </button>
        </div>
        <div className="flow-root mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="relative">
                {selectedPeople.length > 0 && (
                  <div className="absolute top-0 flex items-center h-12 px-20 space-x-3 bg-zinc-50 left-14 sm:left-12">
                    <button
                      type="button"
                      className="inline-flex items-center px-2 py-1 text-sm font-semibold text-gray-900 bg-white rounded shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={handleConvertAccounts}
                    >
                      Convert Selected Accounts
                    </button>
                  </div>
                )}
                {activeTab === 'pending'
                  ? renderTable(filteredRequests)
                  : renderTable(filteredDeniedRequests)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUserRequest;
