import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import SignUpStep1 from '../Components/SignUpStep1';
// import SignUpStep2 from '../Components/SignUpStep2';
// import SuccessModal from '../Components/SuccessModal';

const SignUp = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    address: 'null',
    password: '',
    birthdate: '',
    details: { business: '', tax_id_number: null, tax_id_type: null },
    agreeTerms: false,
    subscribeNewsletter: true,
    roleOptions: '',
    interestOptions: '',
    teamSizeOptions: '',
    country: '',
    city: '',
    postalCode: '',
  });
  // const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleNextClick = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  // const handleBackClick = () => {
  //   if (currentStep > 0) {
  //     setCurrentStep(currentStep - 1);
  //   }
  // };

  // const onFormSubmitSuccess = () => {
  //   setShowSuccessModal(true);
  // };

  // const navigate = useNavigate();

  // useEffect(() => {
  //   let timer;
  //   if (showSuccessModal) {
  //     timer = setTimeout(() => {
  //       navigate('/marketplace');
  //     }, 5000);
  //   }
  //   return () => clearTimeout(timer);
  // }, []);

  const renderStepContent = () => {
    return (
      <SignUpStep1
        onNext={handleNextClick}
        formData={formData}
        setFormData={setFormData}
      />
    );
  };

  return (
    <div className="flex min-h-screen bg-zinc-50">
      <div className=" font-spaceG max-w-[1500px] flex justify-center h-full mx-auto">
        <div className="flex items-center justify-center mt-24 ">
          <div className="rounded-lg ">
            <div className="m-0.5 bg-white dark:bg-gray-800 rounded-md">
              {renderStepContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
