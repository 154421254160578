const TestSelector = ({ selectedTests, setSelectedTests, availableTests }) => {
  return (
    <div className="mb-4 lg:w-1/2 border p-4 rounded-lg">
      <h3 className="font-semibold mb-2">Select Tests</h3>
      <div className="flex gap-2 flex-wrap text-sm">
        {availableTests.map((test) => (
          <label
            key={test}
            className="flex items-center gap-2 border border-gray-200 rounded p-1 px-2"
          >
            <input
              className="relative shadow-sm border border-gray-300 rounded bg-white w-4 h-4 ring-0 appearance-none active:ring-0 checked:bg-indigo-400"
              type="checkbox"
              checked={selectedTests.includes(test)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedTests([...selectedTests, test]);
                } else {
                  setSelectedTests(selectedTests.filter((t) => t !== test));
                }
              }}
            />
            <span className="text-sm">{test}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default TestSelector;
