import ReactApexChart from 'react-apexcharts';
import { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { calculateDuration } from '../../../utils/InstanceUtils';
import DeleteJobButton from '../TrainingMetrics/DeleteJobButton';
import { useUser } from '../../../UserContext';
import Spinner from '../../Spinner';
import { getLogMessage } from '../../../utils/TailorUtils';
import StopTrainingButton from '../TrainingMetrics/StopTrainingButton';
import { v4 as uuidv4 } from 'uuid';

const MyModelsCompletedJob = ({ job, status, setFetchNow, card = false }) => {
  const [finalData, setFinalData] = useState(null);
  const { customAxios } = useUser();
  const [loading, setLoading] = useState(true);
  const [comparisonData, setComparisonData] = useState({});
  const [lossChartData, setLossChartData] = useState({
    series: [],
    options: {},
  });
  const [, setLearningRate] = useState(0);
  const [firstLossValue, setFirstLossValue] = useState(0);
  const [lastLossValue, setLastLossValue] = useState(0);
  const [firstEvalLossValue, setFirstEvalLossValue] = useState(0);
  const [lastEvalLossValue, setLastEvalLossValue] = useState(0);
  const [comparisonChartData, setComparisonChartData] = useState({
    series: [],
    options: {},
  });

  const lossChartRef = useRef(null);
  const comparisonChartRef = useRef(null);

  useEffect(() => {
    setFinalData(null);
    setLoading(true);

    const fetchCompletedJob = async () => {
      try {
        const response = await customAxios.get(
          `tailor/v1/training-info-log/${job.model_id}`,
        );
        setFinalData(response.data.metrics);

        const rate = job?.base_model_data?.default_lr || 0;
        setLearningRate(rate);
      } catch (error) {
        setFinalData(null);
        if (import.meta.env.DEV) {
          console.error('Error fetching training Data:', error);
        }
      } finally {
        setLoading(false);
      }
    };
    fetchCompletedJob();
  }, [job, customAxios]);

  useEffect(() => {
    if (finalData) {
      const preprocessData = (lossArray, evalLossArray) => {
        const cleanedLoss = lossArray.filter((value) => value !== null);

        const normalizedEvalLoss = new Array(cleanedLoss.length).fill(null);
        normalizedEvalLoss[0] = evalLossArray.find((value) => value !== null);
        normalizedEvalLoss[cleanedLoss.length - 1] = evalLossArray
          .reverse()
          .find((value) => value !== null);
        return { cleanedLoss, normalizedEvalLoss };
      };

      const { cleanedLoss, normalizedEvalLoss } = preprocessData(
        finalData.loss,
        finalData.eval_loss,
      );

      setComparisonData((prevState) => ({
        ...prevState,
        cleanedLoss,
        normalizedEvalLoss,
      }));
    }
  }, [finalData]);

  useEffect(() => {
    if (comparisonData.cleanedLoss && comparisonData.normalizedEvalLoss) {
      setLossChartData({
        series: [
          {
            name: 'Training Loss',
            data: comparisonData.cleanedLoss,
          },
          // {
          //   name: 'Validation Loss',
          //   data: comparisonData.normalizedEvalLoss,
          // },
        ],
        options: {
          chart: {
            type: 'line',
            toolbar: {
              show: true,
            },
            animations: {
              enabled: false,
            },
          },
          stroke: {
            curve: 'smooth',
          },
          colors: ['#A5B4FC'],
          xaxis: {
            categories: Array.from(
              { length: comparisonData.cleanedLoss.length },
              (_, i) => `${i + 1}`,
            ),
            title: {
              text: 'Steps',
            },
            stepSize: 10,
          },
          yaxis: {
            title: {
              text: 'Loss',
            },
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            labels: {
              style: {},
              formatter: (val) => {
                return parseFloat(val).toFixed(3);
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
        },
      });
    }
  }, [comparisonData.cleanedLoss, comparisonData.normalizedEvalLoss]);

  useEffect(() => {
    if (comparisonData.cleanedLoss && comparisonData.normalizedEvalLoss) {
      setComparisonChartData({
        series: [
          {
            name: 'Before Fine-Tuning',
            data:
              status !== 'running'
                ? [
                    firstLossValue,
                    firstEvalLossValue ? firstEvalLossValue : null,
                  ]
                : [firstLossValue],
          },
          {
            name:
              status === 'running' ? 'Current Metrics' : 'After Fine-Tuning',
            data:
              status !== 'running'
                ? [
                    lastLossValue,
                    lastEvalLossValue && status !== 'running'
                      ? lastEvalLossValue
                      : null,
                  ]
                : [lastLossValue],
          },
        ],
        options: {
          chart: {
            type: 'bar',
            height: 350,
            animations: {
              enabled: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
            },
          },
          dataLabels: {
            style: {
              colors: ['#333'],
            },
            formatter: function (val) {
              if (val < 0.001) {
                return parseFloat(val).toFixed(5);
              }
              return parseFloat(val).toFixed(3);
            },
          },
          colors: ['#D4D4D8', '#A5B4FC'],
          xaxis: {
            categories:
              status !== 'running'
                ? ['Training Loss', 'Validation Loss']
                : ['Training Loss'],
          },
          yaxis: {
            title: {
              text: 'Loss',
            },
            forceNiceScale: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            labels: {
              formatter: (val) => {
                return parseFloat(val).toFixed(3);
              },
            },
          },
        },
      });
    }
  }, [
    firstLossValue,
    lastLossValue,
    firstEvalLossValue,
    lastEvalLossValue,
    status,
    comparisonData.cleanedLoss,
    comparisonData.normalizedEvalLoss,
  ]);

  // Functions to get first and last values
  const getFirstLossValue = () => {
    for (let i = 0; i < finalData.loss.length; i++) {
      if (finalData.loss[i] !== null) {
        setFirstLossValue(finalData.loss[i]);
        break;
      }
    }
  };

  const getLastLossValue = () => {
    for (let i = finalData.loss.length - 1; i >= 0; i--) {
      if (finalData.loss[i] !== null) {
        setLastLossValue(finalData.loss[i]);
        break;
      }
    }
  };

  const getLastEvalLossValue = () => {
    for (let i = 0; i < finalData.eval_loss.length; i++) {
      if (finalData.eval_loss[i] !== null) {
        setLastEvalLossValue(finalData.eval_loss[i]);
        break;
      }
    }
  };

  const getFirstEvalLossValue = () => {
    for (let i = finalData.eval_loss.length - 1; i >= 0; i--) {
      if (finalData.eval_loss[i] !== null) {
        setFirstEvalLossValue(finalData.eval_loss[i]);
        break;
      }
    }
  };

  useEffect(() => {
    if (finalData) {
      getFirstLossValue();
      getLastLossValue();
      getFirstEvalLossValue();
      getLastEvalLossValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalData, job]);

  useEffect(() => {
    let lossChart = lossChartRef.current;
    let comparisonChart = comparisonChartRef.current;

    return () => {
      if (lossChart) {
        lossChart = null;
      }
      if (comparisonChart) {
        comparisonChart = null;
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-around h-full w-full">
        <div className="flex flex-col items-center gap-2">
          <Spinner size={'36px'} borderTopColor={'zinc'} />
        </div>
      </div>
    );
  }

  if (!finalData) {
    return (
      <>
        <div className="flex flex-col items-center justify-around h-full text-zinc-700 w-full">
          {(job.state === 'deployed' ||
            job.state === 'deploying' ||
            job.state === 'failed_deploy') && (
            <div className="self-start w-full mb-8 text-xs md:text-base">
              <div className="flex items-center gap-8 px-4 py-2 mx-auto border rounded-lg w-fit">
                <div>This model is currently deployed</div>
                <Link
                  to={`../deploy/${job.model_id}`}
                  className="px-4 py-2 font-semibold rounded-lg md:text-sm bg-zinc-200"
                >
                  Go to Deploy
                </Link>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center justify-around h-full gap-2">
            <div className="flex flex-col items-center gap-2 ">
              <div className="text-sm font-semibold text-center md:text-lg max-w-96">
                {new Date(job.created_at) < new Date('jun 17, 2024')
                  ? 'This Model was trained with an earlier version of Tailor and does not have training metrics.'
                  : 'Container loading, data will be available shortly.'}
              </div>
              {status === 'running' && !card && (
                <StopTrainingButton model={job} setFetchNow={setFetchNow} />
              )}

              {new Date(job.created_at) < new Date('jun 17, 2024') && (
                <Link
                  to="/tailor/finetuning"
                  className="px-3 py-1 mx-auto mt-2 text-sm font-normal bg-indigo-200 rounded-md shadow md:text-base text-zinc-900 h-fit-content"
                >
                  Fine-tune a new model
                </Link>
              )}
              {status === 'failed' && !card && (
                <DeleteJobButton model={job} setFetchNow={setFetchNow} />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="p-4  bg-transparent ">
        <div className="mb-4 text-xl font-bold">
          <div className="flex justify-between w-full mb-4">
            <div className="flex flex-wrap items-center text-lg font-medium gap-x-5 gap-y-2">
              <div
                className={clsx(
                  'text-zinc-800',
                  card && 'text-lg font-semibold ',
                )}
              >
                Training Details
              </div>
              <div
                className={clsx(
                  'px-2 text-xs font-light rounded-2xl text-zinc-700 h-fit capitalize',
                  status === 'completed' && 'bg-indigo-200',
                  status === 'failed' && 'bg-red-200',
                  status === 'running' && 'bg-zinc-300',
                  job.state === 'training-cancelled' &&
                    '!bg-zinc-800 !text-white',
                )}
              >
                {job.state === 'training-cancelled' ? 'Cancelled' : status}
              </div>
            </div>
            {status === 'failed' && !card && (
              <DeleteJobButton model={job} setFetchNow={setFetchNow} />
            )}
            {status === 'running' && !card && (
              <StopTrainingButton model={job} setFetchNow={setFetchNow} />
            )}
          </div>
        </div>
        <div className="flex flex-wrap justify-between w-full">
          <div className="text-sm text-zinc-500">
            Trained model{' '}
            <span className="font-bold text-zinc-600">{job.model_name}</span> on{' '}
            <span className="font-bold text-zinc-600">
              {getLogMessage(job)}
            </span>
          </div>
          <div className="text-sm text-zinc-500">
            {status === 'running'
              ? 'Running Time'
              : status === 'failed'
                ? 'Failed in'
                : 'Completed in'}{' '}
            {calculateDuration(
              new Date(job.created_at_unix * 1000).toUTCString(),
              new Date(
                job.training_ended_at_unix
                  ? job.training_ended_at_unix * 1000
                  : new Date(),
              ).toUTCString(),
            )}
          </div>
        </div>
        <hr className="mb-6 border-t border-zinc-300" />
        <div className="mb-6 text-sm md:text-base">
          {status === 'running' ? (
            <strong>Current Metrics:</strong>
          ) : (
            <strong>Final Metrics:</strong>
          )}
          <p>Training Loss: {lastLossValue}</p>
          {status !== 'failed' && status !== 'running' && (
            <p>
              Validation Loss:{' '}
              {lastEvalLossValue ? lastEvalLossValue : 'No validation data'}
            </p>
          )}
        </div>
        {/* <div className="mb-6">
          <strong>Training Duration:</strong>
          <p>
            {calculateDuration(
              new Date(job.created_at).toUTCString(),
              new Date(
                job.completed_at ? job.completed_at : new Date(),
              ).toUTCString(),
            )}
          </p>
        </div> */}
        <div className="mb-6 text-sm md:text-base">
          <strong>Hyperparameters Used:</strong>
          <p>
            Learning Rate:{' '}
            {job?.model_config?.learning_rate ||
              job?.base_model_data?.default_lr}
          </p>
          <p>
            Batch Size:{' '}
            {job.model_config.batch_size ||
              job?.base_model_data?.default_batch_size}
          </p>
          <p>Epochs: {job?.model_config?.epoch || 1}</p>
          <p>
            Percentage of Dataset for Eval:{' '}
            {(job?.model_config?.eval_percentage || 0.05) * 100}%
          </p>
        </div>
        {status !== 'failed' ? (
          <div className="mb-6 text-sm md:text-base">
            <h3 className="mb-1 font-semibold md:text-lg md:flex md:gap-2">
              Performance Comparison{' '}
              {status !== 'running' && <p>(Start/End of Fine-Tuning)</p>}
            </h3>
            <p>
              Training Loss Reduction:{' '}
              {(firstLossValue - lastLossValue).toFixed(4)}
            </p>
            {status !== 'running' && (
              <p>
                Validation Loss Reduction:{' '}
                {(firstEvalLossValue - lastEvalLossValue).toFixed(4)}
              </p>
            )}
          </div>
        ) : null}
        {comparisonChartData?.series?.length > 0 && status !== 'failed' && (
          <div className="mb-6 text-sm md:text-base">
            <h3 className="mb-2 text-sm font-semibold md:text-lg">
              Performance Comparison (Loss)
            </h3>
            <ReactApexChart
              key={uuidv4()}
              ref={comparisonChartRef}
              options={comparisonChartData.options}
              series={comparisonChartData.series}
              type="bar"
              height={350}
            />
          </div>
        )}
        {lossChartData?.series?.length > 0 && (
          <div className="mb-6 text-sm md:text-base">
            <h3 className="mb-2 text-sm font-semibold md:text-lg">
              Model Performance Over Steps (Loss)
            </h3>
            <ReactApexChart
              key={uuidv4()}
              ref={lossChartRef}
              options={lossChartData.options}
              series={lossChartData.series}
              type="line"
              height={350}
            />
          </div>
        )}
      </div>
      {/* {status === 'failed' && (
        <DeleteJobButton model={job} setFetchNow={setFetchNow} />
      )} */}
    </div>
  );
};

export default MyModelsCompletedJob;
