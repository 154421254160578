import { useState } from 'react';
import { toast } from 'react-hot-toast';

/**
 * @function copyToClipboard
 * @description A function to copy text to the clipboard
 * @param {string} code - The text to copy
 * @returns {Promise<void>}
 * */
const copyToClipboard = async (code) => {
  await navigator.clipboard.writeText(code);
};

const CopySvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-5 h-5 bi bi-copy fill-white"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
    />
  </svg>
);

const CopiedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-5 h-5 bi bi-check-all fill-white"
    viewBox="0 0 16 16"
  >
    <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z" />
  </svg>
);

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [buttonText, setButtonText] = useState(CopySvg());

  const handleCopy = async () => {
    try {
      await copyToClipboard(textToCopy);
      toast.success('Text Copied to clipboard');
      setButtonText(CopiedSvg());
      setIsCopied(true);
    } catch (err) {
      toast.error('Could Not Copy Text');
    } finally {
      setTimeout(() => {
        setButtonText(CopySvg());
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <button
      onClick={handleCopy}
      className={`px-4 py-2 mt-2 mb-1 mr-1 text-xs font-bold uppercase transition-all duration-150 ease-linear rounded outline-none ${
        isCopied ? 'bg-green-500' : 'bg-indigo-500'
      } focus:outline-none`}
    >
      {buttonText}
    </button>
  );
};

export default CopyToClipboardButton;
