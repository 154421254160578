import clsx from 'clsx';
import { Modal } from 'flowbite-react';
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import { fileSizeAsString } from '@/utils/generalUtils';
import Spinner from '../../Spinner';

function LogsUpload({
  setFileContent,
  fileContent,
  validationError,
  setValidationError,
  setSelectedFile,
  saveLogsTagList,
  setSaveLogsTagList,
  setValidationInProgress,
  validationInProgress,
  showNextStep = true,
}) {
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [report, setReport] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [totalLines, setTotalLines] = useState(0);
  const [showSaveLogsModal, setShowSaveLogsModal] = useState(false);
  const [tagListErrorMessage, setTagListErrorMessage] = useState('');
  const [saveLogs, setSaveLogs] = useState(false);
  const [tagsTextArea, setTagsTextArea] = useState('');
  const [confirm, setConfirm] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const content = e.target.result;
            validateFileContent(content);
            if (showNextStep) {
              setShowSaveLogsModal(true);
            }
            setConfirm(false);
            setFileContent({
              name: file.name,
              size: fileSizeAsString(file.size),
              numberOfLogs: totalLines,
            });
            setSelectedFile(file);
          } catch (err) {
            setFileContent({
              name: '',
              content: null,
              size: null,
              numberOfLogs: 0,
            });
            setValidationErrorMessage('File validation Failed.');
          }
        };
        reader.readAsText(file);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setFileContent],
  );

  const validateFileContent = (content) => {
    setValidationInProgress(true);
    const lines = content.split('\n');
    let index = 0;
    let hasError = false;

    const validateLine = () => {
      if (index < lines.length && !hasError) {
        const line = lines[index].trim();
        if (line) {
          try {
            const json = JSON.parse(line);
            if (!json.messages || !Array.isArray(json.messages)) {
              throw new Error(
                `Invalid format on line ${index + 1}: "messages" should be an array.`,
              );
            }
            let hasUser = false;
            let hasAssistant = false;
            let roles = [];
            json.messages.forEach((message) => {
              if (!message.role || !message.content) {
                throw new Error(
                  `Invalid format on line ${index + 1}: Each message should have a "role" and "content".`,
                );
              }
              if (message.role === 'user') {
                if (message.content.trim().length === 0) {
                  throw new Error(
                    `Invalid format on line ${index + 1}: "user" message should not be empty.`,
                  );
                } else if (typeof message.content !== 'string') {
                  throw new Error(
                    `Invalid format on line ${index + 1}: "user" message should be a string.`,
                  );
                }
                hasUser = true;
              }
              if (message.role === 'assistant') {
                if (message.content.trim().length === 0) {
                  throw new Error(
                    `Invalid format on line ${index + 1}: "assistant" message should not be empty.`,
                  );
                }
                hasAssistant = true;
              }
              if (
                message.role !== 'system' &&
                message.role !== 'user' &&
                message.role !== 'assistant'
              ) {
                throw new Error(
                  `Invalid role on line ${index + 1}: Each message role should be either "system", "user", "assistant".`,
                );
              }
              roles.push(message.role);
            });
            if (!hasUser) {
              throw new Error(
                `Invalid format on line ${index + 1}: Missing "user" message.`,
              );
            }
            if (!hasAssistant) {
              throw new Error(
                `Invalid format on line ${index + 1}: Missing "assistant" message.`,
              );
            }
            for (let i = 1; i < roles.length; i++) {
              if (roles[i] === roles[i - 1]) {
                throw new Error(
                  `Invalid format on line ${index + 1}: Roles should alternate starting with "user".`,
                );
              }
            }
            if (roles[0] !== 'user' && roles[0] !== 'system') {
              throw new Error(
                `Invalid format on line ${index + 1}: The first role should be "user" or "system".`,
              );
            }
            if (roles[0] === 'system' && roles[1] !== 'user') {
              throw new Error(
                `Invalid format on line ${index + 1}: The role following "system" should be "user".`,
              );
            }
          } catch (err) {
            setReport(`${err.message}`);
            if (import.meta.env.DEV) {
              console.error(`${err.message}`);
            }
            setValidationInProgress(false);
            hasError = true;
            throw err;
          }
        }
        index++;
        setTotalLines((prev) => prev + 1);

        setTimeout(validateLine, 0);
      } else {
        setValidationInProgress(false);
      }
    };

    validateLine();
  };

  useEffect(() => {
    setFileContent((prev) => ({
      ...prev,
      numberOfLogs: totalLines,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLines]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      'application/jsonl': ['.jsonl'],
    },
    maxFiles: 1,
    onDropRejected: (files) => {
      if (files.length === 0) {
        setValidationErrorMessage(
          'Invalid file format. Please upload a .jsonl file.',
        );
      } else {
        setValidationErrorMessage('Only one file can be used.');
      }
    },
    onDragEnter: () => {
      setValidationError('');
      setValidationErrorMessage('');
    },
    onDragLeave: () => {
      setValidationError('');
      setValidationErrorMessage('');
    },
    onDropAccepted: () => {
      setValidationError('');
      setValidationErrorMessage('');
    },
    onFileDialogOpen: () => {
      setValidationError('');
      setValidationErrorMessage('');
    },
  });

  const handleCloseSaveLogsModal = () => {
    setSelectedFile(null);
    setFileContent({
      name: '',
      content: null,
      size: null,
      numberOfLogs: 0,
    });
    setShowSaveLogsModal(false);
    setSaveLogsTagList([]);
    setTagsTextArea('');
    setTagListErrorMessage('');
    setSaveLogs(false);
    setConfirm(false);
  };

  const handleConfirmSaveLogsModal = () => {
    if (saveLogs) {
      if (!tagsTextArea) {
        setTagListErrorMessage('Please enter at least one tag');
        return;
      } else {
        const tags = tagsTextArea.split(',').map((tag) => tag.trim());
        const uniqueTags = [...new Set(tags.filter((tag) => tag))];
        setSaveLogsTagList(uniqueTags);
        setShowSaveLogsModal(false);
        setSaveLogs(false);
        setConfirm(true);
      }
    } else {
      setShowSaveLogsModal(false);
      setSaveLogs(false);
      setSaveLogsTagList([]);
      setConfirm(true);
    }
  };

  const clearFile = () => {
    setFileContent({
      name: '',
      content: null,
      size: null,
      numberOfLogs: 0,
    });
    setSelectedFile(null);
    setTotalLines(0);
    setValidationErrorMessage('');
    setReport('');
    setSaveLogsTagList([]);
    setTagsTextArea('');
    setTagListErrorMessage('');
    setSaveLogs(false);
    setConfirm(false);
  };

  return (
    <>
      <div className="w-full mx-auto relative">
        {validationInProgress ? (
          <div className="flex flex-col items-center justify-center h-40 w-full rounded-lg border-2 shadow border-zinc-300 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-700">
            <p className="text-zinc-500 dark:text-zinc-400 mb-2">
              Validating file...
            </p>
            <Spinner
              size={'32px'}
              borderTopColor={'rgb(156,163,175)'}
              borderColor={'#F9FAFB'}
            />
          </div>
        ) : fileContent.name ? (
          <div className="flex flex-col items-center justify-center min-h-40 py-2 w-full rounded-lg border-2 shadow border-zinc-300 dark:border-zinc-600 bg-zinc-50 dark:bg-zinc-700">
            <p className="text-zinc-500 dark:text-zinc-400 mb-2">
              File Successfully Validated
            </p>
            <p className="text-zinc-500 dark:text-zinc-400">
              Filename: {fileContent.name}
            </p>
            <p className="text-zinc-500 dark:text-zinc-400 mb-1">
              Size: {fileContent.size} - {totalLines} logs
            </p>
            {confirm &&
              (saveLogsTagList.length > 0 ? (
                <p className="text-zinc-500 dark:text-zinc-400  max-w-sm text-center">
                  Logs{' '}
                  <span className="underline underline-offset-2">will</span> be
                  saved after upload. Tag
                  {saveLogsTagList.length > 1
                    ? `s: ${saveLogsTagList.join(', ')}`
                    : `: ${saveLogsTagList[0]}`}
                </p>
              ) : (
                <p className="text-zinc-500 dark:text-zinc-400">
                  Logs{' '}
                  <span className="underline underline-offset-2">will not</span>{' '}
                  be saved.
                </p>
              ))}

            <button
              className="mt-3 text-sm text-zinc-600 underline text-end"
              onClick={(e) => {
                e.preventDefault();
                clearFile();
                open();
              }}
            >
              Select a different file
            </button>
          </div>
        ) : (
          <div
            {...getRootProps()}
            className={clsx(
              'flex h-40 w-full cursor-pointer flex-col items-center justify-center rounded border-2 border-dashed shadow dark:hover:border-zinc-500 dark:hover:bg-zinc-600 transition',
              isDragActive
                ? 'border-blue-600 bg-blue-50'
                : 'border-zinc-300 bg-zinc-50 hover:bg-zinc-100 dark:border-zinc-600 dark:bg-zinc-700',
              (validationError || validationErrorMessage) &&
                'border-red-500 !border-solid',
            )}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p className="text-zinc-500">Drop the files here ...</p>
            ) : (
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className="mb-4 h-8 w-8 text-zinc-500 dark:text-zinc-400"
                  aria-hidden="true"
                  xmlns="http:www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-zinc-500 dark:text-zinc-400">
                  <span className="font-semibold">Click to select</span> or drag
                  and drop
                </p>
                <p className="text-sm text-zinc-500 dark:text-zinc-400 mb-2">
                  Your file will be validated automatically
                </p>
                <p className="text-xs text-zinc-500 dark:text-zinc-400">
                  .jsonl files only
                </p>
              </div>
            )}
            {validationError && (
              <p className="absolute -bottom-4 right-0 text-red-500 text-xs italic">
                {validationError}
              </p>
            )}
            {validationErrorMessage && !validationError && (
              <>
                <div className="absolute -bottom-8 right-1 text-red-500 text-xs italic flex flex-col items-end">
                  {validationErrorMessage}{' '}
                  <button
                    className="underline"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowReport(true);
                    }}
                  >
                    Report
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <button
        className="mt-4 text-sm text-zinc-600 underline text-end"
        onClick={(e) => {
          e.preventDefault();
          setShowExplanationModal(true);
        }}
      >
        How should the file be formatted?
      </button>

      <Modal
        show={showExplanationModal}
        onClose={() => setShowExplanationModal(false)}
      >
        <Modal.Header>
          <p className="capitalize">Prepare the data correctly</p>
        </Modal.Header>
        <Modal.Body>
          <div className="text-sm leading-relaxed md:text-base ">
            <p>
              The file should be a JSONL file, where each line is a JSON object
              with the following structure:
            </p>
            <pre className="mt-4 p-4 bg-zinc-100 dark:bg-zinc-800 rounded-md text-sm font-mono">
              {`{"messages": [{"role": "system","content": "Welcome!"},{"role": "user","content": "Hello!"},{"role": "assistant","content": "Hi there!"}]}
{"messages": [{"role": "user","content": "How are you?"},{"role": "assistant","content": "I'm good, thanks!"}]}`}
            </pre>
            <p className="pt-4">
              Each JSON object should have a "messages" field, which is an array
              of messages. Each message should have a "role" field, which is
              either "user", "assistant" or "system", and a "content" field,
              which is the message content.
            </p>
            <p className="pt-4">
              The file should be in JSONL format, which means that the file
              should have a .jsonl extension. We will validate the file
              automatically when you select it. You can use the file format
              conversion tool to convert from JSON to JSONL.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-end gap-2 justify-between w-full">
            <button
              className="h-12 text-sm font-medium text-center text-white rounded-md w-28 flex-center bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
              onClick={() => setShowExplanationModal(false)}
            >
              Close
            </button>
            <Link
              to="/jsonl-formatter"
              target="_blank"
              rel="noreferrer"
              className="h-12 text-sm font-medium text-center text-white rounded-md w-fit px-3 flex-center bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
            >
              File Format Tool
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showReport} onClose={() => setShowReport(false)}>
        <Modal.Header>
          <p className="capitalize">Validation Report</p>
        </Modal.Header>
        <Modal.Body>
          <div className="text-sm leading-relaxed md:text-base w-full">
            <p>The file could not be validated. Here is the report:</p>
            <pre className="mt-4 p-4 bg-zinc-100 dark:bg-zinc-800 rounded-md text-sm font-mono max-w-full whitespace-normal">
              {report}
            </pre>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-end gap-2">
            <button
              className="h-12 text-sm font-medium text-center text-white rounded-md w-28 flex-center bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
              onClick={() => setShowReport(false)}
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={showSaveLogsModal} onClose={handleCloseSaveLogsModal}>
        <Modal.Header>Save Logs after Upload?</Modal.Header>
        <Modal.Body>
          <div className="text-sm leading-relaxed md:text-base w-full  text-zinc-800">
            <p>Do you want to save the logs for future use?</p>
            <p className="mt-2">
              If you choose not to save them, they will be deleted after they
              are used for training.
            </p>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
              Save Logs
            </label>
            <div className="flex items-center space-x-4 mt-2">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="saveLogs"
                  value="yes"
                  checked={saveLogs === true}
                  onChange={() => setSaveLogs(true)}
                  className="w-5 h-5 bg-zinc-100 border-zinc-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent "
                />
                <span className="ml-2 text-zinc-700 dark:text-zinc-300">
                  Yes
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="saveLogs"
                  value="no"
                  checked={saveLogs === false}
                  onChange={() => {
                    setSaveLogs(false);
                    setTagsTextArea('');
                    setTagListErrorMessage('');
                  }}
                  className="w-5 h-5 bg-zinc-100 border-zinc-300 cursor-pointer md:w-4 md:h-4 text-zinc-800 focus:ring-transparent "
                />
                <span className="ml-2 text-zinc-700 dark:text-zinc-300">
                  No
                </span>
              </label>
            </div>

            {saveLogs && (
              <>
                <div className="mt-4">
                  <label className="block text-sm font-medium text-zinc-700 dark:text-zinc-300">
                    Save by using Tags
                  </label>
                  <textarea
                    className={clsx(
                      'mt-1 block w-full h-24 px-3 py-2 border border-zinc-300 dark:border-zinc-600 rounded-md shadow-sm focus:ring-1 focus:ring-slate-300 focus:outline-none dark:bg-zinc-700 dark:text-zinc-300 placeholder-zinc-400 dark:placeholder-zinc-500 placeholder:text-sm text-sm',
                      tagListErrorMessage && '!border-red-500',
                    )}
                    placeholder="Enter tags separated by commas, case-insensitive."
                    onChange={(e) =>
                      setTagsTextArea(e.target.value.trim().toLowerCase())
                    }
                    autoCapitalize="off"
                    value={tagsTextArea}
                  ></textarea>
                </div>
                {tagListErrorMessage && (
                  <p className="text-red-500 text-xs italic text-right">
                    {tagListErrorMessage}
                  </p>
                )}
              </>
            )}

            {!saveLogs && (
              <div className="mt-4 text-sm text-zinc-700 dark:text-zinc-300">
                <p>Logs will be deleted after they are used for training.</p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex items-center gap-4 ">
            <button
              className="flex items-center justify-center h-10 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100 w-28"
              onClick={handleConfirmSaveLogsModal}
            >
              Confirm
            </button>
            <button
              className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
              onClick={handleCloseSaveLogsModal}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LogsUpload;
