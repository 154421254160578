import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import { useForm, ValidationError } from '@formspree/react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';

import Spinner from '../Components/Spinner';

const JobDetails = ({ jobPostingsData }) => {
  const { jobId } = useParams();
  const job = jobPostingsData.find((posting) => posting.id === parseInt(jobId));
  const [state, handleSubmit] = useForm('xpzvqvnr');
  const [copyFeedback, setCopyFeedback] = useState('');
  const navigate = useNavigate();

  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const shareUrl = `https://marketplace-centre-git-careersdemo-tromero.vercel.app/job-postings/${job.id}`; // Replace with real URL later
  const shareText =
    'Explore Career Opportunities at Tromero: Join us in our commitment to excellence and innovation. Check out our latest job openings.!';
  const shareTitle = `${job.title} at Tromero`;

  const handleLinkedInShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
      '_blank',
    );
  };

  const handleTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`,
      '_blank',
    );
  };

  const handleRedditShare = () => {
    window.open(
      `https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(shareTitle)}`,
      '_blank',
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setCopyFeedback('Link copied!');
        setTimeout(() => setCopyFeedback(''), 2000);
      })
      .catch((err) => console.error('Could not copy text: ', err));
  };

  // let xx = true;
  if (state.succeeded) {
    return (
      <div className="dark:min-h-screen">
        <div className="flex flex-col items-center justify-center p-4 space-y-8 md:my-36 w-[600px] max-w-[1000px] mx-auto border rounded-xl bg-white md:p-12 shadow-xl dark:bg-gray-800">
          <div
            className="absolute inset-x-0 flex justify-center overflow-hidden top-4 -z-10 transform-gpu blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-25"
              style={{
                clipPath:
                  'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
            />
          </div>

          <div className="text-xl font-semibold text-center font-spaceG dark:text-white">
            Application Submitted Successfully
          </div>
          <div className="text-sm text-center text-gray-600 dark:text-gray-200">
            Thank you for your interest in joining our team! We will review your
            submission and get back to you as soon as possible. If your skills
            and experience are a good match for the role, we will contact you to
            discuss the next steps. We appreciate the time you've taken to apply
            and look forward to potentially having you as part of our team!
          </div>

          <div className="flex space-x-4">
            <button
              onClick={() => navigate('/articles')}
              className="px-6 py-2 text-white bg-indigo-500 rounded shadow-lg hover:bg-indigo-600 font-spaceG"
            >
              Articles & Learning
            </button>
            <button
              onClick={() => navigate('/')}
              className="px-6 py-2 text-white bg-black rounded shadow-lg hover:bg-gray-900 font-spaceG"
            >
              Go to Homepage
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <div className="dark:bg-gray-800">
      <div className="relative p-6 wavyslow font-spaceG shadow-3xinner dark:bg-gray-800">
        {/* Gradient Overlay */}
        <div className="absolute inset-0 w-full h-64 bg-gradient-to-l from-transparent via-white to-white dark:bg-gray-800"></div>
        <div className="absolute inset-0 w-full h-64 -z-10 bg-gradient-to-l from-white/50 via-white/50 to-transparent dark:bg-gray-800"></div>

        {/* Text and other content with higher z-index */}
        <div className="relative md:px-48">
          {/* Back Button */}
          <div className="mb-4">
            <Link
              to="/job-postings"
              className="text-indigo-600 hover:underline "
            >
              View all open roles
            </Link>
          </div>

          {/* Team */}
          <h2 className="text-lg font-semibold">{job.team}</h2>

          {/* Job Title */}
          <h1 className="mt-2 text-2xl font-bold lg:text-4xl">{job.title}</h1>

          {/* Full-time and Location */}
          <p className="mt-4">Full-time, London UK</p>

          {/* Apply Button */}
          <div className="mt-6">
            <a
              onClick={scrollToForm}
              className="px-6 py-2 text-white bg-indigo-600 rounded-md cursor-pointer hover:bg-indigo-700"
            >
              Apply
            </a>
          </div>
        </div>
      </div>
      <div className="max-w-[1500px]  mx-auto dark:text-gray-100">
        <div className="flex justify-end" aria-label="Share social media">
          <div class="not-format">
            <button
              onClick={handleLinkedInShare}
              data-tooltip-target="tooltip-linkedin"
              class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
            >
              <svg
                class="w-5 h-5 text-gray-500 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M12.5 8.8v1.7a3.7 3.7 0 0 1 3.3-1.7c3.5 0 4.2 2.2 4.2 5v5.7h-3.2v-5c0-1.3-.2-2.8-2.1-2.8-1.9 0-2.2 1.3-2.2 2.6v5.2H9.3V8.8h3.2ZM7.2 6.1a1.6 1.6 0 0 1-2 1.6 1.6 1.6 0 0 1-1-2.2A1.6 1.6 0 0 1 6.6 5c.3.3.5.7.5 1.1Z"
                  clipRule="evenodd"
                />
                <path d="M7.2 8.8H4v10.7h3.2V8.8Z" />
              </svg>
            </button>

            <button
              onClick={handleTwitterShare}
              data-tooltip-target="tooltip-twitter"
              class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
            >
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  fill="currentColor"
                  d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                />
              </svg>
            </button>
            <div
              id="tooltip-twitter"
              role="tooltip"
              class="inline-block absolute invisible z-10 py-2 px-3 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 transition-opacity duration-300 tooltip dark:bg-gray-700"
            >
              Share on Twitter
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>

            <button
              onClick={handleRedditShare}
              data-tooltip-target="tooltip-reddit"
              class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
            >
              <svg
                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                viewBox="0 0 18 18"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_13676_82300)">
                  <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" />
                  <path
                    d="M15.0004 8.99997C15.0004 8.27365 14.411 7.68418 13.6846 7.68418C13.3267 7.68418 13.011 7.82102 12.7794 8.0526C11.8846 7.41049 10.6425 6.98944 9.27412 6.93681L9.87412 4.12628L11.8215 4.53681C11.8425 5.03155 12.2531 5.43155 12.7583 5.43155C13.2741 5.43155 13.6952 5.01049 13.6952 4.4947C13.6952 3.97891 13.2741 3.55786 12.7583 3.55786C12.3899 3.55786 12.0741 3.76839 11.9267 4.08418L9.7478 3.62102C9.68464 3.61049 9.62148 3.62102 9.56885 3.6526C9.51622 3.68418 9.48464 3.73681 9.46359 3.79997L8.80043 6.93681C7.40043 6.97891 6.1478 7.38944 5.24254 8.0526C5.01096 7.83155 4.68464 7.68418 4.33727 7.68418C3.61096 7.68418 3.02148 8.27365 3.02148 8.99997C3.02148 9.53681 3.33727 9.98944 3.80043 10.2C3.77938 10.3263 3.76885 10.4631 3.76885 10.6C3.76885 12.621 6.11622 14.2526 9.02149 14.2526C11.9267 14.2526 14.2741 12.621 14.2741 10.6C14.2741 10.4631 14.2636 10.3368 14.2425 10.2105C14.6741 9.99997 15.0004 9.53681 15.0004 8.99997ZM6.00043 9.93681C6.00043 9.42102 6.42148 8.99997 6.93727 8.99997C7.45306 8.99997 7.87412 9.42102 7.87412 9.93681C7.87412 10.4526 7.45306 10.8737 6.93727 10.8737C6.42148 10.8737 6.00043 10.4526 6.00043 9.93681ZM11.232 12.4105C10.5899 13.0526 9.36885 13.0947 9.01096 13.0947C8.65306 13.0947 7.42148 13.0421 6.7899 12.4105C6.69517 12.3158 6.69517 12.1579 6.7899 12.0631C6.88464 11.9684 7.04254 11.9684 7.13727 12.0631C7.53727 12.4631 8.40043 12.6105 9.02149 12.6105C9.64254 12.6105 10.4952 12.4631 10.9057 12.0631C11.0004 11.9684 11.1583 11.9684 11.2531 12.0631C11.3267 12.1684 11.3267 12.3158 11.232 12.4105ZM11.0636 10.8737C10.5478 10.8737 10.1267 10.4526 10.1267 9.93681C10.1267 9.42102 10.5478 8.99997 11.0636 8.99997C11.5794 8.99997 12.0004 9.42102 12.0004 9.93681C12.0004 10.4526 11.5794 10.8737 11.0636 10.8737Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_13676_82300">
                    <rect width="18" height="18" />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              onClick={handleCopyLink}
              data-tooltip-target="tooltip-link"
              className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
            >
              {copyFeedback ? (
                // Render Check SVG when link is copied
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 19 19"
                  fill="currentColor"
                >
                  {/* Check SVG Path */}
                  <path
                    d="M5 9l2 2 5-5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  />
                </svg>
              ) : (
                // Render Link SVG otherwise
                <svg
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 19 19"
                >
                  {/* Link SVG Path */}
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11.013 7.962a3.519 3.519 0 0 0-4.975 0l-3.554 3.554a3.518 3.518 0 0 0 4.975 4.975l.461-.46m-.461-4.515a3.518 3.518 0 0 0 4.975 0l3.553-3.554a3.518 3.518 0 0 0-4.974-4.975L10.3 3.7"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-5 md:gap-4 CONTENT">
          {/* Left empty column */}
          <div className="col-span-1">
            {/* This column is left empty or can be used for additional content */}
          </div>

          {/* Center column with main content */}
          <div className="col-span-3 px-4 font-sans text-sm">
            <div className="my-6">
              <strong>Who we are</strong>
              <div className="my-4">
                At Tromero, we're on a mission to revolutionize AI training with
                innovative technologies and methodologies. We're driving the
                evolution of AI to make it more efficient, accessible, and
                impactful.
              </div>
              <div className="mt-2">
                As part of the Tromero team, you'll contribute to groundbreaking
                AI tools and systems, tackling global challenges. You'll join a
                community of experts, where each project is a new opportunity
                for growth and innovation.
              </div>
              <div className="mt-2">
                We're looking for passionate individuals ready to shape the
                future of AI. If you're eager to solve complex problems and make
                a significant impact, join Tromero in our quest to build a
                smarter world through smarter AI.
              </div>
            </div>
            <div className="my-6">
              <strong>
                About the{' '}
                {job.team === 'AI/ML' ? job.team : job.team.toLowerCase()} team
              </strong>
              <div className="my-4">{job.aboutTeam}</div>
            </div>
            <div className="my-6">
              <strong>What you'll do</strong>
              <div className="my-4">{job.whatYoullDo}</div>
            </div>
            <div className="my-6">
              <strong>Qualities we value in a candidate</strong>
              <div className="my-4">{job.qualities}</div>
            </div>

            <div
              ref={formRef}
              className="p-4 mb-24 border rounded-md shadow-lg "
            >
              <form
                action="https://formspree.io/f/xpzvqvnr"
                method="POST"
                enctype="multipart/form-data"
                onSubmit={handleSubmit}
              >
                <div className="space-y-12 ">
                  <div className="pb-6 border-b border-gray-900/10">
                    <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                      Apply for this Role
                    </h2>

                    <div className="grid grid-cols-1 mt-4 gap-x-6 gap-y-8 sm:grid-cols-6"></div>
                  </div>

                  <div className="pb-12 border-b border-gray-900/10">
                    <div className="grid grid-cols-1 mt-4 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="hidden jobTitleInput">
                        <div>
                          <input
                            type="text"
                            name="position"
                            id="position"
                            value={job.title}
                          />
                        </div>
                      </div>
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                        >
                          First name*
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                        >
                          Last name*
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="last-name"
                            id="last-name"
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                          />
                        </div>
                      </div>

                      {/* Email Address */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                        >
                          Email address*
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                          />
                          <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                          />
                        </div>
                      </div>

                      {/* City */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="location-city"
                          className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                        >
                          Location (City)*
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                      >
                        LinkedIn Profile
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="linkedin"
                          id="linkedin"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                      >
                        Github Profile
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="github"
                          id="github"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder=""
                        />
                      </div>

                      {/* About Yourself Text Box */}
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="about-yourself"
                          className="block mt-6 text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                        >
                          What interests you about this role?*
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="about-yourself"
                            name="about-yourself"
                            rows="4"
                            className="block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="Share a little about why you want to work here..."
                            required
                          ></textarea>
                        </div>
                      </div>
                      {/* File inputs for Resume and Cover Letter */}
                      <div className="grid grid-cols-1 mt-10 gap-x-6 gap-y-8 sm:grid-cols-6">
                        {/* Resume Upload */}
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="resume"
                            className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-200"
                          >
                            Resume *
                            <span className="text-gray-600">
                              {' '}
                              (Upload in .pdf, .doc, or .docx format)
                            </span>
                          </label>
                          <div className="mt-2">
                            <div class="max-w-lg mx-auto">
                              <input
                                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                aria-describedby="input resume"
                                id="resume"
                                type="file"
                                name="resume"
                                accept=".pdf,.doc,.docx,.txt"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end mt-6 gap-x-6">
                  <button
                    type="submit"
                    className="flex items-center justify-center w-48 h-10 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={state.submitting}
                  >
                    {state.submitting ? <Spinner /> : 'Submit Application'}
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* Right empty column */}
          <div className="col-span-1">
            {/* This column is left empty or can be used for additional content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
