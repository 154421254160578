import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';

const EditMessageModal = ({
  isOpen,
  setIsOpen,
  initialMessage = '',
  logId,
  isSynthetic,
  fetchLogs,
}) => {
  const [currentMessage, setCurrentMessage] = useState(initialMessage);
  const [error, setError] = useState('');
  const { customAxios } = useUser();

  useEffect(() => {
    setCurrentMessage(initialMessage);
  }, [initialMessage]);

  const closeModal = () => {
    setIsOpen(false);
    setCurrentMessage('');
    setError('');
    fetchLogs();
  };

  const handleSave = async () => {
    const trimmedMessage = currentMessage.trim();
    if (!trimmedMessage) {
      setError('Message cannot be empty.');
      return;
    }

    const payload = isSynthetic
      ? {
          synthetic_messages: [{ content: trimmedMessage, role: 'assistant' }],
        }
      : { messages: [{ content: trimmedMessage, role: 'assistant' }] };

    try {
      const response = await customAxios.post(
        `tailor/v1/edit_training_log/${logId}`,
        payload,
      );

      if (response.data.status === 'success') {
        toast.success('Message updated successfully');

        closeModal();
      } else {
        toast.error('Failed to update message');
      }
    } catch (error) {
      toast.error('Failed to save message.');
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 font-dmSans"
        onClose={closeModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
                <button
                  type="button"
                  className="absolute text-gray-400 top-3 right-3 hover:text-gray-500"
                  onClick={closeModal}
                >
                  <XMarkIcon className="w-5 h-5" />
                </button>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Edit Message
                  </Dialog.Title>
                </div>
                <div className="mt-4">
                  <textarea
                    rows="5"
                    value={currentMessage}
                    onChange={(e) => {
                      setCurrentMessage(e.target.value);
                      setError('');
                    }}
                    placeholder="Edit message"
                    className="w-full p-2 mt-2 border border-gray-300 rounded-md focus:ring-0 focus:ring-indigo-600 focus:outline-none focus:ring-opacity-50"
                  />
                  {error && (
                    <p className="mt-2 text-sm text-red-600">{error}</p>
                  )}
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold bg-indigo-200 rounded-md shadow-sm text-zinc-800 hover:bg-indigo-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditMessageModal;
