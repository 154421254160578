import { Modal } from 'flowbite-react';

import CopyToClipboardButton from '../CopyToClipboardButton';

const OobaboogaModal = ({ showModal, closeModal, password, redirectUrl }) => {
  const handleSetupClick = () => {
    const notebookUrl = redirectUrl;
    window.open(notebookUrl, '_blank', 'noopener,noreferrer');
  };
  return (
    <Modal show={showModal} onClose={closeModal} className="font-spaceG">
      <Modal.Header>
        <p>Here are your credentials</p>
      </Modal.Header>
      <Modal.Body>
        <div className="text-sm font-semibold leading-relaxed md:text-base ">
          User Name:
          <div className="px-4 py-2 mt-2 text-sm font-normal text-gray-500 break-words border-2 rounded md:text-base">
            <div>tromero_client</div>
          </div>
        </div>
        <div className="my-4 text-sm font-semibold leading-relaxed md:text-base ">
          Password:
          <div className="flex px-4 py-2 mt-2 text-sm font-normal text-gray-500 border-2 rounded md:text-base">
            <div className="pr-4 break-words text-wrap overflow-x-clip">
              {password}
            </div>
            <div>
              <CopyToClipboardButton textToCopy={password} />
            </div>
          </div>
        </div>
        <div className="text-sm font-semibold leading-relaxed md:text-base ">
          URL:
          <div className="px-4 py-2 mt-2 text-sm font-normal text-gray-500 break-words border-2 rounded md:text-base">
            {redirectUrl}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-end gap-2">
          <button
            className="flex items-center justify-center w-full h-12 text-sm font-medium text-center text-white rounded-md sm:w-24 bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
            onClick={handleSetupClick}
          >
            Continue
          </button>
          <div className="text-xs text-gray-500">
            Clicking Continue will open a new tab with the URL provided.
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default OobaboogaModal;
