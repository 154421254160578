import { Modal } from 'flowbite-react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';

export const PaymentPolicyTailorListItems = () => {
  return (
    <>
      <li>
        Users are billed based on the number of tokens processed during
        inference and any additional resources required for fine-tuning the
        models.
      </li>
      <li>
        For fine-tuning, we reserve the necessary hardware from the marketplace
        at standard rates, based on the selected base model and the number of
        data samples used. You will always be shown an estimate before you
        proceed.
      </li>
      <li>
        For Inference, the price per token varies based on the selected base
        model.
      </li>
      <li>
        Check the{' '}
        <span>
          <Link to="/pricing" className="text-indigo-500">
            pricing table
          </Link>
        </span>{' '}
        for the latest rates.
        <br />
        You only pay for what you use, with no hidden fees.
      </li>
    </>
  );
};

export const PaymentPolicyListItems = () => {
  return (
    <>
      <li>
        Users are required to maintain a{' '}
        <strong>valid payment method on file</strong> and ensure their{' '}
        <strong>account balance is positive</strong> to rent an instance.
      </li>
      <li>
        Charges for rentals accrue in <strong>15-minute increments</strong> and
        are deducted from your balance. If your balance becomes insufficient,
        your instance will be <strong>terminated,</strong> and all session data
        will be <strong>deleted.</strong>
      </li>
      <li>
        The prices displayed for the credit purchases as well as the auto top-up
        amounts do not include VAT. VAT will be added at checkout by Stripe
        based on the VAT rate in your location. The final price you pay will be
        the price for the credits as well as the VAT.
      </li>
      {/* <li>
        We place a <strong>hold for the equivalent of 1 day's rental</strong>{' '}
        when you start a rental. This hold is converted to a payment after 24
        hours, and a new hold is placed for the next 24 hours. This process
        repeats until the rental ends.
      </li> */}

      {/* <li>
        You may see <strong>multiple transactions</strong> from us each day,
        including both holds and payments. Holds are typically released within
        24 hours. For delays, please contact your bank.
      </li> */}
      {/* <li>
        If you have <strong>credits in your account,</strong> we will still
        place the same hold but will deduct the rental cost from your credits
        before charging your card.
      </li> */}
      {/* <li>
        <strong>Grace period:</strong> If a rental ends within 5 minutes, no charges are incurred.
      </li> */}
      {/* <li>
        <strong className="underline underline-offset-2">Important:</strong> If
        we cannot place a hold, the rental will not be approved. For active
        rentals, this means that the rental will be terminated, and all session
        data will be <strong>deleted.</strong>
      </li> */}
      {/* <li>
        <strong>Removing a Stored Card:</strong> While you have active rentals,
        the card associated with that rental cannot be removed. If there are no
        active rentals, you can remove the card from your account. If you have
        multiple cards stored, you can set a different card as the default.
      </li> */}
    </>
  );
};

export const HowBillingWorks = () => {
  return (
    <Disclosure
      as="div"
      className={`w-full text-left text-gray-900 px-4 py-2 mt-4 rounded-lg  dark:bg-gray-700 dark:text-gray-200 bg-indigo-50 dark:outline dark:outline-gray-200/10`}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-900">
            <div
              className={`text-md font-semibold text-gray-600 dark:text-white`}
            >
              How Our Billing Works:
            </div>
            <span className="flex items-center ml-6 h-7">
              {open ? (
                <svg
                  className="w-6 h-6 text-indigo-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 15l7-7 7 7"
                  />
                </svg>
              ) : (
                <svg
                  className="w-6 h-6 text-indigo-600 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              )}
            </span>
          </Disclosure.Button>

          <Disclosure.Panel
            as="dd"
            className="pr-12 mt-2 font-sans text-gray-600"
          >
            <ul
              className={`pl-4 text-xs space-y-1 text-gray-600 list-disc list-outside dark:text-gray-200`}
            >
              <PaymentPolicyListItems />
            </ul>
            <div className="mt-4 text-xs text-gray-600 list-disc dark:text-gray-400">
              By adding a Card to your account, you agree to our{' '}
              <span className="text-indigo-600 underline dark:text-gray-300 hover:text-indigo-800 dark:hover:text-gray-400 underline-offset-2">
                <Link to="/terms">Terms of Service</Link>
              </span>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

const HowPaymentWorksModal = ({ showModal, closeModal }) => {
  return (
    <Modal show={showModal} onClose={closeModal} dismissible>
      <Modal.Header>
        <div>How Payment Works</div>
      </Modal.Header>
      <Modal.Body>
        <ul className="pl-4 mb-2 space-y-3 text-sm text-gray-600 list-disc dark:text-gray-400">
          <PaymentPolicyListItems />
        </ul>
        <div className="mt-4 text-sm text-gray-600 list-disc dark:text-gray-400">
          By adding a Card to your account, you agree to our{' '}
          <span className="text-indigo-600 underline dark:text-gray-300 hover:text-indigo-800 dark:hover:text-gray-400 underline-offset-2">
            <Link to="/terms">Terms of Service</Link>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowPaymentWorksModal;
