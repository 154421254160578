export class NetworkError extends Error {}
export class ValidationError extends Error {}

export class CustomError extends Error {
  constructor({ code, message, status }) {
    super(message);
    this.code = code;
    this.status = status;
  }
}

export class CustomAPIError extends Error {
  constructor({ data, status }) {
    super();
    this.response = { data };
    this.name = 'CustomAPIError';
    this.status = status;
  }
}
