import { useState, useEffect } from 'react';
import moment from 'moment';

const LastUpdated = ({ date }) => {
  const [timeDifference, setTimeDifference] = useState('');

  useEffect(() => {
    const lastUpdatedDate = moment(date);

    const calculateTimeDifference = () => {
      const timeDifferenceText = lastUpdatedDate.fromNow();

      setTimeDifference(timeDifferenceText);
    };

    calculateTimeDifference();

    const intervalId = setInterval(calculateTimeDifference, 60000);

    return () => clearInterval(intervalId);
  }, [date]);

  return (
    <div className="flex m-2 text-sm text-gray-500 dark:text-gray-400 whitespace-nowrap">
      Updated:
      {date ? (
        <span className="ml-2 font-mono"> {timeDifference}</span>
      ) : (
        'Not fetched yet'
      )}
    </div>
  );
};

export default LastUpdated;
