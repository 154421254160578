import React, { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {
  BugAntIcon,
  ChatBubbleLeftRightIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/outline';

import Faq from '../Components/LandingPage/Faq2';
import Spinner from '../Components/Spinner';

const ContactUs = () => {
  const [state, handleSubmit] = useForm('mnqkwwbv');
  const [activeSection, setActiveSection] = useState(null);
  const userEmail = localStorage.getItem('email') || '';

  const handleSectionClick = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const renderForm = () => (
    <div className="max-w-screen-md py-8 mx-auto lg:py-4">
      {state.succeeded ? (
        <div>
          <h2 className="mb-4 text-2xl font-bold tracking-tight text-center sm:text-3xl text-slate-900 dark:text-white">
            Thank You!
          </h2>
          <p className="mb-8 text-center lg:mb-16 text-slate-500 dark:text-slate-400 sm:text-xl ">
            Your message has been sent. We will get back to you soon.
          </p>
        </div>
      ) : (
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="mb-4 text-xl font-semibold">
            {activeSection} Contact{' '}
          </div>
          <p className=" sm:text-center text-slate-500 dark:text-gray-200 sm:text-lg"></p>

          {userEmail ? (
            <input type="hidden" name="email" value={userEmail} />
          ) : (
            <>
              <label
                className="block mb-2 text-sm font-medium text-slate-900 dark:text-gray-200"
                htmlFor="email"
              >
                Email Address
              </label>
              <input
                className="shadow-sm  border border-gray-300 text-slate-900 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full sm:w-1/2 p-2.5 "
                placeholder="name@email.com"
                id="email"
                type="email"
                name="email"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />
            </>
          )}
          <label
            className="block mb-2 text-sm font-medium text-slate-900 dark:text-gray-200"
            htmlFor="message"
          >
            Message
          </label>
          <textarea
            className="block p-2.5 w-full text-sm text-slate-900  rounded-lg shadow-sm border border-gray-300 focus:ring-slate-500 focus:border-slate-500 "
            placeholder="Leave a message..."
            id="message"
            name="message"
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button
            className="flex items-center justify-center w-full h-10 text-sm font-medium text-center text-white rounded sm:w-24 bg-zinc-800 hover:bg-zinc-700 focus:ring-4 focus:outline-none focus:ring-slate-300 "
            type="submit"
            disabled={state.submitting}
          >
            {state.submitting ? <Spinner /> : 'Submit'}
          </button>
        </form>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-zinc-50 dark:bg-gray-800">
      <div className=" max-w-[800px] mx-auto ">
        <div className="px-6 pt-24 bg-zinc-50 dark:bg-gray-800 isolate lg:px-8">
          <div className="mx-auto ">
            <h2 className="mb-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl font-dmSans dark:text-gray-200">
              Contact Us
            </h2>

            <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
              {/* General Inquiries Section */}
              <button
                className="p-6 text-left border border-gray-300 rounded-lg shadow-sm cursor-pointer hover:shadow hover:dark:bg-gray-900/30 hover:bg-gray-50"
                onClick={() => handleSectionClick('General Inquiries')}
              >
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-200">
                  General Inquiries
                </h3>
                <p className="mt-2 text-gray-600">{/* ... */}</p>
                <div className="mt-4 text-sm font-semibold text-indigo-600 dark:text-indigo-400">
                  Contact us <span aria-hidden="true">&rarr;</span>
                </div>
              </button>

              {/* Bug Reports Section */}
              <button
                className="p-6 text-left border border-gray-300 rounded-lg shadow-sm cursor-pointer hover:shadow hover:dark:bg-gray-900/30 hover:bg-gray-50"
                onClick={() => handleSectionClick('Bug Reports')}
              >
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-200">
                  Bug Reports
                </h3>
                <p className="mt-2 text-gray-600">{/* ... */}</p>
                <div className="mt-4 text-sm font-semibold text-indigo-600 dark:text-indigo-400">
                  Report a bug <span aria-hidden="true">&rarr;</span>
                </div>
              </button>

              {/* Technical Support Section */}
              <button
                className="p-6 text-left border border-gray-300 rounded-lg shadow-sm cursor-pointer hover:shadow hover:dark:bg-gray-900/30 hover:bg-gray-50"
                onClick={() => handleSectionClick('Technical Support')}
              >
                <h3 className="text-lg font-semibold leading-7 text-gray-900 dark:text-gray-200">
                  Technical Support
                </h3>
                <p className="mt-2 text-gray-600">{/* ... */}</p>
                <div className="mt-4 text-sm font-semibold text-indigo-600 dark:text-indigo-400">
                  Tech Support <span aria-hidden="true">&rarr;</span>
                </div>
              </button>
            </div>

            {/* Form */}
            {activeSection && <div className="mt-8"> {renderForm()}</div>}
          </div>
        </div>
        <div className="w-full mx-auto">
          <Faq />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
