import { useState } from 'react';

import HowPaymentWorksModal from './Modals/HowPaymentWorksModal';

const HowPaymentWorksLink = () => {
  const [showHowPaymentWorksModal, setShowHowPaymentWorksModal] =
    useState(false);
  return (
    <>
      <div className="mt-4 break-normal dark:text-white text-wrap">
        <button
          onClick={() => setShowHowPaymentWorksModal(true)}
          className="text-indigo-700 underline dark:text-gray-300 hover:text-indigo-800 dark:hover:text-gray-400 underline-offset-2"
        >
          How does payment work? - Our payment policy
        </button>
      </div>
      <HowPaymentWorksModal
        showModal={showHowPaymentWorksModal}
        closeModal={() => setShowHowPaymentWorksModal(false)}
      />
    </>
  );
};

export default HowPaymentWorksLink;
