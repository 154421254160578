import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

export default function TailorFileFormatTool() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState('');

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      setFile(null);
      setFileName('');
      return;
    }
    setFile(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name.replace(/\.[^/.]+$/, '') + '.jsonl');
    setError(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/json': ['.json'],
    },
    maxFiles: 1,
    onDragEnter: () => {
      setError(null);
    },
    onDragLeave: () => {
      setError(null);
    },
    onDropAccepted: () => {
      setError(null);
    },
    onFileDialogOpen: () => {
      setError(null);
    },
  });

  const convertFile = () => {
    if (!file) {
      setError('Please select a file');
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        let newJsonl = '';

        const processMessages = (messages, additionalKeys) => {
          const filteredMessages = messages
            .map((obj) => {
              const { role, content, ...rest } = obj;
              if (role && content) {
                const formattedContent =
                  content && typeof content === 'object' && 'text' in content
                    ? content.text
                    : content;
                return { role, content: formattedContent, ...rest };
              }
              return null;
            })
            .filter(Boolean);

          return filteredMessages.length > 0
            ? JSON.stringify({
                messages: filteredMessages,
                ...additionalKeys,
              }) + '\n'
            : null;
        };

        if (Array.isArray(json)) {
          newJsonl = json
            .map((obj) => {
              const additionalKeys = { ...obj };
              delete additionalKeys.messages;
              delete additionalKeys.conversation;
              return processMessages(
                obj.messages || obj.conversation,
                additionalKeys,
              );
            })
            .filter(Boolean)
            .join('');
        } else {
          const messages = json.messages || json.conversation;
          const additionalKeys = { ...json };
          delete additionalKeys.messages;
          delete additionalKeys.conversation;
          if (messages) {
            newJsonl = processMessages(messages, additionalKeys);
          } else {
            setError('Invalid JSON file');
            return;
          }
        }
        setError(null);
        downloadJsonl(newJsonl);
      } catch (e) {
        console.error(e);
        setError('Invalid JSON file');
      }
    };
    reader.readAsText(file);
  };

  const downloadJsonl = (content) => {
    const blob = new Blob([content], { type: 'application/jsonl' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
  };

  return (
    <div className="min-h-screen bg-zinc-800 text-white flex flex-col items-center justify-center p-6 font-dmSans min-w-full overflow-hidden">
      <Link to="/tailor">
        <header className="flex items-center mb-8">
          <img
            src="/LogoWhite.png"
            alt="Tromero Logo"
            className="h-28 w-28 mr-4"
          />
          <h1 className="text-4xl">JSONL File Format Tool</h1>
        </header>
      </Link>
      <p className="mb-8 text-center text-lg max-w-2xl">
        Welcome to the Tromero Tailor™️ File Format Tool. This tool allows you
        to upload a JSON file containing your conversation logs and converts it
        into a JSONL file. The JSON file should contain a "messages" or
        "conversation" key with an array of objects, each having a "role" and
        "content" key. Use this tool to format your logs for fine-tuning your
        models.
      </p>
      <div
        {...getRootProps()}
        className={`border-4 p-6 mb-4 rounded-lg w-full max-w-md text-center cursor-pointer h-56 flex-center ${
          isDragActive ? 'border-indigo-300 !bg-zinc-600' : 'border-zinc-300'
        } ${file ? 'bg-zinc-700 !border-transparent' : 'bg-zinc-800'}`}
      >
        <input {...getInputProps()} />
        <div className="text-lg">
          {file ? (
            `File selected: ${file.name}`
          ) : isDragActive ? (
            'Drop the file here'
          ) : (
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-100 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http:www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-100 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-sm text-gray-100 dark:text-gray-400 mb-2">
                Your file will be validated automatically
              </p>
              <p className="text-xs text-gray-100 dark:text-gray-400">
                .json files only
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="relative grid grid-cols-3 w-full max-w-md">
        <div></div>
        <button
          onClick={convertFile}
          className="bg-indigo-200 hover:bg-indigo-300 text-zinc-900 py-2 px-4 rounded text-nowrap w-fit justify-self-center"
        >
          Convert & Download
        </button>
        <button
          onClick={() => {
            setFile(null);
            setFileName('');
            setError(null);
          }}
          className=" text-gray-100 px-2 py-2 flex-center rounded border-gray-100 border w-fit h-fit justify-self-end mr-1"
        >
          <ArrowPathIcon className="h-5 w-5 inline-block" />
        </button>
        {error && (
          <p className="text-red-500 absolute -bottom-10 left-0 right-0 text-center">
            {error}
          </p>
        )}
      </div>
      <a
        href="https://www.tromero.ai"
        target="_blank"
        rel="noreferrer"
        className="absolute bottom-0 right-0 p-4 underline underline-offset-4"
      >
        Learn more about Tromero AI
      </a>
    </div>
  );
}
