import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  const handleRenewClick = (e) => {
    e.preventDefault();
    window.Cookiebot.renew();
  };

  return (
    <div className="my-12 font-sans max-w-[1500px] mx-auto px-4">
      <h1>Privacy Policy</h1>
      <p>
        <br />
        <strong>Effective Date:</strong> 6 March 2024
      </p>
      <p>
        <strong>Last updated:</strong> 4 July 2024
      </p>
      <br />
      <p>
        This Privacy Policy ("<b>Policy</b>") outlines the practices and
        procedures of Tromero Ltd ("<b>we</b>," "<b>our</b>," or "<b>us</b>")
        concerning the collection, use, and disclosure of personal data from
        users of our website, mobile applications, and any other online services
        we provide (collectively referred to as the "<b>Services</b>").
      </p>
      <br />
      <h2 className="mt-4 font-semibold">Legal Basis of Processing</h2>
      <p>
        We use your personal information only as permitted by law. Applicable
        data protection law requires us to have a “legal basis” for each purpose
        for which we collect your personal information. However, we will
        normally process personal information from you when:
      </p>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          We have your consent to do so (you can withdraw your consent at any
          time by contacting us at{' '}
          <a href="mailto:dpo@tromero.ai" className="underline ">
            dpo@tromero.ai
          </a>
          );
        </li>
        <li>
          We have a contract with you and it is necessary to process your
          personal information to perform our contract with you, including to
          provide you with the benefits of the Intuit Platform and operate our
          business;
        </li>
        <li>
          The processing is in our legitimate business interests, such as
          operating our businesses, improving and developing the Intuit
          Platform, communicating with you, marketing our offerings and services
          and personalizing your experience, and to detect illegal activities;
          and/or
        </li>
        <li>
          To comply with legal requirements, including applicable laws and
          regulations.
        </li>
      </ul>
      <br />
      <h2 className="mt-4 font-semibold">1. What is Personal Data?</h2>
      <br />
      <p>
        When we use the term “personal data” in this Policy, we mean any
        information relating to an identified or identifiable natural person
        (‘data subject’); an identifiable natural person is one who can be
        identified, directly or indirectly, in particular by reference to an
        identifier such as a name, an identification number, location data, an
        online identifier or to one or more factors specific to the physical,
        physiological, genetic, mental, economic, cultural or social identity of
        that natural person.
      </p>
      <h2 className="mt-4 font-semibold">2. How We Collect Personal Data:</h2>
      <h3 className="mt-4 font-semibold">Personal data you provide:</h3>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          <strong>Account Information:</strong> including first and last name,
          company name, business email address, user ID and password, and any
          other information you provide to us. We use this information to
          administer your account, provide you with the relevant Services and
          information, communicate with you regarding your account and your use
          of the Service, and for customer support purposes. Please note we
          utilize a third-party provider to process payments on our behalf and
          do not accept payment directly through our Services.
        </li>
        <li>
          <strong>Payment Information:</strong> If you sign up for one of our
          Services requiring payment (i.e., the Tromero GPU marketplace or
          Tromero Tailor), we collect the information provided in connection
          with such payment. Please note that we use third-party payment
          processors, including Stripe, to process credit card payments made to
          us. As such, we do not retain any personally identifiable financial
          information in connection with credit card payments, such as credit
          card numbers. Rather, all such information is provided directly by you
          to our third-party processor. The payment processor’s use of your
          personal data is governed by their privacy notice.
        </li>
        <li>
          <strong>Inquiry and Communications Information:</strong> such as your
          name, company name, or email address, and including information
          provided in custom messages sent through, our “Contact Us” feature, or
          to one of our email addresses. This also includes contact information
          provided on our Services. We use this information to investigate and
          respond to your inquiries, and to communicate with you, to enhance the
          services we offer to our users and to manage and grow our
          organization.
        </li>
        <li>
          <strong>Newsletter, Marketing Emails, and Blog Information:</strong>{' '}
          including email address and applicable interests and communication
          preferences. We use this information to manage our communications with
          you and send you information about products and services we think may
          be of interest to you. If you wish to stop receiving email messages
          from us, simply click the “unsubscribe link” provided at the bottom of
          the email communication. Note that you cannot unsubscribe from certain
          services-related email communications (e.g., account verification,
          confirmations of transactions, technical or legal notices).
        </li>
        <li>
          <strong>Business Representative Contact Information:</strong> If you
          are a business representative, we collect your information in
          connection with the performance of the agreement or potential
          agreement with us. This information may include your first name, last
          name, company contact information (e.g. email, phone, address), job
          title, and any other information related to the performance of the
          agreement with us.
        </li>
        <li>
          <strong>Employment Application Information:</strong> including your
          contact and demographic information, educational and work history,
          employment interests, information obtained during interviews and any
          other information you choose to provide, if you apply for employment.
        </li>
      </ul>
      <h3 className="mt-4 font-semibold">
        Personal data we receive automatically from your use of our Services:
      </h3>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          <strong>Log Data:</strong> Information that your browser or device
          automatically sends when you use our Services. Log data includes your
          Internet Protocol address, browser type and settings, the date and
          time of your request, and how you interact with our Services.
        </li>
        <li>
          <strong>Usage Data:</strong> We may automatically collect information
          about your use of the Services, such as the types of content that you
          view or engage with, the features you use and the actions you take, as
          well as your time zone, country, the dates and times of access, user
          agent and version, type of computer or mobile device, and your
          computer connection.
        </li>
        <li>
          <strong>Device Information:</strong> Includes name of the device,
          operating system, device identifiers, and browser you are using.
          Information collected may depend on the type of device you use and its
          settings.
        </li>
        <li>
          <strong>Cookies and Similar Technologies:</strong> We use cookies and
          similar technologies to operate and administer our Services, and
          improve your experience. Cookies are managed by a third party
          provider, Cookiebot. For details about our use of cookies, please
          visit our{' '}
          <a className="underline" href="#" onClick={handleRenewClick}>
            Cookie Notice
          </a>
          . You can manage your cookie preferences through your browser
          settings.
        </li>
      </ul>
      <p className="mt-4">
        For more information about data, privacy and security when using our
        Services, please click{' '}
        <Link className="underline" to="/enterprise-privacy-policy">
          here
        </Link>
        .
      </p>
      <h2 className="mt-4 font-semibold">3. How We Use Personal Data</h2>
      <br />
      <p>We may use the data we collect to:</p>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          Provide you with products or services that you request or in which you
          express interest;
        </li>
        <li>Communicate with you, via email;</li>
        <li>Customize the advertising and content you see;</li>
        <li>
          For marketing and advertising purposes, including to market to you or
          offer you through email, direct mail, phone or text message,
          information and updates on products or services we think you may be
          interested in (where applicable, we may send you marketing messages if
          you have given us your consent to do so or where we have relied on the
          soft opt-in rule);
        </li>
        <li>Process payment for our Services;</li>
        <li>
          Respond to your emails, comments, questions, or requests for client
          services;
        </li>
        <li>
          Improve and customize our Services to address the needs and interests
          of our user base and other individuals we interact with;
        </li>
        <li>
          Test, enhance, update and monitor the Services, or diagnose or fix
          technology problems;
        </li>
        <li>
          Enforce our Terms of Service, resolve disputes, carry out our
          obligations and enforce our rights, and protect our business interests
          and the interests and rights of third parties;
        </li>
        <li>
          Prevent, investigate or provide notice of fraud or unlawful or
          criminal activity;
        </li>
        <li>
          For any other purpose we may describe when you provide the
          information; and
        </li>
        <li>
          For any other lawful purpose, or other purposes that you consent to.
        </li>
      </ul>
      <h2 className="mt-4 font-semibold">4. Sharing Your Personal Data</h2>
      <br />
      <p>
        We do not sell, trade, or rent your personal data to third parties.
        However, we may share your personal data with third parties under the
        following circumstances:
      </p>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          <strong>Service Providers:</strong> We may share your data with
          third-party service providers who assist us in operating and improving
          our Services.
        </li>
        <li>
          <strong>Legal Compliance:</strong> We may disclose your data when
          required by law or in response to legal requests.
        </li>
        <li>
          <strong>With Your Consent:</strong> We may disclose personal data
          about an individual to certain other third parties or publicly with
          their consent or direction. For example, with an individual’s consent
          or direction we may post their testimonial on our Sites or
          service-related publications.
        </li>
        <li>
          <strong>Business Transaction or Reorganization:</strong> We may take
          part in or be involved with a corporate business transaction, such as
          a merger, acquisition, joint venture, or financing or sale of company
          assets. We may disclose personal data to a third-party during
          negotiation of, in connection with or as an asset in such a corporate
          business transaction. Personal data may also be disclosed in the event
          of insolvency, bankruptcy or receivership.
        </li>
      </ul>
      <h2 className="mt-4 font-semibold">5. Your Rights</h2>
      <br />
      <p>You have the right to:</p>
      <ul className="pl-4 mt-2 list-disc list-inside">
        <li>
          Access and update your personal data: You may access, rectify, erase,
          restrict processing, object to processing, and request data
          portability of your personal data by contacting us at{' '}
          <a href="mailto:dpo@tromero.ai" className="underline ">
            dpo@tromero.ai
          </a>
          .
        </li>
        <li>
          Opt-Out: You can opt-out of receiving promotional emails by following
          the instructions provided in those emails. However, you may still
          receive non-promotional communications.
        </li>
      </ul>
      <h2 className="mt-4 font-semibold">6. Security</h2>
      <br />
      <p>
        We take reasonable measures to protect your personal data from
        unauthorised access, disclosure, alteration, or destruction. However, no
        method of data transmission over the internet or electronic storage is
        entirely secure.
      </p>
      <h2 className="mt-4 font-semibold">7. Children's Privacy</h2>
      <br />
      <p>
        Our Services are not intended for children under the age of 13. We do
        not knowingly collect personal data from children under 13. If you
        believe that a child under 13 has provided us with personal data, please
        contact us, and we will take appropriate steps to promptly remove that
        data.
      </p>
      <h2 className="mt-4 font-semibold">8. Changes to this Privacy Policy</h2>
      <br />
      <p>
        We may update this Policy from time to time to reflect changes in our
        practices or for other operational, legal, or regulatory reasons. The
        updated Policy will be posted on our website with the last updated date.
      </p>
      <h2 className="mt-4 font-semibold">9. Keeping Personal Data</h2>
      <br />
      <p>
        We do not keep your personal data for any longer than is necessary to
        fulfil the purpose for which we collected it, or to comply with any
        legal, regulatory or reporting obligations or to assert or defend
        against legal claims.
      </p>
      <h2 className="mt-4 font-semibold">10. Making a Complaint</h2>
      <br />
      <p>
        You have the right to make a complaint about how we process your
        personal data to the Information Commissioner at{' '}
        <a
          href="https://ico.org.uk/make-a-complaint/"
          className="text-blue-600 underline"
        >
          https://ico.org.uk/make-a-complaint/
        </a>
        .
      </p>
      <h2 className="mt-4 font-semibold">11. Contact Us</h2>
      <br />
      <p>
        If you have any questions, concerns, or requests related to this Privacy
        Policy or your personal data, please contact our Data Protection Officer
        at{' '}
        <a href="mailto:dpo@tromero.ai" className="text-blue-600 underline">
          dpo@tromero.ai
        </a>
        .
      </p>
      <p>
        If you would like to sign a Data Processing Agreement, you can request
        one from us at{' '}
        <a href="mailto:dpo@tromero.ai" className="text-blue-600 underline">
          dpo@tromero.ai
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
