import { useParams, useLocation } from 'react-router-dom';
import {
  ChevronRightIcon,
  RectangleGroupIcon,
} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useState, useEffect, useRef } from 'react';

export default function Breadcrumbs() {
  const { model_name } = useParams();
  const location = useLocation();
  const breadcrumbRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (breadcrumbRef.current) {
        setIsOverflowing(
          breadcrumbRef.current.scrollWidth > breadcrumbRef.current.clientWidth,
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  const evaluationType = location.pathname.includes('benchmark-evaluation')
    ? 'Benchmark Evaluations'
    : location.pathname.includes('custom-evaluation')
      ? 'Custom Evaluations'
      : location.pathname.includes('training-metrics')
        ? 'Training Metrics'
        : null;

  const pages = [
    { name: 'Models', href: '/tailor/my-models', current: false },
    {
      name: model_name,
      href: location.pathname.includes('/base/')
        ? `/tailor/my-models/base/${model_name}`
        : `/tailor/my-models/${model_name}`,
      current: !evaluationType,
    },
    ...(evaluationType
      ? [
          {
            name: evaluationType,
            href: `/tailor/my-models/${location.pathname.includes('/base/') ? 'base/' : ''}${model_name}/${
              evaluationType === 'Benchmark Evaluations'
                ? 'benchmark-evaluation'
                : evaluationType === 'Custom Evaluations'
                  ? 'custom-evaluation'
                  : evaluationType === 'Training Metrics'
                    ? 'training-metrics'
                    : ''
            }`,
            current: true,
          },
        ]
      : []),
  ];

  return (
    <nav aria-label="Breadcrumb" className="flex flex-wrap" ref={breadcrumbRef}>
      <ol role="list" className="flex items-center space-x-1 sm:space-x-4">
        <li className="truncate">
          <div>
            <Link to="/tailor" className="text-gray-400 hover:text-gray-500">
              <RectangleGroupIcon
                aria-hidden="true"
                className="h-5 w-5 shrink-0"
              />
              <span className="sr-only">Dashboard</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name} className="truncate">
            <div className="flex items-center">
              <ChevronRightIcon
                aria-hidden="true"
                className="h-5 w-5 shrink-0 text-gray-400"
              />
              <Link
                to={page.href}
                aria-current={page.current ? 'page' : undefined}
                className={clsx(
                  'ml-1 sm:ml-4 text-sm font-medium whitespace-pre',
                  page.current
                    ? 'text-gray-700 font-semibold'
                    : 'text-gray-500 hover:text-gray-700',
                )}
              >
                {isOverflowing && index === 0 ? '...' : page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
