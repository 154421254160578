import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';

const PressArticle = ({ title, url, imageUrl, date }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="flex justify-between p-2 h-36 font-spaceG group"
  >
    <div className="relative w-1/3 overflow-hidden rounded shadow">
      {/* Padding-top for aspect ratio */}

      <img
        src={imageUrl}
        alt={title}
        className="absolute top-0 left-0 object-cover w-full h-full group-hover:opacity-90"
      />
    </div>
    <div className="flex flex-col justify-between w-2/3">
      <div className="pl-4 font-sans text-sm font-semibold text-gray-950 md:text-md group-hover:text-indigo-600">
        {title}
      </div>
      <div className="pl-4 text-sm text-gray-700">{date}</div>
    </div>
  </a>
);

const DownloadPressKitButton = () => {
  const downloadUrl = '/PressKit.zip';

  return (
    <a
      href={downloadUrl}
      download="PressKit"
      className="flex items-center h-12 px-4 mt-2 text-white bg-indigo-600 rounded hover:bg-indigo-700 font-spaceG"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5 mr-2"
      >
        <path
          fillRule="evenodd"
          d="M12 2.25a.75.75 0 0 1 .75.75v11.69l3.22-3.22a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 1 1 1.06-1.06l3.22 3.22V3a.75.75 0 0 1 .75-.75Zm-9 13.5a.75.75 0 0 1 .75.75v2.25a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5V16.5a.75.75 0 0 1 1.5 0v2.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V16.5a.75.75 0 0 1 .75-.75Z"
          clipRule="evenodd"
        />
      </svg>
      Download Press Kit
    </a>
  );
};

const MediaQueries = () => {
  return (
    <div className="flex flex-col w-full py-8 border-t border-b border-indigo-500 sm:rounded-lg sm:shadow-md sm:border sm:items-center min-h-64 md+:flex-row justify-evenly lg:col-span-3 ">
      {/* Media Queries Section */}

      <div className="flex flex-col w-64 my-6 md:my-0 sm:ml-0">
        <h2 className="text-2xl font-spaceG">Media Inquiries</h2>

        <a
          href="mailto:info@tromero.ai"
          className="flex items-center h-12 mt-1 text-indigo-600 hover:text-indigo-800"
        >
          info@tromero.ai
        </a>
        <div></div>
      </div>
      <div className="flex flex-col w-64 my-6 md:my-0 sm:ml-0">
        <h2 className="text-2xl font-spaceG">Press Assets</h2>
        <div className="flex mt-1">
          <DownloadPressKitButton />
        </div>
      </div>

      <div className="flex flex-col w-64 my-6 md:my-0 sm:ml-0">
        <div className="text-2xl ">Follow Us</div>
        <div className="mt-1 text-base "></div>
        <div className="flex items-center h-12">
          <a
            href="https://twitter.com/TromeroAI"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-600"
          >
            X
          </a>

          <span className="mx-2"> · </span>
          <a
            href="https://www.linkedin.com/company/tromero/about/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-600"
          >
            LinkedIn
          </a>
          <span className="mx-2"> · </span>
          <a
            href="https://discord.com/invite/fHTu5spJ"
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-600"
          >
            Discord
          </a>
        </div>
      </div>
    </div>
  );
};

const PressArticleBottom = ({ title, url, imageUrl, date }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="block max-w-md pl-0 mb-2 overflow-hidden md:p-8 font-spaceG group"
  >
    {/* Image container */}
    <div className="w-full h-56 mb-2 overflow-hidden rounded-lg shadow">
      <img
        src={imageUrl}
        alt={title}
        className="object-cover w-full h-full group-hover:opacity-90"
      />
    </div>

    {/* Text container */}

    <h3 className="mb-2 font-sans text-lg font-semibold leading-tight md:h-12 text-gray-950 group-hover:text-indigo-600">
      {title}
    </h3>
    <p className="text-sm text-gray-700">{date}</p>
  </a>
);

const FeaturedArticle = ({ title, url, imageUrl, date }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="block mb-2 overflow-hidden cursor-pointer font-spaceG group"
  >
    {/* Responsive Image Container */}
    <div className="relative w-full pb-[56.25%] overflow-hidden rounded-lg shadow lg:h-auto lg:max-h-500px group-hover:opacity-90">
      <img
        src={imageUrl}
        alt={title}
        className="absolute inset-0 object-cover w-full h-full"
      />
    </div>

    {/* Text below the image */}
    <div className="py-4 bg-white">
      <h2 className="font-sans text-lg font-bold text-gray-950 md:text-2xl group-hover:text-indigo-600">
        {title}
      </h2>
      <p className="text-sm text-gray-700">{date}</p>
    </div>
  </a>
);

const Press = () => {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const client = createClient({
      space: import.meta.env.VITE_CONTENTFUL_SPACE_ID,
      accessToken: import.meta.env.VITE_CONTENTFUL_ACCESS_TOKEN,
    });

    setIsLoading(true);

    client
      .getEntries({
        content_type: 'pressArticle',
        select: 'fields.title,fields.url,fields.date,fields.image',
        include: 1, // Ensure you include linked assets like images
      })
      .then((response) => {
        const fetchedArticles = response.items.map((item) => {
          const imageUrl = item.fields.image?.fields?.file?.url
            ? 'https:' + item.fields.image.fields.file.url
            : '';

          return {
            title: item.fields.title,
            url: item.fields.url,
            imageUrl: imageUrl,
            date: item.fields.date,
          };
        });

        setArticles(fetchedArticles);
        setIsLoading(false);
      })
      .catch(console.error);
  }, []); // Empty dependency array ensures this runs once after the component mounts

  const featuredArticle = articles[0];
  const recentPressArticles = articles.slice(1, 5); // Next 3 articles for recent press
  const remainingArticles = articles.slice(5); // Rest of the articles

  return (
    <div className="min-h-screen min-w-screen bg-zinc-50">
      <div className="container p-4 pt-24 mx-auto max-w-[1500px] font-dmSans md:px-12 bg-zinc-50">
        <div className="flex justify-between w-full">
          <div>
            <h2 className="pb-6 pl-4 text-3xl ">Recent Press</h2>
          </div>{' '}
        </div>

        <div className="grid grid-cols-1 gap-14 lg:grid-cols-3">
          {/* Featured Article */}
          <div className=" col-span-1 lg:col-span-2 lg:row-span-1 lg:min-h-[500px] mt-2 p-4 ">
            <FeaturedArticle {...featuredArticle} />
          </div>

          {/* Recent Press */}
          <div className="lg:col-span-1 lg:row-span-1">
            {/* <h2 className="hidden pb-6 text-3xl lg:block font-spaceG">
            Recent Press
          </h2> */}
            <ul className="block p-4 -mt-20 sm:hidden">
              {recentPressArticles.map((article, index) => (
                <FeaturedArticle key={index} {...article} />
              ))}
            </ul>
            <ul className="hidden p-4 sm:block">
              {recentPressArticles.map((article, index) => (
                <PressArticle key={index} {...article} />
              ))}
            </ul>
          </div>
          <div className="col-span-1 mx-4 lg:col-span-3">
            <MediaQueries />
          </div>
        </div>

        {/* Remaining Articles */}
        <div className="col-span-1 mt-12 lg:col-span-3 lg:row-span-1">
          <div className="pl-4 text-2xl ">More Articles</div>

          {/* FeaturedArticle for smaller screens */}
          <ul className="block p-4 sm:hidden">
            {remainingArticles.map((article, index) => (
              <FeaturedArticle key={index} {...article} />
            ))}
          </ul>
          <div className="hidden sm:block">
            {/* PressArticleBottom for larger screens */}
            <div className="grid grid-cols-1 p-4 mx-4 md:-mx-8 xl:grid-cols-3 lg:grid-cols-2">
              {remainingArticles.map((article, index) => (
                <PressArticleBottom key={index} {...article} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Press;
