import { useState, useEffect, useRef } from 'react';
import { EmojiConvertor } from 'emoji-js';

import { applyDarkModePreference } from '../../utils/generalUtils';

const PublicPlayground = () => {
  const emoji = new EmojiConvertor();
  emoji.replace_mode = 'unified';
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [adapter] = useState({ model_name: 'linkedin-bot' });
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);

  const chatContainerRef = useRef(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    document.documentElement.classList.remove('dark');
    return () => applyDarkModePreference();
  }, []);

  const clearChat = () => {
    setMessages([]);
    setConversation([]);
    setNewMessage('');
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') {
      return;
    }
    setLoading(true);

    setMessages([]);
    setConversation([]);

    const userMessageWithEmojis = {
      text: emoji.replace_colons(newMessage),
      isUser: true,
    };

    const loadingMessage = { loading: true, isUser: false };

    setMessages((messages) => [
      ...messages,
      userMessageWithEmojis,
      loadingMessage,
    ]);
    setNewMessage('');

    const updatedConversation = [
      ...conversation,
      { role: 'user', content: newMessage },
    ];

    setConversation(updatedConversation);

    const payload = {
      adapter_name: 'tias-linkedin-bot',
      messages: updatedConversation,
    };

    try {
      const response = await fetch(
        'https://marketplace-production-ntdofzztya-uk.a.run.app/tailor/v1/public/generate/stream',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to generate response');
      }

      const reader = response.body.getReader();
      let decoder = new TextDecoder();
      let buffer = '';
      let accumulatedText = '';
      let details = '';
      let totalTokens = 0;

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        buffer += decoder.decode(value, { stream: true });
        const parts = buffer.split('\n');
        for (let i = 0; i < parts.length - 1; i++) {
          const line = parts[i];
          if (line.startsWith('data:')) {
            const data = JSON.parse(line.substring(5));
            if (data.token && data.token.text) {
              accumulatedText += data.token.text;

              const assistantMessageSegments = accumulatedText
                .split('\n')
                .map((segment, index) => (
                  <div key={index}>
                    {emoji.replace_unified(segment)}
                    {index < accumulatedText.split('\n').length - 1 && <br />}
                  </div>
                ));

              setMessages((messages) => {
                const updatedMessages = messages.slice(0, -1);
                return [
                  ...updatedMessages,
                  {
                    text: assistantMessageSegments,
                    isUser: false,
                    tokens: accumulatedText.length,
                    totalTokens: totalTokens,
                  },
                ];
              });
            }
            if (data.generated_text) {
              details = data.details;
              totalTokens = details.prompt_tokens + details.generated_tokens;
              accumulatedText = data.generated_text;

              const assistantMessageSegments = accumulatedText
                .split('\n')
                .map((segment, index) => (
                  <div key={index}>
                    {emoji.replace_unified(segment)}
                    {index < accumulatedText.split('\n').length - 1 && <br />}
                  </div>
                ));

              const assistantMessage = {
                text: assistantMessageSegments,
                isUser: false,
                tokens: details.generated_tokens,
                totalTokens: totalTokens,
              };

              setMessages((messages) => {
                const updatedMessages = messages.slice(0, -1);
                return [...updatedMessages, assistantMessage];
              });
            }
          }
        }
        buffer = parts[parts.length - 1];
      }

      setConversation((conversation) => [
        ...conversation,
        { role: 'assistant', content: accumulatedText },
      ]);
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      setMessages((messages) => messages.slice(0, -1)); // Removes the loading message
    } finally {
      setLoading(false);
    }
  };

  const handleNewMessageChange = (e) => {
    setNewMessage(e.target.value);
  };

  return (
    <>
      <div className="w-full h-[calc(100vh)] pt-0 md:pt-4 font-dmSans text-zinc-500 bg-zinc-50 overflow-hidden">
        <div className="flex items-center w-full h-12 mt-4 md:mt-10 md:mx-10 lg:mt-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="flex w-5 h-5 my-auto ml-4 text-zinc-500"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
            />
          </svg>
          <span className="hidden mx-2 my-auto sm:flex text-zinc-500">
            Chat Playground
          </span>{' '}
          <span className="items-center justify-center hidden w-auto px-2 py-1 my-auto border-b md:flex shadow-zinc-200 text-zinc-500">
            {adapter.model_name}
          </span>
          <span className="flex items-center justify-center w-auto px-2 py-1 mx-2 my-auto border-b md:hidden shadow-zinc-200 text-zinc-500">
            {adapter?.model_name?.length > 30
              ? `${adapter?.model_name?.slice(0, 30)}...`
              : adapter?.model_name}
          </span>
        </div>
        <div
          className={`grid h-full md:p-10 grid-cols-5 md:-mt-12  md:grid-cols-6`}
        >
          <div className={`col-span-6 md:col-span-6 flex flex-col`}>
            {/* Left Column Content (75%) */}
            <div className="relative p-2 lg:p-4 h-[65%]">
              <div
                ref={chatContainerRef}
                className="w-full h-full p-6 pr-12 md:pr-20 overflow-y-auto border border-gray-300 max-h-[68vh] rounded-t-xl bg-white"
                style={{ scrollBehavior: 'smooth' }}
              >
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${message.isUser ? 'justify-end' : 'justify-start'}`}
                  >
                    {message.loading ? (
                      <div className="animate-blink">
                        <div className="w-3 h-3 m-2 bg-indigo-200 border-2 border-indigo-100 rounded-full">
                          {/* Loading indicator */}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`m-2 p-2 text-zinc-600 ${message.isUser ? 'border rounded-tl-lg rounded-tr-lg rounded-bl-lg border-indigo-200 bg-indigo-50' : ''}`}
                      >
                        {message.text}
                        {!message.isUser &&
                          (message.totalTokens > 0 ? (
                            <div className="mt-1 text-xs text-zinc-400">
                              {message.totalTokens} Total Tokens
                            </div>
                          ) : null)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 group">
                <button
                  className="flex items-center justify-center w-full h-full "
                  onClick={clearChat}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="relative p-2 pt-0 md:p-4 md:h-1/4 h-1/6">
              <textarea
                className="w-full h-full pt-4 pl-8 bg-white border border-gray-300 shadow resize-none md:pr-36 rounded-b-xl shadow-zinc-200 focus:outline-none focus:border-indigo-200 focus:ring-1 focus:ring-indigo-200"
                placeholder="Enter text here"
                value={newMessage}
                onChange={handleNewMessageChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && !loading) {
                    e.preventDefault();
                    sendMessage(e);
                  }
                }}
              ></textarea>
              <div className="absolute flex items-center justify-center w-10 h-10 m-2 border md:h-16 md:w-16 hover:shadow hover:shadow-zinc-200 md:right-7 right-5 bottom-7 hover:border-indigo-200 hover:rounded-t-lg hover:rounded-l-lg group hover:bg-indigo-50">
                <button
                  disabled={loading}
                  className="flex items-center justify-center w-full h-full "
                  onClick={sendMessage}
                >
                  {/* Invisible button */}
                </button>
                {/* Position SVG as overlay */}
                <div className="absolute inset-0 flex items-center justify-center pointer-events-none ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 transition-transform duration-500 ease-in-out text-zinc-500 group-hover:-rotate-90 group-hover:text-indigo-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicPlayground;
