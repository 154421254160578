import React from 'react';
import { Modal } from 'flowbite-react';

const ActionModal = ({ showModal, closeModal, onConfirm, onCancel }) => {
  const handleOkClick = () => {
    onConfirm();
    closeModal();
  };

  const handleCloseAndCancel = () => {
    closeModal();
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <Modal show={showModal} onClose={closeModal} className="font-spaceG">
      <svg
        className="w-8 h-8 m-4 text-gray-400 dark:text-gray-200"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>

      <Modal.Body>
        <div>
          <p className="text-xl text-gray-700 dark:text-gray-300">
            Ending this instance will{' '}
            <span className="font-semibold">DELETE</span> the session data.
          </p>
          <p className="mt-4 text-gray-700 dark:text-gray-300">
            If you're sure you want to end your instance, make sure you've
            downloaded your session data first.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="text-white bg-red-600 hover:bg-red-800  font-medium rounded-md text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
          onClick={handleOkClick}
        >
          End Instance
        </button>
        <button
          className="text-gray-500 bg-white hover:bg-white  rounded-md border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 "
          onClick={handleCloseAndCancel}
        >
          No, Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ActionModal;
