import { createContext, useContext, useState } from 'react';

const RentalModalContext = createContext();

/**
 * useRentalModal: A custom hook that returns the rental modal context
 * @function useRentalModal
 * @description A custom hook that returns the rental modal context
 * @return {Object}
 * @example
 * const {
 *  showUserIsUnconfirmedModal,
 * setShowUserIsUnconfirmedModal,
 * showUserIsDelinquentModal,
 * setShowUserIsDelinquentModal,
 * typeofCreditCardModal,
 * setTypeofCreditCardModal,
 * errorMessage,
 * setErrorMessage,
 * showRentalSuccessModal,
 * setShowRentalSuccessModal,
 * } = useRentalModal();
 */
// eslint-disable-next-line react-refresh/only-export-components
export const useRentalModal = () => useContext(RentalModalContext);

/**
 * RentalModalProvider: A component that provides the context for the rental modals
 * @component
 * @description A component that provides the context for the rental modals
 * @return {React.Component}
 * @param {Object} props
 * @param {React.Component} props.children - The children components to be wrapped
 * @example
 * return (
 *  <RentalModalProvider>
 *   {children}
 * </RentalModalProvider>
 * )
 */
export const RentalModalProvider = ({ children }) => {
  const [showUserIsUnconfirmedModal, setShowUserIsUnconfirmedModal] =
    useState(false);
  const [showUserIsDelinquentModal, setShowUserIsDelinquentModal] =
    useState(false);
  const [typeofCreditCardModal, setTypeofCreditCardModal] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showRentalSuccessModal, setShowRentalSuccessModal] = useState(false);
  const [showAddSSHModal, setShowAddSSHModal] = useState(false);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] =
    useState(false);
  const [insufficientTopUpAmount, setInsufficientTopUpAmount] = useState(0);

  return (
    <RentalModalContext.Provider
      value={{
        showUserIsUnconfirmedModal,
        setShowUserIsUnconfirmedModal,
        showUserIsDelinquentModal,
        setShowUserIsDelinquentModal,
        typeofCreditCardModal,
        setTypeofCreditCardModal,
        errorMessage,
        setErrorMessage,
        showRentalSuccessModal,
        setShowRentalSuccessModal,
        showAddSSHModal,
        setShowAddSSHModal,
        showInsufficientCreditsModal,
        setShowInsufficientCreditsModal,
        insufficientTopUpAmount,
        setInsufficientTopUpAmount,
      }}
    >
      {children}
    </RentalModalContext.Provider>
  );
};
