import React, { useCallback } from 'react';
import clsx from 'clsx';

const BaseModelCard = ({
  model,
  handleSetSelectedModel,
  isSelected,
  index,
}) => {
  const getImageUrlForFamily = useCallback((family) => {
    switch (family) {
      case 'Mistral':
      case 'Mixtral':
        return '/mistral.png';
      case 'LLaMA':
        return '/meta.png';
      default:
        return '';
    }
  }, []);

  return (
    <button
      aria-roledescription="button"
      key={model.id + ' ' + index}
      className={clsx(
        'flex items-start justify-between w-[98%] p-4 border rounded-md border-zinc-300 relative min-h-32',
        isSelected ? 'bg-zinc-100 ring-2 ring-indigo-400 ' : 'bg-white',
      )}
      onClick={() => handleSetSelectedModel(model)}
    >
      <div className="grid w-full ">
        <div className="self-start max-w-full col-span-2 text-lg font-medium text-left break-words text-zinc-800 text-wrap md:self-center">
          <div className="w-full overflow-hidden whitespace-nowrap text-ellipsis">
            <div className="flex flex-row items-center">
              <img
                src={getImageUrlForFamily(
                  model.model_name.includes('mistral')
                    ? 'Mistral'
                    : model.model_name.includes('mixtral')
                      ? 'Mixtral'
                      : model.model_name.includes('llama')
                        ? 'LLaMA'
                        : 'Others',
                )}
                alt=""
                className="w-auto h-5 mr-2"
              />
              {model.model_name}
            </div>
          </div>
          <div className="mt-1 text-sm font-light">
            Base model supported for pay-per-token inference
            <br />
            Click to see your usage of this model.
          </div>
        </div>
        {/* <div className="flex items-start text-sm text-zinc-600 justify-self-center">
          {model.last_deployed_on
            ? new Date(model.last_deployed_on).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : null}
        </div> */}
        {/* <div className="flex items-start text-sm text-zinc-600 justify-self-center">
          {model.last_used
            ? new Date(model.last_used).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : null}
        </div> */}
      </div>
    </button>
  );
};

export default React.memo(BaseModelCard);
