import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import CapsuleTabs from './CapsuleTabs';
import FeatureSection from './FeatureSection';
import TabInfoLayout from './TabInfoLayout';
import { tabInfo } from './tabinfo';
import SkewedInfiniteScroll from './SkewedInfiniteScroll';
import ModelsList from './ModelsList';
import Planet from '../Components/LandingPage/Planet';
import { Link } from 'react-router-dom';
import MobileCapsuleTabs from './MobileCapsuleTabs';
import VideoComponent from '../Components/VideoComponent';

const V2Landing = () => {
  const videoContainerRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const tabCount = 5; // Number of tabs
  const intervalDuration = 12000; // 10 seconds
  const [isIntervalActive, setIsIntervalActive] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isIntervalActive) {
      intervalRef.current = setInterval(() => {
        setSelectedTab((prevTab) => (prevTab + 1) % tabCount);
      }, intervalDuration);
    }

    return () => clearInterval(intervalRef.current);
  }, [isIntervalActive, tabCount]);

  const handleTabSelect = (index) => {
    setSelectedTab(index);
    clearInterval(intervalRef.current);
    setIsIntervalActive(false);
  };

  /* Intersection Observer to pause the interval when the VideoComponent is not in the viewport, and restart it when it is */
  useEffect(() => {
    const currentVideoContainer = videoContainerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setIsIntervalActive(true);
        }
      },
      {
        root: null,
        threshold: 0.1,
      },
    );

    if (currentVideoContainer) {
      observer.observe(currentVideoContainer);
    }

    return () => {
      if (currentVideoContainer) {
        observer.unobserve(currentVideoContainer);
      }
    };
  }, []);

  const videos = [
    'data_final.mp4',
    'training_final.mp4',
    'eval_final.mp4',
    'deploy_final.mp4',
    'rent_gpu_final.mp4',
  ];

  const images = [
    ['/LandingPageShots/data.png', 'Handling Data'],
    ['/LandingPageShots/finetuning.png', 'Model Fine-tuning'],
    ['/LandingPageShots/evaluation.png', 'Model Evaluation'],
    ['/LandingPageShots/deployment.png', 'Model Deployment'],
    ['/LandingPageShots/gpu-clusters.png', 'Renting GPU Clusters'],
  ];

  return (
    <div className="w-full min-h-screen mx-auto bg-zinc-50 font-dmSans">
      <div className="h-full min-h-screen max-w-[1500px] mx-auto bg-zinc-50">
        <div className="relative min-h-[100vh]  isolate pt-14">
          <div className="pb-12 sm:pb-24 sm:pt-36">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
              <div className="max-w-2xl mx-auto text-center">
                <h1 className="text-4xl text-gray-900 font sm:text-6xl">
                  The Open Platform for{' '}
                  <span className="italic font-light text-transparent wavy bg-clip-text drop-shadow">
                    Smarter
                  </span>{' '}
                  AI
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-700">
                  A platform where enterprises can gain a competitive edge
                  through AI optimized for their use-case.
                </p>
              </div>
            </div>
          </div>

          <section className="w-full ">
            <div className="flex flex-col items-center w-full h-full sm:justify-center brd md:min-w-3/4">
              <div className="w-full px-6 sm:px-16">
                <div className="flex justify-center">
                  <div className="relative w-full p-2 -m-2 sm:min-w-3/4 2xl:w-3/4 xl:w-3/5  rounded-xl bg-gray-900/5 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                    {/* Aspect Ratio Container */}
                    <div
                      ref={videoContainerRef}
                      className="relative  overflow-hidden rounded-md"
                      style={{ paddingBottom: '62.5%' }}
                    >
                      <VideoComponent
                        key={selectedTab}
                        name={videos[selectedTab]}
                        className="absolute top-0 rounded-md shadow-2xl ring-1 ring-gray-900/10 h-full"
                        isActive={true}
                        fallback={images[selectedTab]}
                      />
                    </div>
                    <div className="justify-center hidden w-full mt-4 md:flex">
                      <CapsuleTabs
                        tabs={[
                          'Data',
                          'Fine-tuning',
                          'Evaluation',
                          'Deployment',
                          'GPU Clusters',
                        ]}
                        selectedTab={selectedTab}
                        onTabSelect={handleTabSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* breakpoint 916 */}
              <div className="flex justify-center w-full md:hidden ">
                <MobileCapsuleTabs
                  tabs={[
                    'Data',
                    'Fine-tuning',
                    'Evaluation',
                    'Deployment',
                    'GPU Clusters',
                  ]}
                  selectedTab={selectedTab}
                  onTabSelect={handleTabSelect}
                />
              </div>
              <div className="mt-16 ">
                <TabInfoLayout tabInfo={tabInfo} selectedTab={selectedTab} />
              </div>
              <div className="flex items-center justify-center w-full py-10 space-x-4 md:space-x-8">
                <Link
                  to="/signup"
                  className="flex items-center justify-center py-2 text-sm text-white border rounded shadow-sm border-zinc-900 w-36 bg-zinc-900 hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                >
                  Request access
                </Link>
                <a
                  href="https://calendar.app.google/Q8ZLru9V5tksuySH9"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center py-2 text-sm border rounded shadow-sm bg-zinc-200 text-zinc-900 hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600 w-36 border-zinc-900"
                >
                  Book a call
                </a>
              </div>
            </div>
          </section>
        </div>
        <FeatureSection />

        <motion.div
          initial={{ opacity: 0.0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.4, ease: 'easeInOut' }}
          viewport={{ once: true }}
        >
          <ModelsList />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.4, ease: 'easeInOut' }}
          viewport={{ once: true }}
          className="grid w-full grid-cols-1 py-36 lg:grid-cols-2"
        >
          <div className="flex justify-center w-full">
            <div className="px-4 text-2xl leading-8 text-center text-gray-900 md:px-24 sm:text-4xl">
              Inference or Host your Model Wherever you want
              <div className="mt-4 text-base leading-7 text-center md:px-12 text-zinc-600">
                Serve models on Tromero serverless inference platform or deploy
                them on any cloud or self-host on your own servers or VPC
                (Virtual Private Cloud).
              </div>
            </div>
          </div>
          <div className="mt-12 lg:mt-0">
            <SkewedInfiniteScroll />
          </div>
        </motion.div>

        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="pt-24 pb-12 ">
            <motion.div
              initial={{ opacity: 0.0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                delay: 0.2,
                duration: 0.4,
                ease: 'easeInOut',
              }}
              viewport={{ once: true }}
              className="px-6 mx-auto max-w-7xl lg:px-8"
            >
              <h2 className="text-2xl leading-8 text-center text-gray-900 sm:text-4xl">
                Backed by Visionaries
              </h2>
              <div className="grid items-center max-w-lg grid-cols-4 mx-auto gap-x-2 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:mx-0 lg:max-w-none ">
                <a
                  href="https://www.conceptionx.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="conception"
                    src="/conception.png"
                    className="object-contain w-full col-span-1 max-h-36"
                  />
                </a>

                <a
                  href="https://blueyard.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="blueyard"
                    src="/blueyard.png"
                    className="object-contain w-full col-span-1 max-h-36"
                  />
                </a>

                <a
                  href="https://www.ambush.capital/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="ambush"
                    src="/ambush.png"
                    className="object-contain w-full col-span-1 p-2 max-h-36"
                  />
                </a>

                <a
                  href="https://leblon-capital.notion.site/Welcome-to-Leblon-Capital-s-page-6554d5551e60441cba6f9ccea358ac91"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="leblon"
                    src="/leblon.png"
                    className="object-contain w-full col-span-1 p-4 max-h-36"
                  />
                </a>
              </div>
            </motion.div>
          </div>
        </div>

        <div className="relative flex justify-center py-16">
          <div
            className="flex flex-col items-center justify-center shadow rounded-3xl h-80 w-full max-w-[1500px] wavymovement z-20 mx-12 xl:mx-0 overflow-hidden"
            style={{
              backgroundImage: `url('/zincgrainy.jpeg')`,
              backgroundPosition: 'center',
            }}
          >
            <div className="absolute w-64 h-64 xl:-top-[300px] -top-[410px] -left-[180px] z-0 ">
              <Planet />
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="z-20 mt-12 text-2xl leading-8 text-center lg:text-3xl text-zinc-50">
                Ready to create your own smarter AI models?
              </div>
              <div className="flex items-center justify-center w-full py-12">
                <Link
                  to="/signup"
                  className="z-30 px-3 py-2 text-sm rounded shadow-sm bg-zinc-50 text-zinc-900 hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
                >
                  Request access
                </Link>
              </div>
            </div>
            <div className="absolute w-64 h-64 bottom-[-0px] xl:right-[-100px] z-0 right-[-180px]">
              <Planet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default V2Landing;
