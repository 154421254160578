import { Dropdown } from 'flowbite-react';

import { getDefaultLocaleForCurrency } from '../../utils/localeCurrencyMapping';
import { getCurrencyName } from '../../utils/CurrencyUtils';

const CurrencyDropDownItem = ({
  currency,
  handleSelectedCurrency,
  showName = false,
  grow = false,
}) => {
  return (
    <Dropdown.Item
      onClick={() => handleSelectedCurrency(currency)}
      className={`flex items-baseline capitalize ${grow ? '' : 'w-fit'}`}
    >
      <span
        className={`mr-2 fi fi-${getDefaultLocaleForCurrency(currency).ISO_code}`}
      ></span>
      <span className="w-fit min-w-fit">
        {currency}
        {showName && <span className="mx-2"> - </span>}
      </span>

      {showName && (
        <span className="text-left">{getCurrencyName(currency)}</span>
      )}
    </Dropdown.Item>
  );
};

export default CurrencyDropDownItem;
