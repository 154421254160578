import { useNavigate } from 'react-router-dom';
import {
  CheckCircleIcon,
  LockClosedIcon,
  BanknotesIcon,
  ArrowsPointingOutIcon,
  GlobeAltIcon,
  LightBulbIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

import articles from '../articlesData';
import AnimationTwoBirds from '../Components/AnimationTwoBirds';

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Resources', href: '#' },
  { name: 'Company', href: '#' },
];

const jobOpenings = [
  {
    id: 1,
    role: 'DevOps Engineer',
    href: '#',
    description:
      'Actively hiring for this role. Contact us for more information.',
    salary: 'Competitive Salary',
    location: 'London or Remote',
  },
  {
    id: 2,
    role: 'Marketing Executive',
    href: '#',
    description:
      'Actively hiring for this role. Contact us for more information.',
    salary: 'Competitive Salary',
    location: 'London or Remote',
  },
];
const stats = [
  { label: 'Transactions every 24 hours', value: '44 million' },
  { label: 'Assets under holding', value: '$119 trillion' },
  { label: 'New users annually', value: '46,000' },
];
const values = [
  {
    icon: GlobeAltIcon,
    name: 'Open Platform',
    description:
      'No vendor lock in. Use any AI model through Tromero, either off-the-shelf models (ChatGPT, Claude, Llama 3.1) or a model that you have trained yourself. Changing between models is just a one line code change and the click of a button. Deploy on any cloud and any infrastructure (including your own secure environment).',
  },
  {
    icon: LightBulbIcon,
    name: 'Smarter AI',
    description:
      'Fine-tune your AI models so that they are specialized to your use case. Tromero provides tools to help with evaluating and improving the performance of your models, including custom benchmarks and real-time training monitoring. Tromero manages everything from collecting data and improving it via state-of-the-art logical integrations and prompting techniques, through to training the model, evaluating the performance and finally deploying it on a high-performance cluster.',
  },
  {
    icon: BanknotesIcon,
    name: 'Pricing',
    description:
      'With Tromero, you can have a specialized model to your use case and still only pay per token. Tromero does not require you to have your own cluster of GPUs for your model. Tromero allows one base model to occupy the GPUs and then it can have many specialized adaptors for different clients, that is how you share the cost.',
  },

  {
    icon: ChatBubbleLeftRightIcon,
    name: 'Support',
    description: (
      <>
        We also offer support with the integration of AI into your systems.
        Contact us if you would like to find out more about how our engineers
        can help with this{' '}
        <a
          className="text-indigo-600 hover:underline"
          href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1UhLhzSsotguU1_rW9MmdIsMG9BNTEoOK39OzrNiGycUFjJ1fbOoB2HQWo85CvllO7Ky_9dC78"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </>
    ),
  },
];
const team = [
  {
    name: 'Team Member',
    role: 'Co-Founder / CTO',
    imageUrl:
      'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
  },
];

export default function About() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="bg-zinc-50 font-dmSans dark:bg-gray-800 dark:text-gray-100">
      <main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)] dark:stroke-gray-900"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg
              x="50%"
              y={-1}
              className="overflow-visible fill-gray-50 dark:fill-gray-700"
            >
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 dark:opacity-0"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="px-6 pb-32 mx-auto max-w-7xl pt-36 sm:pt-60 lg:px-8 lg:pt-32">
              <div className="max-w-2xl mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                <div className="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                  <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl dark:text-gray-200">
                    Our Mission
                  </h1>
                  <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none dark:text-gray-200">
                    Tromero’s mission is to create the open platform for smarter
                    AI. <br />
                    <br />
                    Tromero is based on two key principles — being an open
                    platform — where customers are not limited to specific AI
                    models, GPU types or cloud platforms; and building smarter
                    AI — where we enable enterprises to gain a competitive edge
                    through an AI tailored to their specific use-case.
                    <br />
                    <br /> Tromero's platform offers tools for evaluating and
                    improving the performance of your models, including custom
                    benchmarks and real-time training monitoring to ensure high
                    quality outputs that set you apart from your competitors.
                    Tromero is committed to preserving privacy and ensuring data
                    security, providing multiple options for training and
                    deployment to ensure compliance. Tromero makes the process
                    of specializing models exceedingly simple and intuitive.
                    Through the platform, companies are able to increase
                    performance and reduce the cost of their AI models, whether
                    that be using a proprietary model behind an API or deploying
                    privately in a secure environment.
                  </p>
                </div>
                <div className="flex justify-end gap-8 mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                  <div className="flex-none pt-32 ml-auto space-y-8 w-44 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                    <div className="relative">
                      <div
                        // src='https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80'
                        // alt=''
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover  wavyslow shadow-3xinner opacity-50 dark:opacity-10"
                      />
                      <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36">
                    <div className="relative">
                      <div
                        src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover  wavyslow shadow-3xinner opacity-50 dark:opacity-10"
                      />
                      <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="relative">
                      <div
                        src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover  wavyslow shadow-3xinner opacity-50 dark:opacity-10"
                      />
                      <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                  </div>
                  <div className="flex-none pt-32 space-y-8 w-44 sm:pt-0">
                    <div className="relative">
                      <div
                        src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover  wavyslow shadow-3xinner opacity-50 dark:opacity-10"
                      />
                      <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10 " />
                    </div>
                    <div className="relative">
                      <div
                        src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                        alt=""
                        className="aspect-[2/3] w-full rounded-xl  object-cover  wavyslow shadow-3xinner opacity-50 dark:opacity-10"
                      />
                      <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10 " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Expertise section */}
        <div className="px-6 mx-auto mt-32 max-w-7xl sm:mt-40 lg:px-8">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-200">
              Our Expertise
            </h2>
            <div className="flex flex-col mt-6 gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600 dark:text-gray-200">
                  Tromero was founded in 2022 by Sophia Kalanovska and Charles
                  Higgins, both PhD students at the UKRI Centre for Doctoral
                  Training in Safe and Trusted AI, between Kings College London
                  and Imperial College. The Tromero team is composed of experts
                  with backgrounds in machine learning, software development,
                  data science and optimization.
                </p>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                {' '}
                <div className="relative">
                  <img
                    src="/founders.jpg"
                    alt=""
                    className="aspect-[4/3] w-full rounded-xl bg-gray-900/5 object-cover "
                  />
                  <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* why section */}
        <div className="px-6 mx-auto mt-32 max-w-7xl sm:mt-40 lg:px-8">
          <div className="max-w-2xl mx-auto lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-200">
              Why Choose Us?
            </h2>
            {/* <p className='mt-6 text-lg leading-8 text-gray-600'>
              Lorem ipsum dolor sit amet consect adipisicing elit. Possimus
              magnam voluptatum cupiditate veritatis in accusamus quisquam.
            </p> */}
          </div>
          {/* <dl className='grid max-w-2xl grid-cols-1 mx-auto mt-16 text-base leading-7 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-2'>
            {values.map((value) => (
              <div key={value.name}>
                <dt className='font-semibold text-gray-900'>
                  {value.icon}
                  {value.name}
                </dt>
                <dd className='mt-1 text-gray-600'>{value.description}</dd>
              </div>
            ))}
          </dl> */}
          <dl className="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {values.map((value) => (
              <div key={value.name} className="relative pl-9">
                <dt className="inline text-xl font-semibold text-gray-800 dark:text-gray-200">
                  <value.icon
                    className="absolute w-5 h-5 text-indigo-500 left-1 top-1 "
                    aria-hidden="true"
                  />
                  {value.name}
                </dt>{' '}
                <div>
                  <dd className="inline text-gray-600 dark:text-gray-300">
                    {value.description}
                  </dd>
                </div>
              </div>
            ))}
          </dl>
        </div>

        {/* Logo cloud */}
        <div className="relative mt-32 isolate -z-10 sm:mt-48">
          <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg
              className="h-[40rem] w-[80rem] flex-none stroke-gray-200 dark:stroke-gray-900"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg
                x="50%"
                y="50%"
                className="overflow-visible fill-gray-50 dark:fill-gray-900"
              >
                <path
                  d="M-300 0h201v201h-201Z M300 200h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)"
              />
            </svg>
          </div>
          {/* <div className='px-6 mx-auto max-w-7xl lg:px-8'>
            <h2 className='text-lg font-semibold leading-8 text-center text-gray-900'>
              Trusted by the world’s most innovative teams
            </h2>
            <div className='grid items-center max-w-lg grid-cols-4 mx-auto mt-10 gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5'>
              <img
                className='object-contain w-full col-span-2 max-h-12 lg:col-span-1'
                src='https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg'
                alt='Transistor'
                width={158}
                height={48}
              />
              <img
                className='object-contain w-full col-span-2 max-h-12 lg:col-span-1'
                src='https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg'
                alt='Reform'
                width={158}
                height={48}
              />
              <img
                className='object-contain w-full col-span-2 max-h-12 lg:col-span-1'
                src='https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg'
                alt='Tuple'
                width={158}
                height={48}
              />
              <img
                className='object-contain w-full col-span-2 max-h-12 sm:col-start-2 lg:col-span-1'
                src='https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg'
                alt='SavvyCal'
                width={158}
                height={48}
              />
              <img
                className='object-contain w-full col-span-2 col-start-2 max-h-12 sm:col-start-auto lg:col-span-1'
                src='https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg'
                alt='Statamic'
                width={158}
                height={48}
              />
            </div>
          </div>*/}
        </div>

        {/* Content section */}
        <div className="px-6 mx-auto mt-32 max-w-7xl lg:px-8 xl:mt-32">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-200">
              Get Started
            </h2>
            <div className="flex flex-col mt-6 gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200">
                  Join our open platform for smarter AI today and experience the
                  power, flexibility, and reliability of our services. Let us
                  help you unlock the full potential of your AI needs. Contact
                  us now to request access.
                </p>
              </div>
              <div className="items-center lg:flex lg:flex-auto lg:justify-center">
                <Link
                  to="/tailor"
                  className="transition-transform duration-300 hover:scale-150"
                >
                  <svg
                    className="w-24 h-24 fill-indigo-600 dark:fill-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="48"
                    fill="indigo-600"
                    class="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Blog section */}
        <div className="px-6 mx-auto mt-32 max-w-7xl sm:mt-40 lg:px-8">
          <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-gray-200">
              Articles & Learning
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600"></p>
          </div>
          <div className="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 auto-rows-fr sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {articles
              .slice()
              .reverse()
              .slice(0, 3)
              .map((article) => (
                <article
                  key={article.id}
                  className="relative flex flex-col justify-end px-8 pb-8 overflow-hidden bg-gray-900 isolate rounded-2xl pt-80 sm:pt-48 lg:pt-80"
                >
                  <img
                    src={article.imageUrl}
                    alt=""
                    className="absolute inset-0 object-cover w-full h-full -z-10"
                  />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                  <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                  <div className="flex flex-wrap items-center overflow-hidden text-sm leading-6 text-gray-300 gap-y-1">
                    {/* <time dateTime={article.datetime} className='mr-8'>
                    {article.date}
                  </time> */}
                    <div className="flex items-center -ml-4 gap-x-4">
                      <svg
                        viewBox="0 0 2 2"
                        className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50"
                      >
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      {/* <div className="flex gap-x-2.5">
                        <img
                          src={article.authorImage}
                          alt=""
                          className="flex-none w-6 h-6 rounded-full bg-white/10"
                        />
                        {article.author}
                      </div> */}
                    </div>
                  </div>
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a href={`/articles/${article.id}`}>
                      <span className="absolute inset-0" />
                      {article.title}
                    </a>
                  </h3>
                </article>
              ))}
          </div>
        </div>
      </main>
      <div className="h-24"></div>
    </div>
  );
}
