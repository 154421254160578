import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const GenerateApiKeyModal = ({ isOpen, onClose, onGenerateKey }) => {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto "
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-zinc-900"
              >
                Generate API Key
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-zinc-500">
                  You don't have an API key. You will need one to use the
                  playground. Press continue and we will generate one for you.
                </p>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md bg-zinc-900 hover:bg-zinc-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-zinc-500"
                  onClick={onGenerateKey}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 ml-4 text-sm font-medium border border-transparent text-zinc-900 hover:text-zinc-700 focus:outline-none "
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateApiKeyModal;
