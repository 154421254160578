import { Disclosure } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

const FaqSection = ({ title, faqs }) => {
  return (
    <div className="mt-16 lg:mt-24">
      <div className="flex flex-col lg:flex-row lg:items-start">
        <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl lg:w-1/3 lg:mt-2">
          {title}
        </h3>
        <div className="lg:w-2/3">
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {faqs.map((faq, index) => (
              <FaqItem faq={faq} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const FaqItem = ({ faq }) => {
  return (
    <Disclosure as="div" className="pt-6">
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button className="flex items-start justify-between w-full text-left text-gray-900 dark:text-gray-200">
              <span className="text-lg font-semibold leading-7">
                {faq.question}
              </span>
              <span className="flex items-center ml-6 h-7">
                {open ? (
                  <MinusIcon className="w-6 h-6" aria-hidden="true" />
                ) : (
                  <PlusIcon className="w-6 h-6" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </dt>
          <Transition
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 -translate-y-1"
            enterTo="transform opacity-100 translate-y-0"
            leave="transition ease-in duration-100"
            leaveFrom="transform opacity-100 translate-y-0"
            leaveTo="transform opacity-0 -translate-y-1"
          >
            <Disclosure.Panel
              as="dd"
              className="pr-12 mt-2 text-gray-600 dark:text-gray-400"
            >
              {/* Render the answer as JSX */}
              {faq.answer}
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export { FaqSection, FaqItem };
