const ArticleDetailSkeleton = () => {
  return (
    <div className="min-h-screen bg-zinc-50 animate-pulse">
      <div className="container px-4 py-12 md:pt-36 mx-auto">
        <article className="max-w-4xl mx-auto">
          {/* Skeleton Image */}
          <div className="w-full h-64 bg-gray-200 rounded-lg mb-6" />

          {/* Skeleton Title */}
          <div className="h-8 bg-gray-200 rounded w-3/4 mb-6" />

          {/* Skeleton Meta Info */}
          <div className="flex items-center my-6">
            <div className="h-4 bg-gray-200 rounded w-32" />
          </div>

          {/* Skeleton Content */}
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 rounded w-full" />
            <div className="h-4 bg-gray-200 rounded w-5/6" />
            <div className="h-4 bg-gray-200 rounded w-4/6" />
            <div className="h-4 bg-gray-200 rounded w-full" />
          </div>

          {/* Skeleton Tabs */}
          <div className="mt-12">
            <div className="flex mb-12 gap-4">
              <div className="h-8 bg-gray-200 rounded w-24" />
              <div className="h-8 bg-gray-200 rounded w-24" />
            </div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-full" />
              <div className="h-4 bg-gray-200 rounded w-5/6" />
              <div className="h-4 bg-gray-200 rounded w-4/6" />
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};

export default ArticleDetailSkeleton;
