import moment from 'moment';
import { Dropdown } from 'flowbite-react';
import { useEffect, useState } from 'react';

import HoursElapsedForActiveInstance from '../Instances/HoursElapsedForActiveInstance';
import RunningCostForActiveInstance from '../Instances/RunningCostForActiveInstance';
import NoCryptoMiningTooltip from '../NoCryptoMiningTooltip';
import { useInstanceUtils } from '../../Hooks/useInstanceUtils';
import { getOsTypeDisplayName } from '../OsTemplateSelect/OsType';

const LiveInstancesListItem = ({
  instance,
  handleEndInstanceButton,
  handleOobaboogaButton,
  handleJupyterButton,
  handleSSHButton,
  handlePortMappingButton,
}) => {
  const [buttonsToShow, setButtonsToShow] = useState({
    jupyter: false,
    oobabooga: false,
    ssh: false,
    portMapping: false,
    end: true,
  });
  const { isEndButtonDisabled, getElapsedTime, instanceState } =
    useInstanceUtils(instance);
  const [osTemplate, setOsTemplate] = useState(
    instance.os_template.split(':')[1],
  );

  const buttons = [
    {
      name: 'ssh',
      label: 'Set Up SSH/IP',
      handlerFunction: handleSSHButton,
    },
    {
      name: 'jupyter',
      label: 'Set Up Jupyter Notebook',
      handlerFunction: handleJupyterButton,
    },
    {
      name: 'oobabooga',
      label: 'Connect to Playground',
      handlerFunction: handleOobaboogaButton,
    },
    {
      name: 'portMapping',
      label: 'Port Mapping',
      handlerFunction: handlePortMappingButton,
    },
    {
      name: 'end',
      label: isEndButtonDisabled ? (
        <>
          Initializing (<span className="font-mono">{getElapsedTime()}s</span>)
        </>
      ) : (
        'End Instance'
      ),
      handlerFunction: handleEndInstanceButton,
    },
  ];

  useEffect(() => {
    if (osTemplate === 'text_generation') {
      setButtonsToShow((prev) => ({ ...prev, oobabooga: true, ssh: true }));
    } else if (osTemplate === 'custom_image') {
      setButtonsToShow((prev) => ({ ...prev, ssh: true, portMapping: true }));
    } else {
      setButtonsToShow((prev) => ({ ...prev, jupyter: true, ssh: true }));
    }
  }, [osTemplate]);

  return (
    <>
      <tr className="border-b dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700">
        <td className="w-[12px] px-2 py-3 min-w-fit text-nowrap">
          <div
            className={`w-2 h-2 rounded-full ${instanceState.state !== 'ready' ? 'bg-amber-300' : 'bg-emerald-300'} animate-slow-ping`}
          ></div>
        </td>
        <td className="flex items-center w-1/12 gap-4 px-2 py-3 min-w-fit text-nowrap">
          <span>{instance.id}</span>
          <NoCryptoMiningTooltip providerId={instance.node.provider_id} />
        </td>
        <td className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
          {instance.gpu_count}x {instance.node.normalized_gpu_type}
        </td>
        <td className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
          {getOsTypeDisplayName(osTemplate)}
        </td>
        <td className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
          {moment(instance.created_at).format('YYYY-MM-DD')}
        </td>
        <td className="w-1/6 px-2 py-3 min-w-fit text-nowrap">
          <HoursElapsedForActiveInstance instance={instance} /> hours
        </td>
        <td className="w-1/12 px-2 py-3 min-w-fit text-nowrap">
          <RunningCostForActiveInstance instance={instance} />
        </td>
        <td className="w-1/6 px-2 py-3 pl-3 min-w-fit text-nowrap">
          <div className="md:hidden">
            <Dropdown
              className="font-spaceG"
              inline
              placement="top"
              renderTrigger={() => (
                <svg
                  className="w-6 h-4 px-1 text-gray-400 border rounded dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 3"
                >
                  <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                </svg>
              )}
            >
              {buttons.map((button) => {
                if (buttonsToShow[button.name]) {
                  return (
                    <div className="last:border-t-2" key={button.name}>
                      <Dropdown.Item
                        onClick={() => button.handlerFunction(instanceState)}
                        disabled={isEndButtonDisabled}
                        className="disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {button.label}
                      </Dropdown.Item>
                    </div>
                  );
                }
                return null;
              })}
            </Dropdown>
          </div>
          <div className="hidden space-x-2 md:flex">
            {buttons.map((button) => {
              const commonClasses = `px-2 py-1 text-sm shadow rounded text-gray-100 bg-gray-900 font-dmSans ${isEndButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-800'}`;
              const endButtonClasses = `px-2 py-1 text-sm shadow text-gray-900 border-2 border-gray-900 rounded font-dmSans ${isEndButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-200'}`;

              return (
                buttonsToShow[button.name] && (
                  <button
                    key={button.name}
                    onClick={() => button.handlerFunction(instanceState)}
                    disabled={isEndButtonDisabled}
                    className={
                      button.name === 'end' ? endButtonClasses : commonClasses
                    }
                  >
                    {button.label}
                  </button>
                )
              );
            })}
          </div>
        </td>
      </tr>
    </>
  );
};

export default LiveInstancesListItem;
