import { useEffect, useState } from 'react';

import { useInstanceUtils } from '../../Hooks/useInstanceUtils';

/**
 * HoursElapsedForActiveInstance
 * @component
 * @description Component that displays the hours elapsed for an active instance
 * @param {Object} props
 * @param {Object} props.instance - Instance object
 * @returns {JSX.Element}
 **/
const HoursElapsedForActiveInstance = ({ instance }) => {
  const { getElapsedTime } = useInstanceUtils(instance);
  const [elapsedHours, setElapsedHours] = useState('0.00');

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedHours((getElapsedTime() / 3600).toFixed(2));
    }, 1000);
    return () => clearInterval(interval);
  }, [getElapsedTime]);

  return elapsedHours;
};

export default HoursElapsedForActiveInstance;
