import clsx from 'clsx';
import { useRef, useEffect, useState } from 'react';

const VideoComponent = ({ name, className, isActive, fallback }) => {
  const videoRef = useRef(null);
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    if (!isActive && videoRef.current) {
      videoRef.current.pause();
    } else if (isActive && videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, [isActive]);

  const handleVideoError = () => {
    setVideoError(true);
  };

  return (
    <>
      {!videoError ? (
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={className}
        >
          <source
            src={`/videos/${name}`}
            type="video/mp4"
            onError={handleVideoError}
          />
          Your browser does not support the video tag.
        </video>
      ) : (
        <img
          alt={fallback[1]}
          src={fallback[0]}
          className={clsx(className, 'object-contain')}
        />
      )}
    </>
  );
};

export default VideoComponent;
