import axios from 'axios';

/**
 * @description - This function is used to apply the dark mode preference to the application.
 * @returns {void}
 */

export const applyDarkModePreference = () => {
  const currentTheme = localStorage.getItem('darkMode');
  if (currentTheme === 'true') {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};

// regular axios wrapper
export const normalAxios = axios.create({
  baseURL: import.meta.env.VITE_FRONTEND_SERVER_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * @description - This function is used to pad a number with a zero if it is less than 10.
 * @param {number} number - The number to be padded.
 * @returns {string} - The padded number.
 */
export const padStart = (number) => {
  return number < 10 ? `0${number}` : number;
};

/**
 * @description - This function is used to convert the size of a file to a string.
 * @param {number} size - The size of the file in bytes.
 * @returns {string} - The size of the file as a string, using the appropriate unit (B, KB, MB, GB).
 */
export const fileSizeAsString = (size) => {
  if (size < 1024) {
    return `${size} B`;
  } else if (size < 1024 * 1024 && size >= 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else if (size < 1024 * 1024 * 1024 && size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
};

/**
 * @description - This is the price per token in USD in Tailor. last update June 14, 2024
 */
export const tailor_price_per_token = 0.03 / 4 / 1000;

// updated function to calculate the price per token
/**
 * @description - This function is used to get the price per token based on the base model.
 * @param {string} baseModel - The base model
 * @returns {number} - The price in dollars per token.
 */
export const getPricePerToken = (baseModel) => {
  if (!baseModel || typeof baseModel !== 'string') {
    return 0;
  }
  const modelLowerCase = baseModel.toLowerCase();

  // x0b - x56b = 0.60 / 1_000_000
  // x56.1b - x176b = 1.44 / 1_000_000
  // 0b - 16b = 0.24 / 1_000_000
  // 16.1b - 80b = 1.08 / 1_000_000

  if (/x(\d+(\.\d+)?)b/.test(modelLowerCase)) {
    // Match 'x' followed by a number and 'b' anywhere in the string
    const size = parseFloat(modelLowerCase.match(/x(\d+(\.\d+)?)b/)[1]);

    if (size <= 56) {
      return 0.6 / 1_000_000;
    } else if (size <= 176) {
      return 1.44 / 1_000_000;
    } else {
      console.warn('Size exceeds 176b');
    }
  } else if (/(\d+(\.\d+)?)b/.test(modelLowerCase)) {
    // Match a number followed by 'b' anywhere in the string (without 'x')
    const size = parseFloat(modelLowerCase.match(/(\d+(\.\d+)?)b/)[1]);

    if (size <= 16) {
      return 0.24 / 1_000_000;
    } else if (size <= 80) {
      return 1.08 / 1_000_000;
    } else {
      console.warn('Size exceeds 80b');
    }
  }

  return 0;

  /*
  if (/mistral-7b/.test(modelLowerCase)) {
    return 0.24 / 1_000_000;
  } else if (/mixtral-8x7b/.test(modelLowerCase)) {
    return 0.6 / 1_000_000;
  } else if (/70b/.test(modelLowerCase)) {
    return 1.08 / 1_000_000;
  } else if (/8b/.test(modelLowerCase)) {
    return 0.24 / 1_000_000;
  } else {
    return 0;
  }
  */
};

/**
 * @description - function to convert the number of seconds to hours and minutes
 * @param {number} totalSeconds - the total number of seconds
 * @returns {string} - the number of days, hours and minutes
 **/
export const convertSecondsToDaysHoursAndMinutes = (totalSeconds) => {
  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''} `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }
  if (days === 0 && hours === 0 && minutes === 0) {
    result = '0 minutes';
  }

  return result.trim();
};

/**
 * @description - This function is used to get the initial display date.
 * @returns {object} - The initial display date.
 */
export function getInitialDisplayDate() {
  const currentMonth = new Date().getUTCMonth();
  const currentYear = new Date().getUTCFullYear();
  const previousMonth = currentMonth - 1 < 0 ? 11 : currentMonth - 1;
  const previousYear = currentMonth - 1 < 0 ? currentYear - 1 : currentYear;

  return {
    thisMonth: { month: currentMonth, year: currentYear },
    previousMonth: { month: previousMonth, year: previousYear },
  };
}
