import { useState, useCallback } from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { useUser } from '../../../UserContext';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import UndeployWarningModal from '../Deploy/UndeployWarningModal';
import DeployInstructions from '../Deploy/DeployInstructions';
import { useAtom, useSetAtom } from 'jotai';
import { modelStateAtom } from '../../../context/atoms';

const DeploySwitch = ({ model, className }) => {
  const { customAxios } = useUser();
  const queryClient = useQueryClient();
  const [showUndeployWarning, setShowUndeployWarning] = useState(false);
  const [showDeployInstructions, setShowDeployInstructions] = useState(false);
  const [modelToDeploy, setModelToDeploy] = useState(null);
  const setModelState = useSetAtom(modelStateAtom);

  // Query to poll model status
  const { data: currentModel } = useQuery({
    queryKey: ['model', model.model_id],
    queryFn: async () => {
      const response = await customAxios.get(
        `tailor/v1/models/${model.model_id}`,
      );
      setModelState((prev) => ({
        ...prev,
        [model.model_id]: response.data.message.state,
      }));
      return response.data.message;
    },
    enabled: ['deploying', 'undeploying'].includes(model.state),
    refetchInterval: (query) => {
      return ['deploying', 'undeploying'].includes(query?.state?.data?.state)
        ? 1500
        : false;
    },
    initialData: model,
  });

  const deployMutation = useMutation({
    mutationKey: ['deploy-model', model.model_id],
    mutationFn: async () => {
      const response = await customAxios.post('tailor/v1/deploy_model', {
        model_name: model.model_name,
      });
      return response.data;
    },
    onMutate: async () => {
      await queryClient.cancelQueries(['model', model.model_id]);
      const previousModel = queryClient.getQueryData(['model', model.model_id]);
      queryClient.setQueryData(['model', model.model_id], {
        ...previousModel,
        state: 'deploying',
      });
      // console.log('previousModel', previousModel);
      return { previousModel };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        ['model', model.model_id],
        context.previousModel,
      );

      if (import.meta.env.DEV) {
        console.error('Failed to deploy model:', error);
      }
      toast.error(`Failed to deploy model: ${error.message}`, {
        id: 'deploy-error',
      });
    },
    onSuccess: () => {
      toast.success('Model deployment initiated.', {
        id: 'deploy-success',
      });
      setShowDeployInstructions(true);
      queryClient.invalidateQueries(['model', model.model_id]);
    },
  });

  const undeployMutation = useMutation({
    mutationKey: ['undeploy-model', model.model_id],
    mutationFn: async () => {
      const response = await customAxios.post('tailor/v1/undeploy_model', {
        model_name: model.model_name,
      });
      return response.data;
    },
    onMutate: async () => {
      await queryClient.cancelQueries(['model', model.model_id]);
      const previousModel = queryClient.getQueryData(['model', model.model_id]);
      queryClient.setQueryData(['model', model.model_id], {
        ...previousModel,
        state: 'undeploying',
      });
      return { previousModel };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        ['model', model.model_id],
        context.previousModel,
      );
      if (import.meta.env.DEV) {
        console.error('Failed to undeploy model:', error);
      }
      toast.error('Failed to undeploy model, try again later.', {
        id: 'undeploy-error',
      });
    },
    onSuccess: () => {
      toast.success('Model undeployment initiated.', {
        id: 'undeploy-success',
      });
      queryClient.invalidateQueries(['model', model.model_id]);
    },
  });

  const handleCloseModals = useCallback(() => {
    setShowDeployInstructions(false);
    setShowUndeployWarning(false);
    setModelToDeploy(null);
  }, []);

  const toggleDeployStatus = useCallback(
    async (e) => {
      e.stopPropagation();
      setModelToDeploy(model);

      if (
        currentModel.state === 'deployed' ||
        currentModel.state === 'failed_undeploy'
      ) {
        setShowUndeployWarning(true);
      } else {
        deployMutation.mutate();
      }
    },
    [currentModel, deployMutation, model],
  );

  if (!model?.base_model_data?.available_for_inference) {
    return (
      <div className="text-xs text-zinc-800 max-w-56 overflow-hidden ">
        No base model deployed for this custom model, please download to use.
      </div>
    );
  }

  return (
    <>
      <Switch.Group as="div" className={clsx(className)}>
        <div className="flex justify-end">
          <Switch
            checked={
              currentModel.state === 'deployed' ||
              currentModel.state === 'failed_undeploy'
            }
            onClick={toggleDeployStatus}
            disabled={
              currentModel.state === 'deploying' ||
              currentModel.state === 'undeploying'
            }
            className={clsx(
              currentModel.state === 'deployed' ? 'bg-zinc-800' : 'bg-gray-400',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 disabled:cursor-default mb-1',
              currentModel.state === 'failed_undeploy'
                ? 'disabled:bg-indigo-300'
                : 'disabled:bg-gray-100',
            )}
          >
            <span
              aria-hidden="true"
              className={clsx(
                currentModel.state === 'deployed' ||
                  currentModel.state === 'failed_undeploy'
                  ? 'translate-x-5'
                  : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
        </div>
      </Switch.Group>

      <UndeployWarningModal
        showModal={showUndeployWarning}
        onClose={handleCloseModals}
        model={modelToDeploy}
        undeployMutation={undeployMutation}
      />
      <DeployInstructions
        showModal={showDeployInstructions}
        onClose={handleCloseModals}
        model={modelToDeploy}
      />
    </>
  );
};

export default DeploySwitch;
