import moment from 'moment';
/**
 * @module InstanceUtils
 * @description Utility functions for instances
 * @exports getRunningTime
 * @exports getImageSrcForRegion
 * @exports cleanGpuName
 * @exports formatEndTime
 * @exports calculateDuration
 * @requires moment
 * **/

/**
 * function to get the running time of an active instance.
 * @description This function calculates the running time of an instance by comparing the start time with the current time.
 * @param {string} startTime - The start time of the instance. in the format: "Mon, 05 Feb 2024 13:32:37 GMT"
 * @returns {string} - The running time of the instance in the format of "xx:yy" where xx is the number of hours and yy is the number of minutes.
 */
export const getRunningTime = (startTime) => {
  const start = new Date(startTime);
  const now = new Date();
  const diff = now.getTime() - start.getTime();
  const hours = Math.floor(diff / 3600000);
  const minutes = Math.floor((diff % 3600000) / 60000);
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
};

/**
 * function to get the image source for a region.
 * @description This function returns the image source for a region.
 * @param {string} region - The name of the region.
 * @returns {string} - The image source for the region.
 */
export const getImageSrcForRegion = (region) => {
  return regionToImageMap[region.toLowerCase()] || 'northAmerica.svg';
};

const regionToImageMap = {
  uk: 'europe.svg',
  'north america': 'northAmerica.svg',
  'south america': 'southAmerica.svg',
  africa: 'africa.svg',
  asia: 'asia.svg',
  'new zealand': 'oceania.svg',
  australia: 'oceania.svg',
  europe: 'europe.svg',
};

/**
 * function to clean the GPU name remove duplicate word NVIDIA.
 * @description This function removes the duplicate word 'NVIDIA' from the name of the GPU.
 * @param {string} gpuName - The name of the GPU.
 * @returns {string} - The cleaned name of the GPU.
 */
export const cleanGpuName = (gpuName) => {
  const keyword = 'NVIDIA';
  const parts = gpuName.split(' ');

  // Check if 'NVIDIA' appears more than once at the beginning
  if (parts.length > 1 && parts[0] === keyword && parts[1] === keyword) {
    return parts.slice(1).join(' '); // Remove the first occurrence
  }

  return gpuName; // Return the original name if no duplicate
};

/**
 * function to format the end time of an instance.
 * @description This function formats the end time of an instance to be displayed as "xx hours" or "yy minutes" or "zz seconds" from now.
 * @param {string} endTime - The end time of the instance. in the format: "Mon, 05 Feb 2024 13:32:37 GMT"
 * @returns {string} - The formatted end time of the instance.
 */
export const formatEndTime = (endTime) => {
  return endTime ? moment(endTime).fromNow() : '---';
};

/**
 * function to calculate the duration of an instance.
 * @description This function calculates the duration of an instance by comparing the start time with the end time.
 * @param {string} startTime - The start time of the instance. in the format: "Mon, 05 Feb 2024 13:32:37 GMT"
 * @param {string} endTime - The end time of the instance. in the format: "Mon, 05 Feb 2024 13:32:37 GMT"
 * @returns {string} - The duration of the instance in the format of "xx hours" or "yy minutes" or "zz seconds".
 */
export const calculateDuration = (startTime, endTime, justHours = false) => {
  if (!endTime) {
    return '---';
  }

  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));

  if (justHours) {
    return `${duration.asHours().toFixed(2)} hours`;
  }

  if (duration.asHours() >= 1) {
    return `${duration.asHours().toFixed(2)} hours`;
  } else if (duration.asMinutes() >= 1) {
    return `${Math.round(duration.asMinutes())} minutes`;
  } else {
    return `${Math.round(duration.asSeconds())} seconds`;
  }
};

// function to display elapsed time in the format of "nn days and xx hours " or "xx hours" or "yy minutes" or "zz seconds"
/**
 * function to display elapsed time in the format of  "nn days and xx hours " or "xx hours" or "yy minutes" or "zz seconds".
 * @description This function calculates the elapsed time by comparing the start time with the current time.
 * @param {string} startTime - The start time of the instance. in the format: "Mon, 05 Feb 2024 13:32:37 GMT"
 * @returns {string} - The elapsed time in the format of "xx hours" or "yy minutes" or "zz seconds".
 */
export const getElapsedTime = (startTime) => {
  const start = moment(startTime);
  const now = moment();
  const duration = moment.duration(now.diff(start));

  if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())} days and ${Math.floor(duration.asHours() % 24)} hours`;
  } else if (duration.asHours() >= 1) {
    return `${Math.floor(duration.asHours())} hours`;
  } else if (duration.asMinutes() >= 1) {
    return `${Math.floor(duration.asMinutes())} minutes`;
  } else {
    return `${Math.floor(duration.asSeconds())} seconds`;
  }
};
