import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../UserContext';

const getReadableStatusAndProgress = (status) => {
  switch (status) {
    case 'idle':
      return { name: 'Ready', progress: 5 };
    case 'evaluatingInitialModel':
      return { name: 'Evaluating Initial Model', progress: 10 };
    case 'initialModelEvaluationComplete':
      return { name: 'Initial Model Evaluated', progress: 20 };
    case 'checkingSyntheticDataRequirement':
      return { name: 'Checking Synthetic Data', progress: 30 };
    case 'syntheticDataReady':
      return { name: 'Synthetic Data Ready', progress: 40 };
    case 'trainingModel':
      return { name: 'Training Model', progress: 50 };
    case 'trainingComplete':
      return { name: 'Training Complete', progress: 70 };
    case 'evaluatingTrainedModel':
      return { name: 'Evaluating Trained Model', progress: 80 };
    case 'trainedModelEvaluationComplete':
      return { name: 'Trained Model Evaluated', progress: 90 };
    case 'failedToDeployNewModel':
      return { name: 'Failed to Deploy Model', progress: 95 };
    default:
      return { name: 'Unknown Status', progress: 0 };
  }
};

// Separate component for pipeline list
const PipelinesList = () => {
  const { customAxios } = useUser();
  const { data: pipelinesData } = useQuery({
    queryKey: ['pipelines'],
    queryFn: async () => {
      const response = await customAxios.get(
        '/tailor/v1/continuous-training-pipeline',
      );
      return response.data.pipelines;
    },
    suspense: true,
  });

  const pipelines = pipelinesData?.filter((pipeline) => pipeline.active);

  if (!pipelines?.length) {
    return (
      <Link
        to="/tailor/pipeline"
        type="button"
        className="relative block w-full p-12 text-center border border-dashed rounded-md hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <svg
          className="w-6 h-6 mx-auto text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 1v16M1 9h16"
          />
        </svg>
        <div className="block mt-2 text-sm font-semibold text-gray-900 dark:text-gray-200">
          Create a new pipeline
        </div>
      </Link>
    );
  }

  return (
    <div className="flex space-x-4 overflow-x-auto py-4 px-2">
      {pipelines.map((pipeline, index) => {
        const { name: readableStatus, progress } = getReadableStatusAndProgress(
          pipeline.state,
        );

        return (
          <Link
            key={index}
            to={{
              pathname: '/tailor/pipeline',
              state: { selectedPipeline: pipeline },
            }}
            className="w-64 p-4 border rounded-lg bg-zinc-50 flex-shrink-0 hover:border-zinc-800/50"
          >
            <div className="h-0 w-0 rounded-full "></div>
            <h3 className="text-base font-medium mb-2">{pipeline.job_name}</h3>
            <p className="text-sm text-zinc-600 mb-2">
              <span className="font-medium text-zinc-800">Status:</span>{' '}
              {readableStatus || 'Unknown'}
            </p>
            <div className="w-full bg-white shadow-inner rounded-full h-1.5 mb-4">
              <div
                className="wavyslow opacity-30 h-1.5 rounded-full"
                style={{ width: `${progress}%` }}
              />
            </div>
            <p className="text-sm text-zinc-600 mb-1">
              <span className="font-medium text-zinc-800">Logs Threshold:</span>{' '}
              {pipeline.training_triggers.number_of_logs}
            </p>
          </Link>
        );
      })}
    </div>
  );
};

// Loading fallback component
const PipelinesLoadingFallback = () => (
  <div className="animate-pulse flex space-x-4 overflow-x-auto py-4 px-2">
    {[1, 2].map((i) => (
      <div
        key={i}
        className="w-64 p-4 border rounded-lg bg-gray-100 flex-shrink-0"
      >
        <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-1/2 mb-2"></div>
        <div className="h-1.5 bg-gray-200 rounded w-full mb-4"></div>
        <div className="h-3 bg-gray-200 rounded w-2/3"></div>
      </div>
    ))}
  </div>
);

// Error boundary component
const PipelinesErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleError = (error) => {
      setHasError(true);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>Something went wrong loading the pipelines.</p>
        <button
          onClick={async () => {
            setHasError(false);
            await queryClient.resetQueries({ queryKey: ['pipelines'] });
          }}
          className="mt-2 px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
        >
          Try again
        </button>
      </div>
    );
  }

  return children;
};

// Main component
const ActivePipelines = () => {
  return (
    <PipelinesErrorBoundary>
      <Suspense fallback={<PipelinesLoadingFallback />}>
        <PipelinesList />
      </Suspense>
    </PipelinesErrorBoundary>
  );
};

export default ActivePipelines;
