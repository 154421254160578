import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../UserContext';
import { hasUserInLocalStorage } from '../utils/userUtils';

const ProtectedRouteAdmin = ({ children }) => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();

  useEffect(() => {
    const updateUserFromLocalStorage = async () => {
      const { hasUser, isAdmin } = await hasUserInLocalStorage(setUser);
      if (!hasUser || !isAdmin) {
        navigate('/404');
      }
    };
    // Initial update from local storage
    updateUserFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return user && user.is_admin ? children : null;
};

export default ProtectedRouteAdmin;
