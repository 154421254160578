import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { modelStateMessage } from '../Deploy/modelStateConstants';
import { useAtomValue } from 'jotai';
import { modelStateAtom } from '../../../context/atoms';

const MyModelCard = ({ model, basePath = '../my-models' }) => {
  const modelState = useAtomValue(modelStateAtom);

  // Extract the current state with fallback
  const currentState = (
    modelState[model.model_id] || model.state
  )?.toLowerCase();

  // State-based style configurations
  const stateStyles = {
    deployed: {
      svgPath:
        'M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z',
      svgColor: 'text-green-400',
      gradient: 'bg-gradient-to-bl from-transparent via-green-50 to-green-100',
    },
    dormant: {
      svgPath: 'M14.25 9v6m-4.5 0V9M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z',
      svgColor: 'text-orange-400',
      gradient:
        'bg-gradient-to-bl from-transparent via-orange-50 to-orange-100 border-orange-300',
    },
    training: {
      svgPath:
        'M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z',
      svgColor: 'text-blue-400',
      gradient:
        'bg-gradient-to-bl from-transparent via-blue-50 to-blue-100 border-blue-300',
    },
  };

  const currentStateStyle = stateStyles[currentState] || {
    svgPath: '',
    svgColor: 'text-red-400',
    gradient: 'bg-gray-50 border-gray-300',
  };

  return (
    <Link
      to={`${basePath}/${model.model_name}`}
      className="row-span-1 bg-white rounded-lg shadow-sm border border-zinc-200 p-4 relative overflow-hidden flex flex-col h-52 hover:border-zinc-800 hover:border-[1px]"
    >
      <h3 className="text-lg font-semibold text-zinc-800 text-wrap break-words">
        {model?.model_name}
      </h3>
      <div className="text-sm text-gray-700 mt-2 flex flex-row items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-4 text-gray-400"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3"
          />
        </svg>

        {model?.model_config?.base_model}
      </div>
      <div className="text-sm text-gray-700 mt-2 flex flex-row items-center gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className={`size-4 ${currentStateStyle.svgColor}`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d={currentStateStyle.svgPath}
          />
        </svg>
        {modelStateMessage[currentState]?.switchLabel}
      </div>
      <div className="flex-grow min-h-4"></div>
      <div className="flex justify-between mt-2">
        <div className="flex flex-col gap-1">
          <dt className="text-xs text-zinc-500">Created</dt>
          <dd className="text-xs font-medium text-zinc-900">
            {new Date(model?.created_at).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </dd>
        </div>

        {model?.deployment_records?.length > 0 && (
          <div className="flex flex-col gap-1 z-20 mr-14">
            <dt className="text-xs text-zinc-500">Last Deployed</dt>
            <dd className="text-xs font-medium text-zinc-900">
              {new Date(
                model.deployment_records[
                  model.deployment_records.length - 1
                ][0],
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </dd>
          </div>
        )}
      </div>

      <div
        className={clsx(
          'absolute p-32 text-xs font-semibold rounded-lg top-10 -right-48 shadow-inner',
          currentStateStyle.gradient,
        )}
      ></div>
    </Link>
  );
};

export default MyModelCard;
