const ErrorMessageInsideModal = ({ errorMessage }) => {
  return (
    <div className="w-full mb-4 leading-tight">
      {errorMessage && (
        <span className="text-sm font-normal text-red-600 absolutes dark:text-red-300">
          {errorMessage.includes('Error: User is delinquent')
            ? "We're sorry, you are unable to rent an instance due to overdue charges. Please clear your balance here to continue. If you believe this is an error, please get in contact with us."
            : errorMessage}
        </span>
      )}
    </div>
  );
};

export default ErrorMessageInsideModal;
