import { Link } from 'react-router-dom';
import {
  ExclamationTriangleIcon,
  ArrowLeftIcon,
} from '@heroicons/react/24/outline';

const ModelNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4rem)] bg-zinc-50">
      <div className="p-8 text-center">
        <ExclamationTriangleIcon className="w-16 h-16 mx-auto text-amber-500 mb-4" />

        <h1 className="text-2xl font-bold text-zinc-800 mb-2">
          Model Not Found
        </h1>

        <p className="text-zinc-600 mb-6 max-w-md">
          The model you're looking for might have been deleted or you may not
          have access to it.
        </p>

        <Link
          to="/tailor/my-models"
          className="inline-flex items-center px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-md shadow-sm hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <ArrowLeftIcon className="w-4 h-4 mr-2" />
          Back to Models
        </Link>
      </div>
    </div>
  );
};

export default ModelNotFound;
