import React from 'react';

import Pricing from '../Components/Pricing';

const PricingPage = () => {
  return (
    <div className="w-full min-h-screen bg-zinc-50">
      <div className="max-w-[1500px] mx-auto ">
        <Pricing />
      </div>
    </div>
  );
};

export default PricingPage;
