import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';

const ProviderColumnChart = ({ clusters }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '35%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: 'datetime',
      tickAmount: 'dataPoints', // To specify that each data point should correspond to a tick mark
      labels: {
        style: {
          colors: '#E5E7EB',
        },
        datetimeUTC: false, // Use this if your dates are in local time
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
        formatter: function (value, timestamp) {
          return moment(timestamp).format('DD MMM'); // Format each label as 'Day Month'
        },
      },
      // title: {
      //   text: 'Date',
      // },
      tickPlacement: 'on',
    },
    yaxis: {
      title: {
        text: 'Earnings ($)',
        style: {
          color: '#E5E7EB',
        },
      },
      labels: {
        style: {
          colors: '#E5E7EB',
        },
      },
    },
    fill: {
      opacity: 1,
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: ['#4F46E5'],
        stops: [0, 100],
      },
      colors: ['#111827'],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$' + val.toFixed(2);
        },
      },
    },
    title: {},
  });

  useEffect(() => {
    const earningsByDate = {};

    clusters
      .flatMap((cluster) => cluster.nodes.flatMap((node) => node.pods))
      .forEach((pod) => {
        const startTime = moment(pod.created_at);
        const endTime = pod.ended_at ? moment(pod.ended_at) : moment();
        let currentTime = startTime.clone().startOf('day');
        let endOfDay;

        while (currentTime.isBefore(endTime)) {
          endOfDay = currentTime.clone().endOf('day');
          const periodEndTime = moment.min(endOfDay, endTime);
          const duration = periodEndTime.diff(startTime, 'hours', true);
          const dailyCost =
            ((pod.total_cost * 0.85) / 100) *
            (duration / endTime.diff(startTime, 'hours', true));

          const dateKey = currentTime.format('YYYY-MM-DD');
          earningsByDate[dateKey] = (earningsByDate[dateKey] || 0) + dailyCost;

          // Prepare for next iteration
          startTime.add(duration, 'hours');
          currentTime.add(1, 'day').startOf('day');
        }
      });

    const chartData = Object.entries(earningsByDate)
      .map(([date, earnings]) => ({
        x: new Date(date).getTime(),
        y: parseFloat((earnings / 1000).toFixed(2)),
      }))
      .sort((a, b) => a.x - b.x); // Ensure data is sorted by date

    setSeries([{ name: 'Earnings', data: chartData }]);
  }, [clusters]);

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ProviderColumnChart;
