import React, { useState, Fragment, useEffect, useCallback } from 'react';
import { Dialog, Transition, Listbox } from '@headlessui/react';
import { ChevronUpDownIcon, CheckIcon } from '@heroicons/react/20/solid';
import { useUser } from '@/UserContext';
import toast from 'react-hot-toast';
import ValidationError from '@/Components/ValidationError';

const NewAliasModal = ({
  onClose,
  isOpen,
  aliasObject,
  setAliasList,
  aliasList,
}) => {
  const { user, customAxios } = useUser();
  const [selectedModel, setSelectedModel] = useState(null);
  const [alias, setAlias] = useState('');
  const [aliasStart, setAliasStart] = useState('');
  const [aliasDash, setAliasDash] = useState('');
  const [aliasSuffix, setAliasSuffix] = useState('');
  const [aliasError, setAliasError] = useState('');
  const [deployedModels, setDeployedModels] = useState([]);
  const [selectedModelName, setSelectedModelName] = useState('');

  const fetchDeployedModels = useCallback(async () => {
    let models = [];
    try {
      const response = await customAxios.get('tailor/v1/models');
      models = response?.data?.message;
    } catch (error) {
      console.error(error);
    }
    models = models.filter((model) => model.state === 'deployed');

    let baseModels = [];
    try {
      const response = await customAxios.get(
        'tailor/v1/base_models?finetuning=true',
      );
      baseModels = response?.data?.models;
    } catch (error) {
      console.error(error);
    }

    baseModels = baseModels.filter(
      (model) =>
        model?.available_for_inference &&
        (user.location_preference === 'default' ||
          model.supported_locations.includes(user.location_preference)),
    );

    const allModels = [
      // {
      //   id: 0,
      //   model_name: null,
      //   model_id: 0,
      // },
      ...models,
      ...baseModels,
    ];

    setDeployedModels(allModels);
  }, [user.location_preference, customAxios, aliasObject]);

  useEffect(() => {
    if (isOpen) {
      fetchDeployedModels();
    }
  }, [fetchDeployedModels, isOpen]);

  useEffect(() => {
    let alias = '';
    if (!aliasObject) {
      const randomLetter = String.fromCharCode(
        97 + Math.floor(Math.random() * 26),
      ).toUpperCase();
      const timestamp = Date.now().toString().slice(-5);
      alias = `alias_${randomLetter}_${timestamp}`;
    } else {
      alias = aliasObject.alias_name;
    }

    setAlias(alias);
    const match = alias.match(/^(.*_[A-Z]_\d+)(_?)(.*)$/);
    if (match) {
      const aliasStart = match[1];
      setAliasStart(aliasStart);

      const aliasDash = match[2];
      setAliasDash(aliasDash);

      const aliasSuffix = match[3];
      setAliasSuffix(aliasSuffix);
    }
  }, [aliasObject]);

  const handleAliasSuffixChange = (e) => {
    setAliasError('');

    // Allow the user to clear the suffix without any error
    if (e.target.value === '') {
      setAliasSuffix('');
      setAlias(aliasStart);
      return;
    }
    // verify it is only letters and numbers or underscore
    if (e.target.value && /^[a-zA-Z0-9_]+$/.test(e.target.value)) {
      const newSuffix = e.target.value;
      setAliasSuffix(newSuffix);
      setAlias(`${aliasStart}${newSuffix ? '_' : ''}${newSuffix}`);
    } else {
      setAliasError('Alias must be letters, numbers, or underscores');
    }
  };

  useEffect(() => {
    if (aliasObject) {
      if (aliasObject.model) {
        setSelectedModelName(aliasObject.model.model_name);
        setSelectedModel(aliasObject.model);
      } else if (aliasObject.base_model) {
        setSelectedModelName(aliasObject.base_model.model_name);
        setSelectedModel(aliasObject.base_model);
      } else {
        setSelectedModelName('');
        setSelectedModel(null);
      }
    } else {
      setSelectedModelName('');
      setSelectedModel(null);
    }
  }, [aliasObject]);

  useEffect(() => {
    if (selectedModel) {
      setSelectedModelName(selectedModel.model_name);
    } else {
      setSelectedModelName('');
    }
  }, [selectedModel]);

  const handleCreate = async (e) => {
    e.preventDefault();

    // Validation logic here...
    if (aliasError) {
      return;
    }

    const payload = {
      alias_name: alias,
      model_name: selectedModel ? selectedModel.model_name : null,
    };

    try {
      const response = await customAxios.post(
        'tailor/v1/aliases/create',
        payload,
      );

      toast.success('Alias created successfully');
      setAliasList((prev) => [...prev, response.data.alias]);
      onClose();
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to create alias');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    // Validation logic here...
    if (aliasError) {
      return;
    }
    const payload = {
      alias_name: aliasObject.alias_name,
      model_name: selectedModel ? selectedModel.model_name : null,
    };

    try {
      await customAxios.put(`/tailor/v1/aliases/set/model`, payload);
      if (aliasList) {
        let newAliasList = aliasList;
        newAliasList = newAliasList.map((a) =>
          a.alias_name === aliasObject.alias_name
            ? payload.model_name
              ? { ...a, model: { ...a.model, model_name: payload.model_name } }
              : { ...a, model: null, base_model: null }
            : a,
        );
        setAliasList(newAliasList);
      }
      toast.success('Model unlinked successfully');
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to unlink model');
    } finally {
      onClose();
    }
  };

  const closeModal = () => {
    setTimeout(() => {
      setSelectedModel(null);
      setSelectedModelName('');
      onClose();
    }, 500);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl font-dmSans">
                <Dialog.Title
                  as="h3"
                  className="mb-6 text-lg font-medium leading-6 text-zinc-500 "
                >
                  <span>
                    {aliasObject ? 'Update your' : 'Create a'} Model Alias
                  </span>
                </Dialog.Title>
                <form
                  onSubmit={aliasObject ? handleUpdate : handleCreate}
                  className="mt-2 space-y-2"
                >
                  <div className="pb-4">
                    <label
                      htmlFor="aliasSuffix"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      <span className="text-zinc-800 font-semibold">
                        Alias Name:{' '}
                      </span>
                      <span className="text-zinc-500">{alias}</span>
                    </label>
                    <div className="flex mt-1 h-10 shadow-md rounded-md">
                      <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                        {aliasStart}
                      </span>
                      <input
                        type="text"
                        id="aliasSuffix"
                        value={aliasSuffix}
                        onChange={handleAliasSuffixChange}
                        className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 placeholder:text-sm disabled:bg-gray-100"
                        placeholder="Enter suffix (optional)"
                        disabled={aliasObject}
                      />
                    </div>
                    <ValidationError error={aliasError} />
                  </div>
                  <div>
                    <Listbox value={selectedModel} onChange={setSelectedModel}>
                      <Listbox.Label className="block text-sm text-zinc-800 font-semibold">
                        Select Model (Optional)
                      </Listbox.Label>
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border border-gray-300 shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm h-10 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 ">
                          <span className="block truncate">
                            {selectedModelName || 'No model'}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10 max-h-32">
                            <Listbox.Option
                              key="no-model"
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active
                                    ? 'bg-indigo-100 text-indigo-900'
                                    : 'text-gray-900'
                                }`
                              }
                              value={null}
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    No model
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                            {deployedModels.map((model) => (
                              <Listbox.Option
                                key={model.model_id}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active
                                      ? 'bg-indigo-100 text-indigo-900'
                                      : 'text-gray-900'
                                  }`
                                }
                                value={model}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                    >
                                      {model?.model_name || '-'}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                  <div className="pt-8 flex justify-left gap-4">
                    <button
                      type="submit"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                    >
                      {aliasObject ? 'Update' : 'Create'} Alias
                    </button>
                    <button
                      type="button"
                      onClick={closeModal}
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900   rounded-md  focus:outline-none hover:underline underline-offset-2"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
                <p className="mt-4 text-sm text-gray-500">
                  Note: You can only select from currently deployed models.
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NewAliasModal;
