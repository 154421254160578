import { Link } from 'react-router-dom';

const EvaluationsCard = ({ isBaseModel }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm border border-zinc-200 p-4 h-full flex flex-col w-full">
      <h2 className="text-lg font-semibold text-zinc-800 mb-4">
        Model Evaluations
      </h2>
      <div className="space-y-3 w-full">
        {!isBaseModel && (
          <Link
            to="custom-evaluation"
            className="block w-full px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-md hover:bg-zinc-50 hover:border-zinc-400 transition-colors duration-200 text-center"
          >
            Custom Evaluation
          </Link>
        )}
        <Link
          to="benchmark-evaluation"
          className="block w-full px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-md hover:bg-zinc-50 hover:border-zinc-400 transition-colors duration-200 text-center"
        >
          Benchmark Evaluation
        </Link>
        {!isBaseModel && (
          <Link
            to="training-metrics"
            className="block w-full px-4 py-2 text-sm font-medium text-zinc-700 bg-white border border-zinc-300 rounded-lg hover:bg-zinc-50 hover:border-zinc-400 transition-colors duration-200 text-center"
          >
            Training Metrics
          </Link>
        )}
      </div>
    </div>
  );
};

export default EvaluationsCard;
