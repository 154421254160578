// import '../src/utils/instrument.js';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.jsx';
import './index.css';

import { UserProvider } from './UserContext';
import { UploadServiceProvider } from './context/UploadService.jsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // Data considered fresh for 5 minutes
      retry: false,
      refetchOnWindowFocus: true,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <UploadServiceProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={true} />
        </UploadServiceProvider>
      </UserProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
