import HeaderSkeleton from './HeaderSkeleton';

const ModelCardSkeleton = () => (
  <div className="bg-white rounded-lg shadow animate-pulse h-48">
    <div className="p-4 space-y-3">
      {/* Model name */}
      <div className="h-6 bg-zinc-200 rounded w-3/4"></div>
      {/* Model ID */}
      <div className="h-4 bg-zinc-200 rounded w-1/2"></div>
      {/* Stats */}
      <div className="pt-4 space-y-2">
        <div className="h-4 bg-zinc-200 rounded w-2/3"></div>
        <div className="h-4 bg-zinc-200 rounded w-1/2"></div>
      </div>
    </div>
  </div>
);

const TableRowSkeleton = () => (
  <tr className="animate-pulse">
    <td className="px-4 py-3">
      <div className="h-5 bg-zinc-200 rounded w-3/4"></div>
    </td>
    <td className="px-4 py-3">
      <div className="h-5 bg-zinc-200 rounded w-1/2"></div>
    </td>
    <td className="px-4 py-3">
      <div className="h-5 bg-zinc-200 rounded w-24"></div>
    </td>
    <td className="px-4 py-3">
      <div className="h-5 bg-zinc-200 rounded w-20"></div>
    </td>
    <td className="px-4 py-3">
      <div className="h-5 bg-zinc-200 rounded w-32"></div>
    </td>
  </tr>
);

const ModelsSkeleton = ({ viewMode = 'grid' }) => {
  return (
    <div className="flex flex-col min-h-screen bg-zinc-50 font-dmSans">
      {/* Header Skeleton */}
      <HeaderSkeleton />

      {/* Main Content Skeleton */}
      <main className="p-6">
        {/* Section Title Skeleton */}
        <div className="h-8 bg-zinc-200 rounded w-48 mb-4"></div>

        {viewMode === 'grid' ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {[...Array(8)].map((_, index) => (
              <ModelCardSkeleton key={index} />
            ))}
          </div>
        ) : (
          <div className="overflow-hidden bg-white shadow rounded-lg">
            <table className="min-w-full divide-y divide-zinc-200">
              <thead className="bg-zinc-50">
                <tr>
                  {['Name', 'ID', 'Status', 'Tokens', 'Last Updated'].map(
                    (header) => (
                      <th
                        key={header}
                        className="px-4 py-3 text-left text-sm font-medium text-zinc-500"
                      >
                        {header}
                      </th>
                    ),
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-zinc-200 bg-white">
                {[...Array(5)].map((_, index) => (
                  <TableRowSkeleton key={index} />
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Base Models Section Skeleton */}
        <div className="h-8 bg-zinc-200 rounded w-32 mt-8 mb-4"></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {[...Array(4)].map((_, index) => (
            <ModelCardSkeleton key={`base-${index}`} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default ModelsSkeleton;
