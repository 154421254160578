import React from 'react';

const FooterLogo = () => {
  return (
    <div className="flex w-64 ">
      {' '}
      <div className="text-center">
        <div className="flex items-center max-h-[100px] overflow-hidden max-w-[500px] ">
          <img
            src="/footerlogo2.png"
            className="object-cover mr-3 -mb-10 scale-125 "
            alt="Tromero Logo"
          />
        </div>
        <span className="text-base font-light text-zinc-800 sm:text-center font-dmSans">
          The Open Platform for{' '}
          <span className="text-transparent wavy bg-clip-text">Smarter</span> AI
        </span>
        <br />
        <span className="text-xs text-slate-950 sm:text-center dark:text-slate-400">
          © {new Date().getFullYear() + ' '}
          <a href="https://Tromero.ai" className="hover:underline">
            Tromero™️
          </a>{' '}
          - All Rights Reserved.
        </span>
      </div>
    </div>
  );
};

export default FooterLogo;
