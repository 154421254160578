import { useRouteError } from 'react-router-dom';

const ErrorElementComponent = () => {
  const error = useRouteError();

  return (
    <div className="flex items-center justify-center min-h-[50vh] p-4">
      <div className="text-center">
        <p className="text-red-500 mb-4">
          {error?.message || 'Something went wrong loading the models.'}
        </p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export default ErrorElementComponent;
