import LaunchFineTuneModal from '../Tailor/MyModels/LaunchFineTuneModal';
import { Suspense, useState } from 'react';
import UploadLogsButton from '../Tailor/Logs&Datasets/UploadLogsButton';
import NewCustomEvalModal from '../Tailor/ModelEvaluations/NewCustomEvalModal';

const FineTuneModalButton = () => {
  return (
    <div className="bg-zinc-900 text-white text-sm rounded-md px-3 py-2 mr-4 mt-2 h-10 w-full hover:bg-zinc-800 flex items-center justify-center whitespace-nowrap gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-5"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
        />
      </svg>
      Fine-Tune a Model
    </div>
  );
};

const QuickActionsSection = () => {
  const [isCustomEvalModalOpen, setIsCustomEvalModalOpen] = useState(false);

  return (
    <div className="space-y-3 flex flex-col lg:w-64 w-full h-fit">
      <Suspense fallback={<FineTuneModalButton />}>
        <LaunchFineTuneModal dashboard />
      </Suspense>
      <UploadLogsButton />
      <button
        id="create-custom-eval-button"
        onClick={() => setIsCustomEvalModalOpen(true)}
        type="button"
        className="h-10 px-2 mb-1 text-sm font-medium text-gray-100 bg-zinc-900 rounded-md sm:px-4 hover:bg-zinc-800 whitespace-nowrap flex items-center justify-center gap-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
          />
        </svg>
        <span>Create Custom Evaluation</span>
      </button>
      <NewCustomEvalModal
        isOpen={isCustomEvalModalOpen}
        onClose={() => setIsCustomEvalModalOpen(false)}
        onEvalAdded={() => {}}
      />
    </div>
  );
};

export default QuickActionsSection;
