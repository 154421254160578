const ExpiredRibbon = ({ expMonth, expYear }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based

  if (
    expYear < currentYear ||
    (expYear === currentYear && expMonth < currentMonth)
  ) {
    return (
      <div className="top-0 right-0 z-10 overflow-hidden ">
        <span
          className="block w-24 text-xs font-bold text-center text-white transform rotate-45 bg-red-500 shadow-md"
          style={{
            lineHeight: '30px',
            right: '-28px',
            top: '7px',
            position: 'absolute',
          }}
        >
          Expired
        </span>
      </div>
    );
  } else {
    return null;
  }
};

export default ExpiredRibbon;
