import { Modal } from 'flowbite-react';
import { useForm, ValidationError } from '@formspree/react';

import Spinner from '../Spinner';

export default function EmailModal({
  showModal,
  onClose,
  alternativeMessage,
  userEmail,
}) {
  const [state, handleSubmit] = useForm('mnqkwwbv');

  const message =
    alternativeMessage ||
    'Ready to become a provider? Want to send feedback about a beta feature? Need details about our Business plan? Let us know.';

  return (
    <div className="">
      <Modal
        show={showModal}
        size="lg"
        onClose={onClose}
        className="font-dmSans "
        popup
        dismissible
      >
        <div className="">
          <div className="m-0.5 bg-white dark:bg-gray-950 rounded-lg">
            <Modal.Header />
            <Modal.Body>
              <section className="bg-white dark:bg-gray-950">
                <div className="max-w-screen-md px-4 py-8 mx-auto lg:py-4">
                  {state.succeeded ? (
                    <div>
                      <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-center text-slate-900 dark:text-white">
                        Thank You!
                      </h2>
                      <p className="mb-8 text-center lg:mb-16 text-slate-500 dark:text-slate-400 sm:text-xl ">
                        Your message has been sent. We will get back to you
                        soon.
                      </p>
                    </div>
                  ) : (
                    <>
                      <h2 className="mb-4 text-xl font-extrabold tracking-tight text-center sm:text-4xl text-slate-900 dark:text-white">
                        Contact Us
                      </h2>
                      <p className="mb-8 text-sm lg:mb-16 sm:text-center text-slate-500 dark:text-gray-200 sm:text-lg ">
                        {message}
                      </p>
                      <form className="space-y-4" onSubmit={handleSubmit}>
                        {userEmail ? (
                          <input type="hidden" name="email" value={userEmail} />
                        ) : (
                          <>
                            <label
                              className="block mb-2 text-sm font-medium text-slate-900 dark:text-gray-200"
                              htmlFor="email"
                            >
                              Email Address
                            </label>
                            <input
                              className="shadow-sm bg-slate-50 border border-slate-300 text-slate-900 text-sm rounded-lg focus:ring-slate-500 focus:border-slate-500 block w-full p-2.5 dark:bg-gray-900 dark:border-slate-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500"
                              placeholder="name@email.com"
                              id="email"
                              type="email"
                              name="email"
                              required
                            />
                            <ValidationError
                              prefix="Email"
                              field="email"
                              errors={state.errors}
                            />
                          </>
                        )}
                        <label
                          className="block mb-2 text-sm font-medium text-slate-900 dark:text-gray-200"
                          htmlFor="message"
                        >
                          Message
                        </label>
                        <textarea
                          className="block p-2.5 w-full text-sm text-slate-900 bg-slate-50 rounded-lg shadow-sm border border-slate-300 focus:ring-slate-500 focus:border-slate-500 dark:bg-gray-900 dark:border-slate-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-slate-500 dark:focus:border-slate-500"
                          placeholder="Leave a message..."
                          id="message"
                          name="message"
                          required
                        />
                        <ValidationError
                          prefix="Message"
                          field="message"
                          errors={state.errors}
                        />
                        <button
                          className="flex items-center justify-center w-full h-10 text-sm font-medium text-center text-white rounded-md sm:w-24 bg-zinc-900 hover:bg-zinc-800 focus:ring-4 focus:outline-none focus:ring-slate-300 dark:bg-gray-950 dark:hover:bg-slate-700 dark:focus:ring-slate-800 dark:border dark:border-slate-600"
                          type="submit"
                          disabled={state.submitting}
                        >
                          {state.submitting ? <Spinner /> : 'Submit'}
                        </button>
                      </form>
                    </>
                  )}
                </div>
              </section>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </div>
  );
}
