import { useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';

const GroupedModelsList = ({ availableModels }) => {
  const groupedModelsMemo = useMemo(
    () => groupedModels(availableModels),
    [availableModels],
  );

  return (
    <div>
      {groupedModelsMemo.map((group) => (
        <div key={group.key} className="mb-4">
          <div className="px-4 font-semibold text-gray-900 ">
            <div className="flex flex-row items-end justify-start w-52">
              {group.image && (
                <img
                  src={group.image}
                  alt={`${group.family} logo`}
                  className="w-auto h-5 mr-2"
                />
              )}
              <div className="text-sm flex flex-row items-center w-full text-zinc-500">
                {group.family}
                <span className="text-black w-full mx-2">
                  <div className="w-full h-[1px] bg-zinc-300" />
                </span>
              </div>
            </div>
          </div>
          {group.models.map((model) => (
            <Listbox.Option
              key={model.key}
              value={model}
              disabled={
                !(
                  model.display_name === 'Llama 3.1 70B Instruct' ||
                  group.family === 'Others'
                )
              }
              className={({ active, disabled }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'
                } ${disabled ? 'text-zinc-300 cursor-not-allowed' : ''}`
              }
            >
              {({ selected, disabled }) =>
                disabled ? (
                  <Tooltip
                    style="light"
                    content="Contact us for access to this model."
                  >
                    <div>
                      <span
                        className={`block truncate ${
                          selected ? '' : 'font-normal'
                        }`}
                      >
                        {model?.display_name}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  // Render without Tooltip for enabled models
                  <div>
                    <span
                      className={`block truncate ${
                        selected ? '' : 'font-normal'
                      }`}
                    >
                      {model?.display_name}
                    </span>
                    {selected && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-indigo-600">
                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                      </span>
                    )}
                  </div>
                )
              }
            </Listbox.Option>
          ))}
        </div>
      ))}
    </div>
  );
};

const getImageUrlForFamily = (family) => {
  switch (family) {
    case 'Mistral':
    case 'Mixtral':
      return '/mistral.png';
    case 'LLaMA':
      return '/meta.png';
    case 'Gemma':
      return '/googleicon.png';
    case 'Phi':
      return '/microsoft.svg';
    case 'Databricks':
      return '/databricks.png';
    default:
      return '';
  }
};

// Helper function for grouping models
const groupedModels = (models) => {
  const families = {
    Mistral: [],
    Mixtral: [],
    LLaMA: [],
    Gemma: [],
    Phi: [],
    Databricks: [],
    Others: [],
  };

  models.forEach((model) => {
    if (model.model_name.includes('mistral')) {
      families.Mistral.push({ ...model, key: model.model_id });
    } else if (model.model_name.includes('mixtral')) {
      families.Mixtral.push({ ...model, key: model.model_id });
    } else if (model.model_name.includes('llama')) {
      families.LLaMA.push({ ...model, key: model.model_id });
    } else if (model.model_name.includes('gemma')) {
      families.Gemma.push({ ...model, key: model.model_id });
    } else if (model.model_name.includes('phi')) {
      families.Phi.push({ ...model, key: model.model_id });
    } else if (model.model_name.includes('dbrx')) {
      families.Databricks.push({ ...model, key: model.model_id });
    } else {
      families.Others.push({ ...model, key: model.model_id });
    }
  });

  // Sorting models within each family
  Object.keys(families).forEach((family) => {
    families[family].sort((a, b) => {
      const aTime = a.training_ended_at_unix || 0;
      const bTime = b.training_ended_at_unix || 0;
      return bTime - aTime; // Descending order (newest first)
    });
  });

  // Sort 'Others' family alphabetically by display name
  families.Others.sort((a, b) => a.display_name.localeCompare(b.display_name));

  return Object.entries(families)
    .filter(([_, models]) => models.length > 0)
    .map(([family, models], index) => ({
      key: `${family}-${index}`,
      family,
      models,
      image: getImageUrlForFamily(family),
    }));
};

export default GroupedModelsList;
