import { useState, useEffect, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { QueryErrorResetBoundary, useQueryClient } from '@tanstack/react-query';
import { useGetCustomEvals, useGetModels } from '../../Hooks/react-query';
import Breadcrumbs from './MyModels/Breadcrumbs';
import React, { useCallback, useMemo, useRef, Fragment } from 'react';
import { Listbox, Transition, Popover } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { useUser } from '../../UserContext';
// import LogsUpload from './FineTunning/LogsUpload';
import useBaseModels from '../../Hooks/useBaseModels';
import GroupedModelsList from './Playground/GroupedModelsList';
// import Spinner from '../Spinner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NewCustomEvalModal from './ModelEvaluations/NewCustomEvalModal';
import { Note } from './ModelEvaluations/ModelEvaluations';
// import { QueryErrorResetBoundary, useQuery } from '@tanstack/react-query';
// import { ErrorBoundary } from 'react-error-boundary';
import CustomEvaluationsSkeleton from './MyModels/CustomEvaluationsSkeleton';
import MyModelsErrorBoundary from './MyModels/MyModelsErrorBoundary';

const TailorCustomEvaluation = () => {
  return (
    <MyModelsErrorBoundary>
      <Suspense fallback={<CustomEvaluationsSkeleton />}>
        <TailorCustomEvaluationContent />
      </Suspense>
    </MyModelsErrorBoundary>
  );
};

const TailorCustomEvaluationContent = () => {
  const [customEvalText, setCustomEvalText] = useState('');
  const [customEvalName, setCustomEvalName] = useState('');
  // const [customEvals, setCustomEvals] = useState([]);
  const [selectedEvals, setSelectedEvals] = useState([]);
  const [showInputForm, setShowInputForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [evalToDelete, setEvalToDelete] = useState(null);
  const [dataInput, setDataInput] = useState('all');
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [fileContent, setFileContent] = useState({
    name: '',
    content: null,
    numberOfLogs: 0,
  });
  const [saveLogsTagList, setSaveLogsTagList] = useState([]);
  const [validationMessages, setValidationMessages] = useState({
    tags: '',
    file: '',
    datasets: '',
  });
  const [currentModel, setCurrentModel] = useState(null);
  const [comparisonModel, setComparisonModel] = useState(null);

  const { customAxios, user } = useUser();
  const [userHasLogs, setUserHasLogs] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [models, setModels] = useState([]);
  const {
    getPlaygroundBaseModels,
    getFineTuningBaseModels,
    loading: baseModelsLoading,
    error: baseModelsError,
  } = useBaseModels();
  const [selectedBaseModel, setSelectedBaseModel] = useState(null);
  const [evalResults, setEvalResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [evalAdded, setEvalAdded] = useState(false);
  const errorShownRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { model_name } = useParams();
  const queryClient = useQueryClient();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modelNotFound, setModelNotFound] = useState(false);

  const {
    data: modelsData,
    isPending: modelsLoading,
    error: modelsError,
  } = useGetModels();

  if (modelsError) {
    throw modelsError;
  }

  useEffect(() => {
    if (!modelsLoading && modelsData) {
      const foundModel = modelsData.find((m) => m.model_name === model_name);
      if (foundModel) {
        setModel(foundModel);
        setCurrentModel(foundModel.model_name);
      } else {
        setModelNotFound(true);
      }
      setIsLoading(false);
    }
  }, [modelsData, modelsLoading, model_name]);

  const handleCreateNewEval = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEvalAdded = () => {
    setEvalAdded(!evalAdded);
  };

  const {
    data: customEvals,
    isPending: customEvalsLoading,
    error: customEvalsError,
  } = useGetCustomEvals();

  // useEffect(() => {
  //   const fetchCustomEvals = async () => {
  //     setLoading(true);
  //     try {
  //       // Call the API to get the evaluations
  //       const response = await customAxios.get(
  //         '/tailor/v1/custom_eval/get_eval',
  //       );
  //       setCustomEvals(response.data.data);
  //     } catch (error) {
  //       if (import.meta.env.DEV) {
  //         console.error('Error fetching custom evaluations:', error);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchCustomEvals();
  // }, [customAxios, evalAdded]);

  const handleInputChange = (e) => {
    setCustomEvalText(e.target.value);
  };

  const handleNameChange = (e) => {
    setCustomEvalName(e.target.value);
  };

  const handleSaveEval = async () => {
    if (customEvalName.trim() === '' || customEvalText.trim() === '') {
      toast.error('Please enter both a name and evaluation criteria.');
      return;
    }

    const payload = {
      name: customEvalName,
      prompt: customEvalText,
      all: dataInput === 'all',
    };

    // Include filters or dataset_id based on dataInput
    if (dataInput === 'tags') {
      payload.tags = selectedTags;
    } else if (dataInput === 'datasets') {
      payload.dataset_id = selectedDataset.id; // or use dataset_uuid if required
    }

    try {
      // Send new evaluation data to the server
      const response = await customAxios.post(
        '/tailor/v1/custom_eval/create_new_eval',
        payload,
      );

      setLoading(true);

      if (response.status === 200 || response.status === 201) {
        // Update the local state with the newly created evaluation
        const createdEval = response.data; // Assuming the created evaluation is returned in response
        // setCustomEvals([...customEvals, createdEval]);
        setLoading(false);
        toast.success('Evaluation saved successfully!');
        setEvalAdded(!evalAdded);
      } else {
        toast.error('Failed to save the evaluation. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error saving evaluation:', error);
      toast.error('An error occurred while saving the evaluation.');
      setLoading(false);
    }

    setShowInputForm(false); // Hide the input form after saving
  };

  const handleCancel = () => {
    setShowInputForm(false);
    setDataInput('all');
    setSelectedTags([]);
    setSelectedDataset(null);
    setFileContent({ name: '', content: null, numberOfLogs: 0 });
  };

  const handleEvalSelection = (evalItem) => {
    if (selectedEvals.includes(evalItem)) {
      setSelectedEvals(selectedEvals.filter((item) => item !== evalItem));
    } else {
      setSelectedEvals([evalItem]);
    }
  };

  const baseModels = useMemo(
    () => getFineTuningBaseModels(),
    [getFineTuningBaseModels],
  );

  useEffect(() => {
    if (
      !baseModelsLoading &&
      !baseModelsError &&
      baseModels.length > 0 &&
      !selectedBaseModel
    ) {
      setSelectedBaseModel(baseModels[0]);
    }
  }, [baseModelsLoading, baseModelsError, baseModels, selectedBaseModel]);

  const fetchTags = useCallback(async () => {
    try {
      const response = await customAxios.get('tailor/v1/tags');
      const tags =
        response?.data?.message
          ?.map((tag) => (tag === '[]' ? null : tag.replace(/[[\]{}"]+/g, '')))
          .filter(Boolean) || [];
      setTagList([...new Set(tags)]);

      const userHasLogs = !!response?.data?.total_logs;
      setUserHasLogs(userHasLogs);

      setDatasets(response?.data?.datasets || []);
    } catch (error) {
      if (!errorShownRef.current) {
        errorShownRef.current = true;
        console.error(error);
        toast.error('An error occurred fetching tags. Please try again later.');
        setTimeout(() => {
          errorShownRef.current = false;
        }, 3000);
      }
    }
  }, [customAxios, user?.confirmed]);

  useEffect(() => {
    if (userHasLogs) {
      setDataInput('all');
    } else {
      setDataInput('upload');
    }
  }, [userHasLogs]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  const toggleTags = (tag) => {
    setSelectedTags((prevSelected) =>
      prevSelected.includes(tag)
        ? prevSelected.filter((t) => t !== tag)
        : [...prevSelected, tag],
    );
  };

  const isTagSelected = (tag) => selectedTags?.includes(tag);

  const handleConfirmEvaluation = async () => {
    if (!currentModel || !comparisonModel) {
      toast.error('Please select both models for the evaluation.');
      return;
    }

    // Construct the data payload based on your selection
    const payload = {
      model_1_name: currentModel, // Current model
      model_2_name: comparisonModel.model_name, // Comparison model
      custom_eval_name: selectedEvals[0].name, // Your custom evaluation name
      prompt: selectedEvals[0].content, // Your custom evaluation content
    };

    // Include tags or dataset if selected
    if (selectedTags.length > 0) {
      payload.tags = selectedTags.join(','); // Joining tags as a string
    } else if (selectedDataset) {
      payload.dataset = selectedDataset.name; // Dataset name if selected
    }

    // Call API using customAxios
    try {
      const response = await customAxios.post(
        'tailor/v1/custom_eval/start',
        payload,
      );

      if (response.status === 200) {
        toast.success('Evaluation successfully started!');
      } else {
        toast.error('Failed to start evaluation. Please try again.');
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while starting the evaluation.');
    }

    // Reset comparison model after evaluation is initiated
    setComparisonModel(null);
  };

  useEffect(() => {
    const fetchModels = async () => {
      if (baseModelsLoading || baseModelsError) {
        return;
      }
      try {
        const baseModels = getPlaygroundBaseModels();
        let modifiedBaseModels = [];
        if (baseModels.length > 0) {
          modifiedBaseModels = baseModels.map((model) => {
            if (!model.model_name && model.display_name) {
              model.model_name = model.display_name;
            }
            return model;
          });
        }
        const response = await customAxios.get('tailor/v1/models');
        let models = response?.data?.message;
        models = models.filter(
          (model) => model.base_model_data.available_for_inference,
        );

        if (models.length) {
          const deployedModels = models
            .filter((model) => model.state === 'deployed')
            .map((model) => ({
              ...model,
              display_name: model.model_name,
            }));
          if (deployedModels.length === 0) {
            setModels(baseModels);
          } else {
            setModels([...deployedModels, ...modifiedBaseModels]);
          }
        } else {
          setModels(baseModels);
        }
      } catch (error) {
        if (import.meta.env.DEV) {
          console.error(error);
        }
      }
    };

    fetchModels(); // Call the async function inside useEffect
  }, [
    baseModelsLoading,
    baseModelsError,
    customAxios,
    getPlaygroundBaseModels,
  ]);

  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);
      try {
        const response = await customAxios.get(
          `tailor/v1/custom_eval/get_results?model_name=${currentModel}`,
        );

        if (response.status === 200 && response.data) {
          setEvalResults(response.data);
        } else {
          setError('Failed to load evaluation results.');
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    // Call fetchResults when the component mounts
    if (currentModel) {
      fetchResults();
    }
  }, [customAxios, currentModel]);

  // if (loading) return <div>Loading evaluation results...</div>;
  // if (error) return <div>{error}</div>;

  useEffect(() => {
    const models = queryClient.getQueryData(['models']);
    const foundModel = models?.find((m) => m.model_name === model_name);
    setModel(foundModel || null);
    setLoading(false);
  }, [model_name, queryClient]);

  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans">
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className=" mx-auto w-full px-4 sm:px-6 lg:px-8 overflow-x-hidden">
          <div
            className="flex items-center justify-between h-16 w-full"
            role="banner"
          >
            <Breadcrumbs model={model} />
            <div className="flex items-center gap-2 lg:gap-6" />
          </div>
        </div>
      </header>
      <div className="flex flex-col  bg-zinc-50 font-dmSans p-4 max-w-7xl mx-auto">
        {/* Grid Container */}
        <div className="grid  gap-8 ">
          {/* First Full-Width Two-Row Div */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg ">
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-medium text-gray-800 ">
                Custom Evaluations
              </h2>
              <p className=" text-lg">
                {model?.model_name}{' '}
                <span className="text-sm text-gray-500">
                  {' '}
                  ({model?.base_model_data?.display_name})
                </span>
              </p>
            </div>
            <div className="sm:flex-auto mt-12 ">
              <h1 className="md:text-lg font-semibold">Evaluation Results</h1>
              <p className="mt-2 text-gray-700 text-sm md:text-base">
                Your recorded evaluation results from custom evaluations. This
                is a comparison between your model and a baseline which you've
                selected.
              </p>

              {loading ? (
                <div className="text-center py-6 text-gray-700">
                  Loading evaluation results...
                </div>
              ) : error ? (
                <div className="text-center py-6 text-red-500">{error}</div>
              ) : evalResults && evalResults.data.length === 0 ? (
                <div className="text-center text-zinc-500 text-sm  flex items-center justify-center h-48">
                  <div className="border border-dashed p-6">
                    You don't have any custom evaluation results yet. Run a test
                    to see your score.
                  </div>
                </div>
              ) : evalResults && evalResults.data ? (
                <div className="overflow-x-auto mt-4 space-y-8">
                  {Object.entries(
                    evalResults.data.reduce((acc, result) => {
                      const testName = result.custom_eval_settings.name;
                      if (!acc[testName]) {
                        acc[testName] = [];
                      }
                      acc[testName].push(result);
                      return acc;
                    }, {}),
                  ).map(([testName, testResults]) => (
                    <div
                      key={testName}
                      className="w-full my-4 border shadow bg-white overflow-x-auto text-sm rounded"
                    >
                      <table className="min-w-full divide-y divide-gray-200 rounded">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              colSpan="2"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-2"
                            >
                              Test Name
                            </th>
                            <th
                              colSpan="5"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider col-span-4"
                            >
                              Test Content
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colSpan="2"
                              className="px-6 py-4 text-base font-semibold text-gray-800"
                            >
                              {testName}
                            </td>
                            <td
                              colSpan="5"
                              className="px-6 py-4 italic text-gray-600 "
                            >
                              {testResults[0].custom_eval_settings.prompt}
                            </td>
                          </tr>
                          <tr className="bg-gray-50">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Date
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Evaluated Model
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Comparison Model
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Improvement %
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Win
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Draw
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Loss
                            </th>
                          </tr>

                          {testResults.map((result) => {
                            const improvementPercentage = (
                              ((result.model_1_score - result.model_2_score) /
                                result.data_length) *
                              100
                            ).toFixed(2);

                            return (
                              <tr key={result.uuid}>
                                <td className="px-6 py-4 whitespace-nowrap ">
                                  {moment(result.created_at).format(
                                    'DD/MM/YYYY',
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap font-medium">
                                  {result.model_1.model_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap font-medium">
                                  {result.model_2.model_name}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {improvementPercentage}%
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {result.model_1_score} / {result.data_length}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {result.data_length -
                                    result.model_1_score -
                                    result.model_2_score}{' '}
                                  / {result.data_length}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  {result.model_2_score} / {result.data_length}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-6 text-gray-700">
                  No results available.
                </div>
              )}
            </div>
          </div>

          {/* Second Full-Width One-Row Div */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg h-full flex-grow">
            <div className="w-full flex justify-between">
              <h2 className="text-xl font-medium text-gray-800 mb-2">
                Your Custom Evaluations
              </h2>
              <NewCustomEvalModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onEvalAdded={handleEvalAdded}
              />
              <div className="">
                <button
                  id="create-custom-eval-button"
                  onClick={handleCreateNewEval}
                  type="button"
                  className="block rounded-md bg-zinc-800 sm:px-3 sm:py-2 w-7 h-7 sm:w-auto sm:h-auto text-center text-sm font-semibold text-white shadow-sm hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-800 md:after:content-['Create_Custom_Evaluation']
                  after:content-['+']
                    "
                ></button>
              </div>
            </div>
            {customEvals.length === 0 ? (
              <div className="text-gray-500 mt-8">
                You have no custom evaluations.{' '}
                <button onClick={handleCreateNewEval} className="underline">
                  Create one here.
                </button>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-8 auto-rows-fr grid-flow-row">
                  {customEvals.map((evalItem, index) => (
                    <button
                      key={index}
                      className={clsx(
                        'p-4 bg-white shadow-sm border border-zinc-300 rounded-lg cursor-pointer active:bg-indigo-100 relative flex flex-col space-y-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-50 disabled:shadow-none hover:border-zinc-800',
                        selectedEvals.includes(evalItem) &&
                          'bg-indigo-50 shadow-md',
                      )}
                      onClick={() => handleEvalSelection(evalItem)}
                    >
                      {/* Absolute positioned check icon */}
                      <div className="absolute top-0.5 right-2 text-zinc-600">
                        {selectedEvals.includes(evalItem) && (
                          <CheckIcon className="h-5 w-5 text-zinc-800 stroke-width-2" />
                        )}
                      </div>

                      {/* Top content */}
                      <div>
                        <div className="text-gray-700 font-semibold w-fit">
                          {evalItem.name}
                        </div>
                        <div className="text-gray-700 mb-2 text-left overflow-hidden overflow-ellipsis line-clamp-3 text-sm">
                          {evalItem.prompt}
                        </div>
                      </div>

                      {/* Bottom data section */}
                      <div className="text-gray-700 text-xs text-left overflow-ellipsis line-clamp-2">
                        {evalItem?.dataset_name ? (
                          <div>
                            <span className="font-semibold">Dataset: </span>
                            {evalItem.dataset_name}
                          </div>
                        ) : evalItem?.filters?.tags?.length > 0 ? (
                          <div>
                            <span className="font-semibold">Tags: </span>
                            {evalItem.filters.tags.join(', ')}
                          </div>
                        ) : evalItem?.all ? (
                          <div>
                            <span className="font-semibold">Data: </span>All
                            logs
                          </div>
                        ) : null}
                      </div>
                    </button>
                  ))}
                </div>

                {customEvals.length > 0 && (
                  <div className="mt-8">
                    {/* <button
                onClick={handleRunEvaluation}
                disabled={selectedEvals.length === 0}
                className={clsx(
                  'px-4 py-2 rounded-md text-zinc-900',
                  selectedEvals.length > 0
                    ? 'bg-indigo-200 hover:bg-indigo-100'
                    : 'opacity-50 bg-indigo-200 cursor-not-allowed',
                )}
              >
                Run Evaluation
                </button> */}
                    <Popover className="relative inline-block text-left ">
                      {({ open, close }) => (
                        <>
                          <Popover.Button
                            disabled={
                              selectedEvals.length === 0 ||
                              (model.state !== 'deployed' &&
                                model.state !== 'failed_undeploy')
                            }
                            className={clsx(
                              'px-4 py-2 rounded-md text-zinc-900',
                              selectedEvals.length > 0 &&
                                (model.state === 'deployed' ||
                                  model.state === 'failed_undeploy')
                                ? 'bg-indigo-200 hover:bg-indigo-100'
                                : 'opacity-50 bg-indigo-200 cursor-not-allowed',
                            )}
                          >
                            Run Evaluation
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            show={open}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                          >
                            <Popover.Panel className="absolute left-full bottom-full z-10 mt-2 w-96  bg-white/20 backdrop-blur-2xl rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                              <div className="p-4">
                                <div className="mb-6">
                                  <p className="text-sm font-medium text-zinc-800">
                                    Current Model
                                  </p>
                                  <p className="text-sm text-gray-500 ">
                                    {currentModel}
                                  </p>
                                </div>
                                <div className="mb-6">
                                  <p className="text-sm font-medium text-zinc-800 mb-2">
                                    Select Model to Compare
                                  </p>
                                  <Listbox
                                    value={comparisonModel}
                                    onChange={setComparisonModel}
                                  >
                                    {({ open }) => (
                                      <>
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left border border-gray-300 focus:outline-none sm:text-sm">
                                          <span className="block truncate">
                                            {comparisonModel
                                              ? comparisonModel.display_name
                                              : 'Select a model'}
                                          </span>
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <ChevronUpDownIcon
                                              className="w-5 h-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0"
                                        >
                                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white h-auto rounded-md py-4 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {/* Render the GroupedModelsList once and pass the necessary props */}
                                            <GroupedModelsList
                                              availableModels={models.filter(
                                                (model) =>
                                                  (model.state === 'deployed' &&
                                                    model.model_name !==
                                                      currentModel) ||
                                                  !model.base_model_data,
                                              )}
                                              setComparisonModel={
                                                setComparisonModel
                                              }
                                            />
                                          </Listbox.Options>
                                        </Transition>
                                      </>
                                    )}
                                  </Listbox>
                                </div>
                                <div className="flex justify-end space-x-2">
                                  <button
                                    onClick={() => {
                                      setComparisonModel(null);
                                      close();
                                    }}
                                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleConfirmEvaluation();
                                      close();
                                    }}
                                    disabled={!currentModel || !comparisonModel}
                                    className={clsx(
                                      'px-4 py-2 rounded-md',
                                      currentModel && comparisonModel
                                        ? 'bg-indigo-200 text-zinc-900 hover:bg-indigo-100'
                                        : 'opacity-50 bg-indigo-200 cursor-not-allowed',
                                    )}
                                  >
                                    Confirm and Run
                                  </button>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                  </div>
                )}
                {model?.state !== 'deployed' &&
                  model?.state !== 'failed_undeploy' && (
                    <Note>
                      Please make sure to{' '}
                      <Link
                        to={`../${model?.model_name}`}
                        className="font-semibold underline underline-offset-4"
                      >
                        deploy
                      </Link>
                      {` `}your model before starting the evaluations.
                    </Note>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailorCustomEvaluation;
