/**
 * MultiGpuPage.js
 * A page component to display information about the Multi-GPU Ready OS Template.
 */
const MultiGpuPage = () => {
  return (
    <div className="min-h-screen p-4 mx-auto mt-10 bg-white dark:bg-gray-800 max-w-7xl">
      <h1 className="text-3xl font-bold dark:text-white font-spaceG">
        Multi-GPU Ready OS Template
      </h1>
      <p className="mt-4 dark:text-gray-200">
        Tromero's multi-GPU ready OS template is the easiest way to get started
        with expanding your AI training from single to multiple GPUs.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Skip all the hassle
      </h2>
      <p className="dark:text-gray-200">
        Don't waste time, energy, and money setting up your multi-GPU
        environment, installing and configuring libraries like huggingface's
        <a
          href="https://huggingface.co/docs/accelerate/index"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          Accelerate{' '}
        </a>{' '}
        or PyTorch's
        <a
          href="https://pytorch.org/tutorials/beginner/dist_overview.html"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          torch.distributed{' '}
        </a>{' '}
        can take a long time, a lot of reading through documentation, and a lot
        of painful trial and error getting it right for your exact system. With
        our multi-GPU ready OS template, you can get started straight away with
        a pre-configured environment with all these tools (and more) already set
        up and ready to go.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Splitting models across GPUs
      </h2>
      <p className="dark:text-gray-200">
        Large models like LLMs won't fit onto a single GPU, so to spread out the
        workload over multiple GPUs, we use
        <a
          href="https://pytorch.org/docs/stable/fsdp.html"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          Fully Sharded Data Parallel (FSDP){' '}
        </a>{' '}
        to split the model into shards and distribute them across the GPUs. This
        is a complex process that requires a lot of careful configuration and
        tuning to get right, but with our multi-GPU ready OS template, you can
        get started straight away with a pre-configured environment that has
        FSDP already set up and ready to go.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Replicating models across GPUs
      </h2>
      <p className="dark:text-gray-200">
        For smaller models that fit onto one GPU, but can take a long time to
        train or evaluate, we can replicate the model across multiple GPUs to
        speed up the process. Using
        <a
          href="https://pytorch.org/docs/stable/generated/torch.nn.parallel.DistributedDataParallel.html"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          Distributed Data Parallel (DDP){' '}
        </a>
        , we can replicate the model across multiple GPUs and run them all in
        parallel, speeding up the process of iterating and developing your
        models.
      </p>

      <h2 className="mt-8 mb-2 text-2xl font-semibold dark:text-white font-spaceG">
        Get started straight away
      </h2>
      <p className="mb-8 dark:text-gray-200">
        Even with an environment setup for you, it can be difficult to get
        started with experimenting if you're not familiar with the tools and
        libraries. That's why we've included a number of examples to get you
        started. From finetuning your own
        <a
          href="https://huggingface.co/mistralai/Mistral-7B-Instruct-v0.2"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          Mistral-7B{' '}
        </a>{' '}
        on a custom dataset, to classifying many images in parallel with
        <a
          href="https://huggingface.co/google/vit-base-patch16-224"
          className="text-indigo-500 hover:text-indigo-700 dark:hover:text-indigo-400"
        >
          {' '}
          google-ViT
        </a>
        , our multi-GPU ready template contains code examples to test out and
        build upon, helping you to get started straight away.
      </p>
    </div>
  );
};

export default MultiGpuPage;
